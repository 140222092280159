import React from "react";

import DownloadMoca from "../download-moca/DownloadMoca";
import ModalWrap from "../modal-wrap/ModalWrap";
import logoImg from "../../assets/images/icon-app.png";

import "./ModalDownload.scss";

interface ModalDownloadProps {
  closeModal: () => void;
}

const ModalDownload: React.FC<ModalDownloadProps> = ({ closeModal }) => {
  return (
    <ModalWrap closeModal={closeModal}>
      <div className="modal_download">
        <div className="modal_download__image">
          <img src={logoImg} alt="Logo" />
        </div>
        <p className="download_moca__text">Download my moca app:</p>
        <DownloadMoca />
      </div>
    </ModalWrap>
  );
};
export default ModalDownload;
