import {
  AuthorizedUserAction,
  AuthorizedUserActionTypes,
  AuthorizedUserState,
} from "../types/authorized-user";

const initialState: AuthorizedUserState = {
  me: null,
};

const authorizedUserReducer = (
  state = initialState,
  action: AuthorizedUserAction,
): AuthorizedUserState => {
  switch (action.type) {
    case AuthorizedUserActionTypes.SET_AUTHORIZED_USER:
      return { ...state, me: action.payload };
    default:
      return { ...state };
  }
};
export default authorizedUserReducer;
