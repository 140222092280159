import React from "react";

interface NotificationImageProps {
  imageSrc: string;
  alt: string;
}

const NotificationImage: React.FC<NotificationImageProps> = ({ imageSrc, alt }): JSX.Element => {
  return (
    <div className="notification_image">
      <img src={imageSrc} alt={alt} />
    </div>
  );
};

export default NotificationImage;
