import { initialLocationSortValue } from "../../components/location-filter-select/utils";
import { durationFilters, DurationFilters } from "../../explore-pages/shows-page/utils";
import {
  IShowPageInitialState,
  ShowsPageActions,
  ShowsPageActionTypes,
} from "../types/showsPageTypes";

const initialDurationSortValue =
  durationFilters.find(item => item.name === DurationFilters.ANYTIME) || durationFilters[5];

export const showsPageInitialState: IShowPageInitialState = {
  shows: [],
  durationSortValue: initialDurationSortValue,
  filterQueryParams: {},
  calendarValue: "",
  offset: 0,
  locationSortValue: initialLocationSortValue,
  locationAutocompleteValue: null,
};

export const showsPageReducer = (
  state = showsPageInitialState,
  action: ShowsPageActions,
): IShowPageInitialState => {
  switch (action.type) {
    case ShowsPageActionTypes.SET_SHOWS:
      return {
        ...state,
        shows: [...action.payload],
      };
    case ShowsPageActionTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case ShowsPageActionTypes.SET_DURATION_SORT_VALUE:
      return {
        ...state,
        durationSortValue: action.payload,
      };
    case ShowsPageActionTypes.SET_FILTER_QUERY_PARAMS:
      return {
        ...state,
        filterQueryParams: action.payload,
      };
    case ShowsPageActionTypes.SET_CALENDAR_VALUE:
      return {
        ...state,
        calendarValue: action.payload,
      };
    case ShowsPageActionTypes.SET_LOCATION_SORT_VALUE:
      return {
        ...state,
        locationSortValue: action.payload,
      };
    case ShowsPageActionTypes.SET_LOCATION_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        locationAutocompleteValue: action.payload,
      };
    default:
      return state;
  }
};
