import { LOCATION } from "./LocationRequestParams";
import { IMAGE } from "./ImageRequestParams";
import { ARTWORK } from "./ArtworkRequestParams";

export const USER_PROFILE = `
firstName
middleName
secondName
address {
  city
  location {
    ${LOCATION}
  }
}
image {
  ${IMAGE}
}
bannerImage {
  ${IMAGE}
}`;

export const FULL_USER = `
_id
email
profile {
  firstName
  middleName
  secondName
  phoneNumber
  sex
  address {
    location {
      ${LOCATION}
    }
    city
    postalCode
  }
  bio {
    bornCity
    country
    schools
    teachers
    awards
    heritage
    culture
    ethnic
    spiritualInspirations
    tribalIdentities
    other
    story
  }
  image {
    ${IMAGE}
  }
  bannerImage {
    ${IMAGE}
  }
  address {
    city
  }
}
hasInstitution
institutions {
  _id
  name
  description
  kategory {
    _id
    name
    description
  }
  isVirtual
  availableForFilmAndTV
  availableForEvents
  imagesOfInstitution {
      _id
      medium{
        url
      }
  }
  owner {
      _id
      profile {
          firstName
          secondName
          phoneNumber
      }
  }
  exhibitions {
      _id
      title
      description
      private
      isSolo
      isOpenToSubmitions
      location {
          name
      }
      duration {
        from
        to
      }
      daysOpen {
        dayOfWeek
        hoursOpen {
          from
          to
        }
      }
  }
}
favorites {
  artwork {
    _id
    image {
      ${IMAGE}
    }
  }
}
myFollowers
myFollowings
myArtworksLikes
likedArtworks
followings {
  followed {
    _id
  }
}
myPastCollectedArtworks{
  _id
  image {
    ${IMAGE}
  }
}
collectionRequests{
  ${ARTWORK}
}
suggestedArtworks {
  ${ARTWORK}
}`;

export const FETCH_BLOCKED_USERS = `
  query blockedUsers($limit: Int, $offset: Int, $userId: ID!) {
    blockedUsers(limit: $limit, offset: $offset, userId: $userId) {
      blockers {
        ${FULL_USER}
      }
      meta {
        limit
        offset
      }
    }
  }
`;
