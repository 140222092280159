import { CommentsActionType, CommentsState, CommentType } from "../types/comments";

const initialValue: CommentsState = {
  comments: [],
  replyComments: [],
};

const commentsReducer = (state = initialValue, action: CommentType): CommentsState => {
  switch (action.type) {
    case CommentsActionType.COMMENTS:
      return { ...state, comments: [...action.payload] };
    case CommentsActionType.ADD_COMMENT:
      return { ...state, comments: [...state.comments, action.payload] };
    case CommentsActionType.REMOVE_COMMENT:
      return { ...state, comments: state.comments.filter(el => el._id !== action.id) };
    case CommentsActionType.COMMENTS_REPLY:
      return { ...state, replyComments: [...action.payload] };
    case CommentsActionType.ADD_COMMENT_REPLY:
      return { ...state, replyComments: [...state.comments, action.payload] };
    case CommentsActionType.REMOVE_COMMENT_REPLY:
      return { ...state, replyComments: state.replyComments.filter(el => el._id !== action.id) };
    default:
      return { ...state };
  }
};
export default commentsReducer;
