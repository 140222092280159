import React, { FC, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import ArtistItem from "../../../../components/artist-item/ArtistItem";
import { UserRequests } from "../../../../api-queries/AllRequests";
import ExplorePageTitle from "../../../../components/explore-page-title/ExplorePageTitle";
import FilterItem from "../../../../components/filter-item/FilterItem";
import FilterOriginsOfInspiration from "../../../../components/filter-origins-of-inspiration/FilterOriginsOfInspiration";
import FilterResults from "../../../../components/filter-results/FilterResults";
import { IResultButton } from "../../../../components/filter-units/utils";
import Filter from "../../../../components/filter/Filter";
import ItemsNotFound from "../../../../components/items-not-found/ItemsNotFound";
import { ILocationFilter } from "../../../../context/types/artistsPageTypes";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { BREAKPOINT_COLUMNS_OBJ, CLOSEST_ARTISTS } from "../../../../misc/constants";
import { IFilter, IFilterTypes } from "../../../../types/filterTypes";
import { IUser } from "../../../../types/userTypes";
import SelectSort from "../../../select-sort/SelectSort";
import {
  artistsSortOptions,
  genderFilterOptions,
  IArtistSortValue,
  IGenderFilterOption,
  GenderFilterName,
  sortByClosest,
} from "../../../../explore-pages/artists-page/utils";
import { useActions } from "../../../../hooks/useActions";
import LocationFilter from "../../../../components/location-filter/LocationFilter";
import ScrollToTop from "../../../../components/scroll-to-top/ScrollToTop";

import "../../../../explore-pages/artists-page/ArtistsPage.scss";
import { pluralize } from "../../../../utils/pluralizeWord";
import { CollectorsSearchActions } from "../../../../store/types/collectorsSearchActions";
import FilterTopBar from "../../../filter-top-bar/FilterTopBar";

interface CollectorsSearchPropsArtistsPageProps {
  state: any;
  text: string;
  title?: boolean;
  resultPage?: boolean;
  types?: Array<string> | string;
  saveSearchPrevTab?: () => void;
}

const CollectorsTab: FC<CollectorsSearchPropsArtistsPageProps> = ({
  title,
  state,
  text,
  resultPage,
  types = ["artist"],
  saveSearchPrevTab,
}) => {
  const limit = 50;
  const {
    setCurrentLocation,
    SET_ARTISTS_COLLECTORS,
    SET_OFFSET_COLLECTORS,
    SET_FILTER_QUERY_PARAMS_COLLECTORS,
    SET_SORT_VALUE_COLLECTORS,
    SET_FILTER_RESULTS_COLLECTORS,
    SET_ORIGINS_OF_INSPIRATION_COLLECTORS,
    SET_GENDER_COLLECTORS,
    SET_LOCATION_FILTER_COLLECTORS,
    SET_RADIUS_COLLECTORS,
  } = useActions();
  const {
    artists,
    offset,
    sortValue,
    filterQueryParams,
    filterResults,
    originsOfInspiration,
    gender,
    location,
    radius,
  } = state;
  const { currentLocation } = useTypedSelector(store => store.currentLocationReducer);
  const { filterWindowOpen } = useTypedSelector(store => store.filterReducer);
  const setArtists = (payload: IUser[]): CollectorsSearchActions => SET_ARTISTS_COLLECTORS(payload);
  const setOffset = (payload: number): CollectorsSearchActions => SET_OFFSET_COLLECTORS(payload);
  const setfilterQueryParams = (payload: any): CollectorsSearchActions =>
    SET_FILTER_QUERY_PARAMS_COLLECTORS(payload);
  const setSortValue = (payload: IArtistSortValue): CollectorsSearchActions =>
    SET_SORT_VALUE_COLLECTORS(payload);
  const setFilterResults = (payload: IResultButton[]): CollectorsSearchActions =>
    SET_FILTER_RESULTS_COLLECTORS(payload);
  const setOriginsOfInspiration = (payload: IFilter[]): CollectorsSearchActions =>
    SET_ORIGINS_OF_INSPIRATION_COLLECTORS(payload);
  const setGender = (payload: IGenderFilterOption): CollectorsSearchActions =>
    SET_GENDER_COLLECTORS(payload);
  const setLocation = (payload: ILocationFilter | null): CollectorsSearchActions =>
    SET_LOCATION_FILTER_COLLECTORS(payload);
  const setRadius = (payload: number): CollectorsSearchActions => SET_RADIUS_COLLECTORS(payload);
  const variables = resultPage
    ? {
        offset: 0,
        text,
        limit,
        types,
        ...filterQueryParams,
      }
    : { offset: 0, limit, types: ["artist"], ...filterQueryParams };

  const { data, refetch } = useQuery(UserRequests.SEARCH_USERS_LITE, {
    variables,
  });
  const [isPositionLoading, setIsPositionLoading] = useState(false);
  const breakpointCols = { ...BREAKPOINT_COLUMNS_OBJ, default: filterWindowOpen ? 3 : 4 };
  const total = data?.searchArtists?.meta?.total;
  const routerLocation = useLocation();
  const { addPrevState, removePrevState } = useActions();
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
  const [showFilters, setShowFilters] = useState(false);

  const refetchArtistsTab = async (): Promise<void> => {
    const res = await refetch();
    setArtists([...res.data.searchArtists.artists]);
  };
  const setPrevState = (): void => {
    if (saveSearchPrevTab) saveSearchPrevTab();
    addPrevState({
      url: routerLocation.pathname,
      state: artists,
    });
  };
  useEffect(() => {
    if (prevState) return;
    refetchArtistsTab();
  }, []);
  const stateContext = {
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    filterResults: { filterResults, setFilterResults },
    originsOfInspiration: { originsOfInspiration, setOriginsOfInspiration },
    gender: { gender, setGender },
    location: { location, setLocation },
    offset: { offset, setOffset },
  };

  useEffect(() => {
    setArtists([]);
    refetch();
  }, [routerLocation]);

  const sortByArtists = async (value: IArtistSortValue): Promise<void> => {
    const { sortBy, orderBy, name } = value;
    const currentSortValue = sortValue;

    if (name === CLOSEST_ARTISTS.name) {
      sortByClosest(
        setIsPositionLoading,
        currentLocation,
        setCurrentLocation,
        setArtists,
        setOffset,
        setfilterQueryParams,
        filterQueryParams,
        setSortValue,
        currentSortValue,
      );
      return;
    }
    setArtists([]);
    setOffset(0);
    setfilterQueryParams({ ...filterQueryParams, sortBy, orderBy, sortByLocation: undefined });
  };

  const onGenderSelectChange = (e: IGenderFilterOption): void => {
    const { name, value, id } = e;
    setOffset(0);
    setArtists([]);
    setfilterQueryParams({
      ...filterQueryParams,
      ...value,
    });

    const prevResult = filterResults.find((item: any) => item.type === IFilterTypes.Gender);
    if (name === GenderFilterName.ANY) {
      setFilterResults(filterResults.filter((item: any) => item.type !== IFilterTypes.Gender));
      return;
    }
    if (!prevResult) {
      setFilterResults([...filterResults, { id, title: name, type: IFilterTypes.Gender }]);
      return;
    }
    setFilterResults(
      filterResults.map((item: any) => {
        if (item.type === IFilterTypes.Gender) {
          return {
            ...item,
            title: name,
            id,
          };
        }
        return item;
      }),
    );
  };

  const renderSortValue = (value: IArtistSortValue): any => {
    if (isPositionLoading) {
      return (
        <span>
          <span className="sort_name">Sort by: {value.name}</span>
          <CircularProgress size={15} style={{ color: "#282828" }} />
        </span>
      );
    }
    return `Sort by: ${value.name}`;
  };

  const scrollHandler = async (): Promise<void> => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
      return;
    }
    if (artists.length >= limit) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setArtists([...artists, ...(res.data?.searchArtists?.artists || [])]);
    }
  };

  const refetchCollectors = async (): Promise<void> => {
    const res = await refetch({ text });
    setArtists([...res.data?.searchArtists.artists]);
  };

  const backToAllItems = (): void => {
    setFilterResults([]);
    setArtists([]);
    setOffset(0);
    setOriginsOfInspiration([]);
    setGender(genderFilterOptions[0]);
    setLocation(null);
    setfilterQueryParams({
      ...filterQueryParams,
      offset: 0,
      originsOfInspiration: undefined,
      sex: undefined,
      location: undefined,
    });
  };

  useEffect(() => {
    setShowFilters(false);
    if (!filterResults.length) backToAllItems();
    if (prevState && prevState.state) {
      setArtists(prevState.state);
      removePrevState(routerLocation.pathname);
      setShowFilters(true);

      return;
    }
    refetchCollectors();
  }, [text]);

  useEffect(() => {
    if (data?.searchArtists?.artists.length) setShowFilters(true);
    if (!artists.length) {
      setArtists([...artists, ...(data?.searchArtists?.artists || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const showFiltersInfo = showFilters || filterResults.length > 0;

  return (
    <div className="artists_page wrapper">
      <ScrollToTop />
      {title && <ExplorePageTitle title="ARTISTS" />}
      {showFiltersInfo && (
        <FilterTopBar
          renderValue={renderSortValue}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setQueryValue={sortByArtists}
          options={artistsSortOptions}
        />
      )}
      <div
        className={classNames("explore_grid", {
          active: filterWindowOpen,
        })}
      >
        {showFiltersInfo && (
          <div className="left_side__bar">
            <div className="artworks_item__side-bar">
              <Filter>
                <FilterItem title="Location">
                  <LocationFilter
                    value={location}
                    radiusValue={radius}
                    setRadius={setRadius}
                    setLocation={setLocation}
                    context={stateContext}
                    setDataItem={setArtists}
                  />
                </FilterItem>
                <FilterItem title="Origins of inspiration">
                  <FilterOriginsOfInspiration
                    context={stateContext}
                    setItems={setArtists}
                    filterQueryName="originsOfInspiration"
                  />
                </FilterItem>
                <FilterItem title="Gender">
                  <SelectSort
                    className="artworks_item__gender_filter"
                    options={genderFilterOptions}
                    setQueryValue={onGenderSelectChange}
                    sortValue={gender}
                    setSortValue={setGender}
                    height={50}
                    width="100%"
                    renderValue={(value: IGenderFilterOption) => `${value.name}`}
                  />
                </FilterItem>
              </Filter>
            </div>
          </div>
        )}
        <div className="explore_list">
          {showFiltersInfo && (
            <div className="artists_page__filter_top_panel">
              <div className="artists_page__filter_left_block">
                <span className="artists_page__results">
                  {total?.toLocaleString()} {pluralize("Collector", total)}
                </span>
                {filterResults.length > 0 && (
                  <FilterResults
                    filterResults={filterResults}
                    setFilterResults={setFilterResults}
                    setDataItem={setArtists}
                    categoriesQuery="categories"
                    context={stateContext}
                    inspipationQuery="originsOfInspiration"
                  />
                )}
              </div>
            </div>
          )}
          {artists && (
            <InfiniteScroll
              scrollThreshold={0.5}
              dataLength={artists.length}
              next={scrollHandler}
              hasMore
              loader=""
            >
              <Masonry
                breakpointCols={breakpointCols}
                className="artists_page__masonry_grid"
                columnClassName="artists_page__grid_column"
              >
                {artists.map((artist: any) => {
                  return <ArtistItem saveState={setPrevState} artist={artist} key={artist._id} />;
                })}
              </Masonry>
            </InfiniteScroll>
          )}
          {total === 0 && <ItemsNotFound title="No results found" />}
        </div>
      </div>
    </div>
  );
};

export default CollectorsTab;
