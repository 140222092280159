/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { artworks, messagesRoute } from "../../route-link";
import { IUser } from "../../types/userTypes";
import UserListItem from "../user-list-item/UserListItem";
import ChatsListPlaceholder from "./ChatsListPlaceholder";

interface IResultsSearchProps {
  searchResults: IUser[];
  searchLoading: boolean;
  scrollModalTo?: (scrollY: number) => any;
  setChatName: (chatName: string) => void;
}

const ResultsList: FC<IResultsSearchProps> = ({
  searchResults,
  searchLoading,
  scrollModalTo,
  setChatName,
}) => {
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const isChatsPage = routerLocation.pathname === `/${messagesRoute}`;
  const isArtworkDetails = routerLocation.pathname.includes(artworks);
  const { chatsListScroll } = useTypedSelector(state => state.messagesInfoReducer);
  const { setChatsListScroll } = useActions();
  useEffect(() => {
    if (scrollModalTo) scrollModalTo(chatsListScroll);
    setChatsListScroll(0);
  }, []);

  const showViewMoreButton = !isChatsPage && !isArtworkDetails;

  const goToMessagesPage = (): void => {
    navigate(`/${messagesRoute}`);
  };

  return (
    <>
      <div className="search-results-list">
        {searchResults.map(user => {
          if (!user) return null;
          return (
            <div key={user._id}>
              <UserListItem
                profile={user?.profile}
                id={user?._id}
                chatsPage
                isInstitution={user?.hasInstitution}
                institutionName={user?.institutions[0]?.name}
                setChatName={setChatName}
              />
            </div>
          );
        })}
        {!searchResults.length && searchLoading && <ChatsListPlaceholder />}
        {!searchResults.length && !searchLoading && (
          <p className="no-results">No operators found</p>
        )}
      </div>
      {showViewMoreButton && (
        <div className="view-all-chats_button">
          <p className="mock_link" onClick={goToMessagesPage}>
            View All
          </p>
        </div>
      )}
    </>
  );
};

export default ResultsList;
