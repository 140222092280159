import { IMAGE } from "./ImageRequestParams";
import { LOCATION } from "./LocationRequestParams";

export const EXHIBITION_LITE = `
_id
title
description
isSolo
isOpenToSubmitions
private
duration {
  from
  to
}
image {
  ${IMAGE}
}
location {
  ${LOCATION}
}`;
