import { Skeleton } from "@mui/material";
import React, { FC } from "react";

const ChatsListPlaceholder: FC = () => {
  const chatPlaceholder = (): JSX.Element => {
    return (
      <div className="chats-list_placeholder_item">
        <Skeleton variant="circular" width={50} height={50} />
        <div className="name_placeholders">
          <Skeleton variant="rectangular" width={250} height={10} />
          <Skeleton variant="rectangular" width={250} height={10} />
        </div>
      </div>
    );
  };
  return (
    <div className="chats-list_placeholder">
      <>
        {chatPlaceholder()}
        {chatPlaceholder()}
        {chatPlaceholder()}
        {chatPlaceholder()}
        {chatPlaceholder()}
        {chatPlaceholder()}
        {chatPlaceholder()}
        {chatPlaceholder()}
      </>
    </div>
  );
};

export default ChatsListPlaceholder;
