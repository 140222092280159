import { IArtwork } from "../../types/artwork/artwork";

export interface UserLikedArtworksState {
  userLikedArtworks: IArtwork[] | [];
  offset: number;
  userId: string;
}

export enum UserLikedArtworksTypes {
  SET_USER_LIKED_ARTWORKS = "SET_USER_LIKED_ARTWORKS",
  SET_OFFSET = "SET_OFFSET",
  SET_USER_ID = "SET_USER_ID",
}

interface setUserLikedArtworks {
  type: UserLikedArtworksTypes.SET_USER_LIKED_ARTWORKS;
  payload: IArtwork[] | [];
}

interface setOffset {
  type: UserLikedArtworksTypes.SET_OFFSET;
  payload: number;
}

interface setUserId {
  type: UserLikedArtworksTypes.SET_USER_ID;
  payload: string;
}

export type UserLikedArtworksType = setUserLikedArtworks | setOffset | setUserId;
