import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { useQuery } from "@apollo/client";

import ExplorePageTitle from "../../../../components/explore-page-title/ExplorePageTitle";
import { BREAKPOINT_COLUMNS_OBJ } from "../../../../misc/constants";
import VoteListArtworks from "../../components/vote-list-artworks/VoteListArtworks";

import { ArtworksRequests } from "../../../../api-queries/AllRequests";

import "./Winners.scss";

const Winners = (): JSX.Element => {
  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();
  const [offset, setOffset] = useState(0);
  const limit = 50;
  const { data, refetch } = useQuery(ArtworksRequests.SEARCH_ARTWORKS_LITE, {
    variables: { isWinner: true, limit, offset },
  });
  const [artworks, setArtworks] = useState<Array<any>>([]);
  const total = data?.searchArtworks.meta.total;
  const scrollHandler = async (): Promise<void> => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
    }
    if (artworks.length >= 50) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setArtworks([...artworks, ...(res.data.searchArtworks.artworks || [])]);
    }
  };
  useEffect(() => {
    if (!artworks.length) setArtworks(data?.searchArtworks?.artworks || []);
  }, [data]);
  return (
    <div className="winners_grid">
      <div className="wrapper">
        <div className="vote_title">
          <ExplorePageTitle backButton title="Winners" />
        </div>
      </div>
      <div className="explore_list wrapper">
        {artworks && (
          <InfiniteScroll
            loader
            scrollThreshold={0.7}
            dataLength={artworks.length}
            next={scrollHandler}
            hasMore
          >
            <div ref={widthBlock}>
              <Masonry
                ref={masonryColumns}
                breakpointCols={BREAKPOINT_COLUMNS_OBJ}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {artworks?.map((artwork: any) => {
                  const widthColumn =
                    widthBlock?.current?.offsetWidth / masonryColumns.current?.state?.columnCount -
                    12;
                  const paramsArtwork = artwork.image.medium.width / artwork.image.medium.height;
                  return (
                    <VoteListArtworks
                      key={artwork._id}
                      artwork={artwork}
                      widthColumn={widthColumn}
                      paramsArtwork={paramsArtwork}
                    />
                  );
                })}
              </Masonry>
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Winners;
