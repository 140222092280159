import React, { FC } from "react";

interface IVotesCountProps {
  votes: number;
}

const VotesCount: FC<IVotesCountProps> = ({ votes }) => {
  return <div className="artwork_item__votes">{votes}</div>;
};

export default VotesCount;
