import React, { FC, useEffect, useRef, useState } from "react";
import Masonry from "react-masonry-css";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLazyQuery } from "@apollo/client";

import BackButton from "../../components/back-button/BackButton";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkItem from "../../components/artwork-item/ArtworkItem";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import { ArtRequests } from "../../api-queries/AllRequests";

const limit = 50;

const LostViewMorePage: FC = () => {
  const { state } = useLocation();
  const { artworks: initArtworks, header }: any = state;
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  const { addPrevState, removePrevState } = useActions();
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const location = useLocation();
  const [offset, setOffset] = useState(50);
  const [loadMore] = useLazyQuery(
    header === "Lost Art" ? ArtRequests.LOST_ART : ArtRequests.FOUND_ART,
  );
  const dataName = header === "Lost Art" ? "firstFoundArtworks" : "thirdFoundArtworks";
  const [artworks, setArtworks] = useState(initArtworks);

  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();

  const showEmptyTitle = Boolean(!artworks?.length);

  const saveState = (currentArtwork: IArtwork): void => {
    addPrevState({
      url: location.pathname,
      state: artworks,
      scroll: window.scrollY,
      filtersState: { offset },
    });
    localStorage.setItem(
      "prevState",
      JSON.stringify({
        url: location.pathname,
        state: artworks,
        scroll: window.scrollY,
        filtersState: { offset },
      }),
    );
    setSldrAtrwrs(
      artworks.map((el: IArtwork) => {
        if (currentArtwork._id === el._id) {
          return { id: el._id, artwork: currentArtwork };
        }
        return { id: el._id, artwork: null };
      }),
    );
  };

  const getArtworks = async (): Promise<void> => {
    const dataArt = await loadMore({
      variables: {
        offset,
        limit,
      },
    });
    setOffset(prev => prev + limit);
    setArtworks((prev: any) => [...prev, ...(dataArt?.data[dataName]?.artworks || [])]);
  };

  const scrollHandler = (): void => {
    getArtworks();
  };

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === location.pathname);
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevState) {
      const { filtersState } = prevState;
      setOffset(filtersState.offset);
      setArtworks(prevState.state);
      removePrevState(location.pathname);
      setTimeout(() => window.scroll(0, prevLoadingState.scroll), 500);
      localStorage.removeItem("prevState");
      return;
    }

    if (prevLoadingState?.url === location.pathname) {
      const { filtersState } = prevLoadingState;
      setOffset(filtersState.offset);
      setArtworks(prevLoadingState.state);
      setTimeout(() => window.scroll(0, prevLoadingState.scroll), 500);
      localStorage.removeItem("prevState");
      return;
    }

    setArtworks(initArtworks);
  }, []);

  return (
    <div className="wrapper profile-artworks-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        {header}
      </h3>
      {artworks && (
        <>
          <InfiniteScroll
            loader
            scrollThreshold={0.7}
            dataLength={artworks.length}
            next={scrollHandler}
            hasMore
          >
            <div ref={widthBlock}>
              <Masonry
                ref={masonryColumns}
                breakpointCols={BREAKPOINT_COLUMNS_OBJ}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {artworks?.map((artwork: IArtwork) => {
                  return (
                    <ArtworkItem
                      key={artwork._id}
                      artwork={artwork}
                      masonryColumns
                      widthBlock
                      saveState={saveState}
                    />
                  );
                })}
              </Masonry>
            </div>
          </InfiniteScroll>
          {showEmptyTitle && (
            <p className="profile-artworks-page__no-artworks">There are no artworks.</p>
          )}
        </>
      )}
    </div>
  );
};

export default LostViewMorePage;
