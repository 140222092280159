import React from "react";
import { Link, useLocation } from "react-router-dom";
import { incomingTransfers } from "../../route-link";

import "./ArtworkListItem.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ArtworkListItem = ({ id, artwork, saveState = () => {} }) => {
  const { image, minimumBid, owner, name, saleable } = artwork;
  const ownerProfile = owner?.profile;
  const firstName = ownerProfile?.firstName;
  const middleName = ownerProfile?.middleName;
  const secondName = ownerProfile?.secondName;
  const fullName = `${firstName} ${middleName || ""} ${secondName}`;
  const isInstitution = owner?.hasInstitution;
  const ownerName = isInstitution ? owner?.institutions[0]?.name : fullName;
  const { pathname } = useLocation();
  const artworkLink = pathname.includes(incomingTransfers)
    ? `/explore/artworks/${id}/${incomingTransfers}`
    : `/explore/artworks/${id}`;

  return (
    <div className="artwork_list_item" onClick={() => saveState(artwork)}>
      <Link to={artworkLink} state={artwork}>
        <div className="artwork_list_item__icon__wrap">
          {image?.medium.url && (
            <img alt="icon" className="artwork_list_item__icon" src={image.medium.url || ""} />
          )}
        </div>
        <div className="artwork_list_item__info">
          <div className="artwork_list_item__title">{name}</div>

          {ownerProfile && <div className="artwork_list_item__owner">{ownerName}</div>}

          {saleable && minimumBid !== null && (
            <div className="artwork_list_item__price">${minimumBid.toLocaleString()}</div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ArtworkListItem;
