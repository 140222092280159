import { ITab } from "../../components/artist-profile-artworks-tabs/utils";

export interface ProfileState {
  profileUserId: string;
  activeArtworkTab: string;
  profileContainerHeight: number;
  profileArtworkTabs: ITab[];
}

export enum ProfileActionTypes {
  SET_PROFILE_USER_ID = "SET_PROFILE_USER_ID",
  SET_ACTIVE_ARTWORK_TAB = "SET_ACTIVE_ARTWORK_TAB",
  SET_PROFILE_HEIGHT = "SET_PROFILE_HEIGHT",
  SET_ARTWORK_TABS = "SET_ARTWORK_TABS",
}

export interface ProfileSetUserIdAction {
  type: ProfileActionTypes.SET_PROFILE_USER_ID;
  payload: string;
}

export interface ProfileSetActiveArtworkTabAction {
  type: ProfileActionTypes.SET_ACTIVE_ARTWORK_TAB;
  payload: string;
}

export interface ProfileSetProfileContainerHeight {
  type: ProfileActionTypes.SET_PROFILE_HEIGHT;
  payload: number;
}

export interface ProfileSetArtworkTabs {
  type: ProfileActionTypes.SET_ARTWORK_TABS;
  payload: ITab[];
}

export type ProfileAction =
  | ProfileSetUserIdAction
  | ProfileSetActiveArtworkTabAction
  | ProfileSetProfileContainerHeight
  | ProfileSetArtworkTabs;
