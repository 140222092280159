import React, { FC } from "react";
import { IArtwork } from "../../../types/artwork/artwork";
import ShareButton from "../../share-button/ShareButton";

import { getArtworkDimensions, getMaterialsAndCategoriesText } from "../utils";

interface MainInfoProps {
  artwork: IArtwork;
}
const VotesArtworkDetailsMainInfo: FC<MainInfoProps> = ({ artwork }) => {
  const dataIsReady = Boolean(artwork);

  const displayMaterialsCategories = Boolean(
    artwork?.materials?.length || artwork?.categories?.length || artwork?.colors?.length,
  );

  return (
    <>
      {artwork && (
        <>
          {dataIsReady && (
            <div className="explore_item_details_right__title_wrapper">
              <h2 className="explore_item_details_right__title">
                {artwork?.name?.trim() || "No name"}
                {artwork?.createdByArtistYear && <span>{`, ${artwork?.createdByArtistYear}`}</span>}
                <ShareButton />
              </h2>
            </div>
          )}
          {displayMaterialsCategories && (
            <div className="explore_item_details_right__materials">
              {getMaterialsAndCategoriesText(artwork)}
            </div>
          )}
          {!!artwork && (
            <div className="explore_item_details_right__dimensions">
              {getArtworkDimensions(artwork)}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default VotesArtworkDetailsMainInfo;
