import React, { FC, useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CloseIcon from "@mui/icons-material/Close";

import "./LocationAutocomplete.scss";
import { ILocationFilter } from "../../context/types/artistsPageTypes";

interface LocationAutocompleteProps {
  onChange: (e: ILocationFilter) => void;
  className: string;
  placeholder: string;
  value: ILocationFilter | null;
  onCloseIconClick?: () => void;
  isPrivate?: boolean;
}

const LocationAutocomplete: FC<LocationAutocompleteProps> = ({
  onChange,
  className,
  value,
  onCloseIconClick,
  placeholder,
  isPrivate,
}) => {
  const [currentValue, setCurrentValue] = useState(value || null);
  const onSelectChange = (e: ILocationFilter): void => {
    setCurrentValue(e);
    onChange(e);
  };
  const onCloseClick = (): void => {
    setCurrentValue(null);
    if (onCloseIconClick) {
      onCloseIconClick();
    }
  };
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="location_autocomplete">
      <GooglePlacesAutocomplete
        selectProps={{
          value: isPrivate ? null : currentValue,
          onChange: onSelectChange,
          className,
          placeholder,
          onFocus: () => setCurrentValue(null),
          onBlur: () => setCurrentValue(value || null),
        }}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      />
      {currentValue && (
        <CloseIcon
          onClick={onCloseClick}
          className="location_autocomplete__close_icon"
          fontSize="small"
        />
      )}
    </div>
  );
};

export default LocationAutocomplete;
