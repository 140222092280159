import { IArtwork } from "../../types/artwork/artwork";
import { UserLikedArtworksType, UserLikedArtworksTypes } from "../types/user-liked-artworks";

export const setUserLikedArtworks = (payload: IArtwork[] | []): UserLikedArtworksType => ({
  type: UserLikedArtworksTypes.SET_USER_LIKED_ARTWORKS,
  payload,
});

export const setLikedArtworksOffset = (payload: number): UserLikedArtworksType => ({
  type: UserLikedArtworksTypes.SET_OFFSET,
  payload,
});

export const setUserId = (payload: string): UserLikedArtworksType => ({
  type: UserLikedArtworksTypes.SET_USER_ID,
  payload,
});
