export interface BackUrlState {
  backUrl: string;
}

export enum BackUrlTypes {
  BACK_URL = "BACK_URL",
  CLEAN_BACK_URL = "CLEAN_BACK_URL",
}

interface SetBackUrl {
  type: BackUrlTypes.BACK_URL;
  payload: string;
}

export type BackUrlType = SetBackUrl;
