import { Avatar } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import ResultsList from "../messages-modal/ResultsList";
import { IUser } from "../../types/userTypes";
import ChatsList from "../messages-modal/ChatsList";
import Chat from "../chat/Chat";
import { searchUsers } from "../../api-queries/GraohQlClient";
import { artists, explore } from "../../route-link";

interface IMessageMenuProps {
  isChatsPage?: boolean;
  scrollModalToBottom?: () => any;
  scrollModalTo?: (scrollY: number) => any;
  saveCurrentScroll?: () => any;
  getUnreadMessages: () => void;
  closeModal?: () => void;
  scrollOnLoad?: () => void;
  showScrollToBottomButton?: boolean;
}

const MessagesMenu: FC<IMessageMenuProps> = ({
  isChatsPage,
  scrollModalTo,
  scrollModalToBottom,
  saveCurrentScroll,
  getUnreadMessages,
  closeModal,
  scrollOnLoad,
  showScrollToBottomButton,
}) => {
  const [searchInputRef, setSearchInputRef] = useState<any>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [chatName, setChatName] = useState<string>("");
  const [chatRecipient, setChatRecipient] = useState<IUser | null>(null);
  const navigate = useNavigate();
  const {
    currentChat,
    currentStage,
    messageToSend,
    chatsSearchValue: searchValue,
    chatsSearchResults: searchResults,
    chatsPrevStage,
    defaultSearchResults,
    followingsLoading,
  } = useTypedSelector(reduxStore => reduxStore.messagesInfoReducer);
  const {
    setCurrentChat,
    setCurrentStage,
    setCurrentChatOffset,
    setCurrentMessages,
    setChatsSearchValue: setSearchValue,
    setChatsSearchResults: setSearchResults,
    setChatsSearchOffset: setOffset,
    setMessageToSend,
  } = useActions();

  useEffect(() => {
    if (messageToSend && currentStage === "search-users") {
      setCurrentStage("search-users");
      return;
    }
    if (currentChat) {
      if (isChatsPage && currentStage === "search-users") return;
      setCurrentStage("chat");
      return;
    }
    setCurrentChatOffset(0);
    setCurrentMessages([]);
  }, [currentChat]);

  useEffect(() => {
    if (searchInputRef) {
      searchInputRef.value = searchValue;
    }
  }, [searchInputRef]);

  const search = async (value: string): Promise<void> => {
    if (!value) {
      setSearchResults(defaultSearchResults);
      return;
    }
    setSearchLoading(true);
    const res = await searchUsers({
      text: value,
    });
    setSearchResults(res.users);
    setSearchLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSearchValue(value);
    setOffset(0);
    search(value);
  };

  const debouncedSearch = debounce(handleChange, 600);

  const renderModalHeader = (): any => {
    switch (currentStage) {
      case "chats-list":
        return "Messages";
      case "search-users":
        return "Followings";
      case "chat":
        return chatName;
      default:
        return "Messages";
    }
  };

  const renderCurrentStage = (): any => {
    switch (currentStage) {
      case "chats-list":
        return <ChatsList setCurrentChat={setCurrentChat} scrollModalTo={scrollModalTo} />;
      case "search-users":
        return (
          <ResultsList
            searchLoading={searchLoading || followingsLoading}
            searchResults={searchResults}
            scrollModalTo={scrollModalTo}
            setChatName={setChatName}
          />
        );
      case "chat":
        if (isChatsPage) {
          setCurrentStage("chats-list");
          return null;
        }
        if (!currentChat || !saveCurrentScroll || !scrollModalToBottom || !scrollModalTo)
          return null;
        return (
          <Chat
            saveCurrentScroll={saveCurrentScroll}
            chat={currentChat}
            setChatName={setChatName}
            setChatRecipient={setChatRecipient}
            scrollToBottom={scrollModalToBottom}
            scrollModalTo={scrollModalTo}
            getUnreadMessages={getUnreadMessages}
            scrollOnLoad={scrollOnLoad}
            showScrollToBottomButton={showScrollToBottomButton}
          />
        );
      default:
        return null;
    }
  };

  const goToUserProfile = (): void => {
    navigate(`${explore}/${artists}/${chatRecipient?._id}`);
  };

  const onBackButtonClick = (): void => {
    setMessageToSend(null);

    if (closeModal) {
      closeModal();
      setCurrentStage("chat");
      return;
    }
    setCurrentStage("chats-list");
  };

  const goBackFromChats = (): void => {
    setCurrentStage(chatsPrevStage);
  };

  return (
    <div className="messages-menu" id="messages-menu">
      <div className="messages-menu_title">
        {currentStage === "chat" && (
          <ArrowBackIcon className="switch-stage-button" onClick={goBackFromChats} />
        )}
        <h2>{renderModalHeader()}</h2>
        {currentStage === "chat" && (
          <div className="messages-menu_title_image">
            {chatRecipient?.profile?.image?.thumbnail.url ? (
              <div onClick={goToUserProfile}>
                <Avatar
                  src={chatRecipient.profile.image.thumbnail.url}
                  className="messages-menu_title_image"
                />
              </div>
            ) : (
              <AvatarPlaceholder
                className="messages-menu_title_image"
                id={chatRecipient?._id}
                width="40px"
                height="40px"
                fontSize="8px"
              />
            )}
          </div>
        )}
      </div>
      {currentStage !== "chat" && (
        <div className="search-input">
          {currentStage === "search-users" && (
            <ArrowBackIcon className="switch-stage-button" onClick={onBackButtonClick} />
          )}
          <input
            onClick={() => setCurrentStage("search-users")}
            type="text"
            className="search-chats-input"
            ref={setSearchInputRef}
            placeholder="Search for more users"
            onChange={debouncedSearch}
          />
        </div>
      )}
      {renderCurrentStage()}
    </div>
  );
};

export default MessagesMenu;
