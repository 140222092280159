import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CommentGridProps } from "./CommentGrid.props";
import Button from "../../UI/button/Button";
import formatShortDate from "../../utils/format-short-date";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { artistLink, explore } from "../../route-link";
import ButtonLike from "../../UI/button-like/ButtonLike";
import { removeComment, toggleLike } from "../../api-queries/GraohQlClient";
import { ExhibitionRequests } from "../../api-queries/AllRequests";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { GetToken } from "../../utils/get-token";

const CommentGrid: React.FC<CommentGridProps> = ({
  firstName,
  secondName,
  createdAtDate,
  text,
  likesCount,
  image,
  authorId,
  commentId,
  likes,
  refetch,
  replyLikesCount,
  showInputReply,
  _id,
  findComment,
  type,
  artworkOwnerId,
}) => {
  const { artworkData } = useTypedSelector(state => state.artworkDetailsReducer);
  const { openModalLogin } = useActions();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const [showRemove, setShowRemove] = useState(false);
  const navigate = useNavigate();
  const goToProfile = (): void => {
    navigate(`${explore}${artistLink}/${authorId}`);
    window.scroll(0, 0);
  };

  const handleLikeClick = (
    setIsLiked: (toggle: boolean) => void,
    setLikesCounter: (prevState: any) => void,
  ): void => {
    toggleLike(ExhibitionRequests.TOGGLE_COMMENT_LIKE, { commentId })
      .then(({ toggleCommentLike }) => {
        if (toggleCommentLike.liked) {
          setLikesCounter((prevState: number) => prevState + 1);
        }
        if (!toggleCommentLike.liked) {
          setLikesCounter((prevState: number) => prevState - 1);
        }
        setIsLiked(toggleCommentLike.liked);
      })
      .then(() => refetch());
  };

  const handleRemove = async (): Promise<void> => {
    findComment(commentId);
    switch (type) {
      case "exhibition":
        await removeComment(ExhibitionRequests.REMOVE_EXHIBITION_COMMENT, {
          exhibitionId: _id,
          commentId,
        });
        break;
      case "artwork":
        await removeComment(ExhibitionRequests.REMOVE_ARTWORK_COMMENT, {
          artworkId: _id,
          commentId,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      authorId === myMOCAId ||
      artworkOwnerId === myMOCAId ||
      artworkData?.collector?._id === myMOCAId
    ) {
      setShowRemove(true);
    }
  }, []);

  return (
    <div className="comment_grid">
      {image ? (
        <Avatar className="comment_grid__avatar" onClick={goToProfile} src={image} />
      ) : (
        <AvatarPlaceholder
          onClick={goToProfile}
          id={authorId}
          width="40px"
          height="40px"
          fontSize="10px"
          className="comment_grid__avatar"
        />
      )}
      <div className="comment_content">
        <div onClick={goToProfile} className="comment_author">
          {firstName} {secondName}
        </div>
        <div className="comment_date">
          {createdAtDate && formatShortDate(createdAtDate)}
          <ButtonLike
            likes={likes || []}
            likesCount={likesCount || 0}
            replyLikesCount={replyLikesCount}
            handleLikeClick={GetToken() ? handleLikeClick : openModalLogin}
          />
        </div>
        <div className="comment_text">
          {text}
          <Button
            children="Reply"
            variant="text"
            onClick={GetToken() ? showInputReply : openModalLogin}
            className="reply"
          />
          {showRemove && (
            <Button children="Remove" variant="text" onClick={handleRemove} className="reply" />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentGrid;
