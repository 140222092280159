/* eslint-disable no-case-declarations */
import { IArtwork } from "../../types/artwork/artwork";
import { IUser } from "../../types/userTypes";
import {
  prevStateActions,
  PrevStatesActionTypes,
  IPreviousStatesInitialState,
} from "../types/previous-states.type";

export const previousStatesInitialState: IPreviousStatesInitialState = {
  states: [],
};

export const prevStateReducer = (
  state = previousStatesInitialState,
  action: prevStateActions,
): IPreviousStatesInitialState => {
  switch (action.type) {
    case PrevStatesActionTypes.ADD_PREV_STATE:
      return { states: [...state.states, action.payload] };
    case PrevStatesActionTypes.REMOVE_PREV_STATE:
      return { states: state.states.filter(prevState => prevState.url !== action.payload) };
    case PrevStatesActionTypes.REMOVE_PREV_STATE_BY_ID:
      return { states: state.states.filter(prevState => prevState.id !== action.payload) };
    case PrevStatesActionTypes.REMOVE_ARTWORK_FROM_STATES:
      return {
        states: state.states.map(innerState => {
          return {
            ...innerState,
            state: innerState.state.filter((item: IArtwork | IUser) => {
              return item._id !== action.payload;
            }),
          };
        }),
      };
    case PrevStatesActionTypes.REMOVE_ARTWORK_FROM_SPECIFIC_STATE:
      const specificState = state.states.find(
        prevState => prevState.url === action.payload.stateUrl,
      );
      if (!specificState) return state;

      const filteredSpecificState = {
        ...specificState,
        state: specificState?.state.filter((item: IArtwork) => item._id !== action.payload.id),
      };
      const newStates = [
        ...state.states.filter(prevState => prevState.url !== action.payload.stateUrl),
        filteredSpecificState,
      ];
      return {
        states: newStates,
      };
    default:
      return state;
  }
};
