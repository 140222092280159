import React from "react";
import DownloadMoca from "../../components/download-moca/DownloadMoca";

import "./AboutPage.scss";
import Footer from "../../components/footer/Footer";

const AboutPage: React.FC = () => {
  const title = "Download for iOS/Android.";
  return (
    <div className="about_page">
      <div className="about_page__content">
        <h3>{title}</h3>
        <DownloadMoca />
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
