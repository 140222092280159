import React from "react";

import "./Filter.scss";

interface FilterProps {
  children: React.ReactNode;
}

const Filter: React.FC<FilterProps> = ({ children }) => {
  return (
    <div className="filter_container">
      <div className="filter_content">{children}</div>
    </div>
  );
};
export default Filter;
