import { ISuggestion } from "../../types/suggestion";
import { ArtworkMetrics, ArtworkTypes, IArtwork } from "../../types/artwork/artwork";

export interface IExploreItemDetailsRight {
  artwork: IArtwork;
  artworkId: string | undefined;
  refetch: () => void;
  suggestions: Array<ISuggestion>;
  showOwnerActionsButtons: boolean;
  showCollectorActionButtons: boolean;
  removeArtworkFromSlider: () => void;
  blockTransferModal: boolean;
  setBlockSliding: (block: boolean) => void;
  slideNext?: () => void;
}

export function getArtworkDimensions(artwork: IArtwork): string {
  if (!artwork.dimensions) {
    return "";
  }

  const {
    dimensions: { width, height, length, weight, metrics },
  } = artwork;

  if ([width, height, length, weight].every(d => !d)) {
    return "";
  }

  let dimensions = `${[width, height, length].filter(Boolean).join(" x ")} ${metrics}`;

  if (dimensions.length > 3) {
    dimensions = `${dimensions};`;
  }

  if (weight) {
    dimensions = `${dimensions} ${weight} ${metrics === ArtworkMetrics.IN ? "pound" : "kg"}`;
  }

  return dimensions;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getOwnerLabel = (artworkType: string, showCollectedLabel: boolean) => {
  switch (artworkType) {
    case ArtworkTypes.COLLECTOR: {
      if (showCollectedLabel) {
        return "Collected by ";
      }
      return "";
    }
    case ArtworkTypes.FOUND:
      return "Found by ";

    default:
      return "";
  }
};

export const getMaterialsAndCategoriesText = (artwork: IArtwork): string => {
  if (!artwork.materials || !artwork.colors) return "";
  const materialsText = artwork.materials.join(", ");
  const colorsText = artwork.colors.join(", ");
  const categoriesText = artwork.categories.map(category => category.name).join(", ");
  return [materialsText, categoriesText, colorsText].filter(Boolean).join("  •  ");
};

export const makeOrEditOfferButtonTitle = (artwork: IArtwork): string => {
  return artwork.saleable && artwork.minimumBid
    ? "Edit your offer"
    : "Edit to sell, media use and maps";
};

export const removeEmptyFields = <T>(array: T[]): T[] => {
  return array.filter((el: any) => {
    if (!el) return false;
    const allFields: string[] = Object.keys(el);
    return allFields.every(fieldName => {
      if (
        el[fieldName] === "PastExhibition" ||
        el[fieldName] === "Collector" ||
        fieldName === "collectedAt"
      )
        return true;
      return !!el[fieldName];
    });
  });
};
