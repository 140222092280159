import { IUser } from "../../types/userTypes";
import { IChat, IMessage } from "../../types/chat";
import { MessagesInfoActionTypes, MessagesStage } from "../types/messages-info";
import { ReturnActionType } from "../types/return-type";

export const setShowMessagesModal = (showStatus: boolean): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_SHOW_MESSAGES_MODAL,
  payload: showStatus,
});

export const setUnreadMessagesCount = (count: number): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_UNREAD_MESSAGES_COUNT,
  payload: count,
});

export const incrementUnreadMessagesCount = (): ReturnActionType => ({
  type: MessagesInfoActionTypes.INCREMENT_UNREAD_MESSAGES,
});

export const setChats = (chats: IChat[]): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CHATS,
  payload: chats,
});

export const setHasMoreChats = (hasMore: boolean): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_HAS_MORE,
  payload: hasMore,
});

export const setChatsOffset = (offset: number): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_OFFSET,
  payload: offset,
});

export const setChatsLoading = (isLoading: boolean): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_LOADING,
  payload: isLoading,
});

export const setCurrentChat = (chat: IChat | null): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CURRENT_CHAT,
  payload: chat,
});

export const setCurrentStage = (stage: MessagesStage): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CURRENT_STAGE,
  payload: stage,
});

export const setCurrentMessages = (messages: IMessage[]): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CURRENT_MESSAGES,
  payload: messages,
});

export const setCurrentScroll = (scroll: number | undefined): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CURRENT_SCROLL,
  payload: scroll,
});

export const setCurrentChatOffset = (offset: number): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CURRENT_CHAT_OFFSET,
  payload: offset,
});

export const setMessageToSend = (message: any): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_MESSAGE_TO_SEND,
  payload: message,
});

export const setChatsSearchValue = (value: string): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CHATS_SEARCH_VALUE,
  payload: value,
});

export const setChatsSearchResults = (results: IUser[]): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CHATS_SEARCH_RESULTS,
  payload: results,
});

export const setChatsSearchOffset = (offset: number): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CHATS_SEARCH_OFFSET,
  payload: offset,
});

export const setChatsListScroll = (scroll: number): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CHATS_LIST_SCROLL,
  payload: scroll,
});

export const setChatsPrevStage = (stage: MessagesStage): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_CHATS_PREV_STAGE,
  payload: stage,
});

export const setDefaultSearchResults = (results: IUser[]): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_DEFAULT_SEARCH_RESULTS,
  payload: results,
});

export const setIsOnArtwork = (isOnArtwork: boolean): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_IS_ON_ARTWORK,
  payload: isOnArtwork,
});

export const setFollowingsLoading = (loading: boolean): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_FOLLOWINGS_LOADING,
  payload: loading,
});

export const setMessageUnreadStatus = (messageId: string, newId: string): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_MESSAGE_UNREAD_STATUS,
  payload: [messageId, newId],
});

export const setMessagesToSend = (messagesToSend: any[]): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_MESSAGES_TO_SEND,
  payload: messagesToSend,
});

export const setIsMessageSending = (isMessageSending: boolean): ReturnActionType => ({
  type: MessagesInfoActionTypes.SET_IS_MESSAGE_SENDING,
  payload: isMessageSending,
});

export const shiftMessagesToSend = (): ReturnActionType => ({
  type: MessagesInfoActionTypes.SHIFT_MESSAGES_TO_SEND,
});
