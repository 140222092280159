import React, { useRef, useState } from "react";
import debounce from "lodash.debounce";
import { useActions } from "../../hooks/useActions";
import { SearchType } from "../../store/types/search";

import SearchModal from "./components/search-modal/SearchModal";
import { SEARCH_EXHIBITIONS, SEARCH_USERS } from "../../api-queries/SearchRequests";
import {
  search,
  searchArtists,
  searchArtworks,
  searchInstitution,
} from "../../api-queries/GraohQlClient";
import { ISearchArtists, SearchProps } from "./Search.type";
import { SearchIcon } from "../../assets";

import "./Search.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";

/**
 * The Search Component
 * @typedef Props:
 * @property {string} placeholder - placeholder text
 */

const Search: React.FC<SearchProps> = ({ placeholder }) => {
  const { setSearch, setSearchModal } = useActions();
  const { searchModal } = useTypedSelector(state => state.searchReducer);
  // const [searchModalShow, setSearchModalShow] = useState(false);
  const [searchList, setSearchList] = useState<Array<any>>([]);
  const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): SearchType => {
    const { value } = event.target;
    const args = { text: value };
    if (value) {
      setSearchModal(true);
    } else {
      setSearchModal(false);
    }

    Promise.all([
      searchArtists(SEARCH_USERS, { text: value, types: ISearchArtists.ARTIST }),
      searchArtworks({
        searchableText: value,
      }),
      search(SEARCH_EXHIBITIONS, args),
      searchInstitution(args),
      search(SEARCH_USERS, { text: value, types: ISearchArtists.COLLECTOR }),
      searchArtists(SEARCH_USERS, {
        text: value,
      }),
    ]).then(data => {
      if (!value) {
        setSearchList([]);
      } else {
        setSearchList(data);
      }
    });
    return setSearch(value);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const handleInputFocus = (event: React.MouseEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    if (inputRef.current?.value) {
      setSearchModal(true);
    }
  };
  const debouncedOnChange = debounce(onChangeHandle, 500);
  return (
    <div className="search">
      <img className="search_icon" src={SearchIcon} alt="Search icon" />
      <input
        onClick={handleInputFocus}
        ref={inputRef}
        className="search_input"
        type="text"
        placeholder={placeholder}
        onChange={debouncedOnChange}
      />
      {searchModal && <SearchModal searchList={searchList} inputRef={inputRef} />}
    </div>
  );
};
export default Search;
