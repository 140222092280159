import React, { FC } from "react";
import { Skeleton } from "@mui/material";

import "./UserListItemSkeleton.scss";

interface UserListItemSkeletonProps {
  amount: number;
}

const UserListItemSkeleton: FC<UserListItemSkeletonProps> = ({ amount }) => {
  const items = [...Array(amount).keys()];

  return (
    <>
      {items.map(item => (
        <div key={item} className="user-list-item-skeleton">
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
          <div>
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />
          </div>
        </div>
      ))}
    </>
  );
};

export default UserListItemSkeleton;
