export interface TokenKeyState {
  tokenKey: string;
}

export enum TokenKeyActionTypes {
  ADD_TOKEN_KEY = "ADD_TOKEN_KEY",
}

interface AddTokenKeyAction {
  type: TokenKeyActionTypes.ADD_TOKEN_KEY;
  payload: string;
}

export type TokenAction = AddTokenKeyAction;
