import { ModalState, ModalAction, ModalActionTypes } from "../types/modal";

const initialValue: ModalState = {
  modalWindow: false,
  modalWindowLogin: false,
  reportModal: false,
};

const modalReducer = (state = initialValue, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalActionTypes.MODAL_OPEN:
      return { ...state, modalWindow: true };
    case ModalActionTypes.MODAL_CLOSE:
      return { ...state, modalWindow: false };
    case ModalActionTypes.MODAL_OPEN_LOGIN:
      return { ...state, modalWindowLogin: true };
    case ModalActionTypes.MODAL_CLOSE_LOGIN:
      return { ...state, modalWindowLogin: false };
    case ModalActionTypes.REPORT_OPEN:
      return { ...state, reportModal: true };
    case ModalActionTypes.REPORT_CLOSE:
      return { ...state, reportModal: false };
    default:
      return state;
  }
};
export default modalReducer;
