export interface SearchProps {
  placeholder: string;
}

export enum ISearchArtists {
  ARTIST = "artist",
  COLLECTOR = "collector",
}

export interface SearchModalProps {
  searchList: Array<any>;
  inputRef: any;
}
