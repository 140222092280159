import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery, useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import CardItem from "../../../../components/card-item/CardItem";
import { InstitutionRequests } from "../../../../api-queries/AllRequests";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IFilterCategory, IFilterTypes } from "../../../../types/filterTypes";
import ItemsNotFound from "../../../../components/items-not-found/ItemsNotFound";

import LocationFilterSelect from "../../../../components/location-filter-select/LocationFilterSelect";
import ExplorePageTitle from "../../../../components/explore-page-title/ExplorePageTitle";

import { pluralize } from "../../../../utils/pluralizeWord";
import FilterCategoriesSelect from "../../../../components/filter-categories-select/FilterCategoriesSelect";
import "../../../../components/institution-component/InstitutionComponent.scss";
import { useActions } from "../../../../hooks/useActions";
import { InstitutionsSearchActions } from "../../../../store/types/institutionSearch";
import { IInstitution } from "../../../../types/institution";
import { ILocationSelectValue } from "../../../../components/location-filter-select/utils";
import { ILocationFilter } from "../../../../context/types/artistsPageTypes";

const InstitutionSearch = ({
  availableForEvents,
  availableForFilmAndTV,
  isVirtual,
  title = "",
  text = "",
}: any): JSX.Element => {
  const limit = 50;
  const institutionState = useTypedSelector(state => state.institutionSearchReducer);
  const {
    myInstitutions,
    categories,
    filterQueryParams,
    offset,
    locationSortValue,
    locationAutocompleteValue,
  } = institutionState;
  const { filterWindowOpen } = useTypedSelector(state => state.filterReducer);
  const [loadCategoties] = useLazyQuery(InstitutionRequests.INSTITUTION_CATEGORIES);
  const { data: searchInstitutions, refetch } = useQuery(InstitutionRequests.SEARCH_INSTITUTIONS, {
    variables: {
      text,
      limit,
      availableForFilmAndTV,
      availableForEvents,
      isVirtual,
      ...filterQueryParams,
    },
  });
  const {
    SET_SEARCH_INSTITUTION_CATEGORIES,
    SET_SEARCH_INSTITUTIONS,
    SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS,
    SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE,
    SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE,
    SET_SEARCH_INSTITUTION_OFFSET,
  } = useActions();

  const total = searchInstitutions?.searchInstitutions.meta.total;
  const setCategories = (payload: Array<IFilterCategory>): InstitutionsSearchActions =>
    SET_SEARCH_INSTITUTION_CATEGORIES(payload);
  const setfilterQueryParams = (payload: any): InstitutionsSearchActions =>
    SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS(payload);
  const setOffset = (payload: number): InstitutionsSearchActions =>
    SET_SEARCH_INSTITUTION_OFFSET(payload);
  const setInstitutions = (payload: Array<IInstitution>): InstitutionsSearchActions =>
    SET_SEARCH_INSTITUTIONS(payload);
  const setLocationSortValue = (payload: ILocationSelectValue): InstitutionsSearchActions =>
    SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE(payload);
  const setLocationAutocompleteValue = (
    payload: ILocationFilter | null,
  ): InstitutionsSearchActions => SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE(payload);

  const initialCategoriesId = useMemo(
    () => categories.filter((el: any) => el.checked).map((el: any) => el.id),
    [categories],
  );

  const [chekedCategoriesId, setChekedCategoriesId] = React.useState(initialCategoriesId || []);

  const routerLocation = useLocation();
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    setInstitutions([]);
    refetch();
  }, [routerLocation]);

  const handleChangeCategories = (event: any): void => {
    const {
      target: { value },
    } = event;

    setOffset(0);
    setInstitutions([]);

    if (value.includes("clear")) {
      setChekedCategoriesId([]);
      setCategories(
        categories.map((category: any) => {
          return { ...category, checked: false };
        }),
      );
      setfilterQueryParams({
        ...filterQueryParams,
        kategories: undefined,
      });
      return;
    }

    const newChecked = typeof value === "string" ? value.split(",") : value;
    const newCategories = newChecked.length ? newChecked : undefined;
    setChekedCategoriesId(newChecked);
    setCategories(
      categories.map((category: any) => {
        return newChecked.includes(category.id)
          ? { ...category, checked: true }
          : { ...category, checked: false };
      }),
    );
    setfilterQueryParams({
      ...filterQueryParams,
      kategories: newCategories,
    });
  };

  const getCategories = async (): Promise<void> => {
    const res = await loadCategoties();
    const defaultCategories = res.data.kategories.map((category: any) => {
      return {
        id: category._id,
        type: IFilterTypes.Categories,
        checked: false,
        value: category.name,
      };
    });
    setCategories(categories.length ? categories : defaultCategories);
  };

  useEffect(() => {
    if (searchInstitutions?.searchInstitutions?.institutions.length) setShowFilters(true);
    if (!myInstitutions.length) {
      setInstitutions([
        ...myInstitutions,
        ...(searchInstitutions?.searchInstitutions?.institutions || []),
      ]);
    }
  }, [searchInstitutions]);

  useEffect(() => {
    if (!categories.length) {
      getCategories();
    }
  }, []);

  const scrollHandler = async (): Promise<void> => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
      return;
    }
    if (myInstitutions.length >= 50) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setInstitutions([...myInstitutions, ...(res.data?.searchInstitutions?.institutions || [])]);
    }
  };

  const refetchInstitutions = async (): Promise<void> => {
    const res = await refetch({ text });
    setInstitutions([...res.data?.searchInstitutions.institutions]);
  };

  useEffect(() => {
    setShowFilters(false);
    refetchInstitutions();
  }, [text]);
  return (
    <div className="venues wrapper">
      {title && <ExplorePageTitle title={title} myMoca shareMark />}
      {showFilters && (
        <div className="filter_top_bar">
          <FilterCategoriesSelect
            handleChange={handleChangeCategories}
            options={categories}
            chekedOptions={chekedCategoriesId}
          />
          <LocationFilterSelect
            width="350px"
            setDataItem={setInstitutions}
            locationAutocompleteValue={locationAutocompleteValue}
            setLocationAutocompleteValue={setLocationAutocompleteValue}
            locationSortValue={locationSortValue}
            setLocationSortValue={setLocationSortValue}
            setOffset={setOffset}
            filterQueryParams={filterQueryParams}
            setFilterQueryParams={setfilterQueryParams}
          />
        </div>
      )}
      <div
        className={classNames("explore_grid", {
          active: filterWindowOpen,
        })}
      >
        <div className="explore_list">
          {showFilters && (
            <div className="filter_results__content">
              <div className="filter_results__left_content">
                <span className="filter_results__result">
                  {total?.toLocaleString()} {pluralize("Institution", total)}
                </span>
              </div>
            </div>
          )}
          {total === 0 && <ItemsNotFound title="No results found" />}
          {myInstitutions && (
            <InfiniteScroll
              scrollThreshold={0.7}
              dataLength={myInstitutions.length}
              next={scrollHandler}
              hasMore
              loader=""
            >
              <div className="card_wrap">
                {myInstitutions.map((institution: any) => (
                  <CardItem
                    key={institution._id}
                    img={institution.owner?.profile?.image}
                    name={institution.name || institution.title}
                    id={institution._id}
                    location={institution?.exhibitions[0]?.location}
                    data={institution}
                    isOpenToSubmitions={undefined}
                    duration={undefined}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstitutionSearch;
