import { CommentsActionType } from "../types/comments";
import { ReturnActionType } from "../types/return-type";

export const setComment = (payload: any): ReturnActionType => ({
  type: CommentsActionType.COMMENTS,
  payload,
});

export const setCommentReply = (payload: any): ReturnActionType => ({
  type: CommentsActionType.COMMENTS_REPLY,
  payload,
});

export const addComment = (payload: any): ReturnActionType => ({
  type: CommentsActionType.ADD_COMMENT,
  payload,
});

export const addCommentReply = (payload: any): ReturnActionType => ({
  type: CommentsActionType.ADD_COMMENT_REPLY,
  payload,
});

export const removeMyComment = (id: string): ReturnActionType => ({
  type: CommentsActionType.REMOVE_COMMENT,
  id,
});

export const removeMyCommentReply = (id: string): ReturnActionType => ({
  type: CommentsActionType.REMOVE_COMMENT_REPLY,
  id,
});
