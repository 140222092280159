import { IResultButton } from "../../components/filter-units/utils";
import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { SearchArtworksRequest } from "../../types/artwork/artwork";
import { IFilterCategory } from "../../types/filterTypes";
import { IInstitution, IInstitutionSearch } from "../../types/institution";
import { ILocationFilter } from "./artistsPageTypes";

export enum InstitutionsPageActionTypes {
  SET_FILTER_QUERY_PARAMS = "SET_FILTER_QUERY_PARAMS",
  SET_INSTITUTIONS = "SET_INSTITUTIONS",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_OFFSET = "SET_OFFSET",
  SET_FILTER_RESULTS = "SET_FILTER_RESULTS",
  SET_LOCATION_SORT_VALUE = "SET_LOCATION_SORT_VALUE",
  SET_LOCATION_AUTOCOMPLETE_VALUE = "SET_LOCATION_AUTOCOMPLETE_VALUE",
}

export interface IInstitutionPageState {
  filterQueryParams: SearchArtworksRequest;
  myInstitutions: IInstitution[];
  offset: number;
  categories: IFilterCategory[];
  filterResults: IResultButton[];
  locationSortValue: ILocationSelectValue;
  locationAutocompleteValue: ILocationFilter | null;
}

export interface setfilterQueryParams {
  type: InstitutionsPageActionTypes.SET_FILTER_QUERY_PARAMS;
  payload: IInstitutionSearch;
}

export interface setInstitutions {
  type: InstitutionsPageActionTypes.SET_INSTITUTIONS;
  payload: IInstitution[];
}

export interface setCategories {
  type: InstitutionsPageActionTypes.SET_CATEGORIES;
  payload: IFilterCategory[];
}

export interface setOffset {
  type: InstitutionsPageActionTypes.SET_OFFSET;
  payload: number;
}

export interface setFilterResults {
  type: InstitutionsPageActionTypes.SET_FILTER_RESULTS;
  payload: IResultButton[];
}
export interface setLocationSortValue {
  type: InstitutionsPageActionTypes.SET_LOCATION_SORT_VALUE;
  payload: ILocationSelectValue;
}

export interface setLocationAutocompleteValue {
  type: InstitutionsPageActionTypes.SET_LOCATION_AUTOCOMPLETE_VALUE;
  payload: ILocationFilter | null;
}

export type InstitutionsPageActions =
  | setfilterQueryParams
  | setInstitutions
  | setCategories
  | setOffset
  | setFilterResults
  | setLocationSortValue
  | setLocationAutocompleteValue;
