import React, { FC } from "react";
import { useActions } from "../../../hooks/useActions";
import { IChatMessageContentProps } from "../utils";

import "./PostMessage.scss";

const PostMessage: FC<IChatMessageContentProps> = ({ message }) => {
  const { openModal } = useActions();
  const { post } = message.audited;
  const postAuthorNickname = `${post.owner.profile.firstName} ${post.owner.profile.secondName}`;
  const renderImage = (): string => {
    const postImage = post?.imagesOfPost[0]?.medium.url;
    const postAuthorAvatar = post.owner.profile.image.medium.url;
    return postImage || postAuthorAvatar;
  };

  const postText = post?.text && post.text.length > 70 ? `${post.text.slice(0, 70)}...` : post.text;

  return (
    <div className="post-message" onClick={openModal}>
      <img className="chat-message_image" src={renderImage()} alt="" />
      <div className="post-message_content">
        <p className="post-message_content_text">{postText}</p>
        <p className="post-message_content_nickname">{postAuthorNickname}</p>
      </div>
      <p className="chat-message_text">{message.text}</p>
    </div>
  );
};

export default PostMessage;
