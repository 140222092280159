import { gql } from "@apollo/client";
import { INSTITUTION } from "./request-params/RequestsParams";

export const INSTITUTION_CATEGORIES = gql`
  query kategories($text: String) {
    kategories(text: $text) {
      _id
      name
      description
    }
  }
`;

export const SEARCH_INSTITUTIONS = gql`
    query searchInstitutions(
        $sortBy: String
        $orderBy: orderBy
        $limit: Int
        $offset: Int
        $text: String
        $isVirtual: Boolean
        $availableForFilmAndTV: Boolean
        $availableForEvents: Boolean
        $location: LocationSearchType
        $duration: ExhibitionDurationInput
        $kategories: [String]
    ) {
        searchInstitutions(
            sortBy: $sortBy
            orderBy: $orderBy
            text: $text
            limit: $limit
            offset: $offset
            isVirtual: $isVirtual
            availableForFilmAndTV: $availableForFilmAndTV
            availableForEvents: $availableForEvents
            location: $location
            duration: $duration
            kategories: $kategories
        ) {
            institutions {
                ${INSTITUTION}
            }
            meta {
                limit
                offset
                total
            }
        }
    }`;

export const ADD_INSTITUTION = `
mutation addInstitution(
  $name: String
  $description: String
  $isVirtual: Boolean
  $title: String
  $daysOpen: [DaysOpenInputType]
  $location: LocationInputType
  $kategory: ID
  $private: Boolean
  $isSolo: Boolean
  $availableForFilmAndTV: Boolean
  $availableForEvents: Boolean
  $isOpenToSubmitions: Boolean
  $imagesOfInstitution: [ImageInput]
) {
  addInstitution(
    name: $name
    description: $description
    isVirtual: $isVirtual
    availableForFilmAndTV: $availableForFilmAndTV
    availableForEvents: $availableForEvents
    kategory: $kategory
    imagesOfInstitution: $imagesOfInstitution

    exhibitions: {
      title: $title
      description: $description
      daysOpen: $daysOpen
      location: $location
      private: $private
      isSolo: $isSolo
      isOpenToSubmitions: $isOpenToSubmitions
    }
  ) {
    _id
    exhibitions {
      _id
      title
      description
      private
      isSolo
      isOpenToSubmitions
      location {
        name
        coordinates {
          latitude
          longitude
        }
      }
      duration {
        from
        to
      }
      daysOpen {
        dayOfWeek
        hoursOpen {
          from
          to
        }
      }
    }
  }
}`;

export const EDIT_INSTITUTION = `
mutation updateInstitution(
  $_id: ID!
  $name: String
  $description: String
  $isVirtual: Boolean
  $availableForFilmAndTV: Boolean
  $availableForEvents: Boolean
  $title: String
  $daysOpen: [DaysOpenInputType]
  $location: LocationInputType
  $kategory: ID
  $private: Boolean
  $isSolo: Boolean
  $exhibitionId: ID
  $isOpenToSubmitions: Boolean
  $imagesOfInstitution: [ImageInput]
) {
  updateInstitution(
    _id: $_id,
    name: $name
    description: $description
    isVirtual: $isVirtual
    availableForFilmAndTV: $availableForFilmAndTV
    availableForEvents: $availableForEvents
    kategory: $kategory
    imagesOfInstitution: $imagesOfInstitution
    exhibitions: {
      _id: $exhibitionId
      title: $title
      description: $description
      daysOpen: $daysOpen
      location: $location
      private: $private
      isSolo: $isSolo
      isOpenToSubmitions: $isOpenToSubmitions
    }) {
    ${INSTITUTION}
  }
}`;
