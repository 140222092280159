import React, { useEffect, useState } from "react";
import LeafletMap from "../leaflet-maps/LeafletMap";
import { getButtontitle, IlocationTab } from "./utils";
import Tabs from "../tabs/Tabs";

import "./ArtworkDetailsMap.scss";
import { ILocationState } from "../artworks-explore-item-details/utils";

interface IArtworkDetailsMap {
  artwork: ILocationState;
}

const ArtworkDetailsMap: React.FC<IArtworkDetailsMap> = ({ artwork }) => {
  const [locationTabs, setLocationTabs] = useState<IlocationTab[]>([]);
  const [tabActive, setTabActive] = useState<string>("");

  // eslint-disable-next-line
  const getLocationButtons = (artwork: ILocationState): void => {
    if (artwork) {
      const locationArray = Object.entries(artwork).filter(
        value => value[1] && value[0].startsWith("location"),
      );
      const locationTab: IlocationTab[] = locationArray.map(([key, value]) => ({
        title: getButtontitle(key),
        location: value,
      }));
      setLocationTabs(locationTab);
      if (locationTab.length > 0) setTabActive(locationTab[0].title);
    }
  };

  const onLocationTabClick = (title: string): void => {
    setTabActive(title);
  };

  useEffect(() => {
    getLocationButtons(artwork);
  }, [artwork]);

  return (
    <>
      {artwork && !!locationTabs.length && (
        <div className="artworks_details_map">
          <div className="artworks_details_map__tabs">
            {locationTabs.map((value: IlocationTab) => (
              <Tabs
                key={value.title}
                title={value.title}
                className="artworks_details_map__tabs_item"
                onClick={onLocationTabClick}
                active={value.title === tabActive}
              />
            ))}
          </div>
          <div className="artworks_details_map__map">
            <LeafletMap
              activeTab={tabActive}
              location={artwork}
              zoom={13}
              locations={undefined}
              exhibitions={undefined}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ArtworkDetailsMap;
