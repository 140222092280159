import React, { FC } from "react";
import { ListItemText, Select, Checkbox, MenuItem } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { FilterCategoriesSelectProps } from "./FilterCategoriesSelect.props";
import { MenuProps, renderCategoriesValue } from "./utils";

const FilterCategoriesSelect: FC<FilterCategoriesSelectProps> = ({
  options,
  chekedOptions,
  handleChange,
}) => {
  const isClearButtonDisabled = !chekedOptions.length;
  return (
    <>
      <Select
        className="select_sort"
        labelId="simple-select-label"
        id="simple-select"
        value={chekedOptions}
        onChange={handleChange}
        multiple
        displayEmpty
        renderValue={renderCategoriesValue(options)}
        sx={{ width: 350, height: 50 }}
        MenuProps={MenuProps}
      >
        <MenuItem value="clear" disabled={isClearButtonDisabled}>
          <Checkbox icon={<ClearIcon />} />
          <ListItemText primary="Clear All" />
        </MenuItem>
        {options.map(option => {
          return (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={chekedOptions.includes(option.id)} />
              <ListItemText primary={option.value} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default FilterCategoriesSelect;
