import React, { FC } from "react";
import NotificationSkeleton from "./NotificationSkeleton";

const NotificationsSkeletonsList: FC = () => {
  return (
    <>
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
    </>
  );
};

export default NotificationsSkeletonsList;
