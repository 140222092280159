export interface SearchState {
  searchValue: string;
  searchModal: boolean;
}

export enum SearchActionTypes {
  SEARCH_VALUE = "SEARCH_VALUE",
  SEARCH_MODAL = "SEARCH_MODAL",
}

interface SetSearch {
  type: SearchActionTypes.SEARCH_VALUE;
  payload: string;
}

interface SetSearchModal {
  type: SearchActionTypes.SEARCH_MODAL;
  payload: boolean;
}

export type SearchType = SetSearch | SetSearchModal;
