import {
  UploadImageAction,
  UploadImageActionTypes,
  UploadImagesState,
} from "../types/upload-image";

const initialValue: UploadImagesState = {
  profile: "",
  profileBackground: "",
  artwork: "",
};

const uploadImagesReducer = (
  state = initialValue,
  action: UploadImageAction,
): UploadImagesState => {
  switch (action.type) {
    case UploadImageActionTypes.PROFILE:
      return { ...state, profile: action.payload };
    case UploadImageActionTypes.BACKGROUND_PROFILE:
      return { ...state, profileBackground: action.payload };
    case UploadImageActionTypes.ARTWORK:
      return { ...state, artwork: action.payload };
    default:
      return state;
  }
};

export default uploadImagesReducer;
