import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { IShowDurationSelectValue } from "../../explore-pages/shows-page/utils";
import { IShowExhibitions } from "../../types/exhibitions";
import { ILocationFilter } from "../types/artistsPageTypes";
import { ShowsPageActions, ShowsPageActionTypes } from "../types/showsPageTypes";

export const SET_SHOWS = (payload: IShowExhibitions[]): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_SHOWS,
  payload,
});

export const SET_OFFSET = (payload: number): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_OFFSET,
  payload,
});

export const SET_DURATION_SORT_VALUE = (payload: IShowDurationSelectValue): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_DURATION_SORT_VALUE,
  payload,
});

export const SET_FILTER_QUERY_PARAMS = (payload: any): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_FILTER_QUERY_PARAMS,
  payload,
});

export const SET_CALENDAR_VALUE = (payload: string): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_CALENDAR_VALUE,
  payload,
});

export const SET_LOCATION_SORT_VALUE = (payload: ILocationSelectValue): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_LOCATION_SORT_VALUE,
  payload,
});

export const SET_LOCATION_AUTOCOMPLETE_VALUE = (
  payload: ILocationFilter | null,
): ShowsPageActions => ({
  type: ShowsPageActionTypes.SET_LOCATION_AUTOCOMPLETE_VALUE,
  payload,
});
