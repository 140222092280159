import React, { FC } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { ClickAwayListener, Tooltip } from "@mui/material";
import ShareButton from "../share-button/ShareButton";
import { ExplorePageTitleProps } from "./explore-page.type";

import "./ExplorePageTitle.scss";
import BackButton from "../back-button/BackButton";

/**
 * The ExplorePageTitle Functional Component
 * @typedef Props:
 * @property {boolean} shareMark - boolean checker show or hidden share mark
 * @property {boolean} questionMark - boolean checker show or hidden question mark
 * @property {boolean} backButton - boolean checker show or hidden back button
 * @property {boolean} myMoca - boolean checker show or hidden "myMoca" text
 * @property {Array} tooltipText - component from tooltip
 * @typedef TextFieldProps
 * @property {string} title - text page title
 */

const ExplorePageTitle: FC<ExplorePageTitleProps> = ({
  title,
  shareMark,
  questionMark,
  myMoca,
  tooltipText,
  backButton,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = (): void => setOpen(false);
  const handleTooltipOpen = (): void => setOpen(true);

  return (
    <div className="explore_page_title">
      {myMoca && <h4 className="explore_page_title__moca">MY MOCA</h4>}
      <div className="explore_page_title__grid">
        <h2 className="explore_page_title__title">
          {backButton && <BackButton className="back-button" />}
          {title}
          {questionMark && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipText}
                >
                  <QuestionMarkIcon onClick={handleTooltipOpen} className="question_mark_item" />
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
        </h2>
        {shareMark && <ShareButton />}
      </div>
    </div>
  );
};

export default ExplorePageTitle;
