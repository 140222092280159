import { initialLocationSortValue } from "../../components/location-filter-select/utils";
import {
  IInstitutionSearchState,
  InstitutionsSearchActions,
  InstitutionsSearchActionTypes,
} from "../types/institutionSearch";

export const initialInstitutionPageState: IInstitutionSearchState = {
  myInstitutions: [],
  filterQueryParams: {},
  offset: 0,
  categories: [],
  filterResults: [],
  locationAutocompleteValue: null,
  locationSortValue: initialLocationSortValue,
};

export const institutionSearchReducer = (
  state = initialInstitutionPageState,
  action: InstitutionsSearchActions,
): IInstitutionSearchState => {
  switch (action.type) {
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTIONS:
      return {
        ...state,
        myInstitutions: [...action.payload],
      };
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS: {
      return {
        ...state,
        filterQueryParams: {
          ...action.payload,
        },
      };
    }
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
      };
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_FILTER_RESULTS:
      return {
        ...state,
        filterResults: [...action.payload],
      };
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE:
      return {
        ...state,
        locationSortValue: action.payload,
      };
    case InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        locationAutocompleteValue: action.payload,
      };
    default:
      return state;
  }
};
