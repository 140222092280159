import React, { ChangeEvent, FC, useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { IFilterTypes, IFilter } from "../../types/filterTypes";
import { IArtworksPageFilterContext } from "../../context/exploreContext";

import "./FilterOriginsOfInspiration.scss";
import { IArtistsPageFilterContext } from "../../explore-pages/artists-page/utils";

interface IFilterOriginsOfInspiration {
  context: IArtworksPageFilterContext | IArtistsPageFilterContext;
  setItems: (param: any) => void;
  filterQueryName: string;
}

const FilterOriginsOfInspiration: FC<IFilterOriginsOfInspiration> = ({
  context,
  filterQueryName,
  setItems,
}): JSX.Element => {
  const [inputValue, setInputValue] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const {
    filterResults: { setFilterResults, filterResults },
    filterQueryParams: { setfilterQueryParams, filterQueryParams },
    originsOfInspiration: { originsOfInspiration, setOriginsOfInspiration },
    offset: { setOffset },
  } = context;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };
  const onClearClick = (): void => {
    setInputValue("");
  };
  const onApplyClick = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    const trimInputValue = inputValue.trim();
    const hasSameValue = originsOfInspiration.some(
      (item: IFilter) => item.value.toLowerCase() === trimInputValue.toLowerCase(),
    );
    if (hasSameValue) return;

    const newOriginsOfInspiration = [
      ...originsOfInspiration,
      { value: trimInputValue, id: `${trimInputValue}${IFilterTypes.OriginsOfInspiration}` },
    ];
    const filterValues = newOriginsOfInspiration.map(item => item.value);
    setOffset(0);
    setOriginsOfInspiration(newOriginsOfInspiration);
    setItems([]);
    setfilterQueryParams({ ...filterQueryParams, [filterQueryName]: filterValues, offset: 0 });
    setFilterResults([
      ...filterResults,
      {
        title: trimInputValue,
        id: `${trimInputValue}${IFilterTypes.OriginsOfInspiration}`,
        type: IFilterTypes.OriginsOfInspiration,
      },
    ]);
    setInputValue("");
  };

  useEffect(() => {
    if (inputValue.trim()) {
      setButtonDisabled(false);

      return;
    }
    setButtonDisabled(true);
  }, [inputValue]);
  return (
    <div className="filter_origins_of_inspiration">
      <form onSubmit={onApplyClick}>
        <div className="filter_origins_of_inspiration__input_wpapper">
          <div className="filter_origins_of_inspiration__input">
            <SearchIcon className="filter_origins_of_inspiration__searchIcon" />
            <InputBase
              value={inputValue}
              className="filter_origins_of_inspiration__input_base"
              placeholder="Type inspiration name here"
              onChange={onInputChange}
            />
            <ClearIcon
              fontSize="small"
              className="filter_origins_of_inspiration__clear"
              onClick={onClearClick}
            />
          </div>
        </div>
        <button
          onClick={onApplyClick}
          disabled={buttonDisabled}
          className="filter_price__input filter_price__button"
        >
          Apply
        </button>
      </form>
    </div>
  );
};

export default FilterOriginsOfInspiration;
