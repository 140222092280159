import React, { useEffect, useRef, useState } from "react";
import { Menu } from "@mui/material";
import { useLocation } from "react-router-dom";

import "./MessagesModal.scss";
import { paperProps } from "./MessagesModal.style";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { messagesRoute } from "../../route-link";
import MessagesMenu from "../messages-menu/MessagesMenu";

interface MessagesModalProps {
  isOpen: boolean;
  close: () => void;
  anchorEl: HTMLDivElement | null;
  refetchUnreadMessagesCount: () => void;
}

const MessagesModal: React.FC<MessagesModalProps> = ({
  isOpen,
  close,
  anchorEl,
  refetchUnreadMessagesCount,
}) => {
  const { currentChat, currentStage, currentScroll, messageToSend, isOnArtwork } = useTypedSelector(
    reduxStore => reduxStore.messagesInfoReducer,
  );
  const [showScrollToBottomButton, setShowScrollToBottomButton] = useState<boolean>(false);
  const messagesModalRef = useRef<HTMLDivElement>(null);
  const routerLocation = useLocation();

  const {
    setCurrentChat,
    setCurrentStage,
    setCurrentMessages,
    setCurrentChatOffset,
    setCurrentScroll,
    setMessageToSend,
    setChatsListScroll,
    setIsOnArtwork,
  } = useActions();

  const handleShowScrollToBottomButton = (): void => {
    if (
      messagesModalRef.current?.children[2] &&
      messagesModalRef.current?.children[2].scrollTop <
        messagesModalRef.current?.children[2].scrollHeight - 1000
    ) {
      setShowScrollToBottomButton(true);
      return;
    }
    setShowScrollToBottomButton(false);
  };

  const subscribeForScroll = (): void => {
    if (messagesModalRef.current?.children[2]) {
      messagesModalRef.current.children[2].addEventListener(
        "scroll",
        handleShowScrollToBottomButton,
      );
    }
  };

  const unsubscribeForScroll = (): void => {
    if (messagesModalRef.current?.children[2]) {
      messagesModalRef.current.children[2].removeEventListener(
        "scroll",
        handleShowScrollToBottomButton,
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpen && messagesModalRef.current && currentScroll) {
        messagesModalRef.current.children[2].scrollTop = currentScroll;
        setCurrentScroll(undefined);
      }
      if (isOpen && messagesModalRef.current) {
        subscribeForScroll();
        return;
      }
      unsubscribeForScroll();
    });
  }, [isOpen]);

  useEffect(() => {
    if (messageToSend) {
      setCurrentStage("search-users");
      return;
    }
    if (currentChat) {
      if (messagesModalRef?.current?.children[2]) {
        setChatsListScroll(messagesModalRef.current.children[2].scrollTop);
      }
      setCurrentStage("chat");
      return;
    }
    refetchUnreadMessagesCount();

    if (!isOnArtwork) {
      setCurrentChatOffset(0);
      setCurrentMessages([]);
      return;
    }
    setIsOnArtwork(false);
  }, [currentChat]);

  useEffect(() => {
    if (currentStage !== "chat" && routerLocation.pathname !== `/${messagesRoute}` && isOpen) {
      setCurrentChat(null);
    }
  }, [currentStage]);

  const scrollModalToBottom = (smooth = false): void => {
    if (messagesModalRef.current) {
      if (smooth) {
        messagesModalRef.current.children[2].scrollTo({
          top: messagesModalRef.current.children[2].scrollHeight,
          left: 0,
          behavior: "smooth",
        });
        return;
      }
      messagesModalRef.current.children[2].scrollTop =
        messagesModalRef.current.children[2].scrollHeight;
    }
  };

  const scrollOnLoad = (): void => {
    if (messagesModalRef.current && messagesModalRef.current.children[2].scrollTop === 0) {
      messagesModalRef.current.children[2].scrollTop = 10;
    }
  };

  const saveCurrentScroll = (): void => {
    setCurrentScroll(messagesModalRef.current?.children[2].scrollTop);
  };

  const scrollModalTo = (Ycoords: number): void => {
    if (messagesModalRef.current) {
      messagesModalRef.current.children[2].scrollTop = Ycoords;
    }
  };

  const handleClose = (): void => {
    if (messagesModalRef?.current?.children[2]) {
      setCurrentScroll(messagesModalRef.current.children[2].scrollTop);
      setMessageToSend(null);
      close();
    }
  };
  return (
    <Menu
      id="messages-modal"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      PaperProps={{
        sx: paperProps,
      }}
      ref={messagesModalRef}
    >
      <MessagesMenu
        scrollModalToBottom={scrollModalToBottom}
        saveCurrentScroll={saveCurrentScroll}
        scrollModalTo={scrollModalTo}
        getUnreadMessages={refetchUnreadMessagesCount}
        scrollOnLoad={scrollOnLoad}
        showScrollToBottomButton={showScrollToBottomButton}
      />
    </Menu>
  );
};

export default MessagesModal;
