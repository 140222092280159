import React, { FC } from "react";
import { getColorByUserId } from "../artist-or-institution-profile/utils";

import "./AvatarPlaceholder.scss";

interface AvatarPlaceholderProps {
  className?: string;
  id: string | undefined;
  width: string;
  height: string;
  fontSize?: string;
  onClick?: () => void;
}

const AvatarPlaceholder: FC<AvatarPlaceholderProps> = ({
  className,
  id,
  width,
  height,
  fontSize,
  onClick,
}) => {
  const placeholderColor = id && getColorByUserId(id)[0];
  const fontColor = id && getColorByUserId(id)[1];

  return (
    <div
      className={`avatar__placeholder ${className}`}
      style={{ width, height, backgroundColor: placeholderColor }}
      onClick={onClick}
    >
      <span style={{ fontSize, color: fontColor }}>MOCA</span>
    </div>
  );
};

export default AvatarPlaceholder;
