import { IResultButton } from "../../components/filter-units/utils";
import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { SearchArtworksRequest } from "../../types/artwork/artwork";
import { IFilterCategory } from "../../types/filterTypes";
import { IInstitution, IInstitutionSearch } from "../../types/institution";

export enum InstitutionsSearchActionTypes {
  SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS = "SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS",
  SET_SEARCH_INSTITUTIONS = "SET_SEARCH_INSTITUTIONS",
  SET_SEARCH_INSTITUTION_CATEGORIES = "SET_SEARCH_INSTITUTION_CATEGORIES",
  SET_SEARCH_INSTITUTION_OFFSET = "SET_SEARCH_INSTITUTION_OFFSET",
  SET_SEARCH_INSTITUTION_FILTER_RESULTS = "SET_SEARCH_INSTITUTION_FILTER_RESULTS",
  SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE = "SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE",
  SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE = "SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE",
}

export interface ILocationFilter {
  label: string;
  value: any;
}

export interface IInstitutionSearchState {
  filterQueryParams: SearchArtworksRequest;
  myInstitutions: IInstitution[];
  offset: number;
  categories: IFilterCategory[];
  filterResults: IResultButton[];
  locationSortValue: ILocationSelectValue;
  locationAutocompleteValue: ILocationFilter | null;
}

export interface setfilterQueryParams {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS;
  payload: IInstitutionSearch;
}

export interface setInstitutions {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTIONS;
  payload: IInstitution[];
}

export interface setCategories {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_CATEGORIES;
  payload: IFilterCategory[];
}

export interface setOffset {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_OFFSET;
  payload: number;
}

export interface setFilterResults {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_FILTER_RESULTS;
  payload: IResultButton[];
}
export interface setLocationSortValue {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE;
  payload: ILocationSelectValue;
}

export interface setLocationAutocompleteValue {
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE;
  payload: ILocationFilter | null;
}

export type InstitutionsSearchActions =
  | setfilterQueryParams
  | setInstitutions
  | setCategories
  | setOffset
  | setFilterResults
  | setLocationSortValue
  | setLocationAutocompleteValue;
