import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";

import "./IncomingTransfersPage.scss";
import { USER_INCOMING_TRANSFERS } from "../../api-queries/ArtworksRequests";
import { IArtwork } from "../../types/artwork/artwork";

const IncomingTransfersPage = (): JSX.Element => {
  const { id } = useParams();
  const { data, refetch } = useQuery(USER_INCOMING_TRANSFERS, {
    variables: { _id: id },
  });
  const [artworks, setArtworks] = useState<(IArtwork | undefined)[] | null>(null);

  useEffect(() => {
    if (data?.user?.collectionRequests) {
      setArtworks(data.user.collectionRequests);
    }
  }, [data]);

  return (
    <div className="incoming_transfers">
      <ArtsControllerList
        artworks={artworks}
        refetch={refetch}
        title="Incoming Transfers"
        displayFullArtworkInfo
      />
    </div>
  );
};

export default IncomingTransfersPage;
