import { POST_AUDITED } from "./PostAuditedRequestParams";
import { LOCATION } from "./LocationRequestParams";
import { IMAGE } from "./ImageRequestParams";

export const POST_LITE_WITH_AUDITED = `
  _id

  text
  isLiked
  likesCount
  sharesCount
  shareLocation {
    ${LOCATION}
  }
  commentsCount
  createdAt
  audited {
    ${POST_AUDITED}
  }
  shareLocation {
    ${LOCATION}
  }
  owner {
    _id
    likedArtworks
    hasInstitution
    institutions {
      name
    }
    profile {
      image {
        ${IMAGE}
      }
      firstName
      secondName
      middleName
    }
  }
`;
