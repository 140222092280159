import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RegistrationForm from "../registartion-form/RegistrationForm";

import "./Signup.scss";
import RegistrationFormInstitution from "../registartion-form/RegistrationFormInstitution";

const Signup: React.FC = () => {
  const [value, setValue] = useState("individual");
  const [checked, setChecked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (event: React.SyntheticEvent<EventTarget>, newValue: string) => {
    setValue(newValue);
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="signup">
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab className="registration_tab__item" label="Individual" value="individual" />
          <Tab className="registration_tab__item" label="Institution" value="institution" />
        </TabList>
        <TabPanel value="individual">
          <RegistrationForm checked={checked} handleChangeCheck={handleChangeCheck} />
        </TabPanel>
        <TabPanel value="institution">
          <RegistrationFormInstitution handleChangeCheck={handleChangeCheck} checked={checked} />
        </TabPanel>
      </TabContext>
    </div>
  );
};
export default Signup;
