import React, { useState, createContext, useContext, useRef } from "react";

export const CreateTransferContext = createContext(null);

export const CreateTransferContextProvider = ({ children, filmsAndTv }) => {
  const [showCreateTransferModal, setShowCreateTransferModal] = useState(false);
  const [isSearchStage, setIsSearchStage] = useState(true);
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState("");
  const [notes, setNotes] = useState("");
  const [locationLives, setLocationLives] = useState(null);
  const [tradeForValue, setTradeForValue] = useState("");
  const [tradeForObject, setTradeForObject] = useState("");
  const [loanToName, setLoanToName] = useState("");
  const [loanToDate, setLoanToDate] = useState(null);
  const [saleable, setSaleable] = useState(false);
  const [availableForLoan, setAvailableForLoan] = useState(false);
  const [conditionReport, setConditionReport] = useState("");
  const [typeOfTransfer, setTypeOfTransfer] = useState("Sale");
  const [images, setImages] = useState([]);
  const [filmsOrTv, setFilmsOrTv] = useState([]);
  const [hasFilmsOrTv, setHasFilmsOrTv] = useState(filmsAndTv);
  const [tradeFor, setTradeFor] = useState(false);
  const [error, setError] = useState("");
  const [locationIsPrivate, setLocationIsPrivate] = useState(false);
  const [chosenCollector, setChosenCollector] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState("Operators");
  const [searchText, setSearchText] = useState("");
  const initialHasFilmsOrTv = useRef(filmsAndTv);

  const setInitialHasFilmsOrTv = val => {
    initialHasFilmsOrTv.current = val;
  };

  const resetAllFields = () => {
    setPrice("");
    setCommission("");
    setNotes("");
    setLocationLives(null);
    setTradeForValue("");
    setTradeForObject("");
    setLoanToName("");
    setLoanToDate(null);
    setSaleable(false);
    setAvailableForLoan(false);
    setConditionReport("");
    setTypeOfTransfer("");
    setLocationIsPrivate(false);
    setSearchResults([]);
    setSearchText("");
    setSearchType("Operators");
  };

  const handleChangePrice = (e, prevState = null) => {
    if (prevState !== null) {
      setPrice(prevState);
      return;
    }
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value) || !e.target.value.length) {
      setPrice(e.target.value);
    }
  };

  const handleChangeTerms = (e, prevState = null) => {
    if (prevState !== null) {
      setConditionReport(prevState);
      return;
    }
    setConditionReport(e.target.value);
  };

  const handleChangeNotes = (e, prevState = null) => {
    if (prevState !== null) {
      setNotes(prevState);
      return;
    }
    setNotes(e.target.value);
  };

  const handleChangeCommission = (e, prevState = null) => {
    if (prevState !== null) {
      setCommission(prevState);
      return;
    }
    setCommission(e.target.value);
  };

  const handleChangeTradeForValue = (e, prevState = null) => {
    if (prevState !== null) {
      setTradeForValue(prevState);
      return;
    }
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value) || !e.target.value.length) {
      setTradeForValue(e.target.value);
    }
  };

  const handleChangeTradeForObject = (e, prevState = null) => {
    if (prevState !== null) {
      setTradeForObject(prevState);
      return;
    }
    setTradeForObject(e.target.value);
  };

  const handleChangeLocationLives = value => {
    setLocationLives(value);
  };

  const handleChangeLoanToName = (e, prevState = null) => {
    if (prevState !== null) {
      setLoanToName(prevState);
      return;
    }
    setLoanToName(e.target.value);
  };

  const handleChangeLoanToDate = (e, prevState = null) => {
    if (prevState !== null) {
      setLoanToDate(prevState);
      return;
    }
    setLoanToDate(e.target.value);
  };

  const handleChangeError = value => {
    setError(value);
    setTimeout(() => setError(""), 3000);
  };

  const handleChangeImages = newImages => {
    if (newImages.length > 4) {
      handleChangeError("You cannot load more than 4 images");
      return;
    }
    setImages(newImages);
  };

  const toggleLocationIsPrivate = () => {
    setLocationIsPrivate(prev => !prev);
  };

  return (
    <CreateTransferContext.Provider
      value={{
        chosenCollector,
        setChosenCollector,
        showCreateTransferModal,
        setShowCreateTransferModal,
        isSearchStage,
        setIsSearchStage,
        price,
        setPrice: handleChangePrice,
        commission,
        setCommission: handleChangeCommission,
        notes,
        setNotes: handleChangeNotes,
        locationLives,
        setLocationLives: handleChangeLocationLives,
        tradeForValue,
        setTradeForValue: handleChangeTradeForValue,
        tradeForObject,
        setTradeForObject: handleChangeTradeForObject,
        loanToName,
        setLoanToName: handleChangeLoanToName,
        loanToDate,
        setLoanToDate: handleChangeLoanToDate,
        saleable,
        setSaleable,
        availableForLoan,
        setAvailableForLoan,
        conditionReport,
        setConditionReport: handleChangeTerms,
        typeOfTransfer,
        setTypeOfTransfer,
        images,
        setImages: handleChangeImages,
        hasFilmsOrTv,
        setHasFilmsOrTv,
        filmsOrTv,
        setFilmsOrTv,
        tradeFor,
        setTradeFor,
        error,
        setError: handleChangeError,
        locationIsPrivate,
        toggleLocationIsPrivate,
        resetAllFields,
        initialHasFilmsOrTv: initialHasFilmsOrTv.current,
        setInitialHasFilmsOrTv,
        searchText,
        setSearchText,
        searchResults,
        setSearchResults,
        searchType,
        setSearchType,
      }}
    >
      {children}
    </CreateTransferContext.Provider>
  );
};

export const useCreateTransferContext = () => {
  const {
    chosenCollector,
    setChosenCollector,
    isSearchStage,
    setIsSearchStage,
    showCreateTransferModal,
    setShowCreateTransferModal,
    price,
    setPrice,
    commission,
    setCommission,
    notes,
    setNotes,
    locationLives,
    setLocationLives,
    tradeForValue,
    setTradeForValue,
    tradeForObject,
    setTradeForObject,
    loanToName,
    setLoanToName,
    loanToDate,
    setLoanToDate,
    saleable,
    setSaleable,
    availableForLoan,
    setAvailableForLoan,
    conditionReport,
    setConditionReport,
    typeOfTransfer,
    setTypeOfTransfer,
    images,
    setImages,
    hasFilmsOrTv,
    setHasFilmsOrTv,
    filmsOrTv,
    setFilmsOrTv,
    tradeFor,
    setTradeFor,
    error,
    setError,
    locationIsPrivate,
    toggleLocationIsPrivate,
    resetAllFields,
    initialHasFilmsOrTv,
    setInitialHasFilmsOrTv,
    searchText,
    setSearchText,
    searchResults,
    setSearchResults,
    searchType,
    setSearchType,
  } = useContext(CreateTransferContext);

  return {
    chosenCollector,
    setChosenCollector,
    isSearchStage,
    setIsSearchStage,
    showCreateTransferModal,
    setShowCreateTransferModal,
    price,
    setPrice,
    commission,
    setCommission,
    notes,
    setNotes,
    locationLives,
    setLocationLives,
    tradeForValue,
    setTradeForValue,
    tradeForObject,
    setTradeForObject,
    loanToName,
    setLoanToName,
    loanToDate,
    setLoanToDate,
    saleable,
    setSaleable,
    availableForLoan,
    setAvailableForLoan,
    conditionReport,
    setConditionReport,
    typeOfTransfer,
    setTypeOfTransfer,
    images,
    setImages,
    hasFilmsOrTv,
    setHasFilmsOrTv,
    filmsOrTv,
    setFilmsOrTv,
    tradeFor,
    setTradeFor,
    error,
    setError,
    locationIsPrivate,
    toggleLocationIsPrivate,
    resetAllFields,
    initialHasFilmsOrTv,
    setInitialHasFilmsOrTv,
    searchText,
    setSearchText,
    searchResults,
    setSearchResults,
    searchType,
    setSearchType,
  };
};
