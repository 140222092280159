import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import { IImage } from "../../types/imageData";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";
import ExhibitionNoticeAudited from "./audited-components/ExhibitionNoticeAudited/ExhibitionNoticeAudited";
import PostNoticeAudited from "./audited-components/PostNoticeAudited/PostNoticeAudited";
import { Actions } from "./Notifications.props";

interface LikeNotificationProps {
  nameFollower: string;
  time: Date;
  notice: Actions;
  userId: string | undefined;
  userImage: IImage;
}

const LikeNotification: React.FC<LikeNotificationProps> = ({
  nameFollower,
  time,
  notice,
  userId,
  userImage,
}): JSX.Element => {
  const { artwork, comment, exhibition, post } = notice.audited;
  const renderCommentText = (): string => {
    if (artwork) return `under the artwork`;
    if (exhibition) return "under the show";
    if (post) return "under the post";
    return "";
  };
  const renderAudited = (): any => {
    if (artwork) return <ArtworkNoticeAudited artwork={artwork} time={time} />;
    if (post) return <PostNoticeAudited post={post} time={time} />;
    if (exhibition) return <ExhibitionNoticeAudited exhibition={exhibition} time={time} />;
    return null;
  };
  return (
    <div className="like_notification">
      <div className="notification_text">
        <Link
          className="notification_link"
          to={`/${explore}/${artists}/${userId}`}
          state={{ name: nameFollower, image: userImage }}
        >
          {nameFollower}
        </Link>{" "}
        liked comment "{comment?.text}" {renderCommentText()}
      </div>
      {renderAudited()}
    </div>
  );
};

export default LikeNotification;
