import { prevStateActions, PrevStatesActionTypes, IPrevState } from "../types/previous-states.type";

export const addPrevState = (prevState: IPrevState): prevStateActions => ({
  type: PrevStatesActionTypes.ADD_PREV_STATE,
  payload: prevState,
});

export const removePrevState = (url: string): prevStateActions => ({
  type: PrevStatesActionTypes.REMOVE_PREV_STATE,
  payload: url,
});

export const removePrevStateById = (id: string): prevStateActions => ({
  type: PrevStatesActionTypes.REMOVE_PREV_STATE_BY_ID,
  payload: id,
});

export const removeArtworkFromStates = (id: string): prevStateActions => ({
  type: PrevStatesActionTypes.REMOVE_ARTWORK_FROM_STATES,
  payload: id,
});

export const removeArtworkFromSpecificState = (stateUrl: string, id: string): prevStateActions => ({
  type: PrevStatesActionTypes.REMOVE_ARTWORK_FROM_SPECIFIC_STATE,
  payload: {
    stateUrl,
    id,
  },
});
