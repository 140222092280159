import React from "react";
import formatShortDate from "../../utils/format-short-date";

interface NotificationTimeProps {
  time: Date;
}

const NotificationTime: React.FC<NotificationTimeProps> = ({ time }): JSX.Element => {
  return <p className="notification_time">{formatShortDate(time)}</p>;
};

export default NotificationTime;
