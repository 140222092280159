import React from "react";
import { useOutletContext } from "react-router-dom";
import ArtworksPageComponent from "../../components/artworks-page-component/ArtworksPageComponent";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";

const BuyPage: React.FC = () => {
  const { buyPageContext }: any = useOutletContext();
  const buyPageQueryParams = { forSale: true };

  return (
    <>
      <ScrollToTop />
      <ArtworksPageComponent
        queryParams={buyPageQueryParams}
        context={buyPageContext}
        questionMark={false}
        title="ART FOR SALE"
      />
    </>
  );
};
export default BuyPage;
