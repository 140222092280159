/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useQuery } from "@apollo/client";
import { CircularProgress, Divider } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SEARCH_CHATS_GQL } from "../../api-queries/ChatRequests";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { messagesRoute } from "../../route-link";
import { IChat } from "../../types/chat";
import ChatItem from "../ChatItem/ChatItem";
import ChatsListPlaceholder from "./ChatsListPlaceholder";

interface IChatListProps {
  setCurrentChat: (chat: IChat) => void;
  scrollModalTo?: (scrollY: number) => any;
}

const ChatsList: FC<IChatListProps> = ({ setCurrentChat, scrollModalTo }) => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const isChatsPage = routerLocation.pathname === `/${messagesRoute}`;
  const { chats, hasMore, offset, isLoading, unreadMessagesCount, chatsListScroll } =
    useTypedSelector(reduxState => reduxState.messagesInfoReducer);
  const { setChats, setHasMoreChats, setChatsOffset, setChatsLoading, setChatsListScroll } =
    useActions();

  const limit = 20;
  const { data, loading, fetchMore } = useQuery(SEARCH_CHATS_GQL, {
    variables: {
      offset: 0,
      limit: 20,
    },
  });

  const refetch = async (): Promise<void> => {
    const res = await fetchMore<any, any>({
      variables: { offset: 0, limit: chats.length < 20 ? 20 : chats.length },
    });
    setHasMoreChats(res.data.listChat.meta.total > res.data.listChat.chats.length);
    setChats(res.data.listChat.chats);
    setChatsLoading(false);
  };

  useEffect(() => {
    if (scrollModalTo) {
      scrollModalTo(chatsListScroll);
    }
    setChatsListScroll(0);
  }, []);

  useEffect(() => {
    refetch();
  }, [unreadMessagesCount]);

  useEffect(() => {
    if (data?.listChat?.chats && !chats.length && !isLoading) {
      setChats([...chats, ...data?.listChat?.chats]);
    }
  }, [data]);

  const loadMore = async (prevChats: IChat[]): Promise<void> => {
    setChatsLoading(true);
    const res = await fetchMore<any, any>({ variables: { offset: offset + limit } });
    setChatsOffset(offset + limit);
    if (res?.data?.listChat?.chats) {
      const newChats = [...prevChats, ...res.data.listChat.chats];
      setChats(newChats);
      setHasMoreChats(res.data.listChat.meta.total > newChats.length);
    }
    setChatsLoading(false);
  };

  const observer = useRef<IntersectionObserver>();
  const lastChatElementRef = (node: any): void => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore(chats);
      }
    });
    if (node) observer.current.observe(node);
  };
  const goToMessagesPage = (): void => {
    navigate(`/${messagesRoute}`);
  };
  return (
    <>
      <div className="chats-list" id="chats-list">
        {chats.map(chat => {
          const isLastEl = chats[chats.length - 8]
            ? chat._id === chats[chats.length - 8]._id
            : chat._id === chats[chats.length - 1]._id;
          if (isLastEl)
            return (
              <div key={chat._id} ref={lastChatElementRef}>
                <ChatItem chat={chat} setCurrentChat={setCurrentChat} />
                <Divider />
              </div>
            );
          return (
            <div key={chat._id}>
              <ChatItem chat={chat} setCurrentChat={setCurrentChat} />
              <Divider />
            </div>
          );
        })}
        {!chats.length && (loading || isLoading) && <ChatsListPlaceholder />}

        {!loading && !chats.length && !isLoading && <p className="no-results">No chats yet</p>}
        {!!chats.length && isLoading && (
          <div className="chats-list__loader">
            <CircularProgress size={25} />
          </div>
        )}
      </div>
      {!isChatsPage && (
        <div className="view-all-chats_button">
          <p className="mock_link" onClick={goToMessagesPage}>
            View All
          </p>
        </div>
      )}
    </>
  );
};

export default ChatsList;
