import { Actions } from "../../components/notifications/Notifications.props";

export interface INotificationsState {
  unreadNoticesCount: number;
  notices: Actions[];
  showNotificationModal: boolean;
  notificationsModalScroll: number | undefined;
}

export enum NotificationsActionTypes {
  SET_NOTICES = "SET_NOTICES",
  SET_UNREAD_NOTICES_COUNT = "SET_UNREAD_NOTICES_COUNT",
  SET_SHOW_NOTIFICATIONS_MODAL = "SET_SHOW_NOTIFICATIONS_MODAL",
  SET_NOTIFICATIONS_MODAL_SCROLL = "SET_NOTIFICATIONS_MODAL_SCROLL",
}

interface setShowNotificationsModal {
  type: NotificationsActionTypes.SET_SHOW_NOTIFICATIONS_MODAL;
  payload: boolean;
}

interface setNotificationsModalScroll {
  type: NotificationsActionTypes.SET_NOTIFICATIONS_MODAL_SCROLL;
  payload: number | undefined;
}

interface setNotices {
  type: NotificationsActionTypes.SET_NOTICES;
  payload: Actions[];
}

interface setUnreadNoticesCount {
  type: NotificationsActionTypes.SET_UNREAD_NOTICES_COUNT;
  payload: number;
}

export type NotificationsAction =
  | setShowNotificationsModal
  | setNotices
  | setUnreadNoticesCount
  | setNotificationsModalScroll;
