import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";

import { getCategoriesIds } from "./utils";

import "./FilterCategories.scss";

const FilterCategories = ({ setDataItem, categoryQueryParam, context }) => {
  const {
    categories: { categories, setCategories },
    filterResults: { filterResults, setFilterResults },
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    offset: { setOffset },
  } = context;

  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updateQuery = categories => {
    setOffset(0);
    setDataItem([]);
    const categoriesIds = getCategoriesIds(categories);
    const checkedItems = categories.filter(category => category.checked);
    setfilterQueryParams({
      ...filterQueryParams,
      offset: 0,
      [categoryQueryParam]: checkedItems.length ? categoriesIds : undefined,
    });
  };
  const handleChecked = event => {
    const { id, checked } = event.target;
    const newCategories = categories.map(category => {
      if (category.id === id) return { ...category, checked: !category.checked };
      return category;
    });
    const targetItem = newCategories.find(category => category.id === id);
    const { value, type } = targetItem;
    setCategories(newCategories);

    if (checked) {
      setFilterResults([...filterResults, { title: value, id, type }]);
    } else {
      setFilterResults(filterResults.filter(item => item.id !== id));
    }
    updateQuery(newCategories);
  };
  const onChange = event => {
    const { value } = event.target;
    setInputValue(value);
  };
  const renderCategories = () => {
    return categories
      ?.filter(category => {
        if (inputValue) {
          return category.value.toLowerCase().includes(inputValue.toLowerCase());
        }
        return category;
      })
      ?.map(category => {
        return (
          <FormControlLabel
            key={category.id}
            control={
              <Checkbox checked={category.checked} id={category.id} onChange={handleChecked} />
            }
            label={category.value}
          />
        );
      });
  };

  return (
    <div className="filter_categories">
      <div className="filter_categories__input">
        <SearchIcon className="filter_categories__searchIcon" />
        <InputBase placeholder="Filter" onChange={onChange} />
      </div>
      <div className="filter_categories__items">{renderCategories()}</div>
    </div>
  );
};

export default FilterCategories;
