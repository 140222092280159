import React from "react";
import { useQuery } from "@apollo/client";
import { ClickAwayListener, Tooltip } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Link } from "react-router-dom";

import { ArtRequests } from "../../api-queries/AllRequests";
import ImageList from "../../components/image-list/ImageList";

import "./LostPage.scss";
import "../../styles/titleStyles.scss";
import { lostViewMore } from "../../route-link";

const variables = { variables: { limit: 50 } };

export const LostPage = () => {
  const { data } = useQuery(ArtRequests.LOST_ART, variables);
  const { data: identifiedArt } = useQuery(ArtRequests.IDENTIFIED_ART, {
    variables,
  });
  const { data: foundArt } = useQuery(ArtRequests.FOUND_ART, variables);
  const artworksFirst = data?.firstFoundArtworks?.artworks;
  const artworksSecond = identifiedArt?.secondFoundArtworks?.artworks;
  const artworksThird = foundArt?.thirdFoundArtworks?.artworks;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const TooltipText = () => {
    return (
      <div className="lost_tooltip">
        <h5 className="tooltip_title">About lost art</h5>
        <div className="tooltip_text">
          Users identify if they can name artist. After 30 days it progresses to “Confirm or
          Challenge”. Next, after 30 days, it moves on to “Lost and Found.” *Art in any category can
          be challenged for accuracy on its credential page any time.
        </div>
      </div>
    );
  };

  return (
    <div className="lost-page explore_list wrapper">
      {data && (
        <div className="lost-page-grid">
          {artworksFirst?.length > 0 && (
            <div className="art-show">
              <Link
                to={`/explore/${lostViewMore}`}
                className="view-more-btn"
                state={{ artworks: artworksFirst, header: "Lost Art" }}
              >
                View more
              </Link>
              <div className="lost_art_position">
                <h3 className="title-image">
                  Lost Art
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={<TooltipText />}
                      >
                        <QuestionMarkIcon
                          onClick={handleTooltipOpen}
                          className="question_mark_item"
                        />
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                </h3>
              </div>
              <ImageList images={artworksFirst} />
            </div>
          )}

          {artworksSecond?.length > 0 && (
            <div className="art-show">
              <h3 className="title-image">Confirm, challenge lost art</h3>
              <ImageList images={artworksSecond} />
            </div>
          )}

          {artworksThird?.length > 0 && (
            <div className="art-show">
              <Link
                to={`/explore/${lostViewMore}`}
                className="view-more-btn"
                state={{ artworks: artworksThird, header: "Lost and Found" }}
              >
                View more
              </Link>
              <h3 className="title-image">Lost and Found</h3>
              <ImageList images={artworksThird} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
