import { IFilter, IGeneralFilter } from "../../types/filterTypes";

export const getFiltersValues = (filters: IFilter[]): string[] => {
  return filters.filter(filter => filter.checked).map(filter => filter.value);
};

export const getUniqueItems = (newItems: IFilter[], Items: IFilter[]): IGeneralFilter[] => {
  const itemsValue = new Set(Items.map(item => item.value));
  return [...newItems.filter(item => !itemsValue.has(item.value))];
};
