import {
  UserVotedArtworksState,
  UserVotedArtworksType,
  UserVotedArtworksTypes,
} from "../types/user-voted-artworks";

const userVotedArtworksState: UserVotedArtworksState = {
  userVotedArtworks: [],
  offset: 0,
  userId: "",
};

const userVotedArtworksReducer = (
  state = userVotedArtworksState,
  action: UserVotedArtworksType,
): UserVotedArtworksState => {
  switch (action.type) {
    case UserVotedArtworksTypes.SET_USER_VOTED_ARTWORKS:
      return {
        ...state,
        userVotedArtworks: action.payload,
      };
    case UserVotedArtworksTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case UserVotedArtworksTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
};

export default userVotedArtworksReducer;
