import { artistsSortOptions, genderFilterOptions } from "../../explore-pages/artists-page/utils";
import {
  ArtistsPageActions,
  ArtistsPageActionTypes,
  IArtistsPageInitialState,
} from "../types/artistsPageTypes";

export const artistsPageInitialState: IArtistsPageInitialState = {
  artists: [],
  offset: 0,
  sortValue: artistsSortOptions[0],
  filterQueryParams: {},
  filterResults: [],
  originsOfInspiration: [],
  gender: genderFilterOptions[0],
  location: null,
  radius: 10,
};

export const artistsPageReducer = (
  state = artistsPageInitialState,
  action: ArtistsPageActions,
): IArtistsPageInitialState => {
  switch (action.type) {
    case ArtistsPageActionTypes.SET_ARTISTS:
      return {
        ...state,
        artists: [...action.payload],
      };
    case ArtistsPageActionTypes.SET_OFFSET_ARTISTS:
      return {
        ...state,
        offset: action.payload,
      };
    case ArtistsPageActionTypes.SET_SORT_VALUE_ARTISTS:
      return {
        ...state,
        sortValue: action.payload,
      };
    case ArtistsPageActionTypes.SET_FILTER_QUERY_PARAMS_ARTISTS:
      return {
        ...state,
        filterQueryParams: action.payload,
      };
    case ArtistsPageActionTypes.SET_FILTER_RESULTS_ARTISTS:
      return {
        ...state,
        filterResults: action.payload,
      };
    case ArtistsPageActionTypes.SET_ORIGINS_OF_INSPIRATION_ARTISTS:
      return {
        ...state,
        originsOfInspiration: action.payload,
      };
    case ArtistsPageActionTypes.SET_GENDER_ARTISTS:
      return {
        ...state,
        gender: action.payload,
      };
    case ArtistsPageActionTypes.SET_LOCATION_FILTER_ARTISTS:
      return {
        ...state,
        location: action.payload,
      };

    case ArtistsPageActionTypes.SET_RADIUS_ARTISTS:
      return {
        ...state,
        radius: action.payload,
      };
    default:
      return state;
  }
};
