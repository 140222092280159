import React, { memo, useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";

import Lightbox from "react-image-lightbox";
import { ArtistOrInstitutionDescriptionProps } from "./ArtistOrInstitutionDescription.props";
import formatDate from "../../utils/format-date";
import Button from "../../UI/button/Button";
import SliderArrows from "../slider-arrows/SliderArrows";

import "./ArtistOrInstitutionDescription.scss";

const ArtistOrInstitutionDescription: React.FC<ArtistOrInstitutionDescriptionProps> = ({
  BIO,
  description,
  daysOpen,
  phoneNumber,
  images,
}) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const [textBIO, setTextBIO] = useState("");
  const [textDescription, setTextDescription] = useState("");
  const [showMore, setShowMore] = useState(false);
  const showButtonText = showMore ? "Less info..." : "More info...";
  const displayShowMoreButton = Boolean(daysOpen?.length || phoneNumber);
  const displayDaysOpen = Boolean(daysOpen?.length && showMore);
  const displayPhoneNumber = Boolean(phoneNumber && showMore);
  const displayImages = Boolean(images?.length && showMore);
  const [imageIndex, setImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const detectURLs = (message: string): void => {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const url = message?.match(urlRegex);
    setTextBIO(message?.replace(urlRegex, ""));
    setTextDescription(message?.replace(urlRegex, ""));
    if (url) setPreviewUrl(url[0]);
  };

  const onImageArray = (index: number): void => {
    setImageIndex(index);
    setIsOpen(true);
  };

  useEffect(() => {
    detectURLs((BIO && BIO) || (description && description));
  });

  return (
    <div className="art_inst_description">
      {BIO && (
        <div className="art_inst_description__item">
          <div className="art_inst_description__title">BIO</div>
          <div className="art_inst_description__text">
            {textBIO}
            <a
              className="art_inst_description_reg_link"
              rel="noreferrer"
              target="_blank"
              href={previewUrl}
            >
              {previewUrl}
            </a>
          </div>
        </div>
      )}

      {description && (
        <div className="art_inst_description__item">
          <div className="art_inst_description__title">About</div>
          <div className="art_inst_description__text">
            {textDescription}
            {!!previewUrl && (
              <a
                className="art_inst_description_reg_link"
                rel="noreferrer"
                target="_blank"
                href={previewUrl}
              >
                {previewUrl}
              </a>
            )}
          </div>
        </div>
      )}

      {displayDaysOpen && (
        <div className="art_inst_description__days_open art_inst_description__item">
          <div className="art_inst_description__title">Working days:</div>
          {daysOpen?.map(day => {
            return (
              <div className="calendar_duration" key={day.dayOfWeek}>
                <span>{day.dayOfWeek}</span>{" "}
                <span>{` ${formatDate(day.hoursOpen.from, "h:mm a")} - ${formatDate(
                  day.hoursOpen.to,
                  "h:mm a",
                )}`}</span>
              </div>
            );
          })}
        </div>
      )}

      {displayPhoneNumber && (
        <div className="art_inst_description__item">
          <div className="art_inst_description__title">Phone number</div>
          <div className="art_inst_description__text">{phoneNumber}</div>
        </div>
      )}

      {displayImages && (
        <div className="art_inst_description__images art_inst_description__item">
          <Carousel
            disableArrowsOnEnd
            renderArrow={SliderArrows}
            isRTL={false}
            pagination={false}
            itemsToShow={2}
            itemPadding={[0, 5, 0, 5]}
          >
            {images.map((image, index) => {
              return (
                // eslint-disable-next-line
                <img
                  key={image._id}
                  onClick={() => onImageArray(index)}
                  draggable="false"
                  className="art_inst_description__image"
                  src={image.medium.url}
                  alt={image.medium.url}
                />
              );
            })}
          </Carousel>
        </div>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={images[imageIndex]?.medium?.url}
          onCloseRequest={() => setIsOpen(false)}
          nextSrc={images[imageIndex + 1]?.medium?.url}
          prevSrc={images[imageIndex - 1]?.medium?.url}
          onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
          onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
          enableZoom={false}
        />
      )}

      {displayShowMoreButton && (
        <Button
          className="explore_item_details_right__more_info_button"
          onClick={() => setShowMore(prev => !prev)}
        >
          {showButtonText}
        </Button>
      )}
    </div>
  );
};

export default memo(ArtistOrInstitutionDescription);
