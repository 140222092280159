import React from "react";
import { Link } from "react-router-dom";
import { IImage } from "../../types/imageData";

interface TextTemplateNotificationProps {
  linkNameFollower: string;
  linkNameUser?: string;
  messageText: string;
  textComment?: string;
  nameFollower: string;
  nameUser: string | null;
  userImage: IImage;
}

const TextTemplateNotification: React.FC<TextTemplateNotificationProps> = ({
  linkNameFollower,
  messageText,
  nameUser,
  linkNameUser,
  nameFollower = "",
  textComment,
  userImage,
}): JSX.Element => {
  return (
    <p className="notification_text">
      <Link
        className="notification_link"
        to={linkNameFollower}
        state={{ name: nameFollower, image: userImage }}
      >
        {nameFollower}
      </Link>{" "}
      {messageText}
      {linkNameUser && (
        <Link className="notification_link" to={linkNameUser}>
          {nameUser}
        </Link>
      )}
      {textComment && <span>: "{textComment}"</span>}
    </p>
  );
};

export default TextTemplateNotification;
