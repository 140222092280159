import React, { FC, useRef } from "react";

import { NavLink } from "react-router-dom";

import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { explore } from "../../route-link";

import "./NavMenu.scss";
import Notifications from "../notifications/Notifications";
import Messages from "../messages/Messages";

const NavMenuLogin: FC = () => {
  const notificationsAnchorEl = useRef<HTMLDivElement>(null);
  return (
    <div className="nav_menu__login">
      <div ref={notificationsAnchorEl}>
        <Notifications anchorEl={notificationsAnchorEl.current} />
      </div>
      <Messages />
      <NavLink to={explore}>
        <ExploreOutlinedIcon className="nav_menu__login__item" />
      </NavLink>
    </div>
  );
};

export default NavMenuLogin;
