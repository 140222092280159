/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import BackButton from "../../components/back-button/BackButton";
import LeafletMap from "../../components/leaflet-maps/LeafletMap";
import { IFilterTypes } from "../../types/filterTypes";

import "./MapPage.scss";
import Button from "../../UI/button/Button";
import ShareButton from "../../components/share-button/ShareButton";
import { explore, institutions } from "../../route-link";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import FilterTopBar from "../../UI/filter-top-bar/FilterTopBar";
import FilterItem from "../../components/filter-item/FilterItem";
import FilterFromTo from "../../components/filter-from-to/FilterFromTo";
import FilterResults from "../../components/filter-results/FilterResults";
import FilterUnits from "../../components/filter-units/FilterUnits";
import Filter from "../../components/filter/Filter";
import LocationFilter from "../../components/location-filter/LocationFilter";
import FilterOriginsOfInspiration from "../../components/filter-origins-of-inspiration/FilterOriginsOfInspiration";
import FilterMaterialsColors from "../../components/filter-materials-colors/FilterMaterialsColors";
import FilterCategories from "../../components/filter-categories/FilterCategories";

import { PREDEFINED_MAPS_SORT } from "../../misc/constants";

import { PREDEFINED_COLORS, PREDEFINED_MATERIALS } from "../../data/constants";

import {
  AVAILABLE_ARTWORK_COLORS,
  AVAILABLE_ARTWORK_MATERIALS,
  SEARCH_ARTWORKS_LITE,
} from "../../api-queries/ArtworksRequests";

import {
  SET_ARTWORKS,
  SET_CATEGORIES,
  SET_COLORS,
  SET_FILTER_QUERY_PARAMS,
  SET_FILTER_RESULTS,
  SET_HEIGHT_MAX_VALUE,
  SET_HEIGHT_MIN_VALUE,
  SET_LOCATION_FILTER,
  SET_MATERIALS,
  SET_OFFSET,
  SET_ORIGINS_OF_INSPIRATION,
  SET_RADIUS,
  SET_SORT_VALUE,
  SET_WIDTH_MAX_VALUE,
  SET_WIDTH_MIN_VALUE,
} from "../../context/action-creators/artworkFilterActions";
import { ArtworksRequests } from "../../api-queries/AllRequests";
import { ArtworkLocationTypes, MapTabs } from "../../components/artwork-details-map/utils";
import { useActions } from "../../hooks/useActions";

const MapPage = () => {
  const navigate = useNavigate();
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  const { filterWindowOpen } = useTypedSelector(state => state.filterReducer);
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);

  const routerLocation = useLocation();
  const { addPrevState, removePrevState, openFilter } = useActions();
  // eslint-disable-next-line no-undef
  const dispatch = useDispatch();
  const context = useTypedSelector(reduxState => reduxState.artworkSearchFilterReducer);
  const [priceMinValue, setPriceMinValue] = useState();
  const [priceMaxValue, setPriceMaxValue] = useState();
  const [units, setUnits] = useState("in/pounds");
  const [locationType, setLocationType] = useState();
  const [blockRequest, setBlockRequest] = useState(false);

  const total = 30;

  const {
    categories,
    filterResults,
    filterQueryParams,
    offset,
    artworks,
    materials,
    colors,
    widthMinValue,
    widthMaxValue,
    heightMinValue,
    heightMaxValue,
    sortValue,
    originsOfInspiration,
    location,
    radius,
  } = context;
  const [mapLocation, setMapLocation] = useState(null);
  const setCategories = payload => dispatch(SET_CATEGORIES(payload));
  const setFilterResults = payload => dispatch(SET_FILTER_RESULTS(payload));
  const setfilterQueryParams = payload => dispatch(SET_FILTER_QUERY_PARAMS(payload));
  const setOffset = payload => dispatch(SET_OFFSET(payload));
  const setArtworks = payload => dispatch(SET_ARTWORKS(payload));
  const setMaterials = payload => dispatch(SET_MATERIALS(payload));
  const setColors = payload => dispatch(SET_COLORS(payload));
  const setWidthMinValue = payload => dispatch(SET_WIDTH_MIN_VALUE(payload));
  const setWidthMaxValue = payload => dispatch(SET_WIDTH_MAX_VALUE(payload));
  const setHeightMinValue = payload => dispatch(SET_HEIGHT_MIN_VALUE(payload));
  const setHeightMaxValue = payload => dispatch(SET_HEIGHT_MAX_VALUE(payload));
  const setSortValue = payload => dispatch(SET_SORT_VALUE(payload));
  const setOriginsOfInspiration = payload => dispatch(SET_ORIGINS_OF_INSPIRATION(payload));
  const setLocation = payload => dispatch(SET_LOCATION_FILTER(payload));
  const setRadius = payload => dispatch(SET_RADIUS(payload));

  const stateContext = {
    categories: { categories, setCategories },
    filterResults: { filterResults, setFilterResults },
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    offset: { offset, setOffset },
    artworks: { artworks, setArtworks },
    materials: { materials, setMaterials },
    colors: { colors, setColors },
    widthMinValue: { widthMinValue, setWidthMinValue },
    widthMaxValue: { widthMaxValue, setWidthMaxValue },
    heightMinValue: { heightMinValue, setHeightMinValue },
    heightMaxValue: { heightMaxValue, setHeightMaxValue },
    sortValue: { sortValue, setSortValue },
    originsOfInspiration: { originsOfInspiration, setOriginsOfInspiration },
    location: { location, setLocation },
  };
  const [loadCategoties] = useLazyQuery(ArtworksRequests.CATEGORIES);

  const [getArtworks, { data: filteredArtworks }] = useLazyQuery(SEARCH_ARTWORKS_LITE, {
    variables: { limit: 200, filterQueryParams },
  });

  const goToArtworksPage = () => navigate(`/${explore}/artworks`);
  const goToInstitutionsPage = () => navigate(`/${explore}/${institutions}`);

  const saveState = (currentArtwork, id) => {
    const currArtwork = currentArtwork || artworks.find(artwork => artwork._id === id);

    localStorage.setItem(
      "prevState",
      JSON.stringify({
        url: routerLocation.pathname,
        state: artworks,
        filtersState: { ...stateContext, locationType, filterWindowOpen },
      }),
    );
    setSldrAtrwrs(
      artworks.map(el => {
        if (currArtwork._id === el._id) {
          return { id: el._id, artwork: currArtwork };
        }
        return { id: el._id, artwork: null };
      }),
    );
    addPrevState({
      url: routerLocation.pathname,
      state: artworks,
      filtersState: { ...stateContext, locationType, filterWindowOpen },
    });
  };

  const fetchArtworks = async (type = "") => {
    const variables = {
      limit: 200,
      ...{
        ...filterQueryParams,
        sortBy: undefined,
        findByLocation: {
          types: [sortValue.value || "locationLives"],
          ...filterQueryParams.location,
        },
      },
    };

    if (type === "no loc filters") {
      setfilterQueryParams({ ...filterQueryParams, location: undefined });
      delete variables.location;
      delete variables.findByLocation.radius;
      delete variables.findByLocation.latitude;
      delete variables.findByLocation.longitude;
    }

    if (type === "sort") {
      delete variables.location;
    }

    delete variables.sortBy;
    await getArtworks({
      variables,
    });
  };

  const returnPersistedState = prevState => {
    setBlockRequest(true);
    const { filtersState } = prevState;
    if (!filtersState) return;
    const filterPriceResults = filtersState.filterResults.filterResults.find(
      res => res.id === "Price",
    );
    if (filtersState.filterWindowOpen) openFilter();
    setPriceMinValue(filterPriceResults?.min);
    setPriceMaxValue(filterPriceResults?.max);
    setCategories(filtersState.categories.categories);
    setFilterResults(filtersState.filterResults.filterResults);
    setfilterQueryParams(filtersState.filterQueryParams.filterQueryParams);
    setOffset(filtersState.offset.offset);
    setArtworks(prevState.state);
    setMaterials(filtersState.materials.materials);
    setColors(filtersState.colors.colors);
    setWidthMinValue(filtersState.widthMinValue.widthMinValue);
    setWidthMaxValue(filtersState.widthMaxValue.widthMaxValue);
    setHeightMinValue(filtersState.heightMinValue.heightMinValue);
    setHeightMaxValue(filtersState.heightMaxValue.heightMaxValue);
    setSortValue(filtersState.sortValue.sortValue);
    setOriginsOfInspiration(filtersState.originsOfInspiration.originsOfInspiration);
    setLocation(filtersState.location.location);
    setLocationType(filtersState.locationType);
    removePrevState(routerLocation.pathname);
    localStorage.removeItem("prevState");
    setBlockRequest(false);
  };

  const getLocationType = () => {
    switch (sortValue.value) {
      case ArtworkLocationTypes.LocationCollected:
        return MapTabs.COLLECTED;
      case ArtworkLocationTypes.LocationCreated:
        return MapTabs.CREATED;
      case ArtworkLocationTypes.LocationLives:
        return MapTabs.LIVES;
      default:
        return MapTabs.LIVES;
    }
  };

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevState) {
      returnPersistedState(prevState);
      return;
    }
    if (prevLoadingState) {
      returnPersistedState(prevLoadingState);
      return;
    }
    if (!filteredArtworks?.searchArtworks?.artworks || blockRequest) return;
    setLocationType(getLocationType());
    setArtworks(filteredArtworks.searchArtworks.artworks);
  }, [filteredArtworks]);

  const unitCheck = units === "in/pounds" ? "in" : "cm";

  const getCategories = async () => {
    const res = await loadCategoties();
    const defaultCategories = res.data.categories.map(category => {
      return {
        id: category._id,
        type: IFilterTypes.Categories,
        checked: false,
        value: category.name,
      };
    });
    setCategories(categories.length ? categories : defaultCategories);
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    const defaultMaterials = PREDEFINED_MATERIALS.reduce((accum, material) => {
      if (material)
        accum.push({
          value: material,
          checked: false,
          id: `${material}${IFilterTypes.Materials}`,
          type: IFilterTypes.Materials,
        });
      return accum;
    }, []);
    const defaultColors = PREDEFINED_COLORS.reduce((accum, color) => {
      if (color)
        accum.push({
          value: color,
          checked: false,
          id: `${color}${IFilterTypes.Colors}`,
          type: IFilterTypes.Colors,
        });
      return accum;
    }, []);
    setMaterials(materials.length ? materials : defaultMaterials);
    setColors(colors.length ? colors : defaultColors);
  }, []);

  const searchThisArea = async (coords, filterRadius) => {
    setArtworks([]);
    const loc = { latitude: coords.lat, longitude: coords.lng, radius: filterRadius };
    setfilterQueryParams({ ...filterQueryParams, location: loc });
    setFilterResults(filterResults.filter(res => res.type !== "Location"));
    setBlockRequest(true);
    const variables = {
      limit: 200,
      ...{
        ...filterQueryParams,
        sortBy: undefined,
        findByLocation: {
          types: [sortValue.value || "locationLives"],
          ...loc,
        },
      },
    };
    delete variables.location;
    await getArtworks({
      variables,
    });
  };

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    if (prevState || prevLoadingStateJSON) {
      setBlockRequest(true);
      return;
    }
    if (blockRequest) {
      setBlockRequest(false);
      return;
    }
    const locationFilterResult = filterResults.find(res => res.type === "Location");
    if (!locationFilterResult && filterResults.length > 0 && !filterQueryParams.location) {
      fetchArtworks("no loc filters");
      return;
    }
    fetchArtworks();
  }, [filterResults]);

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    if (prevState || prevLoadingStateJSON) {
      setBlockRequest(true);
      return;
    }
    if (blockRequest) {
      setBlockRequest(false);
      return;
    }
    const locationFilterResult = filterResults.find(res => res.type === "Location");
    if (!locationFilterResult && !filterQueryParams.location) {
      fetchArtworks("sort");
      return;
    }
    fetchArtworks();
  }, [sortValue.value]);

  const getSortItemName = item => {
    switch (item) {
      case ArtworkLocationTypes.LocationCollected:
        return "Collected at";
      case ArtworkLocationTypes.LocationCreated:
        return "Created at";
      case ArtworkLocationTypes.LocationLives:
        return "Lives at";
      default:
        return "Lives at";
    }
  };

  return (
    <div className="wrapper profile-artworks-page map-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        Maps
        <ShareButton />
      </h3>

      {true && (
        <div className="map-page__buttons">
          <Button
            onClick={goToArtworksPage}
            className="map-page__button"
            children="See Artworks"
            color="dark"
          />
          <Button
            onClick={goToInstitutionsPage}
            className="map-page__button"
            children="Search Institutions"
            color="dark"
          />
        </div>
      )}
      {true && (
        <FilterTopBar
          renderValue={value => `Sort by: ${getSortItemName(value.value)}`}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setQueryValue={() => {}}
          options={PREDEFINED_MAPS_SORT}
        />
      )}
      <div className="map-container">
        <div
          className={classNames("explore_grid", {
            active: filterWindowOpen,
          })}
        >
          {true && (
            <div className="left_side__bar">
              <div className="artworks_item__side-bar">
                <Filter>
                  <FilterItem title="Price" unit="$">
                    <FilterFromTo
                      setArtworks={setArtworks}
                      requestField="priceRange"
                      typeFilter={IFilterTypes.Price}
                      unit="$"
                      minValue={priceMinValue}
                      setMinValue={setPriceMinValue}
                      maxValue={priceMaxValue}
                      setMaxValue={setPriceMaxValue}
                      context={stateContext}
                      priceRange
                    />
                  </FilterItem>
                  <FilterItem title="Units">
                    <FilterUnits units={units} setUnits={setUnits} context={stateContext} />
                  </FilterItem>
                  <FilterItem title="Width" unit={unitCheck}>
                    <FilterFromTo
                      setArtworks={setArtworks}
                      requestField="widthRange"
                      typeFilter={IFilterTypes.Width}
                      unit={unitCheck}
                      minValue={widthMinValue}
                      setMinValue={setWidthMinValue}
                      maxValue={widthMaxValue}
                      setMaxValue={setWidthMaxValue}
                      context={stateContext}
                    />
                  </FilterItem>
                  <FilterItem title="Height" unit={unitCheck}>
                    <FilterFromTo
                      setArtworks={setArtworks}
                      requestField="heightRange"
                      typeFilter={IFilterTypes.Height}
                      unit={unitCheck}
                      minValue={heightMinValue}
                      setMinValue={setHeightMinValue}
                      maxValue={heightMaxValue}
                      setMaxValue={setHeightMaxValue}
                      context={stateContext}
                    />
                  </FilterItem>
                  <FilterItem title="Categories">
                    <FilterCategories
                      setDataItem={setArtworks}
                      categoryQueryParam="categories"
                      context={stateContext}
                    />
                  </FilterItem>
                  <FilterItem title="Materials">
                    <FilterMaterialsColors
                      items={materials}
                      setItems={setMaterials}
                      query="materials"
                      apolloQuery={AVAILABLE_ARTWORK_MATERIALS}
                      queryDataName="availableArtworksMaterials"
                      type={IFilterTypes.Materials}
                      context={stateContext}
                    />
                  </FilterItem>
                  <FilterItem title="Colors">
                    <FilterMaterialsColors
                      items={colors}
                      setItems={setColors}
                      query="colors"
                      apolloQuery={AVAILABLE_ARTWORK_COLORS}
                      queryDataName="availableArtworksColors"
                      type={IFilterTypes.Colors}
                      context={stateContext}
                    />
                  </FilterItem>
                  <FilterItem title="Origins of inspiration">
                    <FilterOriginsOfInspiration
                      context={stateContext}
                      setItems={setArtworks}
                      filterQueryName="inspiration"
                    />
                  </FilterItem>
                  <FilterItem title="Location">
                    <LocationFilter
                      value={location}
                      radiusValue={radius}
                      setRadius={setRadius}
                      setLocation={setLocation}
                      context={stateContext}
                      setDataItem={setArtworks}
                      setMapLocation={setMapLocation}
                    />
                  </FilterItem>
                </Filter>
              </div>
            </div>
          )}
        </div>
        <div className="map">
          <div className="filter_results__content">
            <div className="filter_results__left_content">
              {filterResults.length > 0 && (
                <FilterResults
                  total={total}
                  filterResults={filterResults}
                  setFilterResults={setFilterResults}
                  setDataItem={setArtworks}
                  categoriesQuery="categories"
                  context={stateContext}
                  inspipationQuery="inspiration"
                />
              )}
            </div>
            <LeafletMap
              className="map-page__map"
              locations={artworks}
              zoom={10}
              location={null}
              saveState={saveState}
              filterLocation={mapLocation}
              searchThisArea={searchThisArea}
              mapPage
              activeTab={locationType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapPage;
