import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";

interface AcceptTransferArtworkNotificationProps {
  nameFrom: string;
  userId: string;
  time: Date;
  artwork: IArtwork;
  userImage: string;
}

const AcceptTransferArtworkNotification: React.FC<AcceptTransferArtworkNotificationProps> = ({
  nameFrom,
  userId,
  time,
  artwork,
  userImage,
}) => {
  const nameArtwork = artwork?.name;
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`/${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        accepted request to transfer artwork "{nameArtwork}"
      </p>
      <ArtworkNoticeAudited artwork={artwork} time={time} />
    </div>
  );
};

export default AcceptTransferArtworkNotification;
