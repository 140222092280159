import { ExploreLinkItem } from "../components/nav-menu/utils";

export const navExplore: ExploreLinkItem[] = [
  {
    id: "2",
    title: "Artists",
    link: "/explore/artists",
    active: false,
    checkForAuth: false,
  },
  {
    id: "3",
    title: "Movie/Locations",
    link: "/explore/location",
    active: false,
    checkForAuth: false,
  },
  {
    id: "4",
    title: "Institutions",
    link: "/explore/institutions",
    active: false,
    checkForAuth: false,
  },
  {
    id: "5",
    title: "Artworks",
    link: "/explore/artworks",
    active: false,
    checkForAuth: false,
  },
  {
    id: "6",
    title: "Submit Art",
    link: "/explore/submit-art",
    active: false,
    checkForAuth: false,
  },
  {
    id: "7",
    title: "Venues",
    link: "/explore/venues",
    active: false,
    checkForAuth: false,
  },
  {
    id: "8",
    title: "Buy",
    link: "/explore/buy",
    active: false,
    checkForAuth: false,
  },
  {
    id: "9",
    title: "Lost",
    link: "/explore/lost",
    active: false,
    checkForAuth: false,
  },
  {
    id: "10",
    title: "Shows",
    link: "/explore/shows",
    active: false,
    checkForAuth: false,
  },
  {
    id: "13",
    title: "Maps",
    link: "/explore/map",
    active: false,
    checkForAuth: false,
  },
  {
    id: "11",
    title: "Vote",
    link: "/explore/vote",
    active: false,
    checkForAuth: true,
  },
  {
    id: "12",
    title: "Movie/TV Art",
    link: "/explore/movie-tv",
    active: false,
    checkForAuth: false,
  },
];
