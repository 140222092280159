import React from "react";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import { useSearchQuery } from "../../hooks/useSearchQuery";
import { root } from "../../route-link";
import { DeepLinkStyle, DeepLinkIconStyle } from "./DeepLink.styles";

const DeepLink: React.FC = () => {
  const query = useSearchQuery();
  const token = query.get("token");

  if (!token) {
    return <Navigate to={root} />;
  }

  // TODO: set link to redirect to recover password for PC(Browser) users
  // something like `http(s)://domain/recovery-password/${token}`
  let redirectTo = "/";
  if (isMobile) {
    redirectTo = `moca://recoveryToken/${token}`;
    window.location.assign(redirectTo);
    return (
      <div style={DeepLinkStyle}>
        <PublishedWithChangesIcon fontSize="large" color="success" style={DeepLinkIconStyle} />
      </div>
    );
  }

  return <Navigate to={redirectTo} />;
};

export default DeepLink;
