import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { IShowDurationSelectValue } from "../../explore-pages/shows-page/utils";
import { IShowExhibitions } from "../../types/exhibitions";

export enum ShowsSearchActionTypes {
  SET_SEARCH_SHOWS = "SET_SEARCH_SHOWS",
  SET_SEARCH_OFFSET = "SET_SEARCH_OFFSET",
  SET_SEARCH_DURATION_SORT_VALUE = "SET_SEARCH_DURATION_SORT_VALUE",
  SET_SEARCH_FILTER_QUERY_PARAMS = "SET_SEARCH_FILTER_QUERY_PARAMS",
  SET_SEARCH_CALENDAR_VALUE = "SET_SEARCH_CALENDAR_VALUE",
  SET_SEARCH_LOCATION_SORT_VALUE = "SET_SEARCH_LOCATION_SORT_VALUE",
  SET_SEARCH_LOCATION_AUTOCOMPLETE_VALUE = "SET_SEARCH_LOCATION_AUTOCOMPLETE_VALUE",
}

export interface ILocationFilter {
  label: string;
  value: any;
}

export interface IShowSearchInitialState {
  shows: IShowExhibitions[];
  durationSortValue: IShowDurationSelectValue;
  filterQueryParams: any;
  calendarValue: string;
  offset: number;
  locationSortValue: ILocationSelectValue;
  locationAutocompleteValue: ILocationFilter | null;
}

export interface setSearchShows {
  type: ShowsSearchActionTypes.SET_SEARCH_SHOWS;
  payload: IShowExhibitions[];
}

export interface setSearchOffset {
  type: ShowsSearchActionTypes.SET_SEARCH_OFFSET;
  payload: number;
}

export interface setSearchDurationSortValue {
  type: ShowsSearchActionTypes.SET_SEARCH_DURATION_SORT_VALUE;
  payload: IShowDurationSelectValue;
}

export interface setSearchFilterQueryParams {
  type: ShowsSearchActionTypes.SET_SEARCH_FILTER_QUERY_PARAMS;
  payload: any;
}

export interface setSearchCalendarValue {
  type: ShowsSearchActionTypes.SET_SEARCH_CALENDAR_VALUE;
  payload: string;
}

export interface setSearchLocationSortValue {
  type: ShowsSearchActionTypes.SET_SEARCH_LOCATION_SORT_VALUE;
  payload: ILocationSelectValue;
}

export interface setSearchLocationAutocompleteValue {
  type: ShowsSearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_VALUE;
  payload: ILocationFilter | null;
}

export type ShowsSearchActions =
  | setSearchShows
  | setSearchOffset
  | setSearchDurationSortValue
  | setSearchFilterQueryParams
  | setSearchCalendarValue
  | setSearchLocationSortValue
  | setSearchLocationAutocompleteValue;
