import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { changeCollector } from "../../../api-queries/GraohQlClient";
import { useCreateTransferContext } from "../../../context/createTransferContext";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

import DialogWindow from "../../dialog-window/DialogWindow";
import { getCoordinates } from "../../location-filter-select/utils";
import ChosenCollector from "./ChosenCollector/ChosenCollector";

import "./CreateTransferModal.scss";
import SearchNewCollectorStage from "./SearchNewCollectorStage/SearchNewCollectorStage";
import SetTransferInfoStage from "./SetTransferInfoStage/SetTransferInfoStage";

interface CreateTransferModalProps {
  artworkId: string | undefined;
  isOpen: boolean;
  close: () => void;
  refetch: () => void;
  initialHasFilmsAndTv: boolean;
}

const CreateTransferModal: React.FC<CreateTransferModalProps> = ({
  artworkId,
  isOpen,
  close,
  refetch,
  initialHasFilmsAndTv,
}) => {
  const { states } = useTypedSelector(reduxStore => reduxStore.prevStateReducer);
  const { removePrevStateById } = useActions();
  const routerLocation = useLocation();
  const isValidArtworkShowing = artworkId && routerLocation.pathname.includes(artworkId);
  const {
    chosenCollector,
    setChosenCollector,
    isSearchStage,
    setIsSearchStage,
    setShowCreateTransferModal,
    price,
    setPrice,
    commission,
    setCommission,
    notes,
    setNotes,
    locationLives,
    setLocationLives,
    tradeForValue,
    setTradeForValue,
    tradeForObject,
    setTradeForObject,
    loanToName,
    setLoanToName,
    loanToDate,
    saleable,
    setSaleable,
    availableForLoan,
    setAvailableForLoan,
    conditionReport,
    setConditionReport,
    typeOfTransfer,
    setTypeOfTransfer,
    images,
    setImages,
    hasFilmsOrTv,
    setHasFilmsOrTv,
    filmsOrTv,
    setFilmsOrTv,
    tradeFor,
    setTradeFor,
    setError,
    resetAllFields,
    setSearchText,
    setSearchResults,
    setSearchType,
    setInitialHasFilmsOrTv,
  } = useCreateTransferContext();

  useEffect(() => {
    setInitialHasFilmsOrTv(initialHasFilmsAndTv);
    setHasFilmsOrTv(initialHasFilmsAndTv);
  }, [initialHasFilmsAndTv]);

  const returnPersistedState = (state: any): void => {
    setChosenCollector(state.chosenCollector);
    setIsSearchStage(state.isSearchStage);
    setShowCreateTransferModal(state.showCreateTransferModal);
    setPrice(null, state.price);
    setCommission(null, state.commission);
    setNotes(null, state.notes);
    setLocationLives(state.locationLives);
    setTradeForValue(null, state.tradeForValue);
    setTradeForObject(null, state.tradeForObject);
    setLoanToName(null, state.loanToName);
    setSaleable(state.saleable);
    setAvailableForLoan(state.availableForLoan);
    setConditionReport(null, state.conditionReport);
    setTypeOfTransfer(state.typeOfTransfer);
    setImages(state.images);
    setHasFilmsOrTv(state.hasFilmsOrTv);
    setFilmsOrTv(state.filmsOrTv);
    setTradeFor(state.tradeFor);
    setSearchText(state.searchText);
    setSearchResults(state.searchResults);
    setSearchType(state.searchType);
    removePrevStateById("create-transfer-modal");
    localStorage.removeItem("transferModalPrevState");
  };

  useEffect(() => {
    const prevState = states.find(state => state.id === "create-transfer-modal");
    const prevLoadingStateJSON = localStorage.getItem("transferModalPrevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevState) {
      returnPersistedState(prevState.state);
      return;
    }
    if (prevLoadingState) {
      returnPersistedState(prevLoadingState.state);
    }
  }, []);

  const handleClose = (): void => {
    setIsSearchStage(true);
    setChosenCollector(null);
    setImages([]);
    resetAllFields();
    close();
  };

  const transferArtwork = async (): Promise<void> => {
    const blob = (res: any): string => res.blob();
    const blobArray = await Promise.all(
      images
        ? images?.map((item: any) => {
            return fetch(item).then(blob);
          })
        : [],
    );
    const query = {
      artworkId,
      collectorId: chosenCollector.owner ? chosenCollector.owner._id : chosenCollector._id,
      notes,
      price: price === "" ? null : Number(price),
      locationLives,
      tradeFor,
      locationCollected: locationLives,
      saleable,
      filmsOrTv,
      conditionReport,
      tradeForValue,
      hasFilmsOrTv,
      tradeForObject,
      allowUseForFilmAndTv: hasFilmsOrTv,
      availableForLoan,
      loanToName,
      loanToDate,
      type: typeOfTransfer,
      commission,
    };

    if (!locationLives?.label) {
      query.locationLives = null;
    } else {
      const coordinates = await getCoordinates(locationLives);
      query.locationLives = { name: locationLives.label, coordinates };
      query.locationCollected = { name: locationLives.label, coordinates };
    }

    try {
      await changeCollector(blobArray, query);
      handleClose();
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleTransfer = (): void => {
    if (!typeOfTransfer) {
      setError("Please choose a type of transfer");
      return;
    }

    switch (typeOfTransfer) {
      case "Direct":
        if (!price || !conditionReport) {
          setError("Please fill in all required fields");
          return;
        }
        break;
      case "Resale":
        if (!price) {
          setError("Please fill in all required fields");
          return;
        }
        if (!commission || !conditionReport) {
          setError("Fields from the box are required");
          return;
        }
        break;
      case "Trade":
        if (!tradeForObject || !tradeForValue || !conditionReport) {
          setError("Please fill in all required fields");
          return;
        }
        break;
      case "Loan":
        if (!loanToName || !loanToDate || !conditionReport) {
          setError("Please fill in all required fields");
          return;
        }
        break;
      case "Gift":
        if (!conditionReport) {
          setError("Please fill in all required fields");
          return;
        }
        break;
      case "Will":
        if (!conditionReport) {
          setError("Please fill in all required fields");
          return;
        }
        break;
      default:
    }

    if (!images.length) {
      setError("Please upload at least one photo");
      return;
    }

    if (!locationLives) {
      setError("Please provide new location");
      return;
    }

    transferArtwork();
  };

  const renderCurrentStage = (): any => {
    return (
      <div className="create-transfer">
        <div>
          {isSearchStage ? (
            <SearchNewCollectorStage close={handleClose} setChosenCollector={setChosenCollector} />
          ) : (
            <SetTransferInfoStage goToPrevStage={() => setIsSearchStage(true)} />
          )}
        </div>
        <ChosenCollector
          chosenCollector={chosenCollector}
          isSearchStage={isSearchStage}
          onClick={() => (isSearchStage ? setIsSearchStage(false) : handleTransfer())}
        />
      </div>
    );
  };
  return (
    <DialogWindow
      open={!!(isValidArtworkShowing && isOpen)}
      handleClose={handleClose}
      className="transfer_modal_overlay"
    >
      {renderCurrentStage()}
    </DialogWindow>
  );
};

export default CreateTransferModal;
