import React from "react";
import Button from "../button/Button";

interface DialogContentProps {
  handleDialogClose: () => void;
  handleRemove: () => void;
}

const DialogContent: React.FC<DialogContentProps> = ({ handleDialogClose, handleRemove }) => {
  return (
    <div className="explore_item_details_right__dialog_container">
      <Button
        onClick={handleDialogClose}
        className="explore_item_details_right__dialog_button"
        color="dark"
      >
        No
      </Button>
      <Button
        onClick={handleRemove}
        className="explore_item_details_right__dialog_button"
        color="dark"
      >
        Yes
      </Button>
    </div>
  );
};

export default DialogContent;
