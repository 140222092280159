/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";

import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { UserRequests } from "../../api-queries/AllRequests";

import ArtistOrInstitutionProfile from "../../components/artist-or-institution-profile/ArtistOrInstitutionProfile";
import ArtistOrInstitutionDescription from "../../components/artist-or-institution-description/ArtistOrInstitutionDescription";
import ArtistProfileArtworksTabs from "../../components/artist-profile-artworks-tabs/ArtistProfileArtworksTabs";
import { IUser } from "../../types/userTypes";
import { CLEAR_CONTEXT } from "../../context/action-creators/artworkFilterActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

import ArtistProfileArtworks from "../../components/artist-profile-artworks/ArtistProfileArtworks";
import { IParams } from "../../types/IParams";
import "./ArtistProfilePage.scss";
import { fetchLikedArtworksByIdLite } from "../../api-queries/GraohQlClient";

const ArtistProfile: React.FC = () => {
  const { id }: IParams = useParams();
  const { data, refetch } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: id },
  });
  const location = useLocation();
  const { state: locationState }: any = location;

  const { data: dataUserCounter, loading: counterLoading } = useQuery(
    UserRequests.USER_DETAILS_COUNTERS,
    {
      variables: { _id: id, includePast: true },
    },
  );
  const { profileUserId, profileArtworkTabs, profileContainerHeight, activeArtworkTab } =
    useTypedSelector(state => state.profileReducer);
  const { user: userReducer, userCounters } = useTypedSelector(state => state.userReducer);
  const {
    setProfileUserId,
    setActiveArtworkTab,
    setProfileContainerHeight,
    setArtworkTabs,
    setUser,
    setUserCounters,
  } = useActions();
  const { profilePageContext: context }: any = useOutletContext();
  const [likesCount, setLikesCount] = useState(0);

  const profileContainer = useRef<HTMLDivElement>(null);
  const displayTabs = Boolean(data || !!profileArtworkTabs.length);

  useEffect(() => {
    if (!profileUserId) {
      id && setProfileUserId(id);

      return;
    }

    if (profileUserId !== id) {
      context.collected?.dispatch(CLEAR_CONTEXT());
      context.created?.dispatch(CLEAR_CONTEXT());
      context.forSale?.dispatch(CLEAR_CONTEXT());
      context.found?.dispatch(CLEAR_CONTEXT());
      setActiveArtworkTab("");
      setArtworkTabs([]);
      setProfileContainerHeight(200);
      setUser(null);
      setUserCounters(null);
      id && setProfileUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setUser(data?.user);
    }
    if (dataUserCounter) {
      setUserCounters({
        scans: dataUserCounter?.countersInfo?.scans?.length,
        votes: dataUserCounter?.countersInfo?.favorites?.length,
        shows: dataUserCounter?.hostShows?.meta?.total + dataUserCounter?.invitedShows?.meta?.total,
      });
    }
  }, [data, dataUserCounter]);

  useEffect(() => {
    (async () => {
      const likedArtworks = await fetchLikedArtworksByIdLite(id);
      setLikesCount(likedArtworks?.artworks?.length);
    })();
  }, [id]);

  const isMe = !!localStorage.getItem("myMOCAId") && localStorage.getItem("myMOCAId") === id;
  const authUserInfoJSON = localStorage.getItem("userInfo");
  const authUserInfo = authUserInfoJSON ? JSON.parse(authUserInfoJSON) : null;
  const prevImage = isMe ? authUserInfo?.image : locationState?.image;
  const prevName = isMe ? authUserInfo?.name : locationState?.name;
  const user: IUser = userReducer || data?.user;
  const userIsInstitution = Boolean(user?.institutions?.length && user?.hasInstitution);
  const profile = user?.profile;
  const institutionName = user?.institutions[0]?.name;
  const fullName = userIsInstitution
    ? institutionName
    : profile && `${profile?.firstName} ${profile?.middleName || ""} ${profile?.secondName}`;
  const bio = profile?.bio?.story[0];
  const description = user?.institutions[0]?.description;
  const institutionLocation = user?.institutions[0]?.exhibitions[0]?.location?.name;
  const institutionAddress = institutionLocation
    ? `Institution placed at ${institutionLocation}`
    : "";
  const address = profile?.address?.city;
  const scans = userCounters?.scans || dataUserCounter?.countersInfo?.scans?.length;
  const voted = userCounters?.votes || dataUserCounter?.countersInfo?.favorites?.length;
  const phoneNumber = userIsInstitution ? user?.institutions[0]?.owner?.profile?.phoneNumber : "";
  const shows =
    userCounters?.shows ||
    dataUserCounter?.hostShows?.meta?.total + dataUserCounter?.invitedShows?.meta?.total;

  return (
    <div className="artist-profile">
      <div className="wrapper">
        <div
          ref={profileContainer}
          style={{ minHeight: profileContainerHeight }}
          className="artist-profile__grid"
        >
          {true && (
            <>
              <ArtistOrInstitutionProfile
                data={user}
                fullName={prevName || fullName}
                address={address}
                image={prevImage || profile?.image}
                myFollowers={user?.myFollowers}
                myFollowings={user?.myFollowings}
                likedArtworks={likesCount}
                shows={shows}
                avaliableForFilmTV={user?.institutions[0]?.availableForFilmAndTV}
                institutionAddress={institutionAddress}
                scans={scans}
                voted={voted}
                counterLoading={counterLoading}
                isMe={isMe}
                prevImage={prevImage}
                refetch={refetch}
                profile={profile}
              />

              {(bio || description) && (
                <ArtistOrInstitutionDescription
                  BIO={bio}
                  description={description}
                  daysOpen={user?.institutions[0]?.exhibitions[0]?.daysOpen}
                  phoneNumber={phoneNumber}
                  images={user?.institutions[0]?.imagesOfInstitution}
                />
              )}
            </>
          )}
        </div>
        {displayTabs && <ArtistProfileArtworksTabs data={data} isInstitution={userIsInstitution} />}
      </div>
      {!!profileArtworkTabs.length && (
        <ArtistProfileArtworks activeTab={activeArtworkTab} isInstitution={userIsInstitution} />
      )}
    </div>
  );
};

export default ArtistProfile;
