import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Button from "../../UI/button/Button";
import "cropperjs/dist/cropper.css";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import "./CropImage.scss";

interface CropImageProps {
  imageSrc: string;
  cropImage: "profile" | "profileBackground";
  handleClose: () => void;
  setDataProfile?: (value: string) => void;
  setDataBackProfile?: (value: string) => void;
  handleProfileImage?: (value: string) => void;
  handleProfileBackgroundImage?: (value: string) => void;
  minCropBoxHeight?: number;
  minCropBoxWidth?: number;
  aspectRatio?: number;
  initialAspectRatio?: number;
  cropArray?: boolean;
}

export const CropImage: React.FC<CropImageProps> = ({
  imageSrc,
  cropImage,
  handleProfileImage,
  handleProfileBackgroundImage,
  handleClose,
  minCropBoxHeight = 200,
  minCropBoxWidth = 200,
  aspectRatio,
  cropArray,
  initialAspectRatio = 1,
  setDataProfile,
  setDataBackProfile,
}) => {
  const [cropper, setCropper] = useState<any>();
  const cropperRef = useRef<HTMLImageElement>(null);

  const imageElement: any = cropperRef?.current;
  const cropperTest: any = imageElement?.cropper;

  const { profile, profileBackground } = useTypedSelector(state => state.uploadImagesReducer);

  const getCrops = (): void => {
    // eslint-disable-next-line no-unused-expressions
    handleProfileImage && handleProfileImage(cropper.getCroppedCanvas().toDataURL());
    handleClose();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,consistent-return
  const getCropData = () => {
    if (cropper && cropImage === "profile") {
      // eslint-disable-next-line no-unused-expressions
      handleProfileImage && handleProfileImage(cropper.getCroppedCanvas().toDataURL());
      // eslint-disable-next-line no-unused-expressions
      setDataProfile && setDataProfile(profile);
      handleClose();
    }
    if (cropper && cropImage === "profileBackground") {
      // eslint-disable-next-line no-unused-expressions
      handleProfileBackgroundImage &&
        handleProfileBackgroundImage(cropper.getCroppedCanvas().toDataURL());
      // eslint-disable-next-line no-unused-expressions
      setDataBackProfile && setDataBackProfile(profileBackground);
      handleClose();
    }
  };

  const cropCancel = (): void => {
    if (cropper && cropImage === "profile") {
      // eslint-disable-next-line no-unused-expressions
      setDataProfile && setDataProfile("");
      handleClose();
    }
    if (cropper && cropImage === "profileBackground") {
      // eslint-disable-next-line no-unused-expressions
      handleProfileBackgroundImage && handleProfileBackgroundImage("");
      // eslint-disable-next-line no-unused-expressions
      setDataBackProfile && setDataBackProfile("");
      handleClose();
    }
  };

  const handleRotateRight = (): void => cropperTest.rotate(90);
  const handleRotateLeft = (): void => cropperTest.rotate(-90);
  const handleResetImage = (): void => cropperTest.reset();
  const handleZoomInc = (): void => cropperTest.zoom(0.1);
  const handleZoomDec = (): void => cropperTest.zoom(-0.1);

  return (
    <div className="crop_image">
      <Cropper
        ref={cropperRef}
        style={{ height: "70vh", width: "80%" }}
        zoomTo={0.5}
        zoomOnTouch={false}
        zoomOnWheel={false}
        initialAspectRatio={initialAspectRatio}
        preview=".img-preview"
        viewMode={1}
        aspectRatio={aspectRatio}
        src={imageSrc}
        minCropBoxHeight={minCropBoxHeight}
        minCropBoxWidth={minCropBoxWidth}
        background={false}
        responsive
        modal={false}
        checkOrientation={false}
        onInitialized={instance => {
          setCropper(instance);
        }}
        guides
      />
      <div className="crop_image__buttons">
        <Button
          className="crop_image__button crop_image__cancel"
          variant="text"
          children="Cancel"
          onClick={cropCancel}
        />
        <Button
          className="crop_image__around"
          variant="text"
          children={<RotateLeftIcon />}
          onClick={handleRotateLeft}
        />
        <Button
          className="crop_image__around"
          variant="text"
          children={<ZoomOutIcon />}
          onClick={handleZoomDec}
        />
        <Button
          className="crop_image__around"
          variant="text"
          children={<RestartAltIcon />}
          onClick={handleResetImage}
        />
        <Button
          className="crop_image__around"
          variant="text"
          children={<ZoomInIcon />}
          onClick={handleZoomInc}
        />
        <Button
          className="crop_image__around"
          variant="text"
          children={<RotateRightIcon />}
          onClick={handleRotateRight}
        />
        <Button
          className="crop_image__button crop_image__choose"
          variant="text"
          children="Choose"
          onClick={cropArray ? getCrops : getCropData}
        />
      </div>
    </div>
  );
};

export default CropImage;
