import React from "react";

import termsOfUseText from "../../assets/TermsOfUse";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";

import "./TermsOfUsePage.scss";

const TermsOfUsePage: React.FC = () => {
  const title = "Terms of Use";
  return (
    <div className="terms_of_use_page">
      <ScrollToTop />
      <h3 className="terms_of_use_page__title">{title}</h3>
      <p className="terms_of_use_page__text">{termsOfUseText}</p>
    </div>
  );
};

export default TermsOfUsePage;
