import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";

export enum CollectorsPageActionTypes {
  SET_ARTISTS_COLLECTORS = "SET_ARTISTS_COLLECTORS",
  SET_OFFSET_COLLECTORS = "SET_OFFSET_COLLECTORS",
  SET_SORT_VALUE_COLLECTORS = "SET_SORT_VALUE_COLLECTORS",
  SET_FILTER_QUERY_PARAMS_COLLECTORS = "SET_FILTER_QUERY_PARAMS_COLLECTORS",
  SET_FILTER_RESULTS_COLLECTORS = "SET_FILTER_RESULTS_COLLECTORS",
  SET_ORIGINS_OF_INSPIRATION_COLLECTORS = "SET_ORIGINS_OF_INSPIRATION_COLLECTORS",
  SET_GENDER_COLLECTORS = "SET_GENDER_COLLECTORS",
  SET_LOCATION_FILTER_COLLECTORS = "SET_LOCATION_COLLECTORS",
  SET_RADIUS_COLLECTORS = "SET_RADIUS_COLLECTORS",
}

export interface ILocationFilter {
  label: string;
  value: any;
}

export interface IArtistsPageInitialState {
  artists: IUser[];
  offset: number;
  sortValue: IArtistSortValue;
  filterQueryParams: any;
  filterResults: IResultButton[];
  originsOfInspiration: IFilter[];
  gender: IGenderFilterOption;
  location: ILocationFilter | null;
  radius: number;
}

export interface setArtistsCollectors {
  type: CollectorsPageActionTypes.SET_ARTISTS_COLLECTORS;
  payload: IUser[];
}

export interface setOffsetCollectors {
  type: CollectorsPageActionTypes.SET_OFFSET_COLLECTORS;
  payload: number;
}

export interface setSortValueCollectors {
  type: CollectorsPageActionTypes.SET_SORT_VALUE_COLLECTORS;
  payload: IArtistSortValue;
}

export interface setFilterQueryParamsCollectors {
  type: CollectorsPageActionTypes.SET_FILTER_QUERY_PARAMS_COLLECTORS;
  payload: any;
}

export interface setFilterResultsCollectors {
  type: CollectorsPageActionTypes.SET_FILTER_RESULTS_COLLECTORS;
  payload: IResultButton[];
}

export interface setOriginsOfInspirationCollectors {
  type: CollectorsPageActionTypes.SET_ORIGINS_OF_INSPIRATION_COLLECTORS;
  payload: IFilter[];
}

export interface setGenderCollectors {
  type: CollectorsPageActionTypes.SET_GENDER_COLLECTORS;
  payload: IGenderFilterOption;
}

export interface setLocationFilterCollectors {
  type: CollectorsPageActionTypes.SET_LOCATION_FILTER_COLLECTORS;
  payload: ILocationFilter | null;
}

export interface setRadiusCollectors {
  type: CollectorsPageActionTypes.SET_RADIUS_COLLECTORS;
  payload: number;
}

export type CollectorsSearchActions =
  | setArtistsCollectors
  | setOffsetCollectors
  | setSortValueCollectors
  | setFilterQueryParamsCollectors
  | setFilterResultsCollectors
  | setOriginsOfInspirationCollectors
  | setGenderCollectors
  | setLocationFilterCollectors
  | setRadiusCollectors;
