export const BIO = `
  bornCity
  country
  schools
  teachers
  awards
  heritage
  culture
  ethnic
  story
  website
  spiritualInspirations
  tribalIdentities
  other
`;
