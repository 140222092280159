import React from "react";
import { useQuery } from "@apollo/client";
import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";
import { MY_RESALE_ART } from "../../api-queries/ArtworksRequests";

import "./ResaleArtAndTermsPage.scss";
import { getUniqueArtworks } from "../../components/artworks-explore-list/utils";

const ResaleArtAndTermsPage = (): JSX.Element => {
  const { data, refetch, loading } = useQuery(MY_RESALE_ART, {
    variables: { limit: 50 },
  });

  const uniqueArtworks = data?.userCurrentArtworksForResale.artworks
    ? getUniqueArtworks(data?.userCurrentArtworksForResale?.artworks)
    : null;

  return (
    <div className="resale_art_and_terms">
      <ArtsControllerList
        artworks={uniqueArtworks}
        refetch={refetch}
        title="Resale Art and Terms"
        displayFullArtworkInfo
        loading={loading}
      />
    </div>
  );
};

export default ResaleArtAndTermsPage;
