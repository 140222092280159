import React, { useState, FC } from "react";
import { Alert, Button, InputAdornment, TextField } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { useMutation } from "@apollo/client";
import { RecoverPasswordProps } from "./RecoverPassword.props";
import { RECOVER_PASSWORD } from "../../api-queries/RegistrationRequests";

const RecoverPassword: FC<RecoverPasswordProps> = ({ onRecoverPassword }): JSX.Element => {
  const [recoverPassword] = useMutation(RECOVER_PASSWORD);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [successEmail, setSuccessEmail] = useState("");
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };
  // @typescript-eslint/explicit-function-return-type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const validateEmail = (xxx: string) => {
    return xxx.match(
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmitForm = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setErrorEmail("");
    setSuccessEmail("");
    if (!validateEmail(email)) {
      setErrorEmail("Invalid email format");
      return;
    }
    if (!email) {
      setErrorEmail("Email is required");
      return;
    }
    try {
      await recoverPassword({
        variables: {
          email,
        },
      });
      setSuccessEmail("Check email, Link was sent on your email");
    } catch (error: any) {
      setErrorEmail(error.message);
    }
  };
  return (
    <div className="recover_password">
      <form onSubmit={onSubmitForm} noValidate className="recover_password__form">
        <TextField
          id="input_email"
          className="registration_input input"
          label="email"
          type="email"
          name="email"
          value={email}
          onChange={onChangeEmail}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <div className="forgot_pass">
          <div className="forgot_pass__link" onClick={() => onRecoverPassword(false)}>
            Back
          </div>
        </div>

        {errorEmail && (
          <Alert className="alert_error" severity="error">
            {errorEmail}
          </Alert>
        )}

        {successEmail && (
          <Alert className="alert_error" severity="success">
            {successEmail}
          </Alert>
        )}

        <div className="registration_buttons">
          <Button type="submit" className="login_button" variant="contained">
            Recover password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RecoverPassword;
