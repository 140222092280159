import React, { FC } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { IChatMessageContentProps } from "../utils";
import { useActions } from "../../../hooks/useActions";

import "./ExhibitionMessage.scss";

const ExhibitionMessage: FC<IChatMessageContentProps> = ({ message }) => {
  const { openModal } = useActions();
  return (
    <div className="exhibition-message" onClick={openModal}>
      <div className="chat-message_audited_name">
        <p>{message.audited.exhibition.title}</p>
        <ArrowForwardIosIcon sx={{ width: 15, height: 15 }} />
      </div>
      {message.audited?.exhibition?.image?.medium?.url && (
        <img
          className="chat-message_image"
          src={message.audited.exhibition.image.medium.url}
          alt=""
        />
      )}
      <p className="chat-message_text">{message.text}</p>
    </div>
  );
};

export default ExhibitionMessage;
