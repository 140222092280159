import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Checkbox, InputAdornment, TextField } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useMutation } from "@apollo/client";
import { privacyPolicy, termsOfUse, uploadUserImage, user } from "../../route-link";
import { RegistrationRequests } from "../../api-queries/AllRequests";
import { useActions } from "../../hooks/useActions";

const RegistrationForm = ({ checked, handleChangeCheck }) => {
  const navigate = useNavigate();
  const [addUser] = useMutation(RegistrationRequests.SIGN_UP);
  const [errors, setErrors] = useState([]);

  const [errorFormName, setErrorFormName] = useState(false);
  const [errorFormEmail, setErrorFormEmail] = useState(false);
  const [errorFormPassword, setErrorFormPassword] = useState(false);
  const [errorDuplicate, setErrorDuplicate] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
  });

  const onChangeInput = ({ target: { value, name } }) =>
    setFormValues({ ...formValues, [name]: value });
  const splitName = formValues.name.split(" ");

  const userVariables = () => {
    return addUser({
      variables: {
        firstName: splitName[0] || "",
        middleName: splitName.length >= 3 ? splitName[1] : "",
        lastName: splitName[2] ? splitName[2] : splitName[splitName.length - 1] || "",
        email: formValues.email,
        password: formValues.password,
      },
    });
  };

  const { closeModalLogin, addToken, login, headerFlag } = useActions();
  const onSubmitForm = async e => {
    e.preventDefault();
    try {
      const data = await userVariables();
      localStorage.setItem("tokenKey", data.data.signUp.token);
      localStorage.setItem("myMOCAId", data.data.signUp.me._id);
      addToken(data.data.signUp.token);
      headerFlag();
      login();
      closeModalLogin();
      navigate(`${user}/${uploadUserImage}`, {
        state: {
          ...formValues,
          id: data.data.signUp.me._id,
          hasInstitution: false,
          phone: "",
        },
      });
    } catch (err) {
      setErrorFormName(false);
      setErrorFormEmail(false);
      setErrorFormPassword(false);
      setErrorDuplicate(false);
      if (err.message.toLowerCase().includes("name")) setErrorFormName(true);
      if (err.message.toLowerCase().includes("email")) setErrorFormEmail(true);
      if (err.message.toLowerCase().includes("password")) setErrorFormPassword(true);
      if (err.message.toLowerCase().includes("duplicate")) setErrorDuplicate(true);
      const errorMessage = err.message.split(",");
      setErrors(errorMessage);
    }
  };
  const disabled = !formValues.name || !formValues.email || !formValues.password || !checked;
  return (
    <form onSubmit={onSubmitForm} noValidate>
      <TextField
        id="first_name"
        className="registration_input input"
        name="name"
        label="full name"
        value={formValues.name}
        onChange={onChangeInput}
        error={errorFormName}
        placeholder="Example: John Junior Doe"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlinedIcon className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <TextField
        id="input_email"
        className="registration_input input"
        label="email"
        type="email"
        name="email"
        value={formValues.email}
        error={errorFormEmail}
        onChange={onChangeInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <TextField
        id="input_password"
        className="registration_input input"
        label="password"
        name="password"
        type="password"
        value={formValues.password}
        error={errorFormPassword}
        onChange={onChangeInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <p className="under_input">Case sensitive</p>
      {errorDuplicate && (
        <Alert className="alert_error" severity="error">
          <div className="alert_error__message">
            <strong>User with this email already registered</strong>
          </div>
        </Alert>
      )}
      {!!errors.length && !errorDuplicate && (
        <Alert className="alert_error" severity="error">
          {errorFormName && (
            <div className="alert_error__message">
              <strong>{errorFormName}</strong>
            </div>
          )}
          {errors.map(error => (
            <div className="alert_error__message" key={error}>
              <strong>{error}.</strong>
            </div>
          ))}
        </Alert>
      )}

      <div className="registration_check__block">
        <div className="check_box">
          <Checkbox
            className="registration_check"
            checked={checked}
            onChange={handleChangeCheck}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        <p className="registration_check__text">
          By creating your account you agree to our{" "}
          <Link className="registration_check__link" target="_blank" to={termsOfUse}>
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link className="registration_check__link" target="_blank" to={privacyPolicy}>
            Privacy Policy
          </Link>
        </p>
      </div>
      {formValues.check && <p className="error_message">{formValues.check}</p>}
      <div className="registration_buttons">
        <Button disabled={disabled} type="submit" className="login_button" variant="contained">
          Complete profile
        </Button>
        <div className="registration_buttons__or">
          <span className="registration_buttons__or__text">or</span>
        </div>
        <Button
          className="quick_start__button"
          type="submit"
          variant="outlined"
          disabled={disabled}
        >
          quick start finish profile later
        </Button>
      </div>
    </form>
  );
};
export default RegistrationForm;
