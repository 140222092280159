import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputLabel, NativeSelect } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import userSex from "../../data/userSex.json";
import originOfInspiration from "../../data/originOfInspiration.json";
import { updateUser } from "../../api-queries/GraohQlClient";
import { MultiInput } from "../../components/multi-input/MultiInput";
import { root } from "../../route-link";
import Button from "../../UI/button/Button";
import LocationAutocomplete from "../../components/locations-autocomplete/LocationAutocomplete";
import { getCoordinates } from "../../components/location-filter-select/utils";

import "./OriginOfInspiration.scss";

const OriginOfInspiration = () => {
  const [location, setLocation] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const originData = originOfInspiration;
  const [firstName, lastName] = state?.state.name.split(" ");
  const [sexData, setSexData] = useState("");
  const [areaData, setAreaData] = useState("");
  const onSubmit = async event => {
    event.preventDefault();

    const userData = {
      address: {
        location: {},
      },
    };

    originData.forEach(origin => {
      const keyName = origin.name;
      if (keyName === "postalCode") {
        userData.address = {
          ...userData.address,
          postalCode: origin.data,
        };
      }
      userData[keyName] = origin.data;
    });
    if (location && location?.label !== "") {
      const coordinates = await getCoordinates(location);
      userData.address = {
        ...userData.address,
        location: {
          name: location?.label,
          coordinates: coordinates ?? {
            longitude: 0,
            latitude: 0,
          },
        },
      };
    }
    updateUser({
      _id: state.state.id,
      firstName,
      lastName,
      sex: sexData,
      ...userData,
      story: areaData,
    }).then(() => navigate(root));
  };

  const handleChangeLocation = value => setLocation(value);
  const onCloseIconClickLocation = () => setLocation(null);

  const handleAreaData = event => setAreaData(event.target.value);

  const getData = (name, data) => {
    const index = originData.findIndex(d => d.name === name);
    originData[index].data = data;
  };

  return (
    <div className="origin_of_inspiration">
      <div className="origin_title__wrap">
        <ArrowBackIcon className="arrow__back" onClick={() => navigate(-1)} />
        <h2 className="origin_title">Origins Of Inspiration</h2>
        <p className="origin_sub_title">Search terms</p>
      </div>
      <form className="origin_grid" onSubmit={onSubmit}>
        <div className="origin_title__wrap">
          <div className="origin_item">
            <InputLabel variant="standard" className="origin_label" htmlFor="uncontrolled-native">
              Sex
            </InputLabel>
            <NativeSelect
              value={sexData}
              onChange={e => setSexData(e.target.value)}
              className="origin_select"
              defaultValue="Choose a sex..."
              inputProps={{
                name: "sex",
                id: "uncontrolled-native",
              }}
            >
              <option disabled value="">
                Choose a sex...
              </option>
              {userSex.map(item => (
                <option key={item.id} value={item.title.toLowerCase()}>
                  {item.title}
                </option>
              ))}
            </NativeSelect>
          </div>

          <div className="origin_item">
            <InputLabel variant="standard" htmlFor="location" className="origin_label">
              Map my location
            </InputLabel>
            <LocationAutocomplete
              value={location || ""}
              className="edit_item__location"
              onChange={handleChangeLocation}
              onCloseIconClick={onCloseIconClickLocation}
              placeholder="Choose location..."
            />
          </div>

          {originData.map(input => (
            <div className="origin_item" key={input.label}>
              <InputLabel className="origin_label" variant="standard" htmlFor={input.htmlFor}>
                {input.label}
              </InputLabel>
              <MultiInput
                label={input.name}
                id={input.htmlFor}
                getData={getData}
                isMulti={input.isMulti}
                className="text_field"
                textFiledProps={{ variant: "standard", type: input.type }}
              />
              {input.text && <p className="required">{input.text}</p>}
            </div>
          ))}
          <div className="origin_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="user_phone">
              BIO
            </InputLabel>
            <div className="text_area">
              <TextareaAutosize
                className="text_field text_area__input"
                aria-label="minimum height"
                minRows={1}
                value={areaData}
                onChange={handleAreaData}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="upload_image__footer">
          <Link to={root} className="upload_image__link">
            I`ll complete this later
          </Link>
          <Button variant="button-dark" children="Continue" className="bg_dark" />
        </div>
      </form>
    </div>
  );
};

export default OriginOfInspiration;
