import React, { useEffect, useState } from "react";
import EditRenderItems from "./EditRenderItems";
import { EditRenderItemsProps } from "../edit.props";

const EditRenderItemsMiddleware: React.FC<EditRenderItemsProps> = ({
  items,
  arrayCheckItems,
  id,
  value,
  onItemClick,
  predefined,
}): JSX.Element => {
  const [list, setList] = useState<Array<string> | undefined>([]);

  const checkItemList = arrayCheckItems?.map(el => el.value);
  const isNotCheckedPredefined = (pred: string): boolean => !checkItemList?.includes(pred);
  const predefinedList = (): void => setList(predefined?.filter(isNotCheckedPredefined));

  useEffect(() => {
    predefinedList();
  }, [arrayCheckItems]);
  return (
    <EditRenderItems
      items={items}
      onItemClick={onItemClick}
      id={id}
      value={value}
      predefined={predefined}
      arrayCheckItems={arrayCheckItems}
      predefinedList={list}
    />
  );
};

export default EditRenderItemsMiddleware;
