import { LoginState, LoginAction, LoginActionTypes } from "../types/login";

const initialValue: LoginState = {
  loginFlag: true,
};

const loginReducer = (state = initialValue, action: LoginAction): LoginState => {
  switch (action.type) {
    case LoginActionTypes.LOGIN:
      return { ...state, loginFlag: !state.loginFlag };
    default:
      return state;
  }
};
export default loginReducer;
