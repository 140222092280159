import React, { memo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import ExploreItemDetailsRight from "../explore-item-details-right/ExploreItemDetailsRight";
import ImageLightbox from "../image-lightbox/ImageLightbox";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import { artworkDeatils } from "../../api-queries/GraohQlClient";

import "./ArtWorksExploreItemDetails.scss";
import { CreateTransferContextProvider } from "../../context/createTransferContext";
import { myNominatedArtwork, myVotedArtwork, votesArtwork } from "../../route-link";
import VotesArtworkDetails from "../explore-item-details-right/votes-artwork-details/VotesArtworkDetails";
import NominatedArtworkDetails from "../explore-item-details-right/nominated-artwork-details/NominatedArtworkDetails";

const ArtWorksExploreItemDetails = ({
  id,
  artwork,
  removeArtworkFromSlider,
  blockTransferModal,
  setBlockSliding,
  slideNext,
}: any): JSX.Element => {
  const [artworkItem, setArtworkItem] = useState(artwork);
  const suggestions = useRef(artwork?.suggestions?.filter((item: any) => !item.iDontKnow));
  const artworkOwnerId = artwork?.owner?._id;
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const routerLocation = useLocation();
  const notVotesArtwork =
    !routerLocation.pathname.includes(myNominatedArtwork) &&
    !routerLocation.pathname.includes(myVotedArtwork) &&
    !routerLocation.pathname.includes(votesArtwork);

  const showOwnerActionsButtons = Boolean(
    (artworkOwnerId === authUserId && artwork?.type === "artist" && artwork?.collector === null) ||
      (artworkOwnerId === authUserId &&
        artwork?.type === "collector" &&
        artwork?.collector !== null &&
        artwork?.collector._id === authUserId) ||
      (artworkOwnerId === authUserId && artwork?.type === "found" && artwork?.collector === null),
  );
  const artworkCollectorId = artwork?.collector?._id;
  const showCollectorActionButtons = Boolean(
    artwork?.collector !== null && artworkCollectorId === authUserId,
  );

  const refetchArtwork = async (): Promise<void> => {
    const response = await artworkDeatils({ artworkId: id });
    suggestions.current = response.artwork.suggestions.filter((item: any) => !item.iDontKnow);
    setArtworkItem(response.artwork);
  };

  const renderDetailsRight = (): any => {
    let Comp = ExploreItemDetailsRight;
    if (routerLocation.pathname.includes(myNominatedArtwork)) Comp = NominatedArtworkDetails;
    if (routerLocation.pathname.includes(myVotedArtwork)) Comp = VotesArtworkDetails;
    if (routerLocation.pathname.includes(votesArtwork)) Comp = VotesArtworkDetails;
    return (
      <Comp
        artworkId={id}
        artwork={artworkItem}
        refetch={refetchArtwork}
        suggestions={suggestions.current}
        showOwnerActionsButtons={showOwnerActionsButtons}
        showCollectorActionButtons={showCollectorActionButtons}
        removeArtworkFromSlider={removeArtworkFromSlider}
        blockTransferModal={blockTransferModal}
        setBlockSliding={setBlockSliding}
        slideNext={slideNext}
      />
    );
  };

  return (
    <div className="artwork_details_page">
      <ScrollToTop />
      <div
        className="artworks_explore_item_details"
        style={notVotesArtwork ? {} : { gridTemplateColumns: "1fr 20%" }}
      >
        <div className="light_box__wrap">
          <ImageLightbox
            mainImage={artwork?.image}
            images={artwork?.additionalImages || []}
            artworkId={id}
            showOwnerActionsButtons={showOwnerActionsButtons}
            showCollectorActionButtons={showCollectorActionButtons}
            notVotesArtwork={notVotesArtwork}
            artwork={artwork}
          />
        </div>
        <CreateTransferContextProvider filmsAndTv={artworkItem.allowUseForFilmAndTv}>
          {renderDetailsRight()}
        </CreateTransferContextProvider>
      </div>
    </div>
  );
};
export default memo(ArtWorksExploreItemDetails);
