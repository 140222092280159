import React, { FC, useState } from "react";
import ReportIcon from "@mui/icons-material/Report";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ReportContent from "../report-content/ReportContent";
import { getReportIdKey, getReportTitle } from "./utils";
import { useActions } from "../../hooks/useActions";

import "./ReportButton.scss";

interface ReportButtonProps {
  itemId: string | undefined;
  className: string;
  reportAction: (par: any) => Promise<void>;
  reportItemType: string;
}

const ReportButton: FC<ReportButtonProps> = ({
  className,
  reportAction,
  itemId,
  reportItemType,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { openModalLogin } = useActions();
  const reportIdKey = getReportIdKey(reportItemType);
  const reportTitle = getReportTitle(reportItemType);
  const token = localStorage.getItem("tokenKey");
  const onReportClick = token ? () => setModalIsOpen(true) : openModalLogin;

  return (
    <>
      <ReportContent
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        title={reportTitle}
        reportAction={reportAction}
        itemId={itemId}
        reportIdKey={reportIdKey}
      />
      <Tooltip title="Report">
        <div className={`${className} report_button`}>
          <IconButton onClick={onReportClick}>
            <ReportIcon color="error" />
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

export default ReportButton;
