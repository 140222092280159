import { ILocationFilter } from "../../context/types/artistsPageTypes";
import { ArtworkTypes } from "../../types/artwork/artwork";
import { getCoordinates } from "../location-filter-select/utils";
import { IImage } from "../../types/imageTypes";

export interface RenderItems {
  checked: boolean;
  id: string;
  type: string;
  value: string;
}

export interface Predefined {
  addNewActive: boolean;
  id: string | number;
  value: string;
}

export interface EditRenderItemsProps {
  items: Array<string>;
  onItemClick: (text: string, id: number | string) => void;
  value: string | undefined;
  id: number | string;
  predefined?: Array<any>;
  predefinedList?: Array<string>;
  arrayCheckItems?: Array<Predefined>;
}

export interface RenderItemsButtonProps {
  item: any;
}

export interface ErrorFields {
  titleError: string;
  yearError: string;
  originError: string;
  conditionReportError: string;
  notesError: string;
  locationError: string;
  locationLivesError: string;
  locationFoundError: string;
  materialsError: string;
  colorsError: string;
  categoriesError: string;
  collectedAtError: string;
  videoUrlError: string;
  widthError: string;
  weightError: string;
  heightError: string;
  thicknessError: string;
  lengthError: string;
  priceError: string;
  nameOfArtistError: string;
  exhibitionsError: string;
  collectedError: string;
}

export interface InputType {
  id: number | string;
  value: string;
  addNewActive: boolean;
}

export interface DoubleInputType {
  id: number;
  firstInputValue: string | ILocationFilter;
  secondInputValue: string;
}

export interface TextMaterial {
  text: string;
  id: number | string;
}

export interface Category {
  checked: boolean;
  id: string;
  type: string;
  value: string;
}

export interface PasteText {
  value: "Copied" | "Paste";
  copied: boolean;
}

export interface ID {
  id: string;
}

export interface InitialCategory {
  description: null;
  name: string;
  __typename: string;
  _id: string;
}

export interface EditImagesProps {
  images: Array<string>;
  setImages: (value: Array<string>) => void;
  titleWindow: string;
  isEditPage?: boolean;
  additionalImages?: any;
  setAdditionalImagesToDelete?: (arr: any) => void;
  showHeader?: boolean;
  transferModal?: boolean;
}

export interface UseLocationState {
  state: {
    type: string;
    image: IImage;
    name: string;
  };
}

export const getCollectors = async (
  collected: DoubleInputType[],
  type: ArtworkTypes.ARTIST | ArtworkTypes.COLLECTOR | ArtworkTypes.FOUND,
  initCollectors: any[],
): Promise<any> => {
  if (type === ArtworkTypes.COLLECTOR) {
    if (collected?.length > 0) {
      return collected.map(async (el: any) => {
        const initCollector = initCollectors?.find(
          (collector: any) => collector.name === el?.firstInputValue?.label,
        );

        let coordinates;

        if (initCollector) {
          coordinates = initCollector.coordinates;
        } else if (el?.firstInputValue?.label) {
          coordinates = await getCoordinates(el?.firstInputValue);
        }
        return {
          collectedAt: coordinates ? { name: el?.firstInputValue.label, coordinates } : null,
          yearCollected: el?.secondInputValue,
        };
      });
    }

    return undefined;
  }

  if (collected?.length > 0) {
    return collected.map(el => ({
      collectedBy: el?.firstInputValue,
      yearCollected: el?.secondInputValue,
    }));
  }

  return undefined;
};
