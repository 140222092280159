import { IArtwork } from "../../types/artwork/artwork";

export interface UserVotedArtworksState {
  userVotedArtworks: IArtwork[] | [];
  offset: number;
  userId: string;
}

export enum UserVotedArtworksTypes {
  SET_USER_VOTED_ARTWORKS = "SET_USER_VOTED_ARTWORKS",
  SET_OFFSET = "SET_OFFSET",
  SET_USER_ID = "SET_USER_ID",
}

interface setUserVotedArtworks {
  type: UserVotedArtworksTypes.SET_USER_VOTED_ARTWORKS;
  payload: IArtwork[] | [];
}

interface setOffset {
  type: UserVotedArtworksTypes.SET_OFFSET;
  payload: number;
}

interface setUserId {
  type: UserVotedArtworksTypes.SET_USER_ID;
  payload: string;
}

export type UserVotedArtworksType = setUserVotedArtworks | setOffset | setUserId;
