import { IResultButton } from "../../components/filter-units/utils";
import { IArtwork, SearchArtworksRequest } from "../../types/artwork/artwork";
import { IFilter, IFilterCategory, ISortOption } from "../../types/filterTypes";
import { ILocationFilter } from "../types/artistsPageTypes";
import { ArtworkFilterActions, ArtworkFilterActionTypes } from "../types/artworkFilterTypes";

export const SET_SORT_VALUE = (payload: ISortOption): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_SORT_VALUE,
  payload,
});

export const SET_FILTER_QUERY_PARAMS = (payload: SearchArtworksRequest): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_FILTER_QUERY_PARAMS,
  payload,
});

export const SET_ARTWORKS = (payload: IArtwork[]): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_ARTWORKS,
  payload,
});

export const SET_CATEGORIES = (payload: IFilterCategory[]): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_CATEGORIES,
  payload,
});

export const SET_MATERIALS = (payload: IFilter[]): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_MATERIALS,
  payload,
});

export const SET_COLORS = (payload: IFilter[]): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_COLORS,
  payload,
});

export const SET_OFFSET = (payload: number): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_OFFSET,
  payload,
});

export const SET_FILTER_RESULTS = (payload: IResultButton[]): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_FILTER_RESULTS,
  payload,
});

export const SET_WIDTH_MIN_VALUE = (payload: number): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_WIDTH_MIN_VALUE,
  payload,
});

export const SET_WIDTH_MAX_VALUE = (payload: number): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_WIDTH_MAX_VALUE,
  payload,
});

export const SET_HEIGHT_MIN_VALUE = (payload: number): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_HEIGHT_MIN_VALUE,
  payload,
});

export const SET_HEIGHT_MAX_VALUE = (payload: number): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_HEIGHT_MAX_VALUE,
  payload,
});

export const SET_ORIGINS_OF_INSPIRATION = (payload: any[]): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_ORIGINS_OF_INSPIRATION,
  payload,
});

export const CLEAR_CONTEXT = (): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.CLEAR_CONTEXT,
});

export const SET_LOCATION_FILTER = (payload: ILocationFilter | null): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_LOCATION_FILTER,
  payload,
});

export const SET_RADIUS = (payload: number): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.SET_RADIUS,
  payload,
});

export const DELETE_ARTWORK = (payload: string): ArtworkFilterActions => ({
  type: ArtworkFilterActionTypes.DELETE_ARTWORK,
  payload,
});
