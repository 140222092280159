import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import "./TimePickerComponents.scss";

const TimePickerComponents = ({ day, week, setWeek }) => {
  const defaultHours = week?.find(el => el.dayOfWeek === day)?.hoursOpen;
  const [isChecked, setIsChecked] = useState(week?.some(el => el.dayOfWeek === day));
  const [dayOfWeek, setDayOfWeek] = useState({
    dayOfWeek: null,
    hoursOpen: {
      from: new Date(defaultHours?.from ?? "2018-01-01T00:00:00.000Z"),
      to: new Date(defaultHours?.to ?? "2018-01-01T00:00:00.000Z"),
    },
  });
  useEffect(() => {
    setDayOfWeek({ ...dayOfWeek, dayOfWeek: day });
  }, []);

  const onChangeDay = ({ target: { value, checked } }) => {
    if (checked) setWeek([...week, dayOfWeek]);
    if (!checked) setWeek(week.filter(item => item.dayOfWeek !== value));
  };

  const onChangeTimePicker = (newValue, fromTo) => {
    const findDay = week.find(item => item.dayOfWeek === day);
    if (fromTo === "from") findDay.hoursOpen.from = newValue;
    if (fromTo === "to") findDay.hoursOpen.to = newValue;
    setDayOfWeek({
      ...findDay,
      hoursOpen: {
        ...findDay.hoursOpen,
        [fromTo]: newValue,
      },
    });
  };
  return (
    <div className="day_item">
      <FormControlLabel
        control={
          <Checkbox
            onChange={e => {
              setIsChecked(!isChecked);
              onChangeDay(e);
            }}
            checked={isChecked}
            value={day}
          />
        }
        label={day}
      />
      {isChecked && (
        <div className="time_picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
              label="opens"
              value={dayOfWeek.hoursOpen.from}
              onChange={newValue => onChangeTimePicker(newValue, "from")}
              renderInput={params => <TextField {...params} />}
            />
            <MobileTimePicker
              label="closes"
              value={dayOfWeek.hoursOpen.to}
              onChange={newValue => onChangeTimePicker(newValue, "to")}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      )}
    </div>
  );
};
export default TimePickerComponents;
