import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { lengthConvert } from "../../utils/convert";
import { IFilterTypes } from "../../types/filterTypes";

import { changeInputsValueUnit, updateFilterResultButton } from "./utils";
import { LengthUnits } from "../filter-from-to/FilterFromTo.props";
import { maxNumber } from "../../misc/constants";

import "./FilterUnits.scss";

const FilterUnits = ({ units, setUnits, context }) => {
  const {
    filterResults: { setFilterResults, filterResults },
    widthMinValue: { widthMinValue, setWidthMinValue },
    widthMaxValue: { widthMaxValue, setWidthMaxValue },
    heightMinValue: { heightMinValue, setHeightMinValue },
    heightMaxValue: { heightMaxValue, setHeightMaxValue },
  } = context;
  const widthResultButton = filterResults.find(result => {
    return result.type === IFilterTypes.Width;
  });
  const heightResultButton = filterResults.find(result => {
    return result.type === IFilterTypes.Height;
  });
  const heightIn = {
    min: Math.round(lengthConvert(heightMinValue, LengthUnits.Cm, LengthUnits.In).value),
    max: Math.round(lengthConvert(heightMaxValue, LengthUnits.Cm, LengthUnits.In).value),
  };
  const widthIn = {
    min: Math.round(lengthConvert(widthMinValue, LengthUnits.Cm, LengthUnits.In).value),
    max: Math.round(lengthConvert(widthMaxValue, LengthUnits.Cm, LengthUnits.In).value),
  };
  const heightCm = {
    min: Math.round(lengthConvert(heightMinValue, LengthUnits.In, LengthUnits.Cm).value),
    max: Math.round(lengthConvert(heightMaxValue, LengthUnits.In, LengthUnits.Cm).value),
  };
  const widthCm = {
    min: Math.round(lengthConvert(widthMinValue, LengthUnits.In, LengthUnits.Cm).value),
    max: Math.round(lengthConvert(widthMaxValue, LengthUnits.In, LengthUnits.Cm).value),
  };
  const onChange = ({ target }) => {
    setUnits(target.value);
    if (target.value === "cm/kg") {
      changeInputsValueUnit(heightCm, widthCm, {
        setHeightMinValue,
        setHeightMaxValue,
        setWidthMinValue,
        setWidthMaxValue,
      });
      if (widthResultButton) {
        updateFilterResultButton(widthResultButton, maxNumber, {
          from: LengthUnits.In,
          to: LengthUnits.Cm,
        });
        setFilterResults([...filterResults]);
      }
      if (heightResultButton) {
        updateFilterResultButton(heightResultButton, maxNumber, {
          from: LengthUnits.In,
          to: LengthUnits.Cm,
        });
        setFilterResults([...filterResults]);
      }

      return;
    }

    changeInputsValueUnit(heightIn, widthIn, {
      setHeightMinValue,
      setHeightMaxValue,
      setWidthMinValue,
      setWidthMaxValue,
    });
    if (widthResultButton) {
      updateFilterResultButton(widthResultButton, maxNumber, {
        from: LengthUnits.Cm,
        to: LengthUnits.In,
      });
      setFilterResults([...filterResults]);
    }
    if (heightResultButton) {
      updateFilterResultButton(heightResultButton, maxNumber, {
        from: LengthUnits.Cm,
        to: LengthUnits.In,
      });
      setFilterResults([...filterResults]);
    }
  };

  return (
    <FormControl className="filter_units_content">
      <Select
        displayEmpty
        className="select_units"
        labelId="simple_select_label"
        id="simple_select"
        value={units}
        onChange={onChange}
      >
        <MenuItem value="in/pounds">in/pounds</MenuItem>
        <MenuItem value="cm/kg">cm/kg</MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterUnits;
