import React, { FC, useState } from "react";
import { CircularProgress } from "@mui/material";

import { ILocationFilter } from "../../context/types/artistsPageTypes";
import SelectSort from "../../UI/select-sort/SelectSort";
import DialogWindow from "../dialog-window/DialogWindow";
import {
  getCoordinates,
  getUserLocationCoords,
  ILocationSelectValue,
  LocationFilterNames,
  locationFilters,
} from "./utils";
import LocationAutocomplete from "../locations-autocomplete/LocationAutocomplete";
import Button from "../../UI/button/Button";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import "./LocationFilterSelect.scss";

interface LocationFilterSelectProps {
  width: string;
  locationAutocompleteValue: ILocationFilter | null;
  setLocationAutocompleteValue: (param: ILocationFilter | null) => void;
  locationSortValue: ILocationSelectValue;
  setLocationSortValue: (param: ILocationSelectValue) => void;
  setDataItem: (param: any) => void;
  setOffset: (param: number) => void;
  filterQueryParams: any;
  setFilterQueryParams: (param: any) => void;
}

const LocationFilterSelect: FC<LocationFilterSelectProps> = ({
  width,
  locationAutocompleteValue,
  setLocationAutocompleteValue,
  locationSortValue,
  setLocationSortValue,
  setDataItem,
  setOffset,
  filterQueryParams,
  setFilterQueryParams,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [prevLocationSelectValue, setPrevLocationSelectValue] = useState<ILocationSelectValue>(
    locationFilters[2],
  );
  const [isPositionLoading, setIsPositionLoading] = useState(false);

  const { setCurrentLocation } = useActions();
  const { currentLocation } = useTypedSelector(store => store.currentLocationReducer);

  const renderLocationSelectValue = (value: ILocationSelectValue): JSX.Element | string => {
    if (isPositionLoading) {
      return (
        <span className="location_select_title">
          <span className="sort_name">{value.name}</span>
          <CircularProgress size={15} style={{ color: "#282828" }} />
        </span>
      );
    }
    if (locationAutocompleteValue && value.name === LocationFilterNames.SELECT_LOCATION) {
      return `Near ${locationAutocompleteValue?.label || ""}`;
    }
    return value.name;
  };

  const onLocationAutocompleteChange = async (value: ILocationFilter): Promise<void> => {
    const label = value?.label;
    setIsDialogOpen(false);
    const coordinates = await getCoordinates(value);
    if (!coordinates) {
      setLocationSortValue(prevLocationSelectValue);
      return;
    }
    if (label !== locationAutocompleteValue?.label) {
      setLocationAutocompleteValue(value);
      setDataItem([]);
      setOffset(0);
      setFilterQueryParams({ ...filterQueryParams, location: { ...coordinates, radius: 10 } });
    }
  };

  const onLocationOptionClick = ({ target }: any): void => {
    if (target.outerText === LocationFilterNames.SELECT_LOCATION) {
      setIsDialogOpen(true);
    }
    setPrevLocationSelectValue(locationSortValue);
  };

  const sortByLocation = async ({ value }: ILocationFilter): Promise<void> => {
    const currentSortValue = locationSortValue;
    setLocationAutocompleteValue(null);
    if (value === LocationFilterNames.SELECT_LOCATION) {
      setIsDialogOpen(true);
      return;
    }
    if (value === LocationFilterNames.NEAR_CURRENT_LOCATION) {
      getUserLocationCoords(
        currentLocation,
        setIsPositionLoading,
        setCurrentLocation,
        setLocationSortValue,
        currentSortValue,
        setDataItem,
        setOffset,
        setFilterQueryParams,
        filterQueryParams,
      );
      return;
    }
    if (value === LocationFilterNames.ANYWHERE) {
      setDataItem([]);
      setOffset(0);
      setFilterQueryParams({ ...filterQueryParams, location: undefined, offset: 0 });
    }
  };

  const onDialogClose = (): void => {
    if (!locationAutocompleteValue) {
      setLocationSortValue(prevLocationSelectValue);
    }
    setIsDialogOpen(false);
  };
  return (
    <div className="location_filter_select">
      <DialogWindow
        open={isDialogOpen}
        handleClose={onDialogClose}
        title="Select location"
        className="shows_page__dialog"
      >
        <div className="shows_page__location_autocomplete_container">
          <LocationAutocomplete
            value={locationAutocompleteValue}
            onChange={onLocationAutocompleteChange}
            className="shows_page__location_autocomplete"
            placeholder="Search..."
          />
          <Button onClick={onDialogClose} className="shows_page__dialog_cancel" color="dark">
            Cancel
          </Button>
        </div>
      </DialogWindow>
      <SelectSort
        className="select_sort"
        height={51}
        width={width}
        sortValue={locationSortValue}
        options={locationFilters}
        setSortValue={setLocationSortValue}
        setQueryValue={sortByLocation}
        renderValue={renderLocationSelectValue}
        onClick={onLocationOptionClick}
      />
    </div>
  );
};

export default LocationFilterSelect;
