import { IArtwork } from "../../types/artwork/artwork";
import { getCurrentPosition, IArtistSortValue } from "../../explore-pages/artists-page/utils";
import { ReturnActionType } from "../../store/types/return-type";
import { ICoordinates } from "../../types/addresTypes";

export const sortByClosest = async (
  coordinates: ICoordinates | undefined,
  setCoordinates: (param: ICoordinates) => ReturnActionType,
  setArtworks: (param: IArtwork[]) => void,
  setOffset: (param: number) => void,
  setfilterQueryParams: (param: any) => void,
  filterQueryParams: any,
  setSortValue: (param: IArtistSortValue) => void,
  currentSortValue: IArtistSortValue,
): Promise<void> => {
  try {
    if (!coordinates) {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      setCoordinates({ latitude, longitude });
      setArtworks([]);
      setOffset(0);
      setfilterQueryParams({
        ...filterQueryParams,
        sortByLocation: { latitude, longitude, type: "locationLives" },
      });

      return;
    }
    setArtworks([]);
    setOffset(0);
    setfilterQueryParams({
      ...filterQueryParams,
      sortByLocation: { ...coordinates, type: "locationLives" },
    });
  } catch (err: any) {
    setSortValue(currentSortValue);
    // eslint-disable-next-line no-alert
    alert(err.message);
  }
};
