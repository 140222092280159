import { gql } from "@apollo/client";
import { MY_VOTED_ART_INFO } from "./request-params/ArtworkRequestParams";

import {
  ARTWORK,
  BIO,
  EXHIBITION_LITE,
  IMAGE,
  LOCATION,
  POST_LITE_WITH_AUDITED,
  USER_PROFILE,
} from "./request-params/RequestsParams";
import { FULL_USER } from "./request-params/UserProfileRequestParams";

export const USER_DETAILS = gql`query user($_id: ID!) {
    user: user(_id: $_id) {
        _id
        myFollowers
        myFollowings
        likedArtworks
        hasInstitution
        institutions {
            _id
            name
            description
            kategory {
              _id
              name
              description
            }
            isVirtual
            availableForFilmAndTV
            availableForEvents
            imagesOfInstitution {
                _id
                medium{
                  url
                }
            }
            owner {
                _id
                profile {
                    firstName
                    secondName
                    phoneNumber
                }
            }
            exhibitions {
                _id
                title
                description
                private
                isSolo
                isOpenToSubmitions
                location {
                    name
                }
                duration {
                  from
                  to
                }
                daysOpen {
                  dayOfWeek
                  hoursOpen {
                    from
                    to
                  }
                }
            }
        }
        profile {
            _id
            firstName
            middleName
            secondName
            sex
            phoneNumber
            bio {
              ${BIO}
            }
            address {
                city
                location {
                    ${LOCATION}
                }
                postalCode
            }
            image {
                ${IMAGE}
            }
            bannerImage {
                thumbnail {
                    url
                }
                medium {
                    url
                }
                origin {
                    url
                }
            }
        }
        scans {
            artworks {
                scansCount
            }
        }
    }
    createdArtworks: searchArtworks(ownerId: $_id, limit: 6, types: ["artist"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
    artworksForSale: userCurrentArtworks(
      userId: $_id,
      saleable: true,
      orderBy: desc
    )
      {
        artworks {
          _id
          image {
            ${IMAGE}
          }
        }
        meta {
          total
        }
    }
    collectedArtworks: searchArtworks(collectorId: $_id, limit: 6, types: ["artist", "collector"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
    foundArtworks: searchArtworks(ownerId: $_id, limit: 6, types: ["found"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
}`;

export const SEARCH_USER_POSTS = gql`
    query posts ($userId: ID) {
        posts (userId: $userId) {
            ${POST_LITE_WITH_AUDITED}
        }
    }`;

export const USER_DETAILS_COUNTERS = gql`
  query userCounters($_id: ID!, $includePast: Boolean) {
    hostShows: searchExhibitions(owner: [$_id], includePast: $includePast) {
      meta {
        total
      }
    }
    invitedShows: searchExhibitions(guests: [$_id], includePast: $includePast) {
      meta {
        total
      }
    }
    countersInfo: user(_id: $_id) {
      scans {
        _id
      }
      favorites {
        createdAt
      }
    }
  }
`;

export const UPDATE_USER = `
  mutation updateUser(
      $_id: ID!
      $firstName: String!
      $middleName: String
      $lastName: String!
      $phone: String!
      $sex: sex!
      $address: AddressInput
      $cityBorn: String
      $country: String
      $schools: [String]
      $teachers: [String]
      $awards: [String]
      $familyHeritage: [String]
      $culturalIdentities: [String]
      $ethnicInspirations: [String]
      $spiritualInspirations: [String]
      $tribalIdentities: [String]
      $other: [String]
      $story: [String]
      $image: String
      $bannerImage: String
  ) {
    updateUser(
      _id: $_id
      profile: {
        firstName: $firstName
        middleName: $middleName
        secondName: $lastName
        phoneNumber: $phone
        sex: $sex
        address: $address
        image: $image
        bannerImage: $bannerImage
        bio: {
          bornCity: $cityBorn
          country: $country
          schools: $schools
          teachers: $teachers
          awards: $awards
          heritage: $familyHeritage
          culture: $culturalIdentities
          ethnic: $ethnicInspirations
          spiritualInspirations: $spiritualInspirations
          tribalIdentities: $tribalIdentities
          other: $other
          story: $story
        }
      }
    ) {
      _id
    }
  }
`;

export const UPDATE_PROFILE = `
mutation updateUser($_id: ID!, $profile: ProfileInput) {
  updateUser(_id: $_id, profile: $profile) {
    _id
  }
}
`;

export const EXHIBITIONS_WITH_USERS = gql`
query exhibitionWithUsers($users: [ID], $includePast: Boolean, $limit: Int) {
  hostShows: searchExhibitions(owner: $users, includePast: $includePast, limit: $limit ) {
    exhibitions {
      ${EXHIBITION_LITE}
    }
  }
  invitedShows: searchExhibitions(guests: $users, includePast: $includePast, limit: $limit) {
    exhibitions {
      ${EXHIBITION_LITE}
    }
  }
}`;

export const USER_CONTROLS = gql`
  query user($_id: ID!) {
    user: user(_id: $_id) {
        _id
        hasInstitution
        institutions {
          _id
          name
          description
          kategory {
            _id
            name
            description
          }
          isVirtual
          availableForFilmAndTV
          availableForEvents
          imagesOfInstitution {
              ${IMAGE}
          }
          exhibitions {
              _id
              title
              description
              private
              isSolo
              isOpenToSubmitions
              location {
                  name
              }
              duration {
                from
                to
              }
              daysOpen {
                dayOfWeek
                hoursOpen {
                  from
                  to
                }
              }
          }
      }
        profile {
            firstName
            middleName
            secondName
            sex
            phoneNumber
            image {
                ${IMAGE}
            }
            address {
              city
              location {
                  ${LOCATION}
              }
              postalCode
          }
            bio {
              ${BIO}
            }
        }
        collectionRequests {
          _id
        }
    }
  }`;

export const USER_AVATAR = gql`
  query user($_id: ID!) {
    user: user(_id: $_id) {
        _id
        myFollowers
        myFollowings
        likedArtworks
        hasInstitution
        institutions{
          owner{
            _id
          }
        }
        profile {
            firstName
            middleName
            secondName
            image {
                ${IMAGE}
            }
        }
    }
  }`;

export const SEARCH_USERS_LITE = gql`
query searchArtists(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $text: String
  $bornCity: [String]
  $country: [String]
  $schools: [String]
  $heritage: [String]
  $culture: [String]
  $ethnic: [String]
  $categories: [String]
  $colors: [String]
  $saleableArtworks: Boolean
  $types: [String]
  $sex: [sex]
  $originsOfInspiration: [String]
  $sortByLocation: LocationSortType
  $location: LocationSearchType
  $exhibitionId: ID
) {
  searchArtists(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    text: $text
    bornCity: $bornCity
    country: $country
    schools: $schools
    heritage: $heritage
    culture: $culture
    ethnic: $ethnic
    categories: $categories
    colors: $colors
    saleableArtworks: $saleableArtworks
    types: $types
    sex: $sex
    originsOfInspiration: $originsOfInspiration
    sortByLocation: $sortByLocation
    location: $location
    exhibitionId: $exhibitionId
  ) {
    artists {
      _id
      myFollowers
      myArtworksLikes
      likedArtworks
      myFollowings
      hasInstitution
      institutions {
        name
      }
      profile {
        ${USER_PROFILE}
      }
      invited
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const TOGGLE_FOLLOWING = `
  mutation toggleFollowing($userId: ID!) {
    toggleFollowing(userId: $userId) {
      followed
    }
  }
`;

export const TOGGLE_FOLLOWING_GQL = gql`
  mutation toggleFollowing($userId: ID!) {
    toggleFollowing(userId: $userId) {
      followed
    }
  }
`;

export const TOGGLE_BLOCK_USER = `
  mutation toggleBlocking($userId: ID!){
    toggleBlocking(userId: $userId) {
      blocked
    }
  }
`;

export const ME = `
{
  me {
    ${FULL_USER}
  }
}`;

export const ME_GQL = gql`
{
  me {
    ${FULL_USER}
  }
}`;

export const USER = `query user($_id: ID!) {
    user: user(_id: $_id) {
        _id
        myFollowers
        myFollowings
        likedArtworks
        hasInstitution
        followings {
          followed {
            _id
          }
        }
        institutions {
            name
            description
            availableForFilmAndTV
            imagesOfInstitution {
                _id
                medium{
                  url
                }
            }
            owner {
                _id
                profile {
                    firstName
                    secondName
                    phoneNumber
                }
            }
            exhibitions {
                location {
                    name
                }
                daysOpen {
                  dayOfWeek
                  hoursOpen {
                    from
                    to
                  }
                }
            }
        }
        profile {
            _id
            firstName
            middleName
            secondName
            bio {
              ${BIO}
            }
            address {
                city
                location {
                    ${LOCATION}
                }
                postalCode
            }
            image {
                ${IMAGE}
            }
            bannerImage {
                thumbnail {
                    url
                }
                medium {
                    url
                }
                origin {
                    url
                }
            }
        }
        scans {
            artworks {
                scansCount
            }
        }
    }
    createdArtworks: searchArtworks(ownerId: $_id, limit: 6, types: ["artist"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
    artworksForSale: userCurrentArtworks(
      userId: $_id,
      saleable: true,
      orderBy: desc
    )
      {
        artworks {
          _id
          image {
            ${IMAGE}
          }
        }
        meta {
          total
        }
    }
    collectedArtworks: searchArtworks(collectorId: $_id, limit: 6, types: ["artist", "collector"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
    foundArtworks: searchArtworks(ownerId: $_id, limit: 6, types: ["found"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
}`;

export const USER_GQL = gql`query user($_id: ID!) {
    user: user(_id: $_id) {
        myPastCollectedArtworks{
          ${ARTWORK}
        }
        suggestedArtworks {
          ${ARTWORK}
        }
        _id
        myFollowers
        myFollowings
        likedArtworks
        hasInstitution
        followings {
          followed {
            _id
          }
        }
        institutions {
            name
            description
            availableForFilmAndTV
            imagesOfInstitution {
                _id
                medium{
                  url
                }
            }
            owner {
                _id
                profile {
                    firstName
                    secondName
                    phoneNumber
                }
            }
            exhibitions {
                location {
                    name
                }
                daysOpen {
                  dayOfWeek
                  hoursOpen {
                    from
                    to
                  }
                }
            }
        }
        profile {
            _id
            firstName
            middleName
            secondName
            bio {
              ${BIO}
            }
            address {
                city
                location {
                    ${LOCATION}
                }
                postalCode
            }
            image {
                ${IMAGE}
            }
            bannerImage {
                thumbnail {
                    url
                }
                medium {
                    url
                }
                origin {
                    url
                }
            }
        }
        scans {
            artworks {
                scansCount
            }
        }
    }
    createdArtworks: searchArtworks(ownerId: $_id, limit: 6, types: ["artist"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
    artworksForSale: userCurrentArtworks(
      userId: $_id,
      saleable: true,
      orderBy: desc
    )
      {
        artworks {
          _id
          image {
            ${IMAGE}
          }
        }
        meta {
          total
        }
    }
    collectedArtworks: searchArtworks(collectorId: $_id, limit: 6, types: ["artist", "collector"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
    foundArtworks: searchArtworks(ownerId: $_id, limit: 6, types: ["found"], orderBy: desc) {
        artworks {
            _id
            image {
                ${IMAGE}
            }
        }
        meta {
            total
        }
    }
}`;

export const REPORT_USER = `
mutation reportUser(
  $userId: ID!
  $message: String
  $reportType: reportType
) {
  reportUser(
    userId: $userId
    message: $message
    type: $reportType
  ) {
    _id
  }
}
`;

export const USER_LIKED_ARTWORKS = gql`
query likedArtworks(
  $limit: Int
  $offset: Int
  $userId: ID!
) {
  likedArtworks(
    limit: $limit
    offset: $offset
    userId: $userId
  ) {
    artworks {
      _id
      image {
        ${IMAGE}
      }
      locationLives {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      locationFound {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      locationCreated {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      locationCollected {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
    }
    meta {
      total
    }
  }
}`;

export const MY_FOLLOWINGS = gql`
  {
    me {
      _id
      followings {
        followed {
          _id
          profile {
            ${USER_PROFILE}
          }
          institutions {
            name
          }
          hasInstitution
        }
      }
    }
  }
`;

export const USER_FOLLOWINGS = gql`
query followings(
  $userId: ID!, 
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int 
  $exhibitionId: ID 
) {
  followings(
    userId: $userId, 
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset, 
    exhibitionId: $exhibitionId
  ) {
    followings{
      _id
      profile {
        ${USER_PROFILE}
      }
      institutions {
        _id
        name
      }
      hasInstitution
      invited
    }
    meta {
      total
    }
  }
}`;

export const USER_FOLLOWERS = gql`
query followers(
  $userId: ID!
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
) {
  followers(
    userId: $userId
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
  ) {
    followers {
      _id
      hasInstitution
      institutions {
        name
      }
      profile {
        ${USER_PROFILE}
      }
    }
    meta {
      total
    }
  }
}`;

export const USER_VOTES = gql`
query favorites(
  $userId: ID!
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int 
) {
  favorites(
    userId: $userId
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
  ) {
    artworks {
      ${ARTWORK}
    }
    meta {
      total
    }
  }
}`;

export const USER_VOTES_INFO = gql`
query favorites(
  $userId: ID!
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int 
) {
  favorites(
    userId: $userId
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
  ) {
    artworks {
      ${MY_VOTED_ART_INFO}
    }
    meta {
      total
    }
  }
}`;

export const USER_SCANS = gql`
query scans(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $userId: ID
) {
  scans(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    userId: $userId
  ) {
    scans {
     _id
     createdAt
     artworks {
        ${ARTWORK}
     }
    }
    meta {
      total
    }
  }
}`;

export const USER_INSTITUTIONS = gql`
  query user($_id: ID!) {
    user: user(_id: $_id) {
      _id
      hasInstitution
      institutions {
        _id
        name
        description
        kategory {
          _id
          name
          description
        }
        isVirtual
        availableForFilmAndTV
        availableForEvents
        imagesOfInstitution {
          _id
          medium {
            url
          }
        }
        owner {
          _id
          profile {
            firstName
            secondName
            phoneNumber
          }
        }
        exhibitions {
          _id
          title
          description
          private
          isSolo
          isOpenToSubmitions
          location {
            name
          }
          duration {
            from
            to
          }
          daysOpen {
            dayOfWeek
            hoursOpen {
              from
              to
            }
          }
        }
      }
    }
  }
`;

export const USER_ARTWORKS_FOR_VOTE = gql`
query artworksCanBeVoted(
  $limit: Int
  $offset: Int
  $ownerId: ID
) {
  artworksCanBeVoted(
    limit: $limit
    offset: $offset
    ownerId: $ownerId
  ) {
    artworks {
      _id
      selectedForVote,
            name,
            image {
                ${IMAGE}
            },
            additionalImages {
                ${IMAGE}
            },
            collectors {
              collectedBy
              yearCollected
              collectedAt {
                ${LOCATION}
              }
            }
            collector {
              _id
              profile {
                firstName
                middleName
                secondName
                image{
                  ${IMAGE}
                }
              }
              institutions {
                name
                description
              }
              hasInstitution
            },
            
            artistName
            saleable
            filmsOrTv
            hasFilmsOrTv
            materials
            colors
            type
            categories{
              _id
              name
              description
            }
            likesCount
            scansCount
            sharesCount
            createdAt
            createdByArtistYear
            notes
            inspiration
            conditionReport
            pastExhibitions {
              exhibition
              year
            }
            pastCollectors {
              _id
              myArtworksLikes
              profile {
                firstName
                middleName
                secondName
                image {
                    ${IMAGE}
                }
              }
            }
            dimensions {
                height
                length
                width
                weight
                metrics
            },
            locationLives {
                name, coordinates {
                    latitude, longitude, __typename
                }
            },
            locationFound {
              name, coordinates {
                  latitude, longitude, __typename
              }
            },
            locationCreated {
                name, coordinates {
                    latitude, longitude, __typename
                }
            },
            locationCollected {
                name, coordinates {
                    latitude, longitude, __typename
                }
            },
            exhibitions {
                _id
                image {
                    ${IMAGE}
                }
                duration {
                  from
                  to
                }
                title
                description
                private
                isSolo
                isOpenToSubmitions
                location {
                  name
                }
            },
            winner,
            allowUseForFilmAndTv,
            minimumBid,
      materials
      inspiration
      votesCount
      dimensions {
        width
        height
        length
        weight
        metrics
      }
    }
  }
}`;

export const REMOVE_USER = `
mutation removeUser($userId: ID!) {
  removeUser(userId: $userId) {
    _id
  }
}`;
