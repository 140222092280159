import { USER_PROFILE } from "./UserProfileRequestParams";

export const COMMENT = `
_id
text
author {
  _id
  profile {
    ${USER_PROFILE}
  }
  institutions {
    name
    description
  }
  hasInstitution
}
repliesCount
likesCount
likes {
  author {
    _id
  }
}
isPrivate
createdAt`;

export const ARTWORK_COMMENT_REPLY = `
_id
text
createdAt
author {
  _id
  hasInstitution
  institutions {
    name
  }
  profile {
    ${USER_PROFILE}
  }
}
likesCount
likes {
  author {
    _id
  }
}`;
