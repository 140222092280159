export const categoriesDescription = (value: string): string | null => {
  switch (value) {
    case "Conceptual Art":
      return "Art in which the idea presented by the artist is considered more than the finished product.";
    case "Folk Art":
      return "Art produced by an indigenous culture or laboring trades people.";
    case "Mosaic":
      return "Tiled, art.";
    case "Public Art":
      return "Art for public viewing.";
    case "Sculpture":
      return "3-D objects of art.";
    default:
      return null;
  }
};
