import React from "react";
import { Marker } from "react-leaflet";
import logo from "../../../assets/images/logo.png";

export const displayExhibitions = (exhibitions, createMapIcon, goToDetails, openModal) => {
  const locationsList = {};
  return exhibitions.map(exhibition => {
    const location = exhibition?.[0]?.location || exhibition?.location;
    let coords = location?.coordinates;

    if (!location) return null;

    if (locationsList[location?.name]) {
      const num = locationsList[location?.name] / 10000;
      coords = {
        ...coords,
        latitude: coords.latitude + num,
        longitude: coords.longitude + num,
      };
      locationsList[location?.name] += 1;
    } else {
      locationsList[location?.name] = 1;
    }

    const id = exhibition._id;
    const image =
      exhibition.image?.thumbnail.url || exhibition.imagesOfInstitution?.[0]?.thumbnail.url;
    const height =
      exhibition.image?.thumbnail.height || exhibition.imagesOfInstitution?.[0]?.thumbnail.height;
    const width =
      exhibition.image?.thumbnail.width || exhibition.imagesOfInstitution?.[0]?.thumbnail.width;
    return (
      <div key={id}>
        {coords && (
          <Marker
            eventHandlers={{ click: () => openModal() }}
            position={[coords.latitude, coords.longitude]}
            icon={createMapIcon(image || logo, width / 5 || 30, height / 5 || 30)}
          />
        )}
      </div>
    );
  });
};
