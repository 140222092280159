import React, { FC, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Masonry from "react-masonry-css";

import CardItem from "../../components/card-item/CardItem";
import { IExhibitionOutput } from "../../types/exhibitions";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import BackButton from "../../components/back-button/BackButton";

import "./SubmissionsReviewPage.scss";
import { MY_EXHIBITIONS_WITH_ARTWORKS_PENDED } from "../../api-queries/ExhibitionRequests";

const SubmissionsReviewPage: FC = () => {
  const { data, loading, refetch } = useQuery(MY_EXHIBITIONS_WITH_ARTWORKS_PENDED, {
    variables: {
      limit: 100,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const pendingShows: IExhibitionOutput[] = data?.myExhibitionsWithArtworksPended?.exhibitions;

  const renderShows = (shows: IExhibitionOutput[]): JSX.Element => {
    if (shows?.length) {
      return (
        <Masonry
          breakpointCols={BREAKPOINT_COLUMNS_OBJ}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {shows.map(show => (
            <CardItem
              key={show._id}
              id={show._id}
              img={show.image}
              name={show.title}
              location={show.location}
              isOpenToSubmitions={show.isOpenToSubmitions}
              duration={show.duration}
              data={show}
            />
          ))}
        </Masonry>
      );
    }
    return <p className="profile-artworks-page__no-artworks">No Shows</p>;
  };

  if (loading) return null;
  return (
    <div className="wrapper">
      <div className="profile_shows">
        <h3 className="profile_shows__title">
          <BackButton className="profile_shows__back_button" />
          Pending
        </h3>
        <div className="profile_shows__card_wrapper">{renderShows(pendingShows)}</div>
      </div>
    </div>
  );
};

export default SubmissionsReviewPage;
