import { gql } from "@apollo/client";
import { IMAGE, ARTWORK, COMMENT, FULL_ARTWORK, LOCATION } from "./request-params/RequestsParams";
import { TRANSFER } from "./request-params/TransferParams";

export const RANDOM_ARTWORKS_IMAGE = gql`
  query randomArtworks($limit: Int) {
    randomArtworks(limit: $limit) {
      _id
      name
      image {
        thumbnail {
          url
          width
          height
        }
        medium {
          url
          width
          height
        }
      }
      locationLives {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      locationFound {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      locationCreated {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      locationCollected {
        name
        coordinates {
          latitude
          longitude
          __typename
        }
      }
      additionalImages {
        thumbnail {
          url
        }
      }
    }
  }
`;

export const ARTWORK_DETAILS = `
    query artwork($artworkId: ID) {
        artwork(_id: $artworkId) {
            selectedForVote,
            name,
            attachments {
              _id
              url
            }
            image {
                ${IMAGE}
            },
            additionalImages {
                ${IMAGE}
            },
            collectors {
              collectedBy
              yearCollected
              collectedAt {
                ${LOCATION}
              }
            }
            collector {
              _id
              profile {
                firstName
                middleName
                secondName
                image{
                  ${IMAGE}
                }
              }
              institutions {
                name
                description
              }
              hasInstitution
            },
            suggestions {
              _id
              artistName
              artworkTitle
              notes
              iDontKnow
              createdAt
              video {
                _id
                url
                type
                info
              }
              author {
                _id
                institutions {
                  name
                }
                hasInstitution
                profile {
                  firstName
                  middleName
                  secondName
                  image {
                    ${IMAGE}
                  }
                }
              }
            }
            artistName
            saleable
            transferIsPending
            transferringTo {
              _id
              hasInstitution
              institutions {
                name
              }
              profile {
                firstName
                middleName
                secondName
                image{
                  ${IMAGE}
                }
              }
            }
            transferInfo {
              ${TRANSFER}
            }
            filmsOrTv
            hasFilmsOrTv
            materials
            colors
            type
            categories{
              _id
              name
              description
            }
            likesCount
            scansCount
            sharesCount
            createdAt
            createdByArtistYear
            notes
            inspiration
            conditionReport
            pastExhibitions {
              exhibition
              year
            }
            pastCollectors {
              _id
              myArtworksLikes
              profile {
                firstName
                middleName
                secondName
                image {
                    ${IMAGE}
                }
              }
            }
            dimensions {
                height
                length
                width
                weight
                metrics
            },
            owner {
                profile {
                    firstName
                    middleName
                    secondName
                    image {
                        ${IMAGE}
                    }
                }
                _id
                email
                myArtworksLikes
                likedArtworks
                hasInstitution
                institutions{
                  name
                }
            },
            locationLives {
                name, coordinates {
                    latitude, longitude, __typename
                }
            },
            locationFound {
              name, coordinates {
                  latitude, longitude, __typename
              }
            },
            locationCreated {
                name, coordinates {
                    latitude, longitude, __typename
                }
            },
            locationCollected {
                name, coordinates {
                    latitude, longitude, __typename
                }
            },
            exhibitions {
                _id
                image {
                    ${IMAGE}
                }
                duration {
                  from
                  to
                }
                title
                description
                private
                isSolo
                isOpenToSubmitions
                location {
                  name
                }
            },
            transferHistory {
                _id
                price
                notes
                conditionReport
                createdAt
                images {
                    thumbnail {
                        url
                    }
                }
            },
            winner,
            allowUseForFilmAndTv,
            minimumBid,
            comments {
             ${COMMENT}
              replies {
                ${COMMENT}
              }
            }
            likes {
                author {
                  _id
                  profile {
                    _id
                    firstName
                    middleName
                    secondName
                    image {
                      ${IMAGE}
                    }
                  }
                }
            }
        }
    }
`;

export const SEARCH_ARTWORKS_LITE = gql`
query searchArtworks(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $searchableText: String
  $categories: [String]
  $materials: [String]
  $inspiration: [String]
  $colors: [String]
  $forSale: Boolean
  $location: LocationSearchType
  $priceRange: searchPrice
  $heightRange: searchDimension
  $widthRange: searchDimension
  $types: [String]
  $ownerId: ID
  $collectorId: ID
  $culture: [String]
  $ethnic: [String]
  $spiritualInspirations: [String]
  $tribalIdentities: [String]
  $findByLocation: LocationArtworkSearchType
  $sortByLocation: LocationArtworkSortType
  $isWinner: Boolean
  $hasFilmsOrTv: Boolean
  $forVote: Boolean
  $allowUseForFilmAndTv: Boolean
) {
  searchArtworks(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    text: $searchableText
    categories: $categories
    materials: $materials
    inspiration: $inspiration
    colors: $colors
    forSale: $forSale
    location: $location
    price: $priceRange
    width: $widthRange
    height: $heightRange
    types: $types
    ownerId: $ownerId
    collectorId: $collectorId
    culture: $culture
    ethnic: $ethnic
    spiritualInspirations: $spiritualInspirations
    tribalIdentities: $tribalIdentities
    findByLocation: $findByLocation
    sortByLocation: $sortByLocation
    isWinner: $isWinner
    hasFilmsOrTv: $hasFilmsOrTv
    forVote: $forVote
    allowUseForFilmAndTv: $allowUseForFilmAndTv
  ) {
    artworks {
      ${ARTWORK}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const CATEGORIES = gql`
  query categories($text: String) {
    categories(text: $text) {
      _id
      name
      description
    }
  }
`;

export const AVAILABLE_ARTWORK_MATERIALS = gql`
  query availableArtworksMaterials($text: String) {
    availableArtworksMaterials(text: $text)
  }
`;

export const USER_CURRENT_ARTWORKS = gql`
query userCurrentArtworks(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $userId: ID
  $saleable: Boolean
  $categories: [String]
  $materials: [String]
  $colors: [String]
  $inspiration: [String]
  $location: LocationSearchType
  $heightRange: searchDimension
  $widthRange: searchDimension
  $priceRange: searchPrice
) {
  userCurrentArtworks(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    userId: $userId
    saleable: $saleable
    categories: $categories
    materials: $materials
    colors: $colors
    inspiration: $inspiration
    location: $location
    height: $heightRange
    width: $widthRange
    price: $priceRange
  ) {
    artworks {
      ${ARTWORK}
    }
    meta {
      total
    }
  }
}`;

export const TOGGLE_ARTWORK_LIKE = `
mutation toggleArtworkLike($artworkId: ID!) {
  toggleArtworkLike(artworkId: $artworkId) {
    liked
  }
}`;

export const LIKED_ARTWORKS_LITE = `
{
  likedArtworks(limit: 1000) {
    artworks {
      _id
    }
  }
}`;

export const LIKED_ARTWORKS_BY_ID_LITE = `
query likedArtworks($userId: ID) {
  likedArtworks(userId: $userId, limit: 1000) {
    artworks {
      _id
    }
  }
}
`;

export const SUBMIT_ARTWORK_SHARE = `
mutation submitArtworkShare($artworkId: ID!) {
  submitArtworkShare(artworkId: $artworkId) {
    succeeded
  }
}`;

export const AVAILABLE_ARTWORK_COLORS = gql`
  query availableArtworksColors($text: String) {
    availableArtworksColors(text: $text)
  }
`;

export const ADD_SUGGESTION_TO_ARTWORK = `
  mutation addSuggestionToArtwork(
    $artworkId: ID!
    $artistName: String
    $artworkTitle: String
    $notes: String
    $iDontKnow: Boolean
    $video: AttachmentInput
  ) {
    addSuggestionToArtwork(
      artworkId: $artworkId
      artistName: $artistName
      artworkTitle: $artworkTitle
      notes: $notes
      iDontKnow: $iDontKnow
      video: $video
    ) {
      _id
    }
  }
`;

export const REMOVE_SUGGESTION_FROM_ARTWORK = `
mutation removeSuggestionFromArtwork($suggestionId: ID! $artworkId: ID!) {
  removeSuggestionFromArtwork(suggestionId: $suggestionId artworkId: $artworkId) {
    removed
  }
}`;

export const REMOVE_ARTWORK = `
mutation removeArtwork($artworkId: ID!) {
  removeArtwork(artworkId: $artworkId) {
    _id
  }
}`;

export const REPORT_ARTWORK = `
mutation reportArtwork(
  $artworkId: ID!
  $message: String
  $reportType: reportType
) {
  reportArtwork(
    artworkId: $artworkId
    message: $message
    type: $reportType
  ) {
    _id
  }
}
`;

export const ARTWORKS_FOR_VOTE = `
query artworksCanBeVoted(
  $limit: Int
  $offset: Int
  $sortBy: String
  $orderBy: orderBy
  $ownerId: ID
) {
  artworksCanBeVoted(
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    orderBy: $orderBy
    ownerId: $ownerId
  ) {
    artworks {
      ${ARTWORK}
      materials
      colors
      categories {
        _id
        name
        description
      }
      inspiration
      votesCount
      dimensions {
        width
        height
        length
        weight
        metrics
      }
    }
  }
}`;

export const WINNERS = `
query winners($limit: Int, $offset: Int) {
  searchArtworks(isWinner: true, limit: $limit, offset: $offset) {
    artworks {
      ${ARTWORK}
      materials
      inspiration
      dimensions {
        width
        height
        length
        weight
        metrics
      }
    }
    meta {
      total
    }
  }
}`;

export const ADD_ARTWORK = `
mutation addArtwork(
  $name: String
  $artistName: String
  $yearCreated: String
  $price: Int
  $notes: String
  $history: String
  $locationFound: LocationInputType
  $locationCollected: LocationInputType
  $locationLives: LocationInputType
  $locationCreated: LocationInputType
  $dimensions: DimensionsInput
  $saleable: Boolean
  $availableOutOfMoca: Boolean
  $selectedForVote: Boolean
  $colors: [String]
  $materials: [String]
  $categories: [ID]
  $attachments: [AttachmentInput]
  $type: ArtworkType
  $inspiration: String
  $conditionReport: String
  $pastExhibitions: [PastExhibitionInput]
  $collectors: [CollectorInput]
  $additionalImages: [ImageInput]
  $creationInProgress: Boolean
  $published: Boolean
) {
  addArtwork(
    name: $name
    artistName: $artistName
    createdByArtistYear: $yearCreated
    minimumBid: $price
    notes: $notes
    history: $history
    locationFound: $locationFound
    locationCollected: $locationCollected
    locationLives: $locationLives
    locationCreated: $locationCreated
    dimensions: $dimensions
    saleable: $saleable
    availableOut: $availableOutOfMoca
    selectedForVote: $selectedForVote
    colors: $colors
    materials: $materials
    categories: $categories
    attachments: $attachments
    type: $type
    inspiration: $inspiration
    conditionReport: $conditionReport
    pastExhibitions: $pastExhibitions
    collectors: $collectors
    additionalImages: $additionalImages
    creationInProgress: $creationInProgress
    published: $published
  ) {
    ${FULL_ARTWORK}
  }
}`;

export const REMOVE_ARTWORK_ADDITIONAL_IMAGE = `
mutation removeArtworkAdditionalImage($artworkId: ID! $imageId: ID!){
  removeArtworkAdditionalImage(artworkId: $artworkId imageId: $imageId) {
    _id
  }
}`;

export const DECLINE_ARTWORK_TRANSFER = `
mutation declineArtworkCollectionRequest($artworkId: ID!){
  declineArtworkCollectionRequest(artworkId: $artworkId) {
    succeeded
  }
}`;

export const ACCEPT_ARTWORK_TRANSFER = `
mutation acceptArtworkCollectionRequest($artworkId: ID!){
  acceptArtworkCollectionRequest(artworkId: $artworkId) {
    succeeded
  }
}`;

export const CHANGE_COLLECTOR = `
mutation changeCollector(
  $artworkId: ID!
  $collectorId: ID!
  $notes: String
  $price: Int
  $locationLives: LocationInputType!
  $tradeFor: Boolean
  $loanTo: String
  $locationCollected: LocationInputType!
  $saleable: Boolean!
  $filmsOrTv: [String]
  $conditionReport: String
  $tradeForValue: String
  $hasFilmsOrTv: Boolean
  $tradeForObject: String
  $availableForLoan: Boolean
  $loanToName: String
  $loanToDate: Date
  $type: String
  $commission: String
) {
  changeCollector(
    artworkId: $artworkId
    collectorId: $collectorId
    notes: $notes
    price: $price
    locationLives: $locationLives
    tradeFor: $tradeFor
    loanTo: $loanTo
    locationCollected: $locationCollected
    saleable: $saleable
    filmsOrTv: $filmsOrTv
    conditionReport: $conditionReport
    tradeForValue: $tradeForValue
    hasFilmsOrTv: $hasFilmsOrTv
    tradeForObject: $tradeForObject
    availableForLoan: $availableForLoan
    type: $type
    commission: $commission
    loantTo: {
      name: $loanToName
      date: $loanToDate
    }
  ) {
    succeeded
  }
}`;

export const INCOMPLETE_ARTWORKS = gql`
query myArtworks(
  $limit: Int
  $offset: Int
  $published: Boolean
) {
  myArtworks(
    limit: $limit
    offset: $offset
    published: $published
  ) {
    ${FULL_ARTWORK}
  }
}`;

export const ARTWORK_PENDING_TRANSFER = gql`
query artworkPendingTransfers(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
) {
  artworkPendingTransfers(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
  ) {
    artworks {
      ${ARTWORK}
    }
  }
}`;

export const MY_RESALE_ART = gql`
  query userCurrentArtworksForResale($limit: Int, $offset: Int) {
    userCurrentArtworksForResale(limit: $limit, offset: $offset) {
      artworks {
        ${ARTWORK}
      }
      meta {
        total
      }
    }
  }
`;

export const USER_INCOMING_TRANSFERS = gql`
  query user($_id: ID!) {
    user: user(_id: $_id) {
      _id
      collectionRequests {
        ${ARTWORK}
      } 
    }
  }
`;

export const UPLOAD_ADDITIONAL_PHOTO = `
mutation {
  uploadAdditionalImage {
    ${IMAGE}
  }
}`;

export const TOGGLE_ARTWORK_VOTE = `
mutation toggleArtworkVote($artworkId: ID!) {
  toggleArtworkVote(artworkId: $artworkId) {
    _id
  }
}`;
