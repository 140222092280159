import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import ArtWorksExploreItemDetails from "../artworks-explore-item-details/ArtWorksExploreItemDetails";
import { ArtworkItem } from "../../context/artworksSldrCntx";

const Loader: React.FC = () => (
  <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
    <CircularProgress />
  </div>
);

const Artwork: React.FC<ArtworkItem> = ({
  artwork,
  id,
  removeArtworkFromSlider,
  blockTransferModal,
  setBlockSliding,
  slideNext,
}) =>
  artwork ? (
    <ArtWorksExploreItemDetails
      artwork={artwork}
      id={id}
      removeArtworkFromSlider={removeArtworkFromSlider}
      blockTransferModal={blockTransferModal}
      setBlockSliding={setBlockSliding}
      slideNext={slideNext}
    />
  ) : (
    <Loader />
  );

export default Artwork;
