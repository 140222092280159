/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IFilterTypes } from "../../types/filterTypes";
import { lengthConvert } from "../../utils/convert";

interface IlengthValues {
  min: number;
  max: number;
}

interface ISettersObj {
  setHeightMinValue: (param: number) => void;
  setHeightMaxValue: (param: number) => void;
  setWidthMinValue: (param: number) => void;
  setWidthMaxValue: (param: number) => void;
}

type FilterTypeStrings = keyof typeof IFilterTypes;

export interface IResultButton {
  title: string;
  min?: number;
  max?: number;
  id: string;
  type: FilterTypeStrings;
}

interface IConvertParams {
  from: string;
  to: string;
}

export const changeInputsValueUnit = (
  heightValue: IlengthValues,
  widthValue: IlengthValues,
  setters: ISettersObj,
) => {
  if (heightValue.max) setters.setHeightMaxValue(heightValue.max);
  if (heightValue.min) setters.setHeightMinValue(heightValue.min);
  if (widthValue.min) setters.setWidthMinValue(widthValue.min);
  if (widthValue.max) setters.setWidthMaxValue(widthValue.max);
};

export const updateFilterResultButton = (
  resultButton: IResultButton,
  maxNumber: number,
  convertParam: IConvertParams,
) => {
  const maxValue =
    resultButton.max !== maxNumber
      ? Math.round(lengthConvert(resultButton.max, convertParam.from, convertParam.to).value)
      : maxNumber;
  resultButton.min = Math.round(
    lengthConvert(resultButton.min, convertParam.from, convertParam.to).value,
  );
  resultButton.max = maxValue;
  resultButton.title = `${resultButton.type} ${convertParam.to} ${resultButton.min} - ${resultButton.max}`;
};
