import React from "react";
import { Link } from "react-router-dom";
import PostNoticeAudited from "./audited-components/PostNoticeAudited/PostNoticeAudited";
import { artists, explore } from "../../route-link";

interface LikePostNotificationProps {
  nameFollower: string;
  type: string;
  time: Date;
  post: any;
  userId: string;
}

const PostNotification: React.FC<LikePostNotificationProps> = ({
  nameFollower,
  userId,
  time,
  type,
  post,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { artwork, post: sharedPost, exhibition } = post.audited;
  const getEntity = (): string => {
    if (artwork) return "artwork shared by";
    if (sharedPost) return "post shared by";
    if (exhibition) return "show shared by";
    return "Post";
  };

  const entity = getEntity();

  return (
    <div className="notification_like__post">
      <div className="notification_text">
        <Link className="notification_link" to={`/${explore}/${artists}/${userId}`}>
          {nameFollower}
        </Link>{" "}
        {type}
        {entity}{" "}
        {entity !== "Post" && (
          <Link className="notification_link" to={`/${explore}/${artists}/${authUserId}`}>
            You
          </Link>
        )}
      </div>
      <PostNoticeAudited post={post} time={time} />
    </div>
  );
};

export default PostNotification;
