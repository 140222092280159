import {
  CurrentLocationAction,
  CurrentLocationState,
  CurrentLocationTypes,
} from "../types/currentLocation";

const initialValue: CurrentLocationState = {
  currentLocation: undefined,
};

const currentLocationReducer = (
  state = initialValue,
  action: CurrentLocationAction,
): CurrentLocationState => {
  switch (action.type) {
    case CurrentLocationTypes.SET_CURRENT_LOCATION:
      return { ...state, currentLocation: action.payload };
    default:
      return state;
  }
};
export default currentLocationReducer;
