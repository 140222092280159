import React from "react";
import { useOutletContext } from "react-router-dom";
import ArtworksPageComponent from "../../components/artworks-page-component/ArtworksPageComponent";
import ExplorePageTitle from "../../components/explore-page-title/ExplorePageTitle";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";

const ArtworksPage = () => {
  const { state, dispatch } = useOutletContext().artworksPageContext;
  return (
    <>
      <ScrollToTop />
      <div className="wrapper">
        <ExplorePageTitle title="ARTWORKS" myMoca shareMark />
      </div>
      <ArtworksPageComponent context={{ state, dispatch }} showMap />
    </>
  );
};
export default ArtworksPage;
