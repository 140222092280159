import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";

import { Actions } from "./Notifications.props";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { NotificationItemEnum } from "./NotificationItem.enum";
import { artists, explore } from "../../route-link";

import FollowNotification from "./FollowNotification";
import PostNotification from "./PostNotification";
import LikeArtworkNotification from "./LikeArtworkNotification";
import LikeNotification from "./LikeNotification";
import PublicCommentsArtworkNotification from "./PublicCommentsArtworkNotification";
import PublicCommentsPostNotification from "./PublicCommentsPostNotification";
import PublicCommentsShowNotification from "./PublicCommentsShowNotification";
import ReplyCommentsNotification from "./ReplyCommentsNotification";
import TransferNotification from "./TransferNotification";
import InviteToShowNotification from "./InviteToShowNotification";
import VoteArtworkNotification from "./VoteArtworkNotification";
import AcceptArtworkSubmissionNotification from "./AcceptArtworkSubmissionNotification";
import DeclineTransferArtworkNotification from "./DeclineTransferArtworkNotification";
import ScanArtworkNotification from "./ScanArtworkNotification";
import SubmitArtworkNotification from "./SubmitArtworkNotification";
import SharedArtworkNotification from "./SharedArtworkNotification";
import SharedExhibitionNotification from "./SharedExhibitionNotification";
import AcceptTransferArtworkNotification from "./AcceptTransferArtworkNotification";
import SuggestedArtworkNotification from "./SuggestedArtworkNotification";

interface INotificationItemProps {
  notice: Actions;
}

const NotificationItem: FC<INotificationItemProps> = ({ notice }) => {
  const nameFollower = notice?.user?.hasInstitution
    ? notice.user.institutions[0].name
    : `${notice?.user.profile.firstName} ${notice?.user.profile.secondName}` || "";
  const userId = notice?.user._id;
  const [userAvatarUrl, setUserAvatarUrl] = useState("");
  const [bodyNotification, setBodyNotification] = useState<any>(null);
  const artwork = notice?.audited?.artwork;
  const textComment = notice?.associated.comment?.text || "";
  const userImage = notice?.user?.profile?.image;

  useEffect(() => {
    if (notice?.user.profile.image?.thumbnail.url) {
      setUserAvatarUrl(notice?.user.profile.image?.thumbnail.url);
      return;
    }
    setUserAvatarUrl("");
  }, []);

  useEffect(() => {
    switch (notice.type) {
      case NotificationItemEnum.followUser:
        setBodyNotification(
          <FollowNotification
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
            userId={userId}
          />,
        );
        break;
      case NotificationItemEnum.likePost:
        setBodyNotification(
          <PostNotification
            type="liked "
            nameFollower={nameFollower}
            post={notice.audited.post}
            time={new Date(notice.createdAt)}
            userId={userId}
          />,
        );
        break;
      case NotificationItemEnum.sharePost:
        setBodyNotification(
          <PostNotification
            type="shared "
            userId={userId}
            post={notice.audited.post}
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
          />,
        );
        break;
      case NotificationItemEnum.likeArtwork:
        setBodyNotification(
          <LikeArtworkNotification
            artwork={notice?.audited?.artwork}
            userId={userId}
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.likeComment:
        setBodyNotification(
          <LikeNotification
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
            notice={notice}
            userId={userId}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.commentArtwork:
        setBodyNotification(
          <PublicCommentsArtworkNotification
            time={new Date(notice.createdAt)}
            nameFollower={nameFollower}
            userId={userId}
            textComment={textComment}
            artwork={artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.commentPost:
        setBodyNotification(
          <PublicCommentsPostNotification
            time={new Date(notice.createdAt)}
            nameFollower={nameFollower}
            userId={userId}
            textComment={textComment}
            post={notice.audited.post}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.commentExhibition:
        setBodyNotification(
          <PublicCommentsShowNotification
            notice={notice}
            time={new Date(notice.createdAt)}
            nameFollower={nameFollower}
            userId={userId}
            textComment={textComment}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.commentReply:
        setBodyNotification(
          <ReplyCommentsNotification
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            userId={userId}
            textComment={textComment}
            notice={notice}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.transferArtworkRequest:
        setBodyNotification(
          <TransferNotification
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            userId={userId}
            artwork={notice.audited.artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.inviteToExhibition:
        setBodyNotification(
          <InviteToShowNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            exhibition={notice.audited.exhibition}
            notice={notice}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.voteArtwork:
        setBodyNotification(
          <VoteArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={notice.audited.artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.acceptArtworkSubmission:
        setBodyNotification(
          <AcceptArtworkSubmissionNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={artwork}
            exhibition={notice.audited.exhibition}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.declineTransferArtworkRequest:
        setBodyNotification(
          <DeclineTransferArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={artwork}
          />,
        );
        break;
      case NotificationItemEnum.acceptTransferArtworkRequest:
        setBodyNotification(
          <AcceptTransferArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.scanArtwork:
        setBodyNotification(
          <ScanArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.submitArtworkToExhibition:
        setBodyNotification(
          <SubmitArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.shareArtwork:
        setBodyNotification(
          <SharedArtworkNotification
            artwork={artwork}
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.shareExhibition:
        setBodyNotification(
          <SharedExhibitionNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            artwork={artwork}
            userImage={userImage}
          />,
        );
        break;
      case NotificationItemEnum.identifyArtwork:
        setBodyNotification(
          <SuggestedArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            artwork={notice.audited.artwork}
            nameFrom={nameFollower}
            userImage={userImage}
          />,
        );
        break;
      default:
        setBodyNotification(null);
    }
  }, []);
  return (
    <div className="notification_item">
      <Link
        to={`${explore}/${artists}/${userId}`}
        className="notification_avatar"
        state={{ image: userImage, name: nameFollower }}
      >
        {userAvatarUrl ? (
          <Avatar src={userAvatarUrl} sx={{ width: 32, height: 32 }} />
        ) : (
          <AvatarPlaceholder id={userId} width="32px" height="32px" fontSize="8px" />
        )}
      </Link>
      <div className="notification_body">{bodyNotification}</div>
    </div>
  );
};

export default NotificationItem;
