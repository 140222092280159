import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { UserRequests } from "../../api-queries/AllRequests";
import { artists, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";
import Spider from "../../assets/images/spider.jpeg";
import { IArtwork } from "../../types/artwork/artwork";
import { IImage } from "../../types/imageData";

interface SharedExhibitionNotificationProps {
  nameFrom: string;
  userId: string;
  artwork: IArtwork;
  time: Date;
  userImage: IImage;
}

const SharedExhibitionNotification: React.FC<SharedExhibitionNotificationProps> = ({
  nameFrom,
  userId,
  time,
  artwork,
  userImage,
}): JSX.Element => {
  const nameArtwork = artwork?.name;
  const imageSrc = artwork?.image?.thumbnail.url || Spider;
  const artworkId = artwork?._id;
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameOf = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        shared exhibition "{nameArtwork}" uploaded by{" "}
        <Link className="notification_link" to={`${explore}/${artists}/${authUserId}`}>
          {nameOf}
        </Link>
      </p>
      <NotificationTime time={time} />
      <Link to={`${explore}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </div>
  );
};

export default SharedExhibitionNotification;
