export interface IPrevState {
  url?: string;
  id?: string;
  state?: any;
  tab?: string;
  scroll?: number;
  filtersState?: any;
}

export interface IPreviousStatesInitialState {
  states: IPrevState[];
}

export enum PrevStatesActionTypes {
  ADD_PREV_STATE = "ADD_PREV_STATE",
  REMOVE_PREV_STATE = "REMOVE_PREV_STATE",
  REMOVE_PREV_STATE_BY_ID = "REMOVE_PREV_STATE_BY_ID",
  REMOVE_ARTWORK_FROM_STATES = "REMOVE_ARTWORK_FROM_STATES",
  REMOVE_ARTWORK_FROM_SPECIFIC_STATE = "REMOVE_ARTWORK_FROM_SPECIFIC_STATE",
}

export interface addPrevState {
  type: PrevStatesActionTypes.ADD_PREV_STATE;
  payload: IPrevState;
}

export interface removePrevState {
  type: PrevStatesActionTypes.REMOVE_PREV_STATE;
  payload: string;
}

export interface removePrevStateById {
  type: PrevStatesActionTypes.REMOVE_PREV_STATE_BY_ID;
  payload: string;
}

export interface removeArtworkFromStates {
  type: PrevStatesActionTypes.REMOVE_ARTWORK_FROM_STATES;
  payload: string;
}

export interface removeArtworkFromSpecificState {
  type: PrevStatesActionTypes.REMOVE_ARTWORK_FROM_SPECIFIC_STATE;
  payload: {
    stateUrl: string;
    id: string;
  };
}

export type prevStateActions =
  | addPrevState
  | removePrevState
  | removePrevStateById
  | removeArtworkFromStates
  | removeArtworkFromSpecificState;
