import { gql } from "@apollo/client";
import { IMAGE } from "./request-params/RequestsParams";

export const LOST_ART = gql`
    query firstFoundArtworks($sortBy: String $orderBy: orderBy $limit: Int $offset: Int) {
        firstFoundArtworks: firstFoundArtworks(sortBy: $sortBy orderBy: $orderBy limit: $limit offset: $offset) {
            artworks {
                _id
                image {
                    ${IMAGE}
                }
            }
        }
    }`;

export const IDENTIFIED_ART = gql`
    query secondFoundArtworks($sortBy: String $orderBy: orderBy $limit: Int $offset: Int) {
        secondFoundArtworks: secondFoundArtworks(sortBy: $sortBy orderBy: $orderBy limit: $limit offset: $offset) {
            artworks {
                _id
                image {
                    ${IMAGE}
                }
            }
        }
    }`;

export const FOUND_ART = gql`
    query thirdFoundArtworks($sortBy: String $orderBy: orderBy $limit: Int $offset: Int) {
        thirdFoundArtworks: thirdFoundArtworks(sortBy: $sortBy orderBy: $orderBy limit: $limit offset: $offset) {
            artworks {
                _id
                image {
                    ${IMAGE}
                }
            }
        }
    }`;
