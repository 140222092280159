import React from "react";
import { Link } from "react-router-dom";

import { artists, explore } from "../../route-link";
import { Actions } from "./Notifications.props";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";
import PostNoticeAudited from "./audited-components/PostNoticeAudited/PostNoticeAudited";
import ExhibitionNoticeAudited from "./audited-components/ExhibitionNoticeAudited/ExhibitionNoticeAudited";
import { IImage } from "../../types/imageData";

interface ReplyCommentsNotificationProps {
  nameFrom: string;
  userId: string;
  textComment: string;
  time: Date;
  notice: Actions;
  userImage: IImage;
}

const ReplyCommentsNotification: React.FC<ReplyCommentsNotificationProps> = ({
  nameFrom,
  userId,
  time,
  textComment,
  notice,
  userImage,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { artwork, post, exhibition } = notice.audited;
  const getEntity = (): string => {
    if (artwork) return "artwork";
    if (post) return "post";
    if (exhibition) return "show";
    return "";
  };

  const renderAudited = (): any => {
    if (artwork) return <ArtworkNoticeAudited artwork={artwork} time={time} />;
    if (post) return <PostNoticeAudited post={post} time={time} />;
    if (exhibition) return <ExhibitionNoticeAudited exhibition={exhibition} time={time} />;
    return null;
  };

  return (
    <div>
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        replied: "{notice?.audited?.comment?.text}" to{" "}
        <Link className="notification_link" to={`${explore}/${artists}/${authUserId}`}>
          your{" "}
        </Link>
        comment: "{textComment}" under the <strong>{getEntity()}</strong>
      </p>
      {renderAudited()}
    </div>
  );
};

export default ReplyCommentsNotification;
