import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { BrowserView, MobileView } from "react-device-detect";
import DehazeIcon from "@mui/icons-material/Dehaze";
import CloseIcon from "@mui/icons-material/Close";

import NavMenu from "../nav-menu/NavMenu";
import NavMenuLogin from "../nav-menu/NavMenuLogin";
import logoImg from "../../assets/images/icon-app.png";
import { root, uploadArtwork } from "../../route-link";
import NavMobile from "../nav-menu/NavMobile";
import LoginButtonMenu from "../../UI/login-button/LoginButtonMenu";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Button from "../../UI/button/Button";
import { useActions } from "../../hooks/useActions";
import { ReturnActionType } from "../../store/types/return-type";

import "./Header.scss";
import Search from "../../UI/search/Search";

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const [path, setPath] = useState(false);
  const [open, setOpen] = useState(false);
  const getLocalStorageItem = localStorage.getItem("tokenKey") || "";
  const [token, setToken] = useState(getLocalStorageItem);
  const { flag } = useTypedSelector(state => state.headerFlagReducer);
  const { openModalLogin } = useActions();
  const navigate = useNavigate();
  const goToUploadPage = (): void | ReturnActionType => {
    return getLocalStorageItem ? navigate(`/${uploadArtwork}`) : openModalLogin();
  };
  const pathUploadImage = "/user/upload-user-image";
  const pathUploadUserImage = "/user/origins-of-inspiration";
  const pathRegistrationInstitution = "/user/registration-of-institution";
  useEffect(() => {
    if (
      pathname === pathUploadImage ||
      pathname === pathUploadUserImage ||
      pathname === pathRegistrationInstitution
    ) {
      setPath(false);
    } else {
      setPath(true);
    }
  }, [pathname]);

  useEffect(() => {
    setToken(getLocalStorageItem);
  }, [getLocalStorageItem, flag]);

  return (
    <div className="app_header">
      <div className="wrapper app_header__grid">
        <div className="logo">
          <Link to={root}>
            <div className="logo_image">
              <img className="logo_img" src={logoImg} alt="Logo" />
            </div>
          </Link>
          <div className="logo_text">
            <p>My Moca</p>
            <p>is My Museum</p>
            <p>of Contemporary Art</p>
          </div>
        </div>
        {path && (
          <BrowserView className="app_header__user">
            <Search placeholder="Search by artists, artworks, shows, institutions, collectors, operators" />
            <Button
              onClick={goToUploadPage}
              children="+ Add Artwork"
              variant="outlined-dark"
              color="light"
              className="app_header__add_artwork_button"
            />
            {token ? <NavMenuLogin /> : <NavMenu />}
            <div className="header__login">
              <LoginButtonMenu />
            </div>
          </BrowserView>
        )}
        <MobileView>
          <div className="toggle_button" onClick={() => setOpen(prevValue => !prevValue)}>
            {open ? (
              <CloseIcon className="toggle_button__icon" />
            ) : (
              <DehazeIcon className="toggle_button__icon" />
            )}
          </div>
          <div
            className={classNames("mobile_nav", {
              active: open,
            })}
          >
            <NavMobile setOpen={setOpen} />
          </div>
        </MobileView>
      </div>
    </div>
  );
};
export default Header;
