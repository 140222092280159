import * as FilterActionCreators from "./filter";
import * as ModalActionCreators from "./modal";
import * as TokenKeyActionCreators from "./token";
import * as LoginActionCreators from "./login";
import * as ProfileActionCreators from "./profile";
import * as HeaderFlagActionCreators from "./header-flag";
import * as UploadProfileActionCreators from "./upload-image";
import * as CurrentLocationActionCreators from "./currentLocation";
import * as AuthorizedUserActionCreators from "./authorized-user";
import * as RefetchArtwork from "./artwork-refetch";
import * as ArtworkDetails from "./artwork-details";
import * as User from "./user";
import * as Comments from "./comments";
import * as UserLikedArtworksActionCreators from "./user-liked-artworks";
import * as UserVotedArtworksActionCreators from "./user-voted-artworks";
import * as ButtonUpCreators from "./button-up";
import * as SearchCreators from "./search";
import * as artistsSearchCreators from "./artists-search";
import * as collectorsSearchCreators from "./collectors-search";
import * as OperatorsSearchCreators from "./operators-search";
import * as ShowsSearchCreators from "./shows-search";
import * as InstitutionSearchCreators from "./institution-search";
import * as BackUrlCreators from "./back-url";
import * as PrevStateCreators from "./previous-state";
import * as MessagesInfoCreators from "./messages-info";
import * as NotificationCreators from "./notifications";

export default {
  ...FilterActionCreators,
  ...ModalActionCreators,
  ...TokenKeyActionCreators,
  ...LoginActionCreators,
  ...ProfileActionCreators,
  ...HeaderFlagActionCreators,
  ...UploadProfileActionCreators,
  ...CurrentLocationActionCreators,
  ...AuthorizedUserActionCreators,
  ...RefetchArtwork,
  ...ArtworkDetails,
  ...User,
  ...Comments,
  ...UserLikedArtworksActionCreators,
  ...UserVotedArtworksActionCreators,
  ...ButtonUpCreators,
  ...SearchCreators,
  ...artistsSearchCreators,
  ...collectorsSearchCreators,
  ...OperatorsSearchCreators,
  ...ShowsSearchCreators,
  ...InstitutionSearchCreators,
  ...BackUrlCreators,
  ...PrevStateCreators,
  ...MessagesInfoCreators,
  ...NotificationCreators,
};
