import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { navExplore } from "../../data/nav-explore";

import "./NavMenu.scss";
import { useActions } from "../../hooks/useActions";
import { ExploreLinkItem, LinkClickCallback } from "./utils";

const NavMenuExplore: React.FC = () => {
  const { pathname } = useLocation();
  const { openModalLogin } = useActions();
  const isAuth = !!localStorage.getItem("myMOCAId");

  const [links, setLinks] = useState(navExplore);
  const exploreLinkId = "1";
  const exploreLinkIsActive = pathname === "/explore";

  const changeActiveLinkId = (id: string) => () => {
    localStorage.setItem("activeLink", id);
    const newArrayLinks = links.map(el => {
      return { ...el, active: el.id === id };
    });
    setLinks(newArrayLinks);
  };

  useEffect(() => {
    const currentId = navExplore.find(el => el.link === pathname)?.id;
    if (currentId) localStorage.setItem("activeLink", currentId);

    const initialLinks = navExplore.map(el => {
      return {
        ...el,
        active: el.id === localStorage.getItem("activeLink") && pathname !== "/explore",
      };
    });
    setLinks(initialLinks);
  }, [pathname]);

  const handleLoginModal = (e: React.MouseEvent): void => {
    e.preventDefault();
    openModalLogin();
  };

  const clickHandler = (item: ExploreLinkItem): LinkClickCallback => {
    if (item.checkForAuth) {
      return isAuth ? changeActiveLinkId(item.id) : handleLoginModal;
    }
    return changeActiveLinkId(item.id);
  };

  return (
    <div className="nav_menu__explore wrapper">
      <Link
        onClick={changeActiveLinkId(exploreLinkId)}
        to="/explore"
        className={cn("nav_item", {
          active: exploreLinkIsActive,
        })}
      >
        Explore
      </Link>
      {links.map(item => {
        return (
          <Link
            onClick={clickHandler(item)}
            key={item.id}
            to={item.link}
            className={cn("nav_item", {
              active: item.active,
              blocked: item.active && pathname === item.link,
            })}
          >
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};
export default NavMenuExplore;
