import { IUser } from "../../types/userTypes";

export enum UserModalTitle {
  FOLLOWERS = "Followers",
  FOLLOWINGS = "Followings",
}
export interface UserFollowersProps {
  title: UserModalTitle.FOLLOWERS | UserModalTitle.FOLLOWINGS;
}

export interface FollowersData {
  data: {
    followers: {
      followers: IUser[];
      meta: { total: number };
    };
  };
}

export interface FollowingsData {
  data: {
    followings: {
      followings: IUser[];
      meta: { total: number };
    };
  };
}
