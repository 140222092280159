import { IMAGE } from "./ImageRequestParams";

export const ARTWORK_LITE = `
_id
name
artistName
createdAt
owner {
  _id
  hasInstitution
  institutions {
    name
  }
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
}
collector {
  _id
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
}
image {
  ${IMAGE}
}
`;
