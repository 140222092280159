import { getCoordinates } from "../../components/location-filter-select/utils";
import { ILocationFilter } from "../../context/types/artistsPageTypes";
import { ILocationType } from "../../types/addresTypes";

export const getLocationInput = async (
  location: ILocationFilter,
): Promise<ILocationType | null> => {
  let instLocation: ILocationType | null = {
    name: location?.label || "",
    coordinates: { longitude: 0, latitude: 0 },
  };
  if (location?.label) {
    const coordinates = await getCoordinates(location);
    if (coordinates) instLocation.coordinates = coordinates;
  } else {
    instLocation = null;
  }

  return instLocation;
};
