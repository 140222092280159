/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-classes-per-file */

type Constructor = new (...args: any[]) => { array: any[] };

export function emptArrayCheckMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(args);
    }

    notEmpty(): boolean {
      return Boolean(this.array.length);
    }
  };
}

class _Stack {
  constructor(public array: any[]) {
    this.array = array;
  }

  pop() {
    return this.array.pop();
  }
}

class _Queue {
  constructor(public array: any[]) {
    this.array = array;
  }

  shift() {
    return this.array.shift();
  }
}

export const Queue = emptArrayCheckMixin(_Queue);
export const Stack = emptArrayCheckMixin(_Stack);
