import React, { useMemo } from "react";
import { IArtworkState } from "../../../../types/artwork/artwork";
import { WinnersListProps } from "../../vote-explore-page.type";

import { useActions } from "../../../../hooks/useActions";

import "./WinnersList.scss";

const WinnersList: React.FC<WinnersListProps> = ({ artworks }): JSX.Element => {
  const { openModal } = useActions();
  const winnerArtworks = useMemo(() => {
    if (artworks?.length > 5) {
      return artworks.slice(0, 5);
    }
    return artworks;
  }, [artworks]);

  const openModalWindow = () => () => openModal();

  return (
    <div className="winner">
      <div className="winner_grid">
        {winnerArtworks &&
          winnerArtworks.map((el: IArtworkState) => (
            <div key={el._id} onClick={openModalWindow} className="winner_item">
              <img className="winner_image" src={el.image.medium.url} alt={el.name} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default WinnersList;
