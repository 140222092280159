import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import formatDate from "../../utils/format-date";
import { getColorByUserId } from "../artist-or-institution-profile/utils";

import "./CardItem.scss";
import { useActions } from "../../hooks/useActions";

const CardItem = ({ img, name, id, location, isOpenToSubmitions, duration, data }) => {
  const image = img ? img.medium.url || img.origin.url || img.thumbnail.url : "";
  const { pathname } = useLocation();
  const showsPath = pathname.includes("/shows");
  const { openModal } = useActions();

  const hasDuration = !!(duration?.from && duration?.to);
  const hasPermanentHours = !!(hasDuration && moment(duration?.from).year() === 0);
  const institutionOwnerId = data?.owner?._id;
  const backgroundColor = getColorByUserId(institutionOwnerId || id)[0];
  const backgroundTextColor = getColorByUserId(institutionOwnerId || id)[1];
  // todo when use Link with react-router-dom
  // const linkShowsString = showsPath
  //   ? `/explore/shows/${id}`
  //   : `/explore/${artists}/${institutionOwnerId}`;

  const displayDuration = () => {
    if (hasDuration && !hasPermanentHours) {
      return (
        <div>{`${formatDate(new Date(duration?.from), "MMM DD YYYY")} - ${formatDate(
          new Date(duration?.to),
          "MMM DD YYYY",
        )}`}</div>
      );
    }
    return <div>Permanent show</div>;
  };

  return (
    // todo when you use Link, change openModal function to goToTop function
    // eslint-disable-next-line
    <div onClick={openModal} className="institution_card">
      {/* todo when will you make the transition to shows, you need Link */}
      {/* <Link to={linkShowsString} state={{ image, name }}> */}
      <div style={{ backgroundColor }} className="institution_card__image">
        {showsPath && isOpenToSubmitions && (
          <div className="institution_card__open_to_submission">OPEN TO SUBMISSION</div>
        )}
        {image ? (
          <img src={image} alt={name} />
        ) : (
          <span style={{ color: backgroundTextColor }} className="institution_card__MOCA">
            MOCA
          </span>
        )}
      </div>

      <div className="institution_card__body">
        <div className="institution_card__name">{name}</div>
        {showsPath && (
          <>
            <div className="institution_card__duration">
              {displayDuration()}
              {hasPermanentHours && (
                <span className="institution_card__duration__time">
                  {`${formatDate(duration?.from, "h:mm a")} - ${formatDate(
                    duration?.to,
                    "h:mm a",
                  )}`}
                </span>
              )}
            </div>
          </>
        )}
        <div className="institution_card__location">{location?.name || "Virtual Only"}</div>
      </div>
      {/* </Link> */}
    </div>
  );
};
export default CardItem;
