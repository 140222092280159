/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhotoIcon from "@mui/icons-material/Photo";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Skeleton, Avatar } from "@mui/material";
import { useNavigate, useLocation, useParams, Outlet } from "react-router-dom";

import { ArtistOrInstitutionProfileProps } from "./ArtistOrInstitutionProfile.props";
import { useActions } from "../../hooks/useActions";
import Button from "../../UI/button/Button";
import tvIcon from "../../assets/images/iconTelevision@3x.png";
import { IParamsId } from "../artist-profile-artworks/utils";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import {
  fetchBlockedUsers,
  reportUser,
  toggleBlockUser,
  toggleFollowing,
} from "../../api-queries/GraohQlClient";
import { getMyFollowings, useGetMe } from "../../hooks/useGetMe";
import { getReportIdKey, getReportTitle, ReportItemTypes } from "../report-button/utils";

import "./ArtistOrInstitutionProfile.scss";
import {
  artists,
  editInstitutionProfile,
  editProfile,
  explore,
  followers,
  followings,
  userScans,
} from "../../route-link";
import MoreHorizontalMenu from "../more-horizontal-menu/MoreHorizontalMenu";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ReportContent from "../report-content/ReportContent";
import { ReturnActionType } from "../../store/types/return-type";
import { IUser } from "../../types/userTypes";
import ShareButtonMenu from "../ShareButtonMenu/ShareButtonMenu";

const ArtistOrInstitutionProfile: React.FC<ArtistOrInstitutionProfileProps> = ({
  data,
  fullName,
  myFollowers,
  myFollowings,
  likedArtworks,
  shows,
  image,
  address,
  avaliableForFilmTV,
  institutionAddress,
  scans,
  voted,
  counterLoading,
  isMe,
  prevImage,
  refetch,
  profile,
}) => {
  const { openModalLogin, openReportModal, closeReportModal, setShowMessagesModal } = useActions();
  const { reportModal } = useTypedSelector(store => store.modalReducer);
  const { id }: IParamsId = useParams();
  const [currentFollowers, setCurrentFollowers] = useState(myFollowers);
  const [isFollow, setIsFollow] = useState<boolean>();
  const [blockedUsers, setBlockedUsers] = useState([]);
  const { setActiveArtworkTab, setCurrentChat, setCurrentStage, setCurrentMessages } = useActions();

  const navigate = useNavigate();
  const location = useLocation();
  const onShowsClick = (): void => {
    navigate(`${location.pathname}/shows`);
  };
  const token = localStorage.getItem("tokenKey");
  const myMOCAId = localStorage.getItem("myMOCAId");
  const { me, updateAuthUser } = useGetMe();
  const showReportButton = id !== me?._id;
  const showCounterLoader = Boolean(counterLoading && !scans && !voted && !shows);

  const toggleFollowUser = async (): Promise<void> => {
    setIsFollow(!isFollow);
    setCurrentFollowers(prevFollowers => (!isFollow ? prevFollowers + 1 : prevFollowers - 1));
    await toggleFollowing({ userId: id });
    updateAuthUser();
  };
  const goToEditProfile = async (): Promise<void> => {
    return data.hasInstitution
      ? navigate(`/${explore}/${artists}/${id}/${editInstitutionProfile}`, {
          state: { data },
        })
      : navigate(`/${explore}/${artists}/${id}/${editProfile}`, {
          state: { data },
        });
  };
  const goToUserVotedArtworks = token ? () => navigate("voted-artworks") : openModalLogin;
  const goToUserLikedArtworks = token ? () => navigate("liked-artworks") : openModalLogin;
  const goToUserFollowers = token ? () => navigate(followers) : openModalLogin;
  const goToUserFollowings = token ? () => navigate(followings) : openModalLogin;
  const goToUserScans = token ? () => navigate(userScans, { state: scans }) : openModalLogin;

  const onReportMenuClick = token ? openReportModal : openModalLogin;
  const setReportModalIsOpen = (boolean: boolean): ReturnActionType => {
    return boolean ? openReportModal() : closeReportModal();
  };
  const reportIdKey = getReportIdKey(ReportItemTypes.User);
  const reportTitle = getReportTitle(ReportItemTypes.User);

  const getBlockedUsers = async (): Promise<void> => {
    const blockedUsersList = await fetchBlockedUsers({ userId: myMOCAId });
    setBlockedUsers(blockedUsersList?.blockers || []);
  };

  const toggleBlock = async (): Promise<void> => {
    if (!myMOCAId) return;
    const blockInfo = await toggleBlockUser({ userId: id });
    const blocked = blockInfo?.toggleBlocking?.blocked;
    getBlockedUsers();
    refetch();
    if (blocked) {
      setActiveArtworkTab("artworksForSale");
      return;
    }
    setActiveArtworkTab("createdArtworks");
  };

  const getMenuOptions = (): any => {
    const blockedStatus = blockedUsers.find((user: IUser) => user._id === id) ? "Unblock" : "Block";
    return [
      { title: !isMe ? blockedStatus : "", onClick: toggleBlock },
      { title: showReportButton ? "Report" : "", onClick: onReportMenuClick },
      { title: isMe ? "Edit" : "", onClick: goToEditProfile },
    ];
  };

  useEffect(() => {
    const myFollows = me && getMyFollowings(me);
    setIsFollow(Boolean(myFollows?.find((item: { id: string | undefined }) => item.id === id)));
    getBlockedUsers();
  }, [me]);

  useEffect(() => {
    setCurrentFollowers(myFollowers);
  }, [myFollowers]);

  const openChat = (): void => {
    if (!id || !profile) return;

    const mockChat: any = {
      participants: [
        {
          participant: {
            _id: id,
            profile,
          },
        },
      ],
    };
    setCurrentMessages([]);
    setCurrentChat(mockChat);
    setCurrentStage("chat");
    setShowMessagesModal(true);
  };

  return (
    <>
      <ReportContent
        isOpen={reportModal}
        setIsOpen={setReportModalIsOpen}
        title={reportTitle}
        reportAction={reportUser}
        itemId={id || undefined}
        reportIdKey={reportIdKey}
      />
      <Outlet />
      <div className="profile_wrapper">
        <div className="profile">
          <div className="profile_avatar">
            {image?.medium?.url || prevImage ? (
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={image?.medium?.url || prevImage}
                className="profile_avatar__image"
              />
            ) : (
              <AvatarPlaceholder width="100px" height="100px" fontSize="20px" id={id} />
            )}
          </div>
          <div className="profile_info">
            <div className="profile_name">
              {fullName || <Skeleton width={300} height={40} className="profile_skeleton" />}
            </div>
            <div className="profile_buttons">
              {!isMe && (
                <>
                  <Button
                    onClick={token ? toggleFollowUser : openModalLogin}
                    color="dark"
                    className="profile_follow_button"
                  >
                    {isFollow ? "Following" : "Follow"}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="profile_menu_wrapper">
          {data && <ShareButtonMenu type="profile" shareData={{ user: data }} />}
          {!isMe && (
            <div onClick={openChat} className="message_bubble profile_message_button">
              Message
            </div>
          )}
          <MoreHorizontalMenu options={getMenuOptions()} />
        </div>
      </div>
      <div className="profile_counters">
        <div className="profile_follow">
          <span onClick={goToUserFollowers}>{currentFollowers?.toLocaleString()} Followers</span>
          <span onClick={goToUserFollowings}>{myFollowings?.toLocaleString()} Followings</span>
          {!!likedArtworks && (
            <span onClick={goToUserLikedArtworks} className="profile_likes">
              {likedArtworks?.toLocaleString()} Likes
            </span>
          )}
        </div>
        {showCounterLoader ? (
          <>
            <Skeleton className="profile_skeleton" />
            <Skeleton className="profile_skeleton__litle" />
            <Skeleton className="profile_skeleton" />
            <Skeleton className="profile_skeleton__litle" />
          </>
        ) : (
          <>
            {!!scans && (
              <div onClick={goToUserScans} className="profile_scans profile_text">
                <div className="profile_icon_wrapper">
                  <VisibilityOutlinedIcon className="profile_scans__img" />
                </div>
                {`${scans.toLocaleString()} scans`}
              </div>
            )}
            {!!voted && (
              <div onClick={goToUserVotedArtworks} className="profile_vote profile_text">
                <div className="profile_icon_wrapper">
                  <ThumbUpAltIcon className="profile_vote__img" />
                </div>
                {`Voted ${voted.toLocaleString()} times`}
              </div>
            )}
          </>
        )}
        {address && (
          <div className="profile_location profile_text">
            <div className="profile_icon_wrapper">
              <LocationOnOutlinedIcon className="profile_location__img" />
            </div>
            {address}
          </div>
        )}
        {!!shows && (
          <div onClick={onShowsClick} className="profile_shows__count profile_text">
            <div className="profile_icon_wrapper">
              <PhotoIcon fontSize="small" className="profile_shows__img" />
            </div>
            {shows?.toLocaleString()} Shows
          </div>
        )}
        {avaliableForFilmTV && (
          <div className="profile_tv">
            <div className="profile_icon_wrapper">
              <img className="profile_tv__img" alt="tvIcon" src={tvIcon} />
            </div>
            <div className="profile_tv__text">
              <span>Avaliable for film/TV</span>
            </div>
          </div>
        )}
        {institutionAddress && (
          <div className="profile_location profile_text">
            <div className="profile_icon_wrapper">
              <LocationOnOutlinedIcon className="profile_location__img" />
            </div>
            {institutionAddress}
          </div>
        )}
      </div>
    </>
  );
};

export default ArtistOrInstitutionProfile;
