import React, { FC, useEffect, useState } from "react";
import { FormControlLabel, Checkbox, Modal, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

import imageGalerysrc from "../../assets/images/image-gallery@3x.png";
import Button from "../../UI/button/Button";
import { ArtworkTypes } from "../../types/artwork/artwork";
import CropImage from "../../components/crop-image/CropImage";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useGetMe } from "../../hooks/useGetMe";
import { useActions } from "../../hooks/useActions";
import { uploadArtworkInfo } from "../../route-link";
import { addArtwork } from "../../api-queries/GraohQlClient";
import SearchLoader from "../../components/search-loader/SearchLoader";
import BackButton from "../../components/back-button/BackButton";

import "./UploadArtwork.scss";

const UploadArtwork: FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [cropOpen, setCropOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const myMOCAId = localStorage.getItem("myMOCAId");
  const { me } = useGetMe();
  const { me: user } = useTypedSelector(state => state.authorizedUserReducer);

  const { artwork } = useTypedSelector(state => state.uploadImagesReducer);
  const { setUploadArtwork, setAuthorizedUser } = useActions();

  const isInstitution = user?.hasInstitution && !!user?.institutions.length;
  const buttonIsDisablet = Boolean(!artwork || !checkboxValue);
  const backgroundColor = artwork ? "#fbfbfb" : "";

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCheckboxValue(event.target.value);
  };

  const onInputFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setCropOpen(true);
      setUploadArtwork(URL.createObjectURL(event.target.files[0]));
      setErrorMessage(false);
    }
  };

  const handleProfileImage = async (value: string): Promise<void> => {
    if (!value) return;
    fetch(value)
      .then(res => res.blob())
      .then(data => {
        setUploadArtwork(URL.createObjectURL(data));
      });
  };

  const handleCloseModal = (): void => setCropOpen(false);

  const handleCloseCrop = (): void => {
    setCropOpen(false);
    setUploadArtwork("");
  };

  const onContinueClick = async (): Promise<void> => {
    setIsLoading(true);
    const photo = await fetch(artwork);
    const photoBlob = await photo.blob();
    try {
      const data = await addArtwork({ photoBlob, creationInProgress: true });
      if (!data) {
        setErrorMessage(true);
        setIsLoading(false);
        return;
      }
      navigate(`/${uploadArtworkInfo}`, {
        state: { type: checkboxValue, image: artwork, id: data?._id },
      });
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!user) {
      setAuthorizedUser(me);
    }
  }, [myMOCAId, me]);

  return (
    <div className="upload_artwork_page wrapper">
      <SearchLoader loading={isLoading} />
      <Modal
        open={cropOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CropImage
          imageSrc={artwork || ""}
          cropImage="profile"
          handleProfileImage={handleProfileImage}
          handleClose={handleCloseCrop}
          minCropBoxHeight={50}
          minCropBoxWidth={50}
        />
      </Modal>
      <div className="upload_artwork_page__title">
        <BackButton className="back-button" />
        <h2>UPLOAD ARTWORK</h2>
      </div>
      <div className="upload_artwork_page__content">
        <div style={{ backgroundColor }} className="upload_artwork_page__image_section">
          <div>
            <input
              type="file"
              name="profile"
              className="upload_artwork_page__input_file"
              onChange={onInputFileChange}
            />
            {artwork && (
              <img className="upload_artwork_page__artwork_image" src={artwork} alt="art" />
            )}
            <img src={imageGalerysrc} alt="icon" />
          </div>
          <h3 className="upload_artwork_page__image_section_title">
            TAKE A GOOD PHOTO TO ADD ORIGINAL ARTWORK
          </h3>
        </div>
        <div className="upload_artwork_page__controls_section">
          <div className="upload_artwork_page__checkbox_wrapper">
            {!isInstitution && (
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    onChange={handleChecked}
                    checked={checkboxValue === ArtworkTypes.ARTIST}
                    value={ArtworkTypes.ARTIST}
                    color="success"
                  />
                }
                label="I am the artist"
              />
            )}
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  onChange={handleChecked}
                  checked={checkboxValue === ArtworkTypes.COLLECTOR}
                  value={ArtworkTypes.COLLECTOR}
                  color="success"
                />
              }
              label="I am the collector"
            />
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  onChange={handleChecked}
                  checked={checkboxValue === ArtworkTypes.FOUND}
                  value={ArtworkTypes.FOUND}
                  color="success"
                />
              }
              label="I found this art"
            />
          </div>
          <div className="upload_artwork_page__buttons_wrapper">
            <Button
              onClick={onContinueClick}
              className="upload_artwork_page__continue_button"
              children="Continue"
              color="dark"
              disabled={buttonIsDisablet}
            />
          </div>
        </div>
      </div>
      {errorMessage && (
        <Alert style={{ marginTop: "5px" }} severity="error">
          Something went wrong (image is already used for another artwork or image size is too
          small)
        </Alert>
      )}
    </div>
  );
};

export default UploadArtwork;
