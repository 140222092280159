import React, { FC, useState } from "react";
import { IArtwork } from "../../../types/artwork/artwork";
import Button from "../../../UI/button/Button";
import InputField from "../../../UI/input-field/InputField";
import BackButton from "../../back-button/BackButton";
import DialogWindow from "../../dialog-window/DialogWindow";

import "./AskingModal.scss";

interface IAskingModalProps {
  close: () => void;
  isOpen: boolean;
  artwork: IArtwork;
  action: any;
  buttonText: string;
  modalTitle: string;
}

const AskingModal: FC<IAskingModalProps> = ({
  close,
  isOpen,
  artwork,
  action,
  buttonText,
  modalTitle,
}) => {
  const [messageText, setMessageText] = useState<string>("");
  const currentArtworkOwner = artwork?.collector || artwork?.owner;

  return (
    <DialogWindow open={isOpen} handleClose={close} className="asking-modal_overlay">
      <div className="asking-modal">
        <h3 className="asking-modal_title">
          <BackButton className="" callback={close} /> {modalTitle}
        </h3>
        <div className="asking-modal_content">
          <InputField
            label=""
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
            multiline
            minRows={8}
            maxRows={8}
          />
        </div>
        <div className="asking-modal_bottom_panel">
          <div className="asking-modal_artwork_info">
            {artwork?.image?.thumbnail?.url && (
              <img
                className="asking-modal_artwork_info_image"
                src={artwork?.image?.thumbnail?.url}
                alt="artwork"
              />
            )}
            <div className="asking-modal_artwork_info_text-info">
              <p className="author">
                {currentArtworkOwner?.profile?.firstName} {currentArtworkOwner?.profile?.secondName}
              </p>
              <p className="name">{artwork?.name}</p>
            </div>
          </div>

          <div className="asking-modal_buttons">
            <Button
              variant="button-dark"
              className="asking-modal_buttons_button"
              onClick={() => action(messageText)}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </DialogWindow>
  );
};

export default AskingModal;
