import React from "react";

import TextTemplateNotification from "./TextTemplateNotification";
import { artists, explore } from "../../route-link";
import PostNoticeAudited from "./audited-components/PostNoticeAudited/PostNoticeAudited";
import { IImage } from "../../types/imageData";

interface PublicCommentsPostProps {
  nameFollower: string;
  userId: string;
  textComment: string;
  time: Date;
  post: any;
  userImage: IImage;
}

const PublicCommentsPostNotification: React.FC<PublicCommentsPostProps> = ({
  nameFollower,
  userId,
  time,
  textComment,
  post,
  userImage,
}) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const nameUser =
    authUserId === post.owner._id
      ? "You"
      : `${post?.owner.profile.firstName} ${post.owner.profile.secondName}`;
  const { artwork, post: sharedPost, exhibition } = post.audited;
  const getEntity = (): string => {
    if (artwork) return "artwork shared by ";
    if (sharedPost) return "post shared by ";
    if (exhibition) return "show shared by ";
    return "Post";
  };
  const entity = getEntity();
  return (
    <div className="notification_grid">
      <TextTemplateNotification
        messageText={`commented: "${textComment}" on ${entity}`}
        nameFollower={nameFollower}
        linkNameFollower={`${explore}/${artists}/${userId}`}
        nameUser={nameUser}
        linkNameUser={entity === "Post" ? "" : `${explore}/${artists}/${authUserId}`}
        userImage={userImage}
      />
      {/* Todo link to post */}
      <PostNoticeAudited post={post} time={time} />
    </div>
  );
};

export default PublicCommentsPostNotification;
