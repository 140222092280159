import React, { FC } from "react";
import cn from "classnames";
import { ButtonProps } from "./Button.props";

import "./Button.scss";

const Button: FC<ButtonProps> = ({
  className = "",
  children,
  color = "none",
  onClick,
  variant,
  disabled = false,
  ...props
}): JSX.Element => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={cn(
        `btn ${className}`,
        {
          bg_dark: color === "dark",
          bg_light: color === "light",
          bg_none: color === "none",
        },
        {
          text: variant === "text",
          outlined: variant === "outlined",
          outlined_dark: variant === "outlined-dark",
          button: variant === "button",
          button_dark: variant === "button-dark",
        },
        {
          btn_disabled: disabled,
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default Button;
