/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from "react";
import { ITextMessageContentProps } from "../utils";

const TextMessage: FC<ITextMessageContentProps> = ({
  message,
  setImageIndex,
  setLightboxImages,
}) => {
  const { imagesOfChat } = message;
  const openLightbox = (index: number): void => {
    setLightboxImages(imagesOfChat);
    setImageIndex(index);
  };
  const getClassName = (): any => {
    switch (imagesOfChat.length) {
      case 1:
        return "chat-message_images_single";
      case 2:
        return "chat-message_images_double";
      case 3:
        return "chat-message_images_tripple";
      case 4:
        return "chat-message_images_grid";
      default:
        return null;
    }
  };
  const calculateImageHeight = (): any => {
    if (imagesOfChat.length !== 1) return undefined;
    const height = imagesOfChat[0]?.origin?.height;
    const width = imagesOfChat[0]?.origin?.width;
    if (!height || !width) return undefined;
    let coefficient;
    if (width >= height) {
      coefficient = width / height;
      const resultWidth = width > 250 ? 250 : width;
      return {
        minHeight: resultWidth / coefficient,
        maxWidth: resultWidth,
      };
    }
    coefficient = height / width;
    const resultHeight = height > 300 ? 300 : height;
    return {
      minHeight: resultHeight,
      maxWidth: resultHeight / coefficient,
    };
  };
  const imageHeight = calculateImageHeight();
  return (
    <div className="text-message">
      {!!imagesOfChat.length && (
        <div className="chat-message_images">
          <div className={getClassName()}>
            {imagesOfChat.map((image, idx) => (
              <img
                className="chat-message_images_image"
                onClick={() => openLightbox(idx)}
                key={image._id}
                src={image.medium?.url}
                alt={image._id}
                style={imageHeight || {}}
              />
            ))}
          </div>
        </div>
      )}
      <p className="chat-message_text">{message.text}</p>
    </div>
  );
};

export default TextMessage;
