import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation signUp(
    $email: String!
    $password: String!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $phoneNumber: String
    $hasInstitution: Boolean
  ) {
    signUp(
      email: $email
      password: $password
      hasInstitution: $hasInstitution
      profile: {
        firstName: $firstName
        middleName: $middleName
        secondName: $lastName
        phoneNumber: $phoneNumber
      }
    ) {
      token
      me {
        _id
      }
    }
  }
`;

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      me {
        _id
      }
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      succeeded
    }
  }
`;
