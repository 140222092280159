import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCreateTransferContext } from "../../../../../context/createTransferContext";
import InputField from "../../../../../UI/input-field/InputField";
import LocationAutocomplete from "../../../../locations-autocomplete/LocationAutocomplete";

import "./SaleTransferForm.scss";

const SaleTransferForm: React.FC = () => {
  const { typeOfTransfer } = useCreateTransferContext();
  const [type, setType] = useState<string>(typeOfTransfer);
  const {
    price,
    setPrice,
    conditionReport,
    setConditionReport,
    notes,
    setNotes,
    locationLives,
    setLocationLives,
    commission,
    setCommission,
    setTypeOfTransfer,
    resetAllFields,
    toggleLocationIsPrivate,
    locationIsPrivate,
  } = useCreateTransferContext();

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>): void => {
    resetAllFields();
    setType(e.target.value);
  };

  useEffect(() => {
    setTypeOfTransfer(type);
  }, [type]);

  return (
    <>
      <h3>Bill of Sale</h3>
      <div className="transfer-type-checkboxes">
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={type === "Direct"}
              value="Direct"
              color="success"
            />
          }
          label="Direct"
        />
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={type === "Resale"}
              value="Resale"
              color="success"
            />
          }
          label="Resale"
        />
      </div>
      <div className="create-transfer_form">
        {type === "Direct" && (
          <>
            <InputField label="AMOUNT" value={price} onChange={setPrice} helperText="*required" />
            <InputField
              label="TERMS"
              value={conditionReport}
              onChange={setConditionReport}
              helperText="*required"
              multiline
            />
            <InputLabel className="origin_label">LOCATION ON DISPLAY</InputLabel>
            <LocationAutocomplete
              value={locationLives}
              className="edit_item__location"
              onChange={setLocationLives}
              onCloseIconClick={() => setLocationLives(null)}
              placeholder="Choose location..."
              isPrivate={locationIsPrivate}
            />
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  onChange={toggleLocationIsPrivate}
                  checked={locationIsPrivate}
                  value=""
                  color="success"
                />
              }
              label="Location is private"
            />
            <InputField
              label="NOTES, STORIES, HISTORY & INTERESTING FACTS"
              value={notes}
              onChange={setNotes}
              multiline
            />
          </>
        )}
        {type === "Resale" && (
          <>
            <InputField
              label="Asking Price"
              value={price}
              onChange={setPrice}
              helperText="*required"
            />
            <InputLabel className="origin_label">LOCATION ON DISPLAY</InputLabel>
            <LocationAutocomplete
              value={locationLives}
              className="edit_item__location"
              onChange={setLocationLives}
              onCloseIconClick={() => setLocationLives(null)}
              placeholder="Choose location..."
              isPrivate={locationIsPrivate}
            />
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  onChange={toggleLocationIsPrivate}
                  checked={locationIsPrivate}
                  value=""
                  color="success"
                />
              }
              label="Location is private"
            />
            <InputField
              label="NOTES, STORIES, HISTORY & INTERESTING FACTS"
              value={notes}
              onChange={setNotes}
              multiline
            />
            <div className="resale-notices">
              <p className="transfer-notice">
                *This information is private and accessible in Controls under Resale Art and Terms
              </p>
              <InputField label="AGREED COMMISSION" value={commission} onChange={setCommission} />
              <InputField
                label="TERMS"
                value={conditionReport}
                onChange={setConditionReport}
                multiline
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SaleTransferForm;
