import { USER_PROFILE } from "./UserProfileRequestParams";
import { LOCATION } from "./LocationRequestParams";
import { ARTWORK } from "./ArtworkRequestParams";
import { IMAGE } from "./ImageRequestParams";
import { COMMENT } from "./CommentRequestParams";

export const EXHIBITION = `
_id
title
description
duration {
  from
  to
}
owner {
  _id
  myArtworksLikes
  likedArtworks
  hasInstitution
  institutions {
    name
  }
  profile {
    ${USER_PROFILE}
  }
  hasInstitution
  institutions{
    _id
    name
  }
}
location {
  ${LOCATION}
}
artworks {
  ${ARTWORK}
}
guests {
  _id
  hasInstitution
  institutions{
    _id
    name
  }
  profile {
    ${USER_PROFILE}
  }
}
fromInstitution
artists {
  _id
  hasInstitution
  institutions{
    _id
    name
  }
  profile {
    ${USER_PROFILE}
  }
}
daysOpen {
  dayOfWeek
  hoursOpen {
    from
    to
  }
}
image {
  ${IMAGE}
}
private
isSolo
isOpenToSubmitions
submittedArtworks {
  ${ARTWORK}
}
comments {
  ${COMMENT}
  replies {
    ${COMMENT}
  }
}
declinedArtworks {
  _id
}
invitedUsers {
  _id
  hasInstitution
  institutions{
    _id
    name
  }
  profile {
    ${USER_PROFILE}
  }
}`;
