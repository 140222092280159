import React from "react";
import Button from "@mui/material/Button";

import ButtonResult from "../../UI/button-result/ButtonResult";
import { getCategoriesIds } from "../filter-categories/utils";
import { getFiltersValues } from "../filter-materials-colors/utils";
import { refreshInspirationQueryOnDelete, refreshQueryOnDelete, refreshRangeQuery } from "./utils";
import { IFilterTypes } from "../../types/filterTypes";
import { genderFilterOptions } from "../../explore-pages/artists-page/utils";
import { initialLocationSortValue } from "../location-filter-select/utils";

import "./FilterResults.scss";

const FilterResults = ({
  filterResults,
  setFilterResults,
  setDataItem,
  categoriesQuery,
  inspipationQuery = "inspiration",
  context,
}) => {
  const {
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    offset: { setOffset },
  } = context;
  const categories = context?.categories?.categories;
  const setCategories = context?.categories?.setCategories;
  const materials = context?.materials?.materials;
  const setMaterials = context?.materials?.setMaterials;
  const colors = context?.colors?.colors;
  const setColors = context?.colors?.setColors;
  const originsOfInspiration = context?.originsOfInspiration?.originsOfInspiration;
  const setOriginsOfInspiration = context?.originsOfInspiration?.setOriginsOfInspiration;
  const setGender = context?.gender?.setGender;
  const setLocation = context?.location?.setLocation;
  const setLocationSortValue = context?.locationSortValue?.setLocationSortValue;

  const onDelete = (id, type) => {
    setFilterResults(filterResults.filter(item => item.id !== id));
    if (type === IFilterTypes.Categories) {
      refreshQueryOnDelete(
        setDataItem,
        categories,
        setCategories,
        id,
        getCategoriesIds,
        filterQueryParams,
        setfilterQueryParams,
        categoriesQuery,
      );
    }
    if (type === IFilterTypes.Materials) {
      refreshQueryOnDelete(
        setDataItem,
        materials,
        setMaterials,
        id,
        getFiltersValues,
        filterQueryParams,
        setfilterQueryParams,
        "materials",
      );
    }
    if (type === IFilterTypes.Colors) {
      refreshQueryOnDelete(
        setDataItem,
        colors,
        setColors,
        id,
        getFiltersValues,
        filterQueryParams,
        setfilterQueryParams,
        "colors",
      );
    }
    if (type === IFilterTypes.Price) {
      refreshRangeQuery(
        setDataItem,
        { ...filterQueryParams, forSale: undefined },
        setfilterQueryParams,
        "priceRange",
      );
    }
    if (type === IFilterTypes.Width) {
      refreshRangeQuery(setDataItem, filterQueryParams, setfilterQueryParams, "widthRange");
    }
    if (type === IFilterTypes.Height) {
      refreshRangeQuery(setDataItem, filterQueryParams, setfilterQueryParams, "heightRange");
    }
    if (type === IFilterTypes.OriginsOfInspiration) {
      refreshInspirationQueryOnDelete(
        setDataItem,
        originsOfInspiration,
        id,
        setOriginsOfInspiration,
        setfilterQueryParams,
        filterQueryParams,
        inspipationQuery,
      );
    }
    if (type === IFilterTypes.Gender) {
      setDataItem([]);
      setGender(genderFilterOptions[0]);
      setfilterQueryParams({
        ...filterQueryParams,
        sex: undefined,
      });
    }
    if (type === IFilterTypes.Location) {
      setDataItem([]);
      setLocation(null);
      setfilterQueryParams({
        ...filterQueryParams,
        location: undefined,
      });
    }
  };

  const clearAll = () => {
    setOffset(0);
    setFilterResults([]);
    setDataItem([]);
    if (setCategories) {
      setCategories(categories.map(category => ({ ...category, checked: false })));
    }
    if (setMaterials) {
      setMaterials(materials.map(material => ({ ...material, checked: false })));
    }
    if (setColors) {
      setColors(colors.map(color => ({ ...color, checked: false })));
    }
    if (setOriginsOfInspiration) {
      setOriginsOfInspiration([]);
    }
    if (setGender) {
      setGender(genderFilterOptions[0]);
    }
    if (setLocation) {
      setLocation(null);
    }
    if (setLocationSortValue) {
      setLocationSortValue(initialLocationSortValue);
    }
    setfilterQueryParams({
      ...filterQueryParams,
      offset: 0,
      [categoriesQuery]: undefined,
      materials: undefined,
      colors: undefined,
      priceRange: undefined,
      widthRange: undefined,
      heightRange: undefined,
      inspiration: undefined,
      originsOfInspiration: undefined,
      sex: undefined,
      location: undefined,
      forSale: undefined,
    });
  };

  return (
    <div className="filter_results">
      {filterResults.map(filter => (
        <ButtonResult
          title={filter.title}
          key={filter.id}
          id={filter.id}
          type={filter.type}
          onDelete={onDelete}
        />
      ))}
      <Button className="clear" onClick={clearAll}>
        Clear all
      </Button>
    </div>
  );
};

export default FilterResults;
