import { ProfileAction, ProfileActionTypes, ProfileState } from "../types/profile";

const initialValue: ProfileState = {
  profileUserId: "",
  activeArtworkTab: "",
  profileContainerHeight: 200,
  profileArtworkTabs: [],
};

const profileReducer = (state = initialValue, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.SET_PROFILE_USER_ID:
      return { ...state, profileUserId: action.payload };
    case ProfileActionTypes.SET_ACTIVE_ARTWORK_TAB:
      return { ...state, activeArtworkTab: action.payload };
    case ProfileActionTypes.SET_PROFILE_HEIGHT:
      return { ...state, profileContainerHeight: action.payload };
    case ProfileActionTypes.SET_ARTWORK_TABS:
      return { ...state, profileArtworkTabs: action.payload };
    default:
      return state;
  }
};
export default profileReducer;
