import { IArtwork } from "../../types/artwork/artwork";
import { UserVotedArtworksType, UserVotedArtworksTypes } from "../types/user-voted-artworks";

export const setUserVotedArtworks = (payload: IArtwork[] | []): UserVotedArtworksType => ({
  type: UserVotedArtworksTypes.SET_USER_VOTED_ARTWORKS,
  payload,
});

export const setVotedArtworksOffset = (payload: number): UserVotedArtworksType => ({
  type: UserVotedArtworksTypes.SET_OFFSET,
  payload,
});

export const setUserId = (payload: string): UserVotedArtworksType => ({
  type: UserVotedArtworksTypes.SET_USER_ID,
  payload,
});
