import { IMessage } from "../../types/chat";

export const getUniqueMessages = (messages: IMessage[]): any[] => {
  const messagesIds = messages.map(artwork => artwork._id);
  const uniqueMessagesIds = Array.from(new Set(messagesIds));
  return uniqueMessagesIds.map(uniqueMessagesId => {
    return messages.find(message => message._id === uniqueMessagesId);
  });
};

export const sortMessagesByDate = (messages: IMessage[]): IMessage[] => {
  return messages.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};
