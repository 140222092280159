import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCreateTransferContext } from "../../../../context/createTransferContext";
import BackButton from "../../../back-button/BackButton";
import EditImages from "../../../edits/components/EditImages";
import AddTransferInfoForm from "../AddTransferInfoForm";

import "./SetTransferInfoStage.scss";

interface TransferInfoStageProps {
  goToPrevStage: () => void;
}

const SetTransferInfoStage: React.FC<TransferInfoStageProps> = ({ goToPrevStage }) => {
  const {
    resetAllFields,
    setSaleable,
    typeOfTransfer,
    setTypeOfTransfer,
    setAvailableForLoan,
    images,
    setImages,
    initialHasFilmsOrTv,
    hasFilmsOrTv,
    setHasFilmsOrTv,
  } = useCreateTransferContext();
  const [transferType, setTransferType] = useState<string>(typeOfTransfer);

  useEffect(() => {
    switch (transferType) {
      case "Sale":
        setTypeOfTransfer(typeOfTransfer);
        setSaleable(true);
        return;
      case "Trade":
        setTypeOfTransfer("Trade");
        return;
      case "Loan":
        setTypeOfTransfer("Loan");
        setAvailableForLoan(true);
        return;
      case "Gift":
        setTypeOfTransfer(typeOfTransfer || "Gift");
        break;
      case "Resale":
        setTransferType("Sale");
        break;
      case "Direct":
        setTransferType("Sale");
        break;
      case "Will":
        setTransferType("Gift");
        break;
      default:
    }
  }, [transferType]);

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>): void => {
    resetAllFields();
    setTransferType(e.target.value);
  };

  const handleChangeFilmsAndTV = (): void => {
    setHasFilmsOrTv(!hasFilmsOrTv);
  };

  return (
    <div className="create-transfer_form">
      <h3 className="transfer-details_title">
        <BackButton className="" callback={goToPrevStage} /> Transfer object of Art
      </h3>
      <EditImages
        images={images}
        setImages={setImages}
        titleWindow="image"
        showHeader={false}
        transferModal
      />
      <div className="transfer-type-checkboxes">
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={transferType === "Sale"}
              value="Sale"
              color="success"
            />
          }
          label="Sale"
        />
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={transferType === "Trade"}
              value="Trade"
              color="success"
            />
          }
          label="Trade"
        />
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={transferType === "Loan"}
              value="Loan"
              color="success"
            />
          }
          label="Loan"
        />
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={transferType === "Gift"}
              value="Gift"
              color="success"
            />
          }
          label="Gift"
        />
      </div>
      <AddTransferInfoForm transferType={transferType} />
      <div className="notices">
        <p className="transfer-notice">*My MOCA does not process payments, use own platforms</p>
        {initialHasFilmsOrTv && (
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                onChange={handleChangeFilmsAndTV}
                checked={hasFilmsOrTv}
                value=""
                color="success"
              />
            }
            label="Allow for Movie and TV"
          />
        )}
        <p className="transfer-notice">
          *After transfer is accepted operator who holds Credential can Edit to sell, media use and
          maps
        </p>
      </div>
    </div>
  );
};

export default SetTransferInfoStage;
