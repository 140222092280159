/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useActions } from "../../hooks/useActions";
import {
  campaigns,
  incomingTransfers,
  myNominatedArtwork,
  myVotedArtwork,
  votes,
} from "../../route-link";
import ChangeLocationListener from "./ChangeLocationListener";

import { NEW_YORK_COORDINATES } from "../../config";
import { displayExhibitions } from "./utils";

import { MapTabs } from "../artwork-details-map/utils";
import "./LeafletMaps.scss";

const LeafletMap = ({
  locations,
  location,
  exhibitions,
  activeTab = "",
  zoom,
  className = "leaflet_map",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveState = () => {},
  block = false,
  filterLocation = null,
  searchThisArea = null,
  mapPage = false,
  locationType = null,
}) => {
  const [artworkItemLocation, setArtworkItemLocation] = useState();
  const [center, setCenter] = useState();
  const navigate = useNavigate();
  const { openModal } = useActions();
  const decreaseIn = 10;
  const mapItemLimit = 150;
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getLocations = (activeTab = "", location) => {
    switch (activeTab) {
      case MapTabs.CREATED: {
        return location.locationCreated;
      }
      case MapTabs.LIVES: {
        return location.locationLives;
      }
      case MapTabs.COLLECTED: {
        return location.locationCollected;
      }
      case MapTabs.FOUND: {
        return location.locationFound;
      }
      default:
        return (
          location.locationLives ||
          location.locationCreated ||
          location.locationCollected ||
          location.locationFound
        );
    }
  };

  const createMapIcon = (iconUrl, width, height) => {
    return L.icon({
      iconUrl,
      iconSize: [width, height],
    });
  };

  const goToDetails = (path, id, state) => {
    if (saveState) saveState(null, id);
    let artworkLink = `${path}/${id}`;
    if (pathname.includes(incomingTransfers))
      artworkLink = `/explore/artworks/${id}/${incomingTransfers}`;

    if (pathname.includes(campaigns)) artworkLink = `/explore/artworks/${id}/${myNominatedArtwork}`;

    if (pathname.includes(votes)) artworkLink = `/explore/artworks/${id}/${myVotedArtwork}`;

    navigate(artworkLink, { state });
  };

  const ChangeMapCenter = () => {
    const map = useMap();
    map.setView(center);
    return null;
  };

  useEffect(() => {
    if (!location) {
      setCenter(NEW_YORK_COORDINATES);
      return;
    }
    const artworksLocation = getLocations(activeTab, location);

    const { url } = location.image.thumbnail;
    const { medium } = location.image;
    const { width, height } = medium;
    const iconWidth = width / decreaseIn;
    const iconHeight = height / decreaseIn;
    const coordinates = [
      artworksLocation.coordinates.latitude,
      artworksLocation.coordinates.longitude,
    ];

    setCenter(artworksLocation ? coordinates : NEW_YORK_COORDINATES);
    setArtworkItemLocation({
      url,
      iconWidth,
      iconHeight,
      coordinates,
    });
  }, [activeTab]);

  return (
    <div className={className || ""}>
      {center && (
        <>
          <MapContainer
            center={center}
            zoom={zoom}
            className={className}
            scrollWheelZoom={false}
            worldCopyJump
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
              minZoom={3}
            />
            {locations &&
              // eslint-disable-next-line @typescript-eslint/no-shadow
              locations.slice(0, mapItemLimit).map(location => {
                const { _id } = location;
                const { url } = location.image.thumbnail;
                const { medium } = location.image;
                const { width, height } = medium;
                const iconWidth = width / decreaseIn;
                const iconHeight = height / decreaseIn;
                const artworksLocation =
                  location[locationType] || getLocations(activeTab, location);
                return (
                  artworksLocation && (
                    <Marker
                      key={_id}
                      position={[
                        artworksLocation.coordinates.latitude,
                        artworksLocation.coordinates.longitude,
                      ]}
                      icon={createMapIcon(url, iconWidth, iconHeight)}
                      eventHandlers={{
                        click: () =>
                          block ? openModal() : goToDetails("/explore/artworks", _id, location),
                      }}
                    />
                  )
                );
              })}
            {artworkItemLocation && (
              <Marker
                position={artworkItemLocation.coordinates}
                icon={createMapIcon(
                  artworkItemLocation.url,
                  artworkItemLocation.iconWidth,
                  artworkItemLocation.iconHeight,
                )}
              />
            )}
            {exhibitions && displayExhibitions(exhibitions, createMapIcon, goToDetails, openModal)}
            {location && <ChangeMapCenter />}
            {mapPage && (
              <ChangeLocationListener
                filterLocation={filterLocation}
                searchThisArea={searchThisArea}
              />
            )}
          </MapContainer>
        </>
      )}
    </div>
  );
};

export default LeafletMap;
