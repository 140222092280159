export const IMAGE = `
_id
thumbnail {
  url
  width
  height
}
medium {
  url
  width
  height
}
origin {
  url
  width
  height
}`;
