import { SearchActionTypes, SearchState, SearchType } from "../types/search";

const initialValue: SearchState = {
  searchValue: "",
  searchModal: false,
};

const searchReducer = (state = initialValue, action: SearchType): SearchState => {
  switch (action.type) {
    case SearchActionTypes.SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case SearchActionTypes.SEARCH_MODAL:
      return { ...state, searchModal: action.payload };
    default:
      return { ...state };
  }
};

export default searchReducer;
