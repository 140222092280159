export interface InitialState {
  refetch: boolean;
}

export enum RefetchTypes {
  REFETCH_TOGGLE = "REFETCH_TOGGLE",
}

export interface SetRefetchAction {
  type: RefetchTypes.REFETCH_TOGGLE;
}

export type RefetchAction = SetRefetchAction;
