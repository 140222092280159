import io from "socket.io-client";

export default {
  socket: null,
  init(token) {
    if (process.env.REACT_APP_SOCKET_URL) {
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        transportOptions: {
          polling: {
            extraHeaders: {
              token,
            },
          },
        },
      });
    }
  },

  logSocket() {
    console.log(this.socket);
  },

  onMessageReceived(callback) {
    this.socket?.on("data", data => callback(data));
  },

  closeConnection() {
    this.socket?.disconnect();
  },

  async sendMessage(text, recipientId) {
    await this.socket?.emit("data", {
      command: "addChatMessage",
      text,
      recipientId,
    });
  },
};
