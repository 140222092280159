import { IResultButton } from "../../components/filter-units/utils";
import { IArtwork, SearchArtworksRequest } from "../../types/artwork/artwork";
import { IFilter, IFilterCategory, ISortOption } from "../../types/filterTypes";
import { ILocationFilter } from "./artistsPageTypes";

export interface IArtworkFilterState {
  categories: IFilterCategory[];
  filterResults: IResultButton[];
  filterQueryParams: any;
  offset: number;
  artworks: IArtwork[];
  materials: IFilter[];
  colors: IFilter[];
  widthMinValue?: number;
  widthMaxValue?: number;
  heightMinValue?: number;
  heightMaxValue?: number;
  originsOfInspiration: any[];
  sortValue: any;
  location: ILocationFilter | null;
  radius: number;
}

export enum ArtworkFilterActionTypes {
  SET_SORT_VALUE = "SET_SORT_VALUE",
  SET_FILTER_QUERY_PARAMS = "SET_FILTER_QUERY_PARAMS",
  SET_ARTWORKS = "SET_ARTWORKS",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_MATERIALS = "SET_MATERIALS",
  SET_COLORS = "SET_COLORS",
  SET_OFFSET = "SET_OFFSET",
  SET_FILTER_RESULTS = "SET_FILTER_RESULTS",
  SET_WIDTH_MIN_VALUE = "SET_WIDTH_MIN_VALUE",
  SET_WIDTH_MAX_VALUE = "SET_WIDTH_MAX_VALUE",
  SET_HEIGHT_MIN_VALUE = "SET_HEIGHT_MIN_VALUE",
  SET_HEIGHT_MAX_VALUE = "SET_HEIGHT_MAX_VALUE",
  SET_ORIGINS_OF_INSPIRATION = "SET_ORIGINS_OF_INSPIRATION",
  CLEAR_CONTEXT = "CLEAR_CONTEXT",
  SET_LOCATION_FILTER = "SET_LOCATION",
  SET_RADIUS = "SET_RADIUS",
  DELETE_ARTWORK = "DELETE_ARTWORK",
}

export interface setSortValue {
  type: ArtworkFilterActionTypes.SET_SORT_VALUE;
  payload: ISortOption;
}

export interface setfilterQueryParams {
  type: ArtworkFilterActionTypes.SET_FILTER_QUERY_PARAMS;
  payload: SearchArtworksRequest;
}

export interface setArtworks {
  type: ArtworkFilterActionTypes.SET_ARTWORKS;
  payload: IArtwork[];
}

export interface setCategories {
  type: ArtworkFilterActionTypes.SET_CATEGORIES;
  payload: IFilterCategory[];
}

export interface setMaterials {
  type: ArtworkFilterActionTypes.SET_MATERIALS;
  payload: IFilter[];
}

export interface setColors {
  type: ArtworkFilterActionTypes.SET_COLORS;
  payload: IFilter[];
}

export interface setOffset {
  type: ArtworkFilterActionTypes.SET_OFFSET;
  payload: number;
}

export interface setFilterResults {
  type: ArtworkFilterActionTypes.SET_FILTER_RESULTS;
  payload: IResultButton[];
}

export interface setWidthMinValue {
  type: ArtworkFilterActionTypes.SET_WIDTH_MIN_VALUE;
  payload: number;
}

export interface setWidthMaxValue {
  type: ArtworkFilterActionTypes.SET_WIDTH_MAX_VALUE;
  payload: number;
}

export interface setHeightMinValue {
  type: ArtworkFilterActionTypes.SET_HEIGHT_MIN_VALUE;
  payload: number;
}

export interface setHeightMaxValue {
  type: ArtworkFilterActionTypes.SET_HEIGHT_MAX_VALUE;
  payload: number;
}

export interface setOriginsOfInspiration {
  type: ArtworkFilterActionTypes.SET_ORIGINS_OF_INSPIRATION;
  payload: IFilter[];
}

export interface clearContext {
  type: ArtworkFilterActionTypes.CLEAR_CONTEXT;
}

export interface setLocationFilter {
  type: ArtworkFilterActionTypes.SET_LOCATION_FILTER;
  payload: ILocationFilter | null;
}

export interface setRadius {
  type: ArtworkFilterActionTypes.SET_RADIUS;
  payload: number;
}

export interface deleteArtwork {
  type: ArtworkFilterActionTypes.DELETE_ARTWORK;
  payload: string;
}

export type ArtworkFilterActions =
  | setSortValue
  | setfilterQueryParams
  | setArtworks
  | setCategories
  | setMaterials
  | setColors
  | setOffset
  | setFilterResults
  | setWidthMinValue
  | setWidthMaxValue
  | setHeightMinValue
  | setHeightMaxValue
  | setOriginsOfInspiration
  | clearContext
  | setLocationFilter
  | setRadius
  | deleteArtwork;
