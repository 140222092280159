import { Avatar } from "@mui/material";
import React, { FC } from "react";

import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import "./ChatItem.scss";
import { IChat } from "../../types/chat";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

interface IChatItemProps {
  chat: IChat;
  setCurrentChat: (chat: IChat) => void;
}

const ChatItem: FC<IChatItemProps> = ({ chat, setCurrentChat }) => {
  const { currentChat, unreadMessagesCount, chats } = useTypedSelector(
    state => state.messagesInfoReducer,
  );
  const { setChatsPrevStage, setUnreadMessagesCount, setChats } = useActions();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const guest = chat.participants.find(user => user.participant._id !== myMOCAId);
  const me = chat.participants.find(user => user.participant._id === myMOCAId);

  if (!guest) return null;

  const { image, firstName, secondName } = guest.participant.profile;
  const unreadCount = me?.unreadCount;

  const isCurrent =
    chat._id === currentChat?._id ||
    currentChat?.participants.find(({ participant }) => participant._id === guest.participant._id);

  const chatItemBackground = isCurrent ? "#fafafa" : "#fff";

  const participantName =
    guest.participant.hasInstitution && !!guest.participant.institutions.length
      ? guest.participant.institutions[0]?.name
      : `${firstName} ${secondName}`;

  const setChat = (): void => {
    if (isCurrent) return;
    if (unreadCount)
      setTimeout(
        () =>
          setUnreadMessagesCount(
            unreadMessagesCount - unreadCount >= 0 ? unreadMessagesCount - unreadCount : 0,
          ),
        500,
      );
    if (!me) return;
    const updatedChats = chats.map(item => {
      if (item._id !== chat._id) return item;

      const updatedChat = {
        ...item,
        participants: item.participants.filter(
          participant => participant.participant._id !== myMOCAId,
        ) as any,
      };
      updatedChat.participants.push({ ...me, unreadCount: 0 });
      return updatedChat;
    });
    setChats(updatedChats);

    setChatsPrevStage("chats-list");
    setCurrentChat(chat);
  };

  return (
    <div className="chat-item" style={{ backgroundColor: chatItemBackground }} onClick={setChat}>
      {image?.thumbnail?.url ? (
        <Avatar src={image.thumbnail.url} className="guest-avatar" sx={{ width: 32, height: 32 }} />
      ) : (
        <AvatarPlaceholder
          className="guest-avatar-placeholder"
          id={guest.participant._id}
          width="50px"
          height="50px"
          fontSize="8px"
        />
      )}
      <div className="chat-info">
        <p>{participantName}</p>
        <p className="last-message">{chat.messages[chat.messages.length - 1].text}</p>
      </div>
      {!!unreadCount && <span className="unread-messages-count">{unreadCount}</span>}
    </div>
  );
};

export default ChatItem;
