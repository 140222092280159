import { IUser } from "../../types/userTypes";

export interface AuthorizedUserState {
  me: IUser | null;
}

export enum AuthorizedUserActionTypes {
  SET_AUTHORIZED_USER = "SET_AUTHORIZED_USER",
}

interface SetAuthorizedUser {
  type: AuthorizedUserActionTypes.SET_AUTHORIZED_USER;
  payload: IUser | null;
}

export type AuthorizedUserAction = SetAuthorizedUser;
