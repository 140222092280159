import { PREDEFINED_ARTWORKS_SORT } from "../../misc/constants";
import {
  ArtworkFilterActions,
  ArtworkFilterActionTypes,
  IArtworkFilterState,
} from "../../context/types/artworkFilterTypes";

export const initialState: IArtworkFilterState = {
  sortValue: PREDEFINED_ARTWORKS_SORT[0],
  filterQueryParams: PREDEFINED_ARTWORKS_SORT[0],
  artworks: [],
  categories: [],
  materials: [],
  colors: [],
  offset: 0,
  filterResults: [],
  widthMinValue: undefined,
  widthMaxValue: undefined,
  heightMinValue: undefined,
  heightMaxValue: undefined,
  originsOfInspiration: [],
  location: null,
  radius: 10,
};

export const artworkSearchFilterReducer = (
  state = initialState,
  action: ArtworkFilterActions,
): IArtworkFilterState => {
  switch (action.type) {
    case ArtworkFilterActionTypes.SET_SORT_VALUE:
      return {
        ...state,
        sortValue: action.payload,
      };
    case ArtworkFilterActionTypes.SET_FILTER_QUERY_PARAMS: {
      return {
        ...state,
        filterQueryParams: {
          ...action.payload,
        },
      };
    }
    case ArtworkFilterActionTypes.SET_ARTWORKS:
      return {
        ...state,
        artworks: [...action.payload],
      };
    case ArtworkFilterActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
      };
    case ArtworkFilterActionTypes.SET_MATERIALS:
      return {
        ...state,
        materials: [...action.payload],
      };
    case ArtworkFilterActionTypes.SET_COLORS:
      return {
        ...state,
        colors: [...action.payload],
      };
    case ArtworkFilterActionTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case ArtworkFilterActionTypes.SET_FILTER_RESULTS:
      return {
        ...state,
        filterResults: [...action.payload],
      };
    case ArtworkFilterActionTypes.SET_WIDTH_MIN_VALUE:
      return {
        ...state,
        widthMinValue: action.payload,
      };
    case ArtworkFilterActionTypes.SET_WIDTH_MAX_VALUE:
      return {
        ...state,
        widthMaxValue: action.payload,
      };
    case ArtworkFilterActionTypes.SET_HEIGHT_MIN_VALUE:
      return {
        ...state,
        heightMinValue: action.payload,
      };
    case ArtworkFilterActionTypes.SET_HEIGHT_MAX_VALUE:
      return {
        ...state,
        heightMaxValue: action.payload,
      };
    case ArtworkFilterActionTypes.SET_ORIGINS_OF_INSPIRATION:
      return {
        ...state,
        originsOfInspiration: [...action.payload],
      };
    case ArtworkFilterActionTypes.SET_LOCATION_FILTER:
      return {
        ...state,
        location: action.payload,
      };
    case ArtworkFilterActionTypes.CLEAR_CONTEXT:
      return {
        ...initialState,
      };
    case ArtworkFilterActionTypes.SET_RADIUS:
      return {
        ...state,
        radius: action.payload,
      };
    case ArtworkFilterActionTypes.DELETE_ARTWORK:
      return {
        ...state,
        artworks: [...state.artworks.filter(item => item._id !== action.payload)],
      };
    default:
      return state;
  }
};
