export interface LoginState {
  loginFlag: boolean;
}

export enum LoginActionTypes {
  LOGIN = "LOGIN",
}

interface LogInAction {
  type: LoginActionTypes.LOGIN;
}

export type LoginAction = LogInAction;
