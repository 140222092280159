import React, { FC } from "react";
import ChatMessageSkeleton from "./ChatMessageSkeleton";

const ChatMessagesSkeleton: FC = () => {
  return (
    <div className="chat_messages_placeholders">
      <ChatMessageSkeleton size="small" />
      <ChatMessageSkeleton myMessage size="medium" />
      <ChatMessageSkeleton size="small" />
      <ChatMessageSkeleton myMessage size="small" />
      <ChatMessageSkeleton myMessage size="large" />
    </div>
  );
};

export default ChatMessagesSkeleton;
