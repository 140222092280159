import { useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { USER_FOLLOWINGS } from "../../../../api-queries/UserRequests";
import { useCreateTransferContext } from "../../../../context/createTransferContext";
import { IInstitution } from "../../../../types/institution";
import { IUser } from "../../../../types/userTypes";
import Button from "../../../../UI/button/Button";
import BackButton from "../../../back-button/BackButton";
import UserListItem from "../../../user-list-item/UserListItem";
import { handleSearch } from "../utils";

import "./SearchNewCollectorStage.scss";

interface SearchStageProps {
  close: () => void;
  setChosenCollector: (param: any) => void;
}

const searchTypes = ["Operators", "Institutions"];

const SearchNewCollectorStage: React.FC<SearchStageProps> = ({ close, setChosenCollector }) => {
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("myMOCAId") as any;
  const { searchResults, setSearchResults, searchType, setSearchType, searchText, setSearchText } =
    useCreateTransferContext();
  const inputRef = useRef<HTMLInputElement>(searchText);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = searchText;
    }
  }, []);

  const { data, loading: followingsLoading } = useQuery(USER_FOLLOWINGS, {
    variables: { userId, limit: 20 },
  });

  const search = async (value: string): Promise<void> => {
    if (!value && searchType === "Operators") {
      setSearchResults(data?.followings?.followings || []);
      return;
    }
    setLoading(true);
    const res = await handleSearch(searchType, value);
    if (searchType === "Operators") {
      setSearchResults(res.searchArtists.artists);
      setLoading(false);
      return;
    }
    setSearchResults(res.searchInstitutions.institutions);
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSearchText(value);
    search(value);
  };

  const debouncedSearch = debounce(handleChange, 600);

  const renderResults = useCallback((): any => {
    if (searchType === "Operators") {
      return (
        <>
          {searchResults.map((artist: IUser) => (
            <UserListItem
              key={artist._id}
              profile={artist?.profile}
              id={artist?._id}
              transferModal
              onClick={() => setChosenCollector(artist)}
            />
          ))}
        </>
      );
    }
    return (
      <>
        {searchResults.map((institution: IInstitution) => (
          <UserListItem
            key={institution.name + institution.owner._id}
            profile={institution?.owner?.profile}
            isInstitution
            institutionName={institution.name}
            id={institution.name + institution.owner._id}
            transferModal
            onClick={() => setChosenCollector(institution)}
          />
        ))}
      </>
    );
  }, [searchResults]);

  useEffect(() => {
    search(searchText);
  }, [searchType]);

  useEffect(() => {
    if (data?.followings && !searchText) {
      setSearchResults(data?.followings?.followings);
    }
  }, [data]);

  const resultsNotFound = !searchResults.length && !loading && !followingsLoading;

  return (
    <>
      <h3 className="create-transfer_title">
        <BackButton className="" callback={close} /> Transfer Artwork
      </h3>
      <div className="search-type-switchers">
        {searchTypes.map(type => (
          <Button
            key={type}
            className="type-switcher"
            variant={type === searchType ? "button-dark" : "text"}
            onClick={() => setSearchType(type)}
            disabled={loading}
          >
            {type}
          </Button>
        ))}
      </div>
      <input
        type="text"
        className="create-transfer_search"
        placeholder="Search for a new collector"
        ref={inputRef}
        onChange={debouncedSearch}
      />
      <div className="search-results">{renderResults()}</div>
      {resultsNotFound && (
        <p className="no-results">
          {searchType === "Operators" ? "No operators found" : "No institutions found"}
        </p>
      )}
    </>
  );
};

export default SearchNewCollectorStage;
