import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import uniqid from "uniqid";

import { SearchModalProps } from "../../Search.type";
import { searchResult } from "../../../../route-link";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";

import "./SearchModal.scss";

/**
 * The Search Component
 * @typedef Props:
 * @property {array} searchList - placeholder text
 * @property {function} setSearchModalShow - close modal search
 */

const SearchModal: React.FC<SearchModalProps> = ({ searchList, inputRef }): JSX.Element => {
  const { setSearch, setSearchModal } = useActions();
  const { searchValue } = useTypedSelector(state => state.searchReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const artists = searchList[0]?.searchArtists?.artists.slice(0, 3);
  const artworks = searchList[1]?.searchArtworks?.artworks.slice(0, 3);
  const exhibitions = searchList[2]?.searchExhibitions?.exhibitions.slice(0, 3);
  const institutions = searchList[3]?.searchInstitutions?.institutions.slice(0, 3);
  const collectors = searchList[4]?.searchArtists?.artists.slice(0, 3);
  const operators = searchList[5]?.searchArtists?.artists.slice(0, 3);
  const searchResultNavigate = async (): Promise<void> => {
    if (location.pathname !== `/${searchResult}`) {
      sessionStorage.setItem("searchPageBackUrl", JSON.stringify(location.pathname));
    }
    await navigate(searchResult, {
      state: { text: searchValue },
    });
    setSearch("");
    // eslint-disable-next-line no-param-reassign
    inputRef.current.value = "";
    setSearchModal(false);
  };

  const linkClick = (): void => {
    // eslint-disable-next-line no-param-reassign
    inputRef.current.value = "";
    setSearchModal(false);
  };

  return (
    <div className="search_modal">
      <div className="search_modal__grid">
        <div className="search_modal__all__results">
          <div className="search_modal__button__all" onClick={searchResultNavigate}>
            View all results
          </div>
        </div>
        {!!artists?.length && (
          <div className="search_modal__list__wrap">
            <h4 className="search_modal__list__title">Artist</h4>
            <div className="search_list__item__wrap">
              {artists.map((el: any) => (
                <Link
                  to={`/explore/artists/${el._id}`}
                  onClick={linkClick}
                  key={uniqid()}
                  className="search_modal__list__item"
                >
                  {el.profile.firstName} {el.profile.secondName}
                </Link>
              ))}
            </div>
          </div>
        )}

        {!!artworks?.length && (
          <div className="search_modal__list__wrap">
            <h4 className="search_modal__list__title">Artworks</h4>
            <div className="search_list__item__wrap">
              {artworks.map((el: any) => {
                return (
                  <Link
                    to={`/explore/artworks/${el._id}`}
                    onClick={linkClick}
                    key={el._id}
                    className="search_modal__list__item"
                  >
                    {el.name || "No name"}
                  </Link>
                );
              })}
            </div>
          </div>
        )}

        {!!exhibitions?.length && (
          <div className="search_modal__list__wrap">
            <h4 className="search_modal__list__title">Shows</h4>
            <div className="search_list__item__wrap">
              {exhibitions?.map((el: any) => (
                <Link
                  to={`/explore/shows/${el._id}`}
                  onClick={linkClick}
                  key={el._id}
                  className="search_modal__list__item"
                >
                  {el.title}
                </Link>
              ))}
            </div>
          </div>
        )}
        {!!institutions?.length && (
          <div className="search_modal__list__wrap">
            <h4 className="search_modal__list__title">Institutions</h4>
            <div className="search_list__item__wrap">
              {institutions?.map((el: any) => {
                return (
                  <Link
                    to={`explore/artists/${el.owner._id}`}
                    key={el._id}
                    className="search_modal__list__item"
                    onClick={linkClick}
                  >
                    {el.name}
                  </Link>
                );
              })}
            </div>
          </div>
        )}

        {!!collectors?.length && (
          <div className="search_modal__list__wrap">
            <h4 className="search_modal__list__title">Collectors</h4>
            <div className="search_list__item__wrap">
              {collectors.map((el: any) => {
                return (
                  <Link
                    to={`/explore/artists/${el._id}`}
                    key={el._id}
                    className="search_modal__list__item"
                    onClick={linkClick}
                  >
                    {el.profile.firstName} {el.profile.secondName}
                  </Link>
                );
              })}
            </div>
          </div>
        )}

        {!!operators?.length && (
          <div className="search_modal__list__wrap">
            <h4 className="search_modal__list__title">Operators</h4>
            <div className="search_list__item__wrap">
              {operators.map((el: any) => {
                return (
                  <Link
                    to={`/explore/artists/${el._id}`}
                    key={el._id}
                    className="search_modal__list__item"
                    onClick={linkClick}
                  >
                    {el.profile.firstName} {el.profile.secondName}
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
