import React, { useState, FC } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface MoreVertMenuProps {
  onRemove: () => void;
}

const MoreVertMenu: FC<MoreVertMenuProps> = ({ onRemove }) => {
  const ITEM_HEIGHT = 48;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const options = ["Remove"];

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleAction = (): void => {
    onRemove();
    handleClose();
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map(option => (
          <MenuItem key={option} onClick={handleAction}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MoreVertMenu;
