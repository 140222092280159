import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "./SliderArrows.scss";
import cn from "classnames";

const SliderArrows = ({ type, onClick, isEdge }: any): any => {
  const pointer = type === "PREV" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;

  return (
    <button
      className={cn("slider_arrow_button", {
        prev: type === "PREV",
        next: type === "NEXT",
      })}
      onClick={onClick}
      disabled={isEdge}
    >
      {pointer}
    </button>
  );
};

export default SliderArrows;
