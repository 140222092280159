import React, { FC } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useActions";

import { IOwner } from "../../types/owner";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { artists, explore } from "../../route-link";

import "./CollectedByUser.scss";

interface CollectedByUserProps {
  collector: IOwner;
}

const CollectedByUser: FC<CollectedByUserProps> = ({ collector }) => {
  const imageUrl = collector?.profile?.image?.thumbnail?.url;
  const id = collector?._id;
  const navigate = useNavigate();
  const { openModal } = useActions();
  const isInstitution = Boolean(collector?.hasInstitution && collector?.institutions.length);
  const fullName = isInstitution
    ? collector?.institutions[0]?.name
    : `${collector?.profile?.firstName} ${collector?.profile?.middleName || ""} ${
        collector?.profile?.secondName
      }`;
  const goToProfile = (): void => {
    if (isInstitution) {
      openModal();
      return;
    }
    navigate(`/${explore}/${artists}/${id}`);
    window.scroll(0, 0);
  };

  return (
    collector && (
      <div onClick={goToProfile} className="collected_by_user">
        <span className="collected_by_user__text">Collected by:</span>
        {imageUrl ? (
          <Avatar
            className="collected_by_user__avatar"
            src={imageUrl}
            sx={{ width: 25, height: 25 }}
          />
        ) : (
          <AvatarPlaceholder
            className="collected_by_user__avatar"
            id={id}
            width="25px"
            height="25px"
            fontSize="8px"
          />
        )}
        <span className="collected_by_user__name">{fullName}</span>
      </div>
    )
  );
};

export default CollectedByUser;
