import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { updateArtwork } from "../../../api-queries/GraohQlClient";
import { ILocationFilter } from "../../../context/types/artistsPageTypes";

import { IArtwork } from "../../../types/artwork/artwork";
import Button from "../../../UI/button/Button";
import InputField from "../../../UI/input-field/InputField";
import DialogWindow from "../../dialog-window/DialogWindow";
import { getCoordinates } from "../../location-filter-select/utils";
import LocationAutocomplete from "../../locations-autocomplete/LocationAutocomplete";

import "./EditCollectedArtworkModal.scss";

interface EditCollectedArtworkModalProps {
  artworkId: string | undefined;
  artwork: IArtwork;
  isOpen: boolean;
  close: () => void;
  refetch: () => void;
}

const EditCollectedArtworkModal: React.FC<EditCollectedArtworkModalProps> = ({
  artworkId,
  artwork,
  isOpen,
  close,
  refetch,
}) => {
  const initLocationLives = artwork?.locationLives?.name
    ? {
        label: artwork?.locationLives?.name,
        value: null,
      }
    : null;

  const [locationLives, setLocationLives] = useState<ILocationFilter | null>(initLocationLives);
  const [saleable, setSaleable] = useState(artwork?.saleable);
  const [hasFilmsOrTv, setHasFilmsOrTv] = useState(artwork?.hasFilmsOrTv);
  const [price, setPrice] = useState(artwork?.minimumBid?.toString() || "");

  useEffect(() => {
    setLocationLives(initLocationLives);
    setSaleable(artwork?.saleable);
    setHasFilmsOrTv(artwork?.hasFilmsOrTv);
    setPrice(artwork?.minimumBid?.toString() || "");
  }, [artwork]);

  const handleEdit = async (): Promise<void> => {
    const query: any = {
      _id: artworkId,
      saleable,
      price: Number(price),
      hasFilmsOrTv,
    };
    const coordinates = locationLives ? await getCoordinates(locationLives) : null;
    query.locationLives = locationLives ? { name: locationLives.label, coordinates } : null;
    await updateArtwork(query);
    close();
    refetch();
  };

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value) || !e.target.value.length) {
      setPrice(e.target.value);
    }
  };

  return (
    <DialogWindow open={isOpen} handleClose={close} className="remove">
      <div className="edit_modal">
        <div className="edit_modal_title__wrap">
          <ArrowBackIcon onClick={close} className="edit_modal_back_button" />
          <div>
            <h2 className="edit_modal_title">Edit Artwork</h2>
            <div className="edit_modal_sub_title">I AM THE COLLECTOR</div>
          </div>
        </div>

        <InputLabel className="origin_label" variant="standard">
          LIVES AT
        </InputLabel>
        <LocationAutocomplete
          value={locationLives}
          className="edit_item__location"
          onChange={setLocationLives}
          onCloseIconClick={() => setLocationLives(null)}
          placeholder="Choose location..."
        />

        {artwork.allowUseForFilmAndTv && (
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                onChange={() => setHasFilmsOrTv(prev => !prev)}
                checked={hasFilmsOrTv}
                value="Use for films or TV"
                color="success"
              />
            }
            label="Use for films or TV"
          />
        )}
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={() => setSaleable(prev => !prev)}
              checked={saleable}
              value="This artwork for sale"
              color="success"
            />
          }
          label="This artwork for sale"
        />
        {saleable && (
          <div className="register_item">
            <InputField label="PRICE" value={price} onChange={handleChangePrice} />
          </div>
        )}
        <div className="edit_modal_buttons">
          <Button
            variant="button-dark"
            disabled={saleable && !Number(price)}
            className="accept_button"
            onClick={handleEdit}
          >
            SAVE CHANGES
          </Button>
        </div>
      </div>
    </DialogWindow>
  );
};

export default EditCollectedArtworkModal;
