import { BackUrlState, BackUrlType, BackUrlTypes } from "../types/back-url";

const initialState: BackUrlState = {
  backUrl: "",
};

export const backUrlReducer = (state = initialState, action: BackUrlType): BackUrlState => {
  switch (action.type) {
    case BackUrlTypes.BACK_URL:
      return { ...state, backUrl: action.payload };
    default:
      return { ...state };
  }
};
