import { InitialState, RefetchAction, RefetchTypes } from "../types/refetch";

const initialState: InitialState = {
  refetch: false,
};

const refetchReducer = (state = initialState, action: RefetchAction): InitialState => {
  switch (action.type) {
    case RefetchTypes.REFETCH_TOGGLE:
      return { ...state, refetch: !state.refetch };
    default:
      return state;
  }
};

export default refetchReducer;
