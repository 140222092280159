import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchTab from "../../UI/search/components/search-tab/SearchTab";

import "./SearchResult.scss";
import BackButton from "../../components/back-button/BackButton";

const SearchResult = (): JSX.Element => {
  const { state }: any = useLocation();
  const [backUrl, setBackUrl] = useState("");
  useEffect(() => {
    const url = sessionStorage.getItem("searchPageBackUrl");
    if (url) setBackUrl(JSON.parse(url));
  }, []);
  return (
    <div className="search_result">
      <div className="wrapper">
        <h2 className="search_result__title">
          <BackButton url={backUrl} className="search_result__back-button" />
          Results for "{state.text}"
        </h2>
      </div>
      <div className="search_result__body">
        <SearchTab />
      </div>
    </div>
  );
};

export default SearchResult;
