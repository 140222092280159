import React from "react";
import { Link } from "react-router-dom";

import { about } from "../../route-link";

import "./NavMenu.scss";

const NavMenu: React.FC = () => {
  return (
    <nav className="nav">
      <Link to={about} className="nav_item">
        about
      </Link>
    </nav>
  );
};

export default NavMenu;
