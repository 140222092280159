import React, { FC } from "react";
import { InputLabel, NativeSelect } from "@mui/material";

import { ISelectOption } from "./utils";

import "./SelectBasic.scss";

interface SelectBasicProps {
  options: ISelectOption[];
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder: string;
  label: string;
  isError?: boolean;
  errorMessage?: string;
}

const SelectBasic: FC<SelectBasicProps> = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  isError,
  errorMessage,
}) => {
  return (
    <div className="origin_item register_item basic_select">
      <InputLabel
        variant="standard"
        className="origin_label basic_select__label"
        htmlFor="uncontrolled-native"
      >
        {label}
      </InputLabel>
      <NativeSelect
        value={value}
        onChange={onChange}
        className="origin_select"
        defaultValue={placeholder}
        inputProps={{
          id: "uncontrolled-native",
        }}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map(item => (
          <option key={item.id} value={item.title.toLowerCase()}>
            {item.title}
          </option>
        ))}
      </NativeSelect>
      {isError && <span className="error_message">{errorMessage}</span>}
    </div>
  );
};

export default SelectBasic;
