import { IFilter } from "../../types/filterTypes";

export const refreshQueryOnDelete = (
  setDataItem: Function,
  filters: any[],
  filtersSetter: Function,
  id: string,
  getQueryValues: Function,
  filterQueryParams: any,
  setfilterQueryParams: Function,
  queryParam: string,
): void => {
  setDataItem([]);
  const newFilters = filters.map(filter => {
    if (filter.id === id) return { ...filter, checked: false };
    return filter;
  });
  const checkedFilters = newFilters.filter(filter => filter.checked);
  filtersSetter(newFilters);
  const queryValues = getQueryValues(newFilters);
  setfilterQueryParams({
    ...filterQueryParams,
    [queryParam]: checkedFilters.length ? queryValues : undefined,
  });
};

export const refreshRangeQuery = (
  setArtworks: Function,
  filterQueryParams: any[],
  setfilterQueryParams: Function,
  queryParam: string,
): void => {
  setArtworks([]);
  setfilterQueryParams({ ...filterQueryParams, [queryParam]: undefined });
};

export const refreshInspirationQueryOnDelete = (
  setDataItem: (arg: any[]) => {},
  originsOfInspiration: IFilter[],
  id: string,
  setOriginsOfInspiration: (arg: IFilter[]) => {},
  setfilterQueryParams: (arg: any) => {},
  filterQueryParams: IFilter[],
  filterQueryName: string,
): void => {
  setDataItem([]);
  const newOriginsOfInspiration = originsOfInspiration.filter(item => item.id !== id);
  const filterValues = newOriginsOfInspiration.map(item => item.value);
  setOriginsOfInspiration(newOriginsOfInspiration);
  setfilterQueryParams({ ...filterQueryParams, [filterQueryName]: filterValues });
};
