import uniqid from "uniqid";
import { ControllerItem } from "./LoginButtonMenu.props";
import {
  artFoundIdentified,
  artTransferred,
  artworksFound,
  collectionArt,
  createdArt,
} from "../../route-link";

export const artistsControllers = (id: string): Array<ControllerItem> => {
  return [
    {
      id: uniqid(),
      title: "My Shows",
      link: `explore/artists/${id}/shows`,
    },
    {
      id: uniqid(),
      title: "My Scans",
      link: `scans/${id}`,
    },
    {
      id: uniqid(),
      title: "My Found Artworks",
      link: `${artworksFound}/${id}`,
    },
    {
      id: uniqid(),
      title: "My Created Art",
      link: `${createdArt}/${id}`,
    },
    {
      id: uniqid(),
      title: "My Art Collection by other Artist",
      link: `${collectionArt}/${id}`,
    },
    {
      id: uniqid(),
      title: "Found Art I’ve identified",
      link: `${artFoundIdentified}/${id}`,
    },
    {
      id: uniqid(),
      title: "Art Transferred",
      link: `${artTransferred}/${id}`,
    },
  ];
};

export const institutionsControllers = (id: string): Array<ControllerItem> => {
  return [
    {
      id: uniqid(),
      title: "My Shows",
      link: `explore/artists/${id}/shows`,
    },
    {
      id: uniqid(),
      title: "My Scans",
      link: `scans/${id}`,
    },
    {
      id: uniqid(),
      title: "My Found Artworks",
      link: `${artworksFound}/${id}`,
    },
    {
      id: uniqid(),
      title: "My Art  Collection by other Artist",
      link: `${collectionArt}/${id}`,
    },
    {
      id: uniqid(),
      title: "Found Art I’ve identified",
      link: `${artFoundIdentified}/${id}`,
    },
    {
      id: uniqid(),
      title: "Art Transferred",
      link: `${artTransferred}/${id}`,
    },
  ];
};

export const artShowsControllers = (id: string): Array<ControllerItem> => {
  return [
    {
      id: uniqid(),
      title: "My Art nominated to The New York MoCa",
      link: `/campaigns/${id}`,
    },
    {
      id: uniqid(),
      title: "My Votes for Art into NYMoCa",
      link: `/votes/${id}`,
    },
  ];
};

export const getPendingActionsControllers = (
  id: string,
  transfersCount: number,
): Array<ControllerItem> => {
  return [
    {
      id: uniqid(),
      title: "Complete Unfinished Credentials",
      link: `unfinished-creds/${id}`,
    },
    {
      id: uniqid(),
      title: "My Submission To Art Shows",
      link: `shows-submissions/${id}`,
    },
    {
      id: uniqid(),
      title: "Incomplete Transfers",
      link: `incomplete-transfers/${id}`,
    },
    {
      id: uniqid(),
      title: "Submissions to my Art Shows for me to review",
      link: `submissions-review/${id}`,
    },
    {
      id: uniqid(),
      title: "My Art For Sale",
      link: `art-for-sale/${id}`,
    },
    {
      id: uniqid(),
      title: "Resale Art and Terms",
      link: `resale-art/${id}`,
    },
    {
      id: uniqid(),
      title: `Incoming Transfers ${transfersCount ? `(${transfersCount})` : ""}`,
      link: `incoming-transfers/${id}`,
    },
  ];
};
