import React, { FC, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useParams, useSearchParams } from "react-router-dom";

import { ArtistProfileResponse, getProfileTabs } from "./utils";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IParamsId } from "../artist-profile-artworks/utils";

import "./ArtistProfileArtworksTabs.scss";

interface IArtistProfileArtworksTabs {
  data: ArtistProfileResponse;
  isInstitution: boolean;
}

const ArtistProfileArtworksTabs: FC<IArtistProfileArtworksTabs> = ({ data, isInstitution }) => {
  const { setActiveArtworkTab, setArtworkTabs } = useActions();
  const { activeArtworkTab, profileArtworkTabs } = useTypedSelector(state => state.profileReducer);
  const { id }: IParamsId = useParams();
  const [searchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onTabClick = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setActiveArtworkTab(newValue);
  };

  useEffect(() => {
    if (!data) return;
    const profileTabs = getProfileTabs(data);
    setArtworkTabs(profileTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isInstitution, id, profileArtworkTabs.length]);

  useEffect(() => {
    if (profileArtworkTabs.length && !activeArtworkTab) {
      const tab = searchParams.get("tab");
      searchParams.delete("tab");
      const tabsValues = profileArtworkTabs.map(el => el.value);

      if (tab && tabsValues.includes(tab)) {
        setActiveArtworkTab(tab);
      } else {
        setActiveArtworkTab(profileArtworkTabs[0].value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileArtworkTabs, id]);
  return (
    <div className="artist_profile_tabs">
      {!!activeArtworkTab && (
        <Tabs
          className="artist_profile_tabs__tabs_container"
          value={activeArtworkTab}
          onChange={onTabClick}
        >
          {profileArtworkTabs.map(tab => (
            <Tab
              key={tab.value}
              className="artist_profile_tabs__tab_item"
              label={tab.title}
              value={tab.value}
            />
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default ArtistProfileArtworksTabs;
