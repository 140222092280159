import React, { FC, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "@apollo/client";
import Masonry from "react-masonry-css";
import { useLocation, useParams } from "react-router-dom";

import { USER_VOTES_INFO } from "../../api-queries/UserRequests";
import BackButton from "../../components/back-button/BackButton";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkItem from "../../components/artwork-item/ArtworkItem";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { UserVotesArtworksData } from "./utils";

import "./ProfileVotedArtworksPage.scss";
import LeafletMap from "../../components/leaflet-maps/LeafletMap";
import { pluralize } from "../../utils/pluralizeWord";
import { myVotedArtwork } from "../../route-link";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";

interface IProfileVotedArtworksPage {
  title?: string;
  controlsPage?: boolean;
}

const ProfileVotedArtworksPage: FC<IProfileVotedArtworksPage> = ({ title, controlsPage }) => {
  const limit = 20;
  const { userVotedArtworks, offset, userId } = useTypedSelector(
    store => store.userVotedArtworksReducer,
  );

  const { states } = useTypedSelector(state => state.prevStateReducer);

  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  const { setUserVotedArtworks, setVotedArtworksOffset, setUserId, addPrevState, removePrevState } =
    useActions();
  const routerLocation = useLocation();

  const { id } = useParams();
  const { data, fetchMore, refetch, loading } = useQuery(USER_VOTES_INFO, {
    variables: { userId, limit },
  });

  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();

  const votedArtworks: IArtwork[] = data?.favorites?.artworks;
  const showEmptyTitle = Boolean(!votedArtworks?.length && !loading);
  const total = votedArtworks?.length;

  const onScroll = async (): Promise<void> => {
    setVotedArtworksOffset(offset + limit);
    const newData: UserVotesArtworksData = await fetchMore({
      variables: { offset: offset + limit },
    });
    setUserVotedArtworks([...userVotedArtworks, ...newData.data.favorites.artworks]);
  };

  useEffect(() => {
    if (userId !== id) {
      setUserVotedArtworks([]);
      setUserId(id || "");
      setVotedArtworksOffset(0);

      refetch();
    }
  }, [id]);

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevState) {
      setUserVotedArtworks(prevState.state);
      setTimeout(() => window.scroll(0, prevState.scroll as number), 100);
      removePrevState(routerLocation.pathname);
      localStorage.removeItem("prevState");
      return;
    }

    if (prevLoadingState?.url === routerLocation.pathname) {
      setUserVotedArtworks(prevLoadingState.state);
      setTimeout(() => window.scroll(0, prevLoadingState.scroll), 100);
      localStorage.removeItem("prevState");
      return;
    }

    if (!userVotedArtworks?.length) {
      setUserId(id || "");
      setUserVotedArtworks(votedArtworks);
    }
  }, [votedArtworks]);

  const setPrevState = (): void => {
    setSldrAtrwrs(
      userVotedArtworks.map((el: IArtwork) => {
        return { id: el._id, artwork: el };
      }),
    );
    localStorage.setItem(
      "prevState",
      JSON.stringify({
        state: userVotedArtworks,
        url: routerLocation.pathname,
        scroll: window.scrollY,
      }),
    );
    addPrevState({
      url: routerLocation.pathname,
      scroll: window.scrollY,
      state: userVotedArtworks,
    });
  };

  return (
    <div className="wrapper profile-artworks-page profile-voted-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        {title}
      </h3>
      {controlsPage && (
        <LeafletMap
          locations={votedArtworks}
          zoom={3}
          location={undefined}
          exhibitions={undefined}
        />
      )}
      <div className="total">
        {!!total && (
          <span className="filter_results__result">
            {total} {pluralize("Artwork", total)}
          </span>
        )}
      </div>
      {userVotedArtworks && (
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={userVotedArtworks?.length}
          next={onScroll}
          hasMore
          loader
        >
          <div ref={widthBlock}>
            <Masonry
              ref={masonryColumns}
              breakpointCols={BREAKPOINT_COLUMNS_OBJ}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {userVotedArtworks?.map(artwork => (
                <div key={artwork._id}>
                  <ArtworkItem
                    artwork={artwork}
                    masonryColumns
                    widthBlock
                    url={`/explore/artworks/${artwork._id}/${myVotedArtwork}`}
                    saveState={setPrevState}
                  />
                </div>
              ))}
            </Masonry>
          </div>
          {showEmptyTitle && (
            <p className="profile-artworks-page__no-artworks">There are no artworks.</p>
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ProfileVotedArtworksPage;
