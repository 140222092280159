import { ICoordinates } from "../../types/addresTypes";

export interface CurrentLocationState {
  currentLocation?: ICoordinates;
}

export enum CurrentLocationTypes {
  SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION",
}

interface setCurrentLocation {
  type: CurrentLocationTypes.SET_CURRENT_LOCATION;
  payload: ICoordinates;
}

export type CurrentLocationAction = setCurrentLocation;
