import { FilterState, FilterAction, FilterActionTypes } from "../types/filter";

const initialValue: FilterState = {
  filterWindowOpen: false,
};

const filterReducer = (state = initialValue, action: FilterAction): FilterState => {
  switch (action.type) {
    case FilterActionTypes.FILTER_OPEN:
      return { ...state, filterWindowOpen: true };
    case FilterActionTypes.FILTER_CLOSE:
      return { ...state, filterWindowOpen: false };
    default:
      return state;
  }
};
export default filterReducer;
