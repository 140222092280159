import React, { useEffect } from "react";
import cn from "classnames";

import ButtonUp from "../../assets/images/ButtonUp";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import { useActions } from "../../hooks/useActions";
import "./ScrollButtonUp.scss";

const ScrollButtonUp = (): JSX.Element => {
  const { classesButton } = useTypedSelector(state => state.buttonUpReducer);
  const { setButtonUp } = useActions();
  const handleVisibleButton = (): void => {
    const position = window.pageYOffset;
    if (position > 500) {
      setButtonUp(true);
    } else {
      setButtonUp(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
    return () => {
      window.removeEventListener("scroll", handleVisibleButton);
    };
  }, []);
  const onTopButton = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={cn("button_top", {
        show: classesButton,
      })}
    >
      <ButtonUp width={50} onClick={onTopButton} />
    </div>
  );
};

export default ScrollButtonUp;
