import React, { FC } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Masonry from "react-masonry-css";

import { UserRequests } from "../../api-queries/AllRequests";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import CardItem from "../../components/card-item/CardItem";
import { IExhibitionOutput } from "../../types/exhibitions";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import BackButton from "../../components/back-button/BackButton";
import ItemsNotFound from "../../components/items-not-found/ItemsNotFound";

import "./ProfileShowsPage.scss";

const ProfileShowsPage: FC = () => {
  const { id }: IParamsId = useParams();
  const { data, loading } = useQuery(UserRequests.EXHIBITIONS_WITH_USERS, {
    variables: {
      limit: 100,
      includePast: true,
      users: [id],
    },
  });
  const hostShows: IExhibitionOutput[] = data?.hostShows?.exhibitions;
  const invitedShows: IExhibitionOutput[] = data?.invitedShows?.exhibitions;

  const renderShows = (shows: IExhibitionOutput[]): JSX.Element => {
    if (shows?.length) {
      return (
        <Masonry
          breakpointCols={BREAKPOINT_COLUMNS_OBJ}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {shows.map(show => (
            <CardItem
              key={show._id}
              id={show._id}
              img={show.image}
              name={show.title}
              location={show.location}
              isOpenToSubmitions={show.isOpenToSubmitions}
              duration={show.duration}
              data={show}
            />
          ))}
        </Masonry>
      );
    }
    return <ItemsNotFound title="No Shows" />;
  };

  if (loading) return null;
  return (
    <div className="wrapper">
      <div className="profile_shows">
        <div className="profile_shows__host">
          <h3 className="profile_shows__title">
            <BackButton className="profile_shows__back_button" />
            Host shows
          </h3>
          <div className="profile_shows__card_wrapper">{renderShows(hostShows)}</div>
        </div>

        <div className="profile_shows__invited">
          <h3 className="profile_shows__title">Invited shows</h3>
          <div className="profile_shows__card_wrapper">{renderShows(invitedShows)}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileShowsPage;
