import { LOCATION } from "./LocationRequestParams";

export const TRANSFER = `
_id
conditionReport
notes
tradeFor
tradeForValue
tradeForObject
loantTo {
    name
    date
}
loanTo
price
commission
saleable
locationLives {
    ${LOCATION}
}
locationCollected {
    ${LOCATION}
}
images {
    _id
    thumbnail {
        url
    }
    medium {
        url
    }
    origin {
        url
    }
}
availableForLoan
createdAt
type
`;
