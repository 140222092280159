import React, { FC, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import ArtistItem from "../../components/artist-item/ArtistItem";
import ExplorePageTitle from "../../components/explore-page-title/ExplorePageTitle";
import FilterItem from "../../components/filter-item/FilterItem";
import FilterOriginsOfInspiration from "../../components/filter-origins-of-inspiration/FilterOriginsOfInspiration";
import FilterResults from "../../components/filter-results/FilterResults";
import { IResultButton } from "../../components/filter-units/utils";
import Filter from "../../components/filter/Filter";
import ItemsNotFound from "../../components/items-not-found/ItemsNotFound";
import {
  SET_ARTISTS,
  SET_FILTER_QUERY_PARAMS_ARTISTS,
  SET_FILTER_RESULTS_ARTISTS,
  SET_GENDER_ARTISTS,
  SET_LOCATION_FILTER_ARTISTS,
  SET_OFFSET_ARTISTS,
  SET_ORIGINS_OF_INSPIRATION_ARTISTS,
  SET_RADIUS_ARTISTS,
  SET_SORT_VALUE_ARTISTS,
} from "../../context/action-creators/artistsPageActions";
import { ArtistsPageActions, ILocationFilter } from "../../context/types/artistsPageTypes";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { BREAKPOINT_COLUMNS_ARTISTS, CLOSEST_ARTISTS } from "../../misc/constants";
import { IFilter, IFilterTypes } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";
import SelectSort from "../../UI/select-sort/SelectSort";
import {
  artistsSortOptions,
  genderFilterOptions,
  IArtistSortValue,
  IGenderFilterOption,
  GenderFilterName,
  sortByClosest,
  getUniqueArtists,
} from "./utils";
import { useActions } from "../../hooks/useActions";
import LocationFilter from "../../components/location-filter/LocationFilter";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";

import { pluralize } from "../../utils/pluralizeWord";
import { SEARCH_USERS_LITE } from "../../api-queries/UserRequests";

import "./ArtistsPage.scss";
import FilterTopBar from "../../UI/filter-top-bar/FilterTopBar";
import { fetchMe } from "../../api-queries/GraohQlClient";

interface ArtistsPagePropsArtistsPageProps {
  dispatch: any;
  state: any;
  text: string;
  title?: boolean;
  resultPage?: boolean;
  types?: Array<string> | string;
  saveSearchPrevTab?: () => void;
}

const ArtistsPage: FC<ArtistsPagePropsArtistsPageProps> = ({
  title,
  dispatch,
  state,
  text,
  resultPage,
  types = ["artist"],
  saveSearchPrevTab,
}) => {
  const limit = 50;
  const {
    artists,
    offset,
    sortValue,
    filterQueryParams,
    filterResults,
    originsOfInspiration,
    gender,
    location,
    radius,
  } = state;
  const { currentLocation } = useTypedSelector(store => store.currentLocationReducer);
  const { filterWindowOpen } = useTypedSelector(store => store.filterReducer);
  const setArtists = (payload: IUser[]): void => dispatch(SET_ARTISTS(payload));
  const setOffset = (payload: number): ArtistsPageActions => dispatch(SET_OFFSET_ARTISTS(payload));
  const setfilterQueryParams = (payload: any): ArtistsPageActions =>
    dispatch(SET_FILTER_QUERY_PARAMS_ARTISTS(payload));
  const setSortValue = (payload: IArtistSortValue): ArtistsPageActions =>
    dispatch(SET_SORT_VALUE_ARTISTS(payload));
  const setFilterResults = (payload: IResultButton[]): ArtistsPageActions =>
    dispatch(SET_FILTER_RESULTS_ARTISTS(payload));
  const setOriginsOfInspiration = (payload: IFilter[]): ArtistsPageActions =>
    dispatch(SET_ORIGINS_OF_INSPIRATION_ARTISTS(payload));
  const setGender = (payload: IGenderFilterOption): ArtistsPageActions =>
    dispatch(SET_GENDER_ARTISTS(payload));
  const setLocation = (payload: ILocationFilter | null): ArtistsPageActions =>
    dispatch(SET_LOCATION_FILTER_ARTISTS(payload));
  const setRadius = (payload: number): ArtistsPageActions => dispatch(SET_RADIUS_ARTISTS(payload));
  const { setCurrentLocation, openFilter } = useActions();
  const variables = resultPage
    ? {
        offset: 0,
        limit,
        text,
        types,
        ...filterQueryParams,
      }
    : { offset: 0, limit, types: ["artist"], ...filterQueryParams };
  const [showFilters, setShowFilters] = useState(false);
  const uniqueArtists = getUniqueArtists(artists);

  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);

  const { addPrevState, removePrevState, setAuthorizedUser } = useActions();

  const routerLocation = useLocation();

  const { data, refetch } = useQuery(SEARCH_USERS_LITE, {
    variables,
  });
  const [isPositionLoading, setIsPositionLoading] = useState(false);

  const breakpointCols = {
    ...BREAKPOINT_COLUMNS_ARTISTS,
    default: filterWindowOpen ? 3 : 4,
    1900: filterWindowOpen ? 3 : 4,
    1100: filterWindowOpen ? 2 : 3,
    870: filterWindowOpen ? 1 : 2,
  };
  const total = data?.searchArtists?.meta?.total;
  const stateContext = {
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    filterResults: { filterResults, setFilterResults },
    originsOfInspiration: { originsOfInspiration, setOriginsOfInspiration },
    gender: { gender, setGender },
    location: { location, setLocation },
    offset: { offset, setOffset },
  };

  const setPrevState = (): void => {
    if (saveSearchPrevTab) saveSearchPrevTab();
    localStorage.setItem(
      "prevState",
      JSON.stringify({
        state: artists,
        url: routerLocation.pathname,
        scroll: window.scrollY,
        filtersState: { ...stateContext, filterWindowOpen },
      }),
    );
    addPrevState({
      url: routerLocation.pathname,
      state: artists,
      filtersState: stateContext,
      scroll: window.scrollY,
    });
  };

  const getMe = async (): Promise<void> => {
    const myData = await fetchMe();
    setAuthorizedUser(myData);
  };

  useEffect(() => {
    setArtists([]);
    refetch();
    getMe();
  }, [routerLocation]);

  const sortByArtists = async (value: IArtistSortValue): Promise<void> => {
    const { sortBy, orderBy, name } = value;
    const currentSortValue = sortValue;

    if (name === CLOSEST_ARTISTS.name) {
      sortByClosest(
        setIsPositionLoading,
        currentLocation,
        setCurrentLocation,
        setArtists,
        setOffset,
        setfilterQueryParams,
        filterQueryParams,
        setSortValue,
        currentSortValue,
      );
      return;
    }
    setArtists([]);
    setOffset(0);
    setfilterQueryParams({ ...filterQueryParams, sortBy, orderBy, sortByLocation: undefined });
  };

  const backToAllItems = (): void => {
    setFilterResults([]);
    setArtists([]);
    setOffset(0);
    setOriginsOfInspiration([]);
    setGender(genderFilterOptions[0]);
    setLocation(null);
    setfilterQueryParams({
      ...filterQueryParams,
      offset: 0,
      originsOfInspiration: undefined,
      sex: undefined,
      location: undefined,
    });
  };

  const onGenderSelectChange = (e: IGenderFilterOption): void => {
    const { name, value, id } = e;
    setOffset(0);
    setArtists([]);
    setfilterQueryParams({
      ...filterQueryParams,
      ...value,
    });

    const prevResult = filterResults.find((item: any) => item.type === IFilterTypes.Gender);
    if (name === GenderFilterName.ANY) {
      setFilterResults(filterResults.filter((item: any) => item.type !== IFilterTypes.Gender));
      return;
    }
    if (!prevResult) {
      setFilterResults([...filterResults, { id, title: name, type: IFilterTypes.Gender }]);
      return;
    }
    setFilterResults(
      filterResults.map((item: any) => {
        if (item.type === IFilterTypes.Gender) {
          return {
            ...item,
            title: name,
            id,
          };
        }
        return item;
      }),
    );
  };

  const renderSortValue = (value: IArtistSortValue): any => {
    if (isPositionLoading) {
      return (
        <span>
          <span className="sort_name">Sort by: {value.name}</span>
          <CircularProgress size={15} style={{ color: "#282828" }} />
        </span>
      );
    }
    return `Sort by: ${value.name}`;
  };

  const scrollHandler = async (): Promise<void> => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
      return;
    }
    if (artists.length >= limit) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setArtists([...artists, ...(res.data?.searchArtists?.artists || [])]);
    }
  };

  useEffect(() => {
    if (data?.searchArtists?.artists.length) setShowFilters(true);
    if (!artists.length) {
      setArtists([...artists, ...(data?.searchArtists?.artists || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refetchCollectors = async (): Promise<void> => {
    const res = await refetch({ text, types });
    setArtists([...res.data?.searchArtists.artists]);
  };

  const returnPersistedState = (prevState: any): void => {
    const { filtersState } = prevState;

    setArtists(prevState.state);
    setShowFilters(true);
    removePrevState(routerLocation.pathname);
    if (prevState.scroll) setTimeout(() => window.scroll(0, prevState.scroll), 0);
    localStorage.removeItem("prevState");
    if (!filtersState) return;
    if (filtersState.filterWindowOpen) openFilter();
    setFilterResults(filtersState.filterResults.filterResults);
    setfilterQueryParams(filtersState.filterQueryParams.filterQueryParams);
    setOffset(filtersState.offset.offset);
    setOriginsOfInspiration(filtersState.originsOfInspiration.originsOfInspiration);
    setLocation(filtersState.location.location);
  };

  useEffect(() => {
    setShowFilters(false);
    if (!filterResults.length) backToAllItems();
    const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
    if (prevState) {
      returnPersistedState(prevState);
      return;
    }
    const prevLoadingStateJSON = localStorage.getItem("prevState");

    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevLoadingState?.url === routerLocation.pathname) {
      returnPersistedState(prevLoadingState);
      return;
    }
    refetchCollectors();
  }, [text]);

  const showFiltersInfo = showFilters || filterResults.length > 0;

  return (
    <div className="artists_page wrapper">
      <ScrollToTop />
      {title && <ExplorePageTitle title="ARTISTS" myMoca />}
      {showFiltersInfo && (
        <FilterTopBar
          renderValue={renderSortValue}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setQueryValue={sortByArtists}
          options={artistsSortOptions}
        />
      )}
      <div
        className={classNames("explore_grid", {
          active: filterWindowOpen,
        })}
      >
        {showFiltersInfo && (
          <div className="left_side__bar">
            <div className="artworks_item__side-bar">
              <Filter>
                <FilterItem title="Location">
                  <LocationFilter
                    value={location}
                    radiusValue={radius}
                    setRadius={setRadius}
                    setLocation={setLocation}
                    context={stateContext}
                    setDataItem={setArtists}
                  />
                </FilterItem>
                <FilterItem title="Origins of inspiration">
                  <FilterOriginsOfInspiration
                    context={stateContext}
                    setItems={setArtists}
                    filterQueryName="originsOfInspiration"
                  />
                </FilterItem>
                <FilterItem title="Gender">
                  <SelectSort
                    className="artworks_item__gender_filter"
                    options={genderFilterOptions}
                    setQueryValue={onGenderSelectChange}
                    sortValue={gender}
                    setSortValue={setGender}
                    height={50}
                    width="100%"
                    renderValue={(value: IGenderFilterOption) => `${value.name}`}
                  />
                </FilterItem>
              </Filter>
            </div>
          </div>
        )}
        <div className="explore_list">
          {showFiltersInfo && (
            <div className="artists_page__filter_top_panel">
              <div className="artists_page__filter_left_block">
                <span className="artists_page__results">
                  {total?.toLocaleString()} {pluralize("Artist", total)}
                </span>
                {filterResults.length > 0 && (
                  <FilterResults
                    filterResults={filterResults}
                    setFilterResults={setFilterResults}
                    setDataItem={setArtists}
                    categoriesQuery="categories"
                    context={stateContext}
                    inspipationQuery="originsOfInspiration"
                  />
                )}
              </div>
            </div>
          )}
          {uniqueArtists && (
            <InfiniteScroll
              scrollThreshold={0.5}
              dataLength={artists.length}
              next={scrollHandler}
              hasMore
              loader=""
            >
              <Masonry
                breakpointCols={breakpointCols}
                className="artists_page__masonry_grid"
                columnClassName="artists_page__grid_column"
              >
                {uniqueArtists.map((artist: any) => {
                  return <ArtistItem saveState={setPrevState} artist={artist} key={artist._id} />;
                })}
              </Masonry>
            </InfiniteScroll>
          )}
          {total === 0 && <ItemsNotFound title="No results found" onClick={backToAllItems} />}
        </div>
      </div>
    </div>
  );
};

export default ArtistsPage;
