import { IMAGE } from "./ImageRequestParams";

export const INSTITUTION = `
_id
name
description
isVirtual
availableForFilmAndTV
availableForEvents
kategory {
  _id
  name
  description
}
imagesOfInstitution {
  _id
  thumbnail {
    url
    width
    height
  }
  medium {
    url
    width
    height
  }
  origin {
    url
    width
    height
  }
}
owner {
  _id
  hasInstitution
  profile {
    _id
    firstName
    secondName
    image {
      ${IMAGE}
    }
    address {
      location {
        coordinates {
            latitude
            longitude
        }
      }
    }
  }
}
exhibitions {
  _id
  title
  description
  private
  isSolo
  isOpenToSubmitions
  location {
    name
    coordinates {
      latitude
      longitude
    }
  }
  duration {
    from
    to
  }
  daysOpen {
    dayOfWeek
    hoursOpen {
      from
      to
    }
  }
  image {
    _id
    thumbnail {
      url
      width
      height
    }
    medium {
      url
      width
      height
    }
    origin {
      url
      width
      height
    }
  }
}`;
