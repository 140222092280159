/* eslint-disable no-nested-ternary */
import React from "react";

import { Link } from "react-router-dom";
import { artists, artworks, explore } from "../../route-link";
import TextTemplateNotification from "./TextTemplateNotification";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";
import Spider from "../../assets/images/spider.jpeg";
import { IArtwork } from "../../types/artwork/artwork";
import { IImage } from "../../types/imageData";

interface LikedArtworkNotificationProps {
  nameFollower: string;
  userId: string;
  time: Date;
  artwork: IArtwork | undefined;
  userImage: IImage;
}

const LikeArtworkNotification: React.FC<LikedArtworkNotificationProps> = ({
  nameFollower,
  time,
  userId,
  artwork,
  userImage,
}) => {
  const myMOCAId = localStorage.getItem("myMOCAId") || "";
  const nameUser = artwork?.owner.hasInstitution
    ? artwork.owner.institutions[0].name
    : artwork?.owner?.profile
    ? `${artwork.owner.profile.firstName} ${artwork.owner.profile.secondName}`
    : "";
  const linkNameUser = artwork?.owner?._id;
  const imageSrc = artwork?.image?.thumbnail.url || Spider;
  const nameArtwork = artwork?.name || "";
  const artworkId = artwork?._id || "";
  const isMyArtwork = artwork?.owner?._id === myMOCAId;
  return (
    <div className="notification_like__artwork">
      <TextTemplateNotification
        messageText={`liked artwork "${nameArtwork}" uploaded by `}
        nameFollower={nameFollower}
        linkNameFollower={`${explore}/${artists}/${userId}`}
        nameUser={isMyArtwork ? "You" : nameUser}
        linkNameUser={`${explore}/${artists}/${linkNameUser}`}
        userImage={userImage}
      />
      <NotificationTime time={time} />
      <Link to={`${explore}/${artworks}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </div>
  );
};

export default LikeArtworkNotification;
