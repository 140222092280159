import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";

export enum ArtistsPageActionTypes {
  SET_ARTISTS = "SET_ARTISTS",
  SET_OFFSET_ARTISTS = "SET_OFFSET_ARTISTS",
  SET_SORT_VALUE_ARTISTS = "SET_SORT_VALUE_ARTISTS",
  SET_FILTER_QUERY_PARAMS_ARTISTS = "SET_FILTER_QUERY_PARAMS_ARTISTS",
  SET_FILTER_RESULTS_ARTISTS = "SET_FILTER_RESULTS_ARTISTS",
  SET_ORIGINS_OF_INSPIRATION_ARTISTS = "SET_ORIGINS_OF_INSPIRATION_ARTISTS",
  SET_GENDER_ARTISTS = "SET_GENDER_ARTISTS",
  SET_LOCATION_FILTER_ARTISTS = "SET_LOCATION_ARTISTS",
  SET_RADIUS_ARTISTS = "SET_RADIUS_ARTISTS",
}

export interface ILocationFilter {
  label: string;
  value: any;
}

export interface IArtistsPageInitialState {
  artists: IUser[];
  offset: number;
  sortValue: IArtistSortValue;
  filterQueryParams: any;
  filterResults: IResultButton[];
  originsOfInspiration: IFilter[];
  gender: IGenderFilterOption;
  location: ILocationFilter | null;
  radius: number;
}

export interface setArtists {
  type: ArtistsPageActionTypes.SET_ARTISTS;
  payload: IUser[];
}

export interface setOffset {
  type: ArtistsPageActionTypes.SET_OFFSET_ARTISTS;
  payload: number;
}

export interface setSortValue {
  type: ArtistsPageActionTypes.SET_SORT_VALUE_ARTISTS;
  payload: IArtistSortValue;
}

export interface setFilterQueryParams {
  type: ArtistsPageActionTypes.SET_FILTER_QUERY_PARAMS_ARTISTS;
  payload: any;
}

export interface setFilterResults {
  type: ArtistsPageActionTypes.SET_FILTER_RESULTS_ARTISTS;
  payload: IResultButton[];
}

export interface setOriginsOfInspiration {
  type: ArtistsPageActionTypes.SET_ORIGINS_OF_INSPIRATION_ARTISTS;
  payload: IFilter[];
}

export interface setGender {
  type: ArtistsPageActionTypes.SET_GENDER_ARTISTS;
  payload: IGenderFilterOption;
}

export interface setLocationFilter {
  type: ArtistsPageActionTypes.SET_LOCATION_FILTER_ARTISTS;
  payload: ILocationFilter | null;
}

export interface setRadius {
  type: ArtistsPageActionTypes.SET_RADIUS_ARTISTS;
  payload: number;
}

export type ArtistsPageActions =
  | setArtists
  | setOffset
  | setSortValue
  | setFilterQueryParams
  | setFilterResults
  | setOriginsOfInspiration
  | setGender
  | setLocationFilter
  | setRadius;
