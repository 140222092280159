import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Modal from "react-modal";
import { isAndroid, isIOS } from "react-device-detect";

import Main from "./components/main/Main";
import ScrollButtonUp from "./UI/scroll-up-button/ScrollButtonUp";

import "./App.scss";
import {
  about,
  artists,
  artworks,
  buy,
  deepLink,
  editInstitutionProfile,
  editProfile,
  explore,
  institutions,
  location,
  lost,
  map,
  movieTV,
  originsOfInspiration,
  privacyPolicy,
  registrationOfInstitution,
  shows,
  submitArt,
  termsOfUse,
  upload,
  uploadUserImage,
  user,
  venues,
  vote,
  winners,
  uploadArtwork,
  editArtwork,
  uploadArtworkInfo,
  likedArtworks,
  followers,
  followings,
  votedArtworks,
  userScans,
  searchResult,
  artworksFound,
  createdArt,
  collectionArt,
  artFoundIdentified,
  artTransferred,
  campaigns,
  votes,
  editOriginsAndInspiration,
  artForSale,
  incomingTransfers,
  artResales,
  incompleteTransfers,
  showsSubmissions,
  unfinishedCreds,
  submissionsReview,
  lostViewMore,
  editUnfinishedArtwork,
  uploadedNow,
  blockedUsers,
  votesArtwork,
  myNominatedArtwork,
  myVotedArtwork,
  messagesRoute,
} from "./route-link";
import ExplorePage from "./pages/explore/ExplorePage";
import DeepLink from "./pages/deep-link/DeepLink";
import AboutPage from "./pages/about/AboutPage";
import MapPage from "./pages/map/MapPage";
import UploadPage from "./pages/upload/UploadPage";
import TermsOfUsePage from "./pages/terms-of-use/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicy";
import ArtWorksExplorePage from "./explore-pages/artworks-explore-page/ArtWorksExplorePage";
import ArtworksPage from "./explore-pages/artworks-page/ArtworksPage";
import MovieExplorePage from "./explore-pages/movie-explore-page/MovieExplorePage";
import VenuesExplorePage from "./explore-pages/venues-explore-page/VenuesExplorePage";
import { LostPage } from "./explore-pages/lost-page/LostPage";
import BuyPage from "./explore-pages/buy-page/BuyPage";
import ArtistProfile from "./pages/artist-profile/ArtistProfile";
import ProfileShowsPage from "./pages/profile-shows/ProfileShowsPage";
import UploadImage from "./pages/upload-image/UploadImage";
import OriginOfInspiration from "./pages/origins-of-inspiration/OriginOfInspiration";
import RegistrationInstitution from "./pages/registration-institution/RegistrationInstitution";
import ShowInfo from "./components/show-info/ShowInfo";
import InstitutionsExplorePage from "./explore-pages/institutions-explore-page/InstitutionsExplorePage";
import ShowsPageWrap from "./explore-pages/shows-page/ShowsPageWrap";
import SubmitArtExplorePage from "./explore-pages/submit-art-explore-page/SubmitArtExplorePage";
import TvArtExplorePage from "./explore-pages/tv-art-explore-page/TVArtExplorePage";
import VoteExplorePage from "./explore-pages/vote-explore-page/VoteExplorePage";
import Winners from "./explore-pages/vote-explore-page/pages/winners/Winners";
import EditProfile from "./pages/edit-profile/EditProfile";
import UploadArtwork from "./pages/upload-artwork/UploadArtwork";
import Edit from "./pages/edit/Edit";
import UploadArtworkInfo from "./pages/upload-artwork-info/UploadArtworkInfo";
import { useActions } from "./hooks/useActions";
import { useTypedSelector } from "./hooks/useTypedSelector";
import ModalDownload from "./components/modal-download/ModalDownload";
import Registration from "./components/registration/Registration";
import { modalWindowStyles } from "./styles/modalStyles";
import ProfileLikedArtworksPage from "./pages/profile-liked-artworks/ProfileLikedArtworksPage";
import { GetToken } from "./utils/get-token";
import UserFollowersModal from "./components/user-followers-modal/UserFollowersModal";
import { UserModalTitle } from "./components/user-followers-modal/UserFollowersModal.props";
import ProfileVotedArtworksPage from "./pages/profile-voted-artworks/ProfileVotedArtworksPage";
import ProfileScansPage from "./pages/profile-scans/ProfileScansPage";
import SearchResult from "./explore-pages/search-result/SearchResult";
import ArtistsWrap from "./explore-pages/artists-page/ArtistsWrap";
import Scans from "./pages/scans/Scans";
import ArtworksFoundProfile from "./pages/artworks-found-profile/ArtworksFoundProfile";
import CreatedArt from "./pages/created-art/CreatedArt";
import ArtCollection from "./pages/art-collection/ArtCollection";
import ArtFoundIdentified from "./pages/art-found-identified/ArtFoundIdentified";
import ArtTransferred from "./pages/art-transfered/ArtTransfered";
import { DynamicRoute } from "./utils/dynamic-route";
import Campaigns from "./pages/profile-campaigns/Campaigns";
import ArtworkDetailsSlider from "./components/artwork-details-slider/ArtworkDetailsSlider";
import { ArtworksSldrCntxProvider } from "./context/artworksSldrCntx";
import EditProfileInspirations from "./pages/edit-profile-inspirations/EditProfileInspirations";
import IncomingTransfersPage from "./pages/incoming-transfers/IncomingTransfersPage";
import IncompleteTransfersPage from "./pages/incomplete-transfers/IncompleteTransfersPage";
import ResaleArtAndTermsPage from "./pages/resale-art-and-terms/ResaleArtAndTermsPage";
import ArtForSale from "./pages/arts-for-sale/ArtForSalePage";
import ShowsSubmissionsPage from "./pages/shows-submissions/ShowsSubmissionsPage";
import IncompleteCredentialsPage from "./pages/incomplete-credentials/IncompleteCredentialsPage";
import SubmissionsReviewPage from "./pages/submissions-review/SubmissionsReviewPage";
import LostViewMorePage from "./explore-pages/lost-view-more-mape/LostViewMorePage";
import UsersBlockedByMePage from "./pages/blocked-users/UsersBlockedByMePage";
import MessagesPage from "./pages/messages-page/MessagesPage";

const App: React.FC = () => {
  const { modalWindow, modalWindowLogin } = useTypedSelector(state => state.modalReducer);
  const { closeModal, closeModalLogin, setSearchModal } = useActions();
  const token = GetToken();
  const onCloseSearchModal = (): void => {
    setSearchModal(false);
  };

  useEffect(() => {
    if (isAndroid) {
      const url = "intent://mymoca.art/#Intent;scheme=https;package=com.moca;end";

      window.location.replace(url);
    } else if (isIOS) {
      window.location.replace("moca://");

      setTimeout(() => {
        window.location.replace("https://apps.apple.com/us/app/my-moca/id1434062247");
      }, 1000);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App" onClick={onCloseSearchModal}>
        <Modal
          isOpen={modalWindow}
          onRequestClose={closeModal}
          style={modalWindowStyles}
          contentLabel="Example Modal"
          overlayClassName="modal_custom"
        >
          <ModalDownload closeModal={closeModal} />
        </Modal>
        <Registration isOpen={modalWindowLogin} closeModal={closeModalLogin} />
        <ScrollButtonUp />
        <ArtworksSldrCntxProvider>
          <Routes>
            {!token && <Route path="/" element={<DynamicRoute />} />}
            <Route path="/" element={<Main />}>
              {/* required for further use */}
              {/* <Route index element={<HomePage />} /> */}
              <Route path="/" element={<DynamicRoute />} />
              <Route path={explore} element={<ExplorePage />}>
                <Route index element={<ArtWorksExplorePage />} />
                <Route path={`${artists}`} element={<ArtistsWrap />} />
                <Route path={`${artworks}`} element={<ArtworksPage />} />
                <Route path={`${movieTV}`} element={<TvArtExplorePage />} />
                <Route path={`${location}`} element={<MovieExplorePage />} />
                <Route path={`${institutions}`} element={<InstitutionsExplorePage />} />
                <Route path={`${venues}`} element={<VenuesExplorePage />} />
                <Route path={`${lost}`} element={<LostPage />} />
                <Route path={`${lostViewMore}`} element={<LostViewMorePage />} />
                <Route path={`${buy}`} element={<BuyPage />} />
                <Route path={`${shows}`} element={<ShowsPageWrap />} />
                <Route path={`${submitArt}`} element={<SubmitArtExplorePage />} />
                <Route path={`${vote}`} element={<VoteExplorePage />} />
                <Route path={`${vote}/${winners}`} element={<Winners />} />
                <Route path={map} element={<MapPage />} />
                <Route path={`${artists}/:id`} element={<ArtistProfile />}>
                  <Route
                    path={followers}
                    element={<UserFollowersModal title={UserModalTitle.FOLLOWERS} />}
                  />
                  <Route
                    path={followings}
                    element={<UserFollowersModal title={UserModalTitle.FOLLOWINGS} />}
                  />
                </Route>
                <Route path={`${shows}/:id`} element={<ShowInfo />} />
                <Route
                  path={`${artists}/:id/${likedArtworks}`}
                  element={<ProfileLikedArtworksPage />}
                />
                <Route path={`${artists}/:id/${userScans}`} element={<ProfileScansPage />} />
                <Route
                  path={`${artists}/:id/${votedArtworks}`}
                  element={<ProfileVotedArtworksPage title="Voted artworks" />}
                />
                <Route
                  path={`${artists}/:id/${editInstitutionProfile}`}
                  element={<RegistrationInstitution isEditInstitution />}
                />
                <Route path={`${artists}/:id/${editProfile}`} element={<EditProfile />} />
                <Route
                  path={`/explore/${artists}/:id/${editOriginsAndInspiration}`}
                  element={<EditProfileInspirations />}
                />
                <Route path={`/explore/${blockedUsers}`} element={<UsersBlockedByMePage />} />
                <Route path={`${artworks}/:id/${editArtwork}`} element={<Edit />} />
                <Route
                  path={`${artworks}/:id/${editUnfinishedArtwork}`}
                  element={<Edit isUploadArtwork />}
                />
              </Route>
              <Route path={`${explore}/${artists}/:id/${shows}`} element={<ProfileShowsPage />} />
              <Route path={`${searchResult}`} element={<SearchResult />} />
              <Route path={deepLink} element={<DeepLink />} />
              <Route path={upload} element={<UploadPage />} />
              <Route path={about} element={<AboutPage />} />
              <Route path={termsOfUse} element={<TermsOfUsePage />} />
              <Route path={privacyPolicy} element={<PrivacyPolicyPage />} />
              <Route path={`${user}/${uploadUserImage}`} element={<UploadImage />} />
              <Route path={`${user}/${originsOfInspiration}`} element={<OriginOfInspiration />} />
              <Route path={`${uploadArtwork}`} element={<UploadArtwork />} />
              <Route path={`${uploadArtworkInfo}`} element={<UploadArtworkInfo />} />
              <Route path={`${userScans}/:id`} element={<Scans />} />
              <Route path={`${artworksFound}/:id`} element={<ArtworksFoundProfile />} />
              <Route path={`${createdArt}/:id`} element={<CreatedArt />} />
              <Route path={`${collectionArt}/:id`} element={<ArtCollection />} />
              <Route path={`${artFoundIdentified}/:id`} element={<ArtFoundIdentified />} />
              <Route path={`${artTransferred}/:id`} element={<ArtTransferred />} />
              <Route path={`${artForSale}/:id`} element={<ArtForSale />} />
              <Route path={`${campaigns}/:id`} element={<Campaigns />} />
              <Route path={`${incomingTransfers}/:id`} element={<IncomingTransfersPage />} />
              <Route path={`${artResales}/:id`} element={<ResaleArtAndTermsPage />} />
              <Route path={`${incompleteTransfers}/:id`} element={<IncompleteTransfersPage />} />
              <Route path={`${showsSubmissions}/:id`} element={<ShowsSubmissionsPage />} />
              <Route path={`${unfinishedCreds}/:id`} element={<IncompleteCredentialsPage />} />
              <Route path={`${submissionsReview}/:id`} element={<SubmissionsReviewPage />} />
              <Route
                path={`${votes}/:id`}
                element={<ProfileVotedArtworksPage title="Votes" controlsPage />}
              />
              <Route path={`${messagesRoute}`} element={<MessagesPage />} />
            </Route>
            <Route
              path={`${user}/${registrationOfInstitution}`}
              element={<RegistrationInstitution isEditInstitution={false} />}
            />
            <Route path={`/${explore}/${artworks}/:id`} element={<ArtworkDetailsSlider />} />
            <Route
              path={`/${explore}/${artworks}/:id/${uploadedNow}`}
              element={<ArtworkDetailsSlider />}
            />
            <Route
              path={`/${explore}/${artworks}/:id/${incomingTransfers}`}
              element={<ArtworkDetailsSlider />}
            />
            <Route
              path={`/${explore}/${artworks}/:id/${votesArtwork}`}
              element={<ArtworkDetailsSlider />}
            />
            <Route
              path={`/${explore}/${artworks}/:id/${myNominatedArtwork}`}
              element={<ArtworkDetailsSlider />}
            />
            <Route
              path={`/${explore}/${artworks}/:id/${myVotedArtwork}`}
              element={<ArtworkDetailsSlider />}
            />
          </Routes>
        </ArtworksSldrCntxProvider>
      </div>
    </BrowserRouter>
  );
};

export default App;
