import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./FilterItem.scss";

type Units = "$" | "cm" | "in";

interface FilterItemProps {
  children: React.ReactNode;
  title: string;
  unit?: Units;
}

const FilterItem = ({ children, title = "Title", unit }: FilterItemProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const KeyboardArrowIcon = !open ? (
    <KeyboardArrowDownIcon className="filter__icon" />
  ) : (
    <KeyboardArrowUpIcon className="filter__icon" />
  );
  return (
    <div className="filter_item">
      <div className="filter_item__title" onClick={() => setOpen(prevState => !prevState)}>
        <span>
          {title} <span className="filter_item__unit">{unit}</span>
        </span>
        <div className="filter_arrow_icons">{KeyboardArrowIcon}</div>
      </div>
      {open && <div className="filter_item__content">{children}</div>}
    </div>
  );
};

export default FilterItem;
