import React from "react";

import { AppStore, GooglePlay } from "../../assets";

import "./DownloadMoca.scss";

const DownloadMoca = (): JSX.Element => {
  return (
    <div className="download_moca__buttons">
      <a className="download_moca__link" href="https://apps.apple.com/us/app/my-moca/id1434062247">
        <AppStore className="app_store__button" />
      </a>
      <a
        className="download_moca__link"
        href="https://play.google.com/store/apps/details?id=com.moca&hl=en&gl=US"
      >
        <GooglePlay className="google_play__button" />
      </a>
    </div>
  );
};
export default DownloadMoca;
