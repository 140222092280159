import { useEffect } from "react";
import { fetchMe } from "../api-queries/GraohQlClient";
import { IUser } from "../types/userTypes";
import { useTypedSelector } from "./useTypedSelector";
import { useActions } from "./useActions";
import { GetToken } from "../utils/get-token";

interface UseGetMe {
  me: IUser | null;
  updateAuthUser: () => Promise<void>;
}

export const getMyFollowings = (me: IUser): any[] => {
  return me.followings.map(item => {
    return { id: item?.followed?._id, followed: item.followed };
  });
};

export const useGetMe = (): UseGetMe => {
  const { me } = useTypedSelector(store => store.authorizedUserReducer);
  const { setAuthorizedUser } = useActions();
  const token = GetToken();

  const fetchData = async (): Promise<void> => {
    try {
      const data = await fetchMe();
      setAuthorizedUser(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      setAuthorizedUser(null);
    }
  };

  const updateAuthUser = async (): Promise<void> => {
    const newMe = await fetchMe();
    setAuthorizedUser(newMe);
  };

  useEffect(() => {
    if (me || !token) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { me, updateAuthUser };
};
