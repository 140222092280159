export enum ReportItemTypes {
  Artwork = "Artwork",
  User = "User",
  Show = "Show",
  Post = "Post",
}

export enum ReportIdKeys {
  artworkId = "artworkId",
  userId = "userId",
  exhibitionId = "exhibitionId",
  postId = "postId",
}

export const getReportIdKey = (reportItemType: string): string => {
  switch (reportItemType) {
    case ReportItemTypes.Artwork:
      return ReportIdKeys.artworkId;
    case ReportItemTypes.User:
      return ReportIdKeys.userId;
    case ReportItemTypes.Show:
      return ReportIdKeys.exhibitionId;
    case ReportItemTypes.Post:
      return ReportIdKeys.postId;

    default:
      return "";
  }
};

export const getReportTitle = (reportItemType: string): string => {
  switch (reportItemType) {
    case ReportItemTypes.Artwork:
      return "Report Artwork";
    case ReportItemTypes.User:
      return "Report User";
    case ReportItemTypes.Show:
      return "Report Show";
    case ReportItemTypes.Post:
      return "Report Post";

    default:
      return "";
  }
};

export interface IReportActionParams {
  [key: string]: string;
  message: string;
  reportType: string;
}
