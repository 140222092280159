import React, { FC } from "react";
import { sendChatMessage } from "../../../api-queries/GraohQlClient";
import { IArtwork } from "../../../types/artwork/artwork";
import { generateArtworkRequestMessage } from "../../../utils/generateArtworkRequestMessage";
import AskingModal from "../AskingModal/AskingModal";

interface IRequestPaymentModalProps {
  close: () => void;
  isOpen: boolean;
  artwork: IArtwork;
  artworkId: string | undefined;
}

const RequestPaymentModal: FC<IRequestPaymentModalProps> = ({
  close,
  isOpen,
  artwork,
  artworkId,
}) => {
  const currentArtworkOwner = artwork.collector || artwork.owner;

  const requestPurchase = async (text: string): Promise<void> => {
    const messageText = `Request purchase: \n${generateArtworkRequestMessage(artwork, text)}`;
    const message = {
      text: messageText,
      recipientId: currentArtworkOwner._id,
      audited: {
        artwork: artworkId,
      },
      imagesOfChat: [],
    };
    try {
      await sendChatMessage(message);
    } catch (error) {
      console.log(error);
    }
    close();
  };

  return (
    <AskingModal
      close={close}
      isOpen={isOpen}
      artwork={artwork}
      action={requestPurchase}
      buttonText="REQUEST PURCHASE"
      modalTitle="Request Purchase"
    />
  );
};

export default RequestPaymentModal;
