import { IArtwork } from "../types/artwork/artwork";

export const generateArtworkRequestMessage = (artwork: IArtwork, message: string): string => {
  const { firstName, secondName, middleName } = artwork.owner.profile;
  const fullName = `${firstName} ${middleName || ""} ${secondName}`;
  return `\nArtwork ${artwork.name}.
Uploaded by ${fullName}.

${message}`;
};
