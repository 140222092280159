import { Actions } from "../../components/notifications/Notifications.props";
import { NotificationsActionTypes } from "../types/notifications.type";
import { ReturnActionType } from "../types/return-type";

export const setShowNotificationsModal = (show: boolean): ReturnActionType => ({
  type: NotificationsActionTypes.SET_SHOW_NOTIFICATIONS_MODAL,
  payload: show,
});

export const setNotices = (notices: Actions[]): ReturnActionType => ({
  type: NotificationsActionTypes.SET_NOTICES,
  payload: notices,
});

export const setUnreadNotificationsCount = (count: number): ReturnActionType => ({
  type: NotificationsActionTypes.SET_UNREAD_NOTICES_COUNT,
  payload: count,
});

export const setNotificationModalScroll = (scroll: number): ReturnActionType => ({
  type: NotificationsActionTypes.SET_NOTIFICATIONS_MODAL_SCROLL,
  payload: scroll,
});
