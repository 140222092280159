import { IMAGE } from "./request-params/RequestsParams";

export const ADD_POST = `
mutation addPost($text: String, $audited: AuditedInput, $shareLocation: LocationInputType) {
  addPost(text: $text, audited: $audited, shareLocation: $shareLocation) {
    _id
    imagesOfPost {
      ${IMAGE}
    }
  }
}`;
