import {
  UserLikedArtworksState,
  UserLikedArtworksType,
  UserLikedArtworksTypes,
} from "../types/user-liked-artworks";

const userLikedArtworksState: UserLikedArtworksState = {
  userLikedArtworks: [],
  offset: 0,
  userId: "",
};

const userLikedArtworksReducer = (
  state = userLikedArtworksState,
  action: UserLikedArtworksType,
): UserLikedArtworksState => {
  switch (action.type) {
    case UserLikedArtworksTypes.SET_USER_LIKED_ARTWORKS:
      return {
        ...state,
        userLikedArtworks: action.payload,
      };
    case UserLikedArtworksTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case UserLikedArtworksTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
};

export default userLikedArtworksReducer;
