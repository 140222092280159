/* eslint-disable no-console */
/* eslint-disable radix */
import React, { FC, useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputLabel } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import MyButton from "../../UI/button/Button";
import { updateUser } from "../../api-queries/GraohQlClient";
import originOfInspiration from "./inputsInfo.json";
import { MultiInput } from "../../components/multi-input/MultiInput";
import "./EditProfileInspirations.scss";
import { IInspirationInput } from "./inspirations.types";

const EditProfileInspirations: FC = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const {
    bornCity,
    country,
    schools,
    spiritualInspirations,
    teachers,
    ethnic,
    culture,
    awards,
    other,
    tribalIdentities,
    heritage,
  } = state.data.profile.bio;

  const textValues = useMemo(
    () =>
      ({
        cityBorn: bornCity,
        country,
        schools,
        spiritualInspirations,
        teachers,
        ethnicInspirations: ethnic,
        culturalIdentities: culture,
        awards,
        other,
        tribalIdentities,
        familyHeritage: heritage,
      } as any),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.data.profile.bio],
  );
  const originData: IInspirationInput[] = originOfInspiration;
  const onSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const userData: any = {};

    originData.forEach((origin: any) => {
      const keyName = origin.name;
      if (typeof origin.data === "string") {
        userData[keyName] = origin.data;
        return;
      }
      userData[keyName] = origin.data.filter((field: any) => !!field);
    });
    await updateUser({
      _id: state.data._id,
      firstName: state.data.profile.firstName,
      lastName: state.data.profile.secondName,
      phone: state.data.profile.phoneNumber,
      sex: state.data.profile.sex,
      ...userData,
    });
    navigate(-1);
  };

  const getData = (name: string, data: any): void => {
    const index = originData.findIndex((d: { name: string }) => d.name === name);
    originData[index].data = data;
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="edit_origin_of_inspiration">
        <div className="origin_title__wrap">
          <ArrowBackIcon className="arrow__back" onClick={() => navigate(-1)} />
          <h2 className="origin_title">Edit my origins of inspiration</h2>
          <p className="origin_sub_title">Search terms</p>
        </div>
        <div className="origin_grid">
          {originData.map((input: any) => (
            <div className="origin_item" key={input.label}>
              <InputLabel className="origin_label" variant="standard" htmlFor={input.htmlFor}>
                {input.label}
              </InputLabel>
              <MultiInput
                label={input.name}
                initialValue={textValues[input.name]}
                getData={getData}
                isMulti={input.isMulti}
                className="text_field"
                textFiledProps={{ variant: "standard", type: input.type }}
              />
              {input.text && <p className="required">{input.text}</p>}
            </div>
          ))}
        </div>
        <MyButton className="submit_form" variant="button-dark" children="Save Changes" />
      </div>
    </form>
  );
};

export default EditProfileInspirations;
