import { IResultButton } from "../../components/filter-units/utils";
import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { SearchArtworksRequest } from "../../types/artwork/artwork";
import { IFilterCategory } from "../../types/filterTypes";
import { IInstitution } from "../../types/institution";
import {
  ILocationFilter,
  InstitutionsSearchActions,
  InstitutionsSearchActionTypes,
} from "../types/institutionSearch";

export const SET_SEARCH_INSTITUTIONS = (payload: IInstitution[]): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTIONS,
  payload,
});

export const SET_SEARCH_INSTITUTION_OFFSET = (payload: number): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_OFFSET,
  payload,
});

export const SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS = (
  payload: SearchArtworksRequest,
): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_FILTER_QUERY_PARAMS,
  payload,
});

export const SET_SEARCH_INSTITUTION_CATEGORIES = (
  payload: IFilterCategory[],
): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_CATEGORIES,
  payload,
});

export const SET_SEARCH_INSTITUTION_FILTER_RESULTS = (
  payload: IResultButton[],
): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_FILTER_RESULTS,
  payload,
});

export const SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE = (
  payload: ILocationSelectValue,
): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_LOCATION_SORT_VALUE,
  payload,
});

export const SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE = (
  payload: ILocationFilter | null,
): InstitutionsSearchActions => ({
  type: InstitutionsSearchActionTypes.SET_SEARCH_INSTITUTION_LOCATION_AUTOCOMPLETE_VALUE,
  payload,
});
