import { POST } from "./PostRequestParams";
import { ARTWORK_LITE } from "./ArtworkLiteRequestParams";
import { USER_PROFILE } from "./UserProfileRequestParams";
import { EXHIBITION_LITE } from "./ExhibitionLiteRequestParams";
import { POST_LITE } from "./PostLiteRequestParams";
import { COMMENT } from "./CommentRequestParams";

export const ACTION = `
_id
type
createdAt
user {
  _id
  hasInstitution
  institutions {
    name
  }
  profile {
    ${USER_PROFILE}
  }
}
audited {
  post {
    ${POST}
  }
  artwork {
    ${ARTWORK_LITE}
  }
  user {
    _id
    hasInstitution
    profile {
      ${USER_PROFILE}
    }
  }
  exhibition {
    ${EXHIBITION_LITE}
    owner {
      _id
      profile {
        firstName
        secondName
      }
      institutions {
          isVirtual
        }
    }
  }
  comment {
    text
  }
}
associated {
  post {
    ${POST_LITE}
  }
  comment {
    ${COMMENT}
  }
  suggestion {
    _id
    author {
      _id
      profile {
        ${USER_PROFILE}
      }
    }
  }
  user {
    _id
    hasInstitution
    institutions {
      name
    }
    profile {
      ${USER_PROFILE}
    }
  }
}
`;
