import { artistsSortOptions, genderFilterOptions } from "../../explore-pages/artists-page/utils";

import {
  OperatorsSearchActions,
  IArtistsPageInitialState,
  OperatorsSearchActionTypes,
} from "../types/operatorsSearchActions";

export const operatorsSearchInitialState: IArtistsPageInitialState = {
  artists: [],
  offset: 0,
  sortValue: artistsSortOptions[0],
  filterQueryParams: {},
  originsOfInspiration: [],
  filterResults: [],
  gender: genderFilterOptions[0],
  location: null,
  radius: 10,
};

export const operatorsSearchReducer = (
  state = operatorsSearchInitialState,
  action: OperatorsSearchActions,
): IArtistsPageInitialState => {
  switch (action.type) {
    case OperatorsSearchActionTypes.SET_ARTISTS_OPERATORS:
      return {
        ...state,
        artists: [...action.payload],
      };
    case OperatorsSearchActionTypes.SET_OFFSET_OPERATORS:
      return {
        ...state,
        offset: action.payload,
      };
    case OperatorsSearchActionTypes.SET_FILTER_QUERY_PARAMS_OPERATORS:
      return {
        ...state,
        filterQueryParams: action.payload,
      };
    case OperatorsSearchActionTypes.SET_SORT_VALUE_OPERATORS:
      return {
        ...state,
        sortValue: action.payload,
      };
    case OperatorsSearchActionTypes.SET_FILTER_RESULTS_OPERATORS:
      return {
        ...state,
        filterResults: action.payload,
      };
    case OperatorsSearchActionTypes.SET_ORIGINS_OF_INSPIRATION_OPERATORS:
      return {
        ...state,
        originsOfInspiration: action.payload,
      };
    case OperatorsSearchActionTypes.SET_GENDER_OPERATORS:
      return {
        ...state,
        gender: action.payload,
      };
    case OperatorsSearchActionTypes.SET_LOCATION_FILTER_OPERATORS:
      return {
        ...state,
        location: action.payload,
      };

    case OperatorsSearchActionTypes.SET_RADIUS_OPERATORS:
      return {
        ...state,
        radius: action.payload,
      };
    default:
      return state;
  }
};
