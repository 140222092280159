import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "./SelectSort.scss";

const SelectSort = ({
  className = "",
  options,
  setQueryValue,
  sortValue,
  setSortValue,
  height,
  width,
  onClick = e => e,
  renderValue,
}) => {
  const handleChange = e => {
    setSortValue(e.target.value);
    setQueryValue(e.target.value);
  };

  return (
    <Select
      className={className}
      labelId="simple-select-label"
      id="simple-select"
      value={sortValue}
      onChange={handleChange}
      displayEmpty
      renderValue={renderValue}
      defaultValue=""
      sx={{ height, width }}
      onClick={onClick}
    >
      {options.map(option => {
        return (
          <MenuItem key={option.name} value={option}>
            {option.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectSort;
