import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { USER_INSTITUTIONS } from "../../api-queries/UserRequests";

import { IOwner } from "../../types/owner";
import { ITransfer } from "../../types/transfer";
import formatDate from "../../utils/format-date";
import UserListItem from "../user-list-item/UserListItem";

import "./TransferHistoryItem.scss";

interface ITransferHistoryItem {
  owner: IOwner;
  transferHistory: ITransfer;
}

const TransferHistoryItem: React.FC<ITransferHistoryItem> = ({ owner, transferHistory }) => {
  const createdAt = transferHistory?.createdAt;
  const { notes, images } = transferHistory;
  const [isInstitution, setIsInstitution] = useState<boolean>(false);
  const [institutionName, setInstitutionName] = useState(null);
  const { data } = useQuery(USER_INSTITUTIONS, {
    variables: { _id: owner._id },
  });
  useEffect(() => {
    setIsInstitution(Boolean(data?.user.hasInstitution && data?.user.institutions.length));
    if (data?.user.institutions.length) {
      setInstitutionName(data?.user.institutions[0].name);
    }
  }, [data]);
  const imgUrls = images?.map(image => image?.thumbnail?.url);

  return (
    <div className="transfer_history">
      <div className="transfer_history__info">
        <div className="transfer_history__owner">
          {owner && (
            <UserListItem
              profile={owner?.profile}
              id={owner?._id}
              likedArtworks={owner?.likedArtworks}
              likes={owner?.myArtworksLikes}
              isInstitution={isInstitution}
              institutionName={institutionName}
            />
          )}
          <p className="transfer_history__date">
            {formatDate(new Date(Number(createdAt)), "MMMM DD, YYYY")}
          </p>
        </div>
        {notes && <p className="transfer_history__text">{notes}</p>}
      </div>
      <div className="transfer_history__image">
        {!!imgUrls.length &&
          imgUrls.map(url => <img key={url} src={url} alt={owner?.profile.firstName} />)}
      </div>
    </div>
  );
};
export default TransferHistoryItem;
