import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ArtworksPageComponent from "../../components/artworks-page-component/ArtworksPageComponent";

const ArtCollection = (): JSX.Element => {
  const { id }: IParamsId = useParams();
  const dispatch = useDispatch();
  const artworks = useTypedSelector(state => state.artworkSearchFilterReducer);
  return (
    <div className="artworks_found">
      <ArtworksPageComponent
        queryParams={{ collectorId: id, types: ["collector", "artist"] }}
        context={{ dispatch, state: artworks }}
        title="Collected Artworks"
        myMoca={false}
        shareMark={false}
        questionMark={false}
        backButton
        controlsPage
      />
    </div>
  );
};

export default ArtCollection;
