/* eslint-disable @typescript-eslint/no-empty-function */
import { IconButton, Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { FC, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";

import { fetchUnreadMessages, sendChatMessage } from "../../api-queries/GraohQlClient";
import messagesIcon from "../../assets/images/mail.png";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import MessagesModal from "../messages-modal/MessagesModal";
import socket from "../../socket";

import "./Messages.scss";
import { IChat } from "../../types/chat";
import { messagesRoute } from "../../route-link";
import { MY_FOLLOWINGS } from "../../api-queries/UserRequests";
import { IUser } from "../../types/userTypes";
import { SEARCH_CHATS_GQL } from "../../api-queries/ChatRequests";

const Messages: FC = () => {
  const {
    unreadMessagesCount,
    showMessagesModal,
    chatsSearchResults,
    chats,
    messagesToSend,
    isMessageSending,
  } = useTypedSelector(reduxStore => reduxStore.messagesInfoReducer);

  const {
    setUnreadMessagesCount,
    incrementUnreadMessagesCount,
    setShowMessagesModal,
    setDefaultSearchResults,
    setChatsSearchResults,
    setChats,
    setFollowingsLoading,
    shiftMessagesToSend,
    setIsMessageSending,
    setMessageUnreadStatus,
  } = useActions();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const tokenKey = localStorage.getItem("tokenKey");
  const anchorEl = useRef<HTMLDivElement>(null);
  const routerLocation = useLocation();
  const isChatsPage = routerLocation.pathname === `/${messagesRoute}`;

  const { data: chatsData } = useQuery(SEARCH_CHATS_GQL, {
    variables: {
      offset: 0,
      limit: 20,
    },
    fetchPolicy: "network-only",
  });

  const sendMessage = async (): Promise<void> => {
    try {
      setIsMessageSending(true);
      const currentMessageToSend = messagesToSend[0];
      const newMessageId = await sendChatMessage({
        ...currentMessageToSend,
        _id: undefined,
      });
      setMessageUnreadStatus(currentMessageToSend._id, newMessageId);
      shiftMessagesToSend();
      setIsMessageSending(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMessageSending || !messagesToSend.length) return;
    sendMessage();
  }, [messagesToSend.length, isMessageSending]);

  useEffect(() => {
    if (chatsData?.listChat?.chats && !chats.length) {
      setChats(chatsData.listChat.chats);
    }
  }, [chatsData]);
  const { data: followingsData } = useQuery(MY_FOLLOWINGS, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (!followingsData?.me?.followings) return;
    const filteredFollowings = followingsData.me.followings
      .map((user: any) => user.followed)
      .filter((user: IUser) => !!user);
    setDefaultSearchResults(filteredFollowings);
    if (!chatsSearchResults.length) {
      setChatsSearchResults(filteredFollowings);
    }
    setFollowingsLoading(false);
  }, [followingsData]);

  const getUnreadMessages = async (): Promise<void> => {
    const userChats: IChat[] = await fetchUnreadMessages();
    if (!userChats) return;
    let count = 0;
    userChats.forEach(({ participants }) => {
      participants.forEach((user: any) => {
        const { participant, unreadCount } = user;
        if (participant._id === myMOCAId) count += unreadCount;
      });
    });
    setUnreadMessagesCount(count);
  };

  const onMessageReceived = (): void => {
    incrementUnreadMessagesCount();
    getUnreadMessages();
  };

  useEffect(() => {
    getUnreadMessages();
    if (!tokenKey) return;
    socket.init(tokenKey);
    socket.onMessageReceived(onMessageReceived);
  }, []);

  const parsedCount = unreadMessagesCount > 99 ? "99+" : unreadMessagesCount.toString();

  const handleMessagesIconClick = (): void => {
    if (isChatsPage) return;
    setShowMessagesModal(true);
  };

  return (
    <>
      <div className="messages" ref={anchorEl}>
        <Tooltip title="Messages" className="messages_list">
          <IconButton onClick={handleMessagesIconClick} size="small" sx={{ ml: 2 }}>
            <img src={messagesIcon} className="messages-icon" alt="" />
            {!!unreadMessagesCount && <div className="unreaded-messages-count">{parsedCount}</div>}
          </IconButton>
        </Tooltip>
      </div>
      {!isChatsPage && (
        <MessagesModal
          isOpen={showMessagesModal}
          close={() => setShowMessagesModal(false)}
          anchorEl={anchorEl.current}
          refetchUnreadMessagesCount={getUnreadMessages}
        />
      )}
    </>
  );
};

export default Messages;
