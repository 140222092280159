import React from "react";

const TooltipTextVote = (): JSX.Element => {
  return (
    <>
      <ol className="tooltip_list">
        <li className="tooltip_item">
          *Original Artwork, submitted by artist, for Sale, are eligible for exhibitions at The New
          York Museum of Contemporary Art in Manhattan.
        </li>
        <li className="tooltip_item">
          *Art with the highest number of votes, in any 30 day sample, are considered for
          exhibitions.
        </li>
        <li className="tooltip_item">*Sharing to get votes is key to inclusion.</li>
        <li className="tooltip_item">
          *The artist's name is not included on this Vote page but you can reveal name in a share.
        </li>
        <li className="tooltip_item">
          *Members and random users reviewing Vote page Curate based on aesthetics, materials, size
          and inspirations alone.
        </li>
        <li className="tooltip_item">*Once a vote is cast, it cannot be changed.</li>
        <li className="tooltip_item">
          * Artworks in Museum can be used in sets on Film and TV shoots, possibly reaching millions
          of eyes.
        </li>
        <li className="tooltip_item">
          *An artwork seen on a screen, then scanned, brings user to Credential page.
        </li>
      </ol>
    </>
  );
};

export default TooltipTextVote;
