import React, { ChangeEvent, useState } from "react";
import {
  Alert,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  Modal,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";

import Button from "../../UI/button/Button";
import { Close } from "../../assets";
import { addSuggestionToArtwork } from "../../api-queries/GraohQlClient";

import "./ArtworkIdentify.scss";
import { ISuggestion } from "../../types/suggestion";
import RenderCopyLink from "../../UI/render-copy-link/RenderCopyLink";
import { GetToken } from "../../utils/get-token";
import { useActions } from "../../hooks/useActions";

interface ArtworkIdentifyProps {
  handleAddSuggestions: () => void;
  artworkImage: string;
  artworkId: string | undefined;
  suggestionsText: string;
  iDontKnowSuggestions: Array<ISuggestion>;
  refetch: () => void;
}

interface Form {
  artistName: string;
  notes: string;
  video: string;
}

const ArtworkIdentify: React.FC<ArtworkIdentifyProps> = ({
  handleAddSuggestions,
  artworkImage,
  artworkId,
  suggestionsText,
  iDontKnowSuggestions,
  refetch,
}): JSX.Element => {
  const iDontKnowSuggestionsLength = iDontKnowSuggestions?.length;
  const { register, handleSubmit, reset } = useForm<Form>();
  const [openArtist, setOpenArtist] = useState(false);
  const [checked, setChecked] = useState(false);
  const [videoUrlMessage, setVideoUrlMessage] = useState<null | string>(null);
  const token = GetToken();
  const { openModalLogin } = useActions();

  const handleOpen = token ? (): void => setOpenArtist(true) : openModalLogin;
  const handleClose = (): void => setOpenArtist(false);
  const onIdontKnowClick = token ? handleAddSuggestions : openModalLogin;

  const [artworkName, setArtworkName] = useState("");

  const handleChangeArtworkName = (e: ChangeEvent<HTMLInputElement>): void =>
    setArtworkName(e.target.value);

  const isValidWebUrl = (url: string): boolean => {
    const regEx =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/gm;
    return regEx.test(url);
  };

  const onSubmit = async (data: Form): Promise<void> => {
    if (!isValidWebUrl(data.video) && !!data.video.length) {
      setVideoUrlMessage("Should be a secure url (starts with https://)");
      return;
    }
    setVideoUrlMessage(null);
    await addSuggestionToArtwork({
      artworkId,
      artistName: data.artistName,
      artworkTitle: artworkName,
      notes: data.notes,
      iDontKnow: checked,
      video: data.video ? { url: data.video, type: "video" } : null,
    });
    refetch();
    handleClose();
    reset();
  };

  const handleChecked = (): void => {
    setArtworkName("");
    setChecked(prevState => !prevState);
  };
  return (
    <div className="artwork_identify">
      <div className="artwork_identify__text">Do you know more about this artwork?</div>
      <div className="artwork_identify__title">{suggestionsText}</div>
      <Button
        variant="text"
        children="name of artist"
        className="artwork_identify__btn"
        onClick={handleOpen}
      />
      <Divider />
      <div className="artwork_identify__bottom">
        <div className="dont_identify">
          <Button variant="text" children="I don't know" onClick={onIdontKnowClick} />
          <p className="dont_identify__text">{iDontKnowSuggestionsLength} people can't identify</p>
        </div>
        <p className="artwork_identify__text">
          Or ask your friend for suggestion. <RenderCopyLink title="Copy link" />
        </p>
      </div>
      <Modal
        hideBackdrop
        open={openArtist}
        className="open_artist"
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="open_artist__form">
          <Close className="close-svg" onClick={handleClose} height={20} />
          <div className="open_artist__title">Can you identify this art?</div>
          <div className="open_artist__image">
            <img src={artworkImage} alt="Title artwork" />
          </div>
          <form className="open_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form_item">
              <InputLabel variant="standard" htmlFor="name_artist" className="label">
                submit name of artist
              </InputLabel>
              <TextField
                id="name_artist"
                className="input"
                type="text"
                {...register("artistName")}
                variant="standard"
              />
            </div>
            <div className="form_item">
              <InputLabel variant="standard" htmlFor="name_artwork" className="label">
                submit name of artwork
              </InputLabel>
              <FormControlLabel
                className="open_artist__check"
                control={<Checkbox onChange={handleChecked} checked={checked} color="default" />}
                label="Unknown"
              />
              <TextField
                id="name_artwork"
                className="input"
                type="text"
                onChange={handleChangeArtworkName}
                value={artworkName}
                variant="standard"
                disabled={checked}
              />
            </div>
            <div className="form_item">
              <InputLabel variant="standard" htmlFor="notes" className="label">
                notes
              </InputLabel>
              <TextField
                id="notes"
                className="input"
                type="text"
                {...register("notes")}
                variant="standard"
              />
            </div>
            <div className="form_item">
              <InputLabel variant="standard" htmlFor="video_url" className="label">
                video url
              </InputLabel>
              <TextField
                id="video_url"
                className="input"
                type="text"
                variant="standard"
                {...register("video")}
              />
              {videoUrlMessage && (
                <Alert className="alert_error" severity="error">
                  {videoUrlMessage}
                </Alert>
              )}
            </div>
            <Button variant="button-dark" children="Identify artwork" />
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ArtworkIdentify;
