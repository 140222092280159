import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation } from "react-router-dom";
import searchTabData from "./SearchTab.data.json";

import ArtworksPageComponent from "../../../../components/artworks-page-component/ArtworksPageComponent";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import ArtistsPage from "../../../../explore-pages/artists-page/ArtistsPage";
import { ISearchArtists } from "../../Search.type";
import CollectorsTab from "../pages/CollectorsTab";
import OperatorsTab from "../pages/OperatorsTab";
import ShowsTab from "../pages/ShowsTab";
import InstitutionSearch from "../pages/InstitutionSearch";

import "./SearchTab.scss";
import { useActions } from "../../../../hooks/useActions";

const SearchTab = (): JSX.Element => {
  const [value, setValue] = useState<string>(searchTabData[0].value);
  const dispatch = useDispatch();
  const artworks = useTypedSelector(state => state.artworkSearchFilterReducer);
  const artistsState = useTypedSelector(state => state.artistsSearchReducer);
  const collectorsState = useTypedSelector(state => state.collectorsSearchReducer);
  const operatorsState = useTypedSelector(state => state.operatorsSearchReducer);
  const { state }: any = useLocation();
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const [stateText, setStateText] = useState(state.text);
  const { addPrevState, removePrevStateById } = useActions();

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };
  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.id === "search-results-tab");
    if (prevState && prevState.tab) {
      setValue(prevState.tab);
    }
    setStateText(state.text);
  }, [stateText]);

  const saveSearchPrevTab = (): void => {
    addPrevState({ tab: value, id: "search-results-tab" });
  };

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.id === "search-results-tab");

    if (prevState && prevState.id) {
      removePrevStateById(prevState.id);
    }
  }, []);

  return (
    <div className="search_tabs__body">
      <TabContext value={value}>
        <div className="wrapper">
          <TabList
            className="search_tabs"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {searchTabData.map(tab => (
              <Tab className="search_tab" key={tab.id} label={tab.label} value={tab.value} />
            ))}
          </TabList>
        </div>
        <TabPanel value="artworks">
          <ArtworksPageComponent
            saveSearchPrevTab={saveSearchPrevTab}
            queryParams={{ searchableText: state.text }}
            context={{ dispatch, state: artworks }}
            showMap={false}
            title=""
            text={state.text}
            searchPage
          />
        </TabPanel>
        <TabPanel value="artists">
          <ArtistsPage
            saveSearchPrevTab={saveSearchPrevTab}
            dispatch={dispatch}
            state={artistsState}
            text={state.text}
            resultPage
          />
        </TabPanel>
        <TabPanel value="collectors">
          <CollectorsTab
            saveSearchPrevTab={saveSearchPrevTab}
            state={collectorsState}
            text={state.text}
            resultPage
            types={[ISearchArtists.COLLECTOR]}
          />
        </TabPanel>
        <TabPanel value="operators">
          <OperatorsTab
            saveSearchPrevTab={saveSearchPrevTab}
            state={operatorsState}
            text={state.text}
            resultPage
          />
        </TabPanel>
        <TabPanel value="shows">
          <ShowsTab
            saveSearchPrevTab={saveSearchPrevTab}
            isOpenToSubmitions={undefined}
            text={state.text}
            showMap={false}
            title={false}
          />
        </TabPanel>
        <TabPanel value="institutions">
          <InstitutionSearch
            saveSearchPrevTab={saveSearchPrevTab}
            availableForFilmAndTV={undefined}
            availableForEvents={undefined}
            isVirtual={undefined}
            title=""
            text={state.text}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default SearchTab;
