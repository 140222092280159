import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { IShowDurationSelectValue } from "../../explore-pages/shows-page/utils";
import { IShowExhibitions } from "../../types/exhibitions";
import { ILocationFilter } from "./artistsPageTypes";

export enum ShowsPageActionTypes {
  SET_SHOWS = "SET_SHOWS",
  SET_OFFSET = "SET_OFFSET",
  SET_DURATION_SORT_VALUE = "SET_DURATION_SORT_VALUE",
  SET_FILTER_QUERY_PARAMS = "SET_FILTER_QUERY_PARAMS",
  SET_CALENDAR_VALUE = "SET_CALENDAR_VALUE",
  SET_LOCATION_SORT_VALUE = "SET_LOCATION_SORT_VALUE",
  SET_LOCATION_AUTOCOMPLETE_VALUE = "SET_LOCATION_AUTOCOMPLETE_VALUE",
}

export interface IShowPageInitialState {
  shows: IShowExhibitions[];
  durationSortValue: IShowDurationSelectValue;
  filterQueryParams: any;
  calendarValue: string;
  offset: number;
  locationSortValue: ILocationSelectValue;
  locationAutocompleteValue: ILocationFilter | null;
}

export interface setShows {
  type: ShowsPageActionTypes.SET_SHOWS;
  payload: IShowExhibitions[];
}

export interface setOffset {
  type: ShowsPageActionTypes.SET_OFFSET;
  payload: number;
}

export interface setDurationSortValue {
  type: ShowsPageActionTypes.SET_DURATION_SORT_VALUE;
  payload: IShowDurationSelectValue;
}

export interface setFilterQueryParams {
  type: ShowsPageActionTypes.SET_FILTER_QUERY_PARAMS;
  payload: any;
}

export interface setCalendarValue {
  type: ShowsPageActionTypes.SET_CALENDAR_VALUE;
  payload: string;
}

export interface setLocationSortValue {
  type: ShowsPageActionTypes.SET_LOCATION_SORT_VALUE;
  payload: ILocationSelectValue;
}

export interface setLocationAutocompleteValue {
  type: ShowsPageActionTypes.SET_LOCATION_AUTOCOMPLETE_VALUE;
  payload: ILocationFilter | null;
}

export type ShowsPageActions =
  | setShows
  | setOffset
  | setDurationSortValue
  | setFilterQueryParams
  | setCalendarValue
  | setLocationSortValue
  | setLocationAutocompleteValue;
