export const avatarColors = [
  ["#F44336", "#FFF"],
  ["#E91E63", "#FFF"],
  ["#9C27B0", "#FFF"],
  ["#673AB7", "#FFF"],
  ["#3F51B5", "#FFF"],
  ["#2196F3", "#FFF"],
  ["#00BCD4", "#FFF"],
  ["#009688", "#FFF"],
  ["#4CAF50", "#FFF"],
  ["#CDDC39", "#FFF"],
  ["#FFEB3B", "#000"],
  ["#FFC107", "#000"],
  ["#FF9800", "#000"],
  ["#FF5722", "#FFF"],
  ["#795548", "#FFF"],
];

export const getColorByUserId = (id: string): string[] => {
  if (id) {
    const subId = id.substring(id.length - 4);
    const intId = parseInt(subId, 16);
    return avatarColors[intId % avatarColors.length];
  }
  return avatarColors[0];
};
