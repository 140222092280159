import CopyToClipboard from "react-copy-to-clipboard";
import React, { useState } from "react";
import "./RenderCopyLink.scss";

interface RenderCopyLinkProps {
  title: string;
}

const RenderCopyLink: React.FC<RenderCopyLinkProps> = ({ title }): JSX.Element => {
  const pageLink = window.location.href;
  const [tooltip, setTooltip] = useState(title);

  const copyLink = (): void => {
    setTooltip("Copied");
    setTimeout(() => {
      setTooltip(title);
    }, 3000);
  };
  return (
    <CopyToClipboard text={pageLink} onCopy={copyLink}>
      <div className="copy_link">
        <strong>Ask now.</strong>
        <div className="tooltip">{tooltip}</div>
      </div>
    </CopyToClipboard>
  );
};

export default RenderCopyLink;
