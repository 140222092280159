import React from "react";
import { Link } from "react-router-dom";

import formatDate from "../../utils/format-date";
import { artists, explore } from "../../route-link";
import { NotificationsItemProps } from "./Notifications.props";

const FollowNotification: React.FC<NotificationsItemProps> = ({
  nameFollower,
  time,
  userId,
  userImage,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  return (
    <div className="follow_notification">
      <p className="follow_notification__text">
        <Link
          className="notification_link"
          to={`/${explore}/${artists}/${userId}`}
          state={{ name: nameFollower, image: userImage }}
        >
          {nameFollower}
        </Link>{" "}
        started following{" "}
        <Link className="notification_link" to={`/${explore}/${artists}/${authUserId}`}>
          You
        </Link>
      </p>
      <p className="follow_notification__time">{formatDate(time, "MMM DD YYYY")}</p>
    </div>
  );
};

export default FollowNotification;
