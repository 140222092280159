import { IMAGE } from "./ImageRequestParams";
import { LOCATION } from "./LocationRequestParams";

export const POST_LITE = `
  _id
  imagesOfPost {
    ${IMAGE}
  }
  text
  isLiked
  likesCount
  sharesCount
  shareLocation {
    ${LOCATION}
  }
  commentsCount
  createdAt
  owner {
    _id
    likedArtworks
    hasInstitution
    institutions {
      name
    }
    profile {
      image {
        ${IMAGE}
      }
      firstName
      secondName
      middleName
    }
  }
`;
