import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ArtworksPageComponent from "../artworks-page-component/ArtworksPageComponent";
import { IParamsId } from "./utils";

const FoundArtworks: React.FC = () => {
  const { id }: IParamsId = useParams();
  const { profilePageContext }: any = useOutletContext();
  return (
    <ArtworksPageComponent
      queryParams={{ ownerId: id, types: ["found"] }}
      showMap={false}
      context={profilePageContext.found}
      title={false}
    />
  );
};

export default FoundArtworks;
