import React from "react";
import { useOutletContext } from "react-router-dom";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import InstitutionComponent from "../../components/institution-component/InstitutionComponent";

const VenuesExplorePage: React.FC = () => {
  const { venuesExplorePageContext }: any = useOutletContext();
  return (
    <>
      <ScrollToTop />
      <InstitutionComponent
        availableForFilmAndTV={undefined}
        availableForEvents
        isVirtual={false}
        context={venuesExplorePageContext}
        title="VENUES FOR EVENTS & SHOWS"
      />
    </>
  );
};

export default VenuesExplorePage;
