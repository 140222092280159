import moment, { Moment } from "moment";

export const DURATION_FILTERS = [
  "Today",
  "Tomorrow",
  "This week",
  "This month",
  "This year",
  "Anytime",
  "Select date",
  "Virtual only",
];

export enum DurationFilters {
  TODAY = "Today",
  TOMORROW = "Tomorrow",
  THIS_WEEK = "This week",
  THIS_MONTH = "This month",
  THIS_YEAR = "This year",
  ANYTIME = "Anytime",
  CUSTOM = "Select date",
  VIRTUAL_ONLY = "Virtual only",
}

type Value = {
  duration?: {
    from: Moment;
    to: Moment;
  };
  dayOfWeek?: string;
  isPrivate?: boolean;
};
export interface IShowDurationSelectValue {
  id: number;
  name: string;
  value: Value | string;
}

export const durationFilters = DURATION_FILTERS.map((item, id): IShowDurationSelectValue => {
  switch (item) {
    case DurationFilters.TODAY:
      return {
        id,
        name: item,
        value: {
          duration: { from: moment().startOf("day"), to: moment().endOf("day") },
          dayOfWeek: moment().format("dddd"),
          isPrivate: undefined,
        },
      };
    case DurationFilters.TOMORROW:
      return {
        id,
        name: item,
        value: {
          duration: {
            from: moment().add(1, "days").startOf("day"),
            to: moment().add(1, "days").endOf("day"),
          },
          dayOfWeek: moment().add(1, "days").format("dddd"),
          isPrivate: undefined,
        },
      };
    case DurationFilters.THIS_WEEK:
      return {
        id,
        name: item,
        value: {
          duration: {
            from: moment().startOf("week"),
            to: moment().endOf("week"),
          },
          dayOfWeek: undefined,
          isPrivate: undefined,
        },
      };
    case DurationFilters.THIS_MONTH:
      return {
        id,
        name: item,
        value: {
          duration: {
            from: moment().startOf("month"),
            to: moment().endOf("month"),
          },
          dayOfWeek: undefined,
          isPrivate: undefined,
        },
      };
    case DurationFilters.THIS_YEAR:
      return {
        id,
        name: item,
        value: {
          duration: {
            from: moment().startOf("year"),
            to: moment().endOf("year"),
          },
          dayOfWeek: undefined,
          isPrivate: undefined,
        },
      };
    case DurationFilters.CUSTOM:
      return {
        id,
        name: item,
        value: item,
      };
    case DurationFilters.ANYTIME:
      return {
        id,
        name: item,
        value: {
          duration: undefined,
          dayOfWeek: undefined,
          isPrivate: undefined,
        },
      };
    case DurationFilters.VIRTUAL_ONLY:
      return {
        id,
        name: item,
        value: {
          duration: undefined,
          dayOfWeek: undefined,
          isPrivate: true,
        },
      };
    default:
      return {
        id,
        name: item,
        value: {
          duration: undefined,
          dayOfWeek: undefined,
          isPrivate: undefined,
        },
      };
  }
});
