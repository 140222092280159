import React from "react";
import { Link } from "react-router-dom";

import { artists, explore, shows } from "../../route-link";
import { IImage } from "../../types/imageData";
import ExhibitionNoticeAudited from "./audited-components/ExhibitionNoticeAudited/ExhibitionNoticeAudited";

interface PublicCommentsShowNotificationProps {
  notice: any;
  nameFollower: string;
  userId: string;
  textComment: string;
  time: Date;
  userImage: IImage;
}

const PublicCommentsShowNotification: React.FC<PublicCommentsShowNotificationProps> = ({
  notice,
  nameFollower,
  userId,
  time,
  textComment,
  userImage,
}): JSX.Element => {
  const titleExhibition = notice?.audited?.exhibition?.title;
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFollower, image: userImage }}
        >
          {nameFollower}
        </Link>{" "}
        left a comment on{" "}
        <Link className="notification_link" to={`${explore}/${shows}/`}>
          {titleExhibition}
        </Link>
        : "{textComment}"
      </p>
      <ExhibitionNoticeAudited exhibition={notice?.audited?.exhibition} time={time} />
    </div>
  );
};

export default PublicCommentsShowNotification;
