import React from "react";
import GiftTransferForm from "./GiftTransferForm/GiftTransferForm";
import LoanTransferForm from "./LoanTransferForm/LoanTransferForm";
import SaleTransferForm from "./SaleTransferForm/SaleTransferForm";
import TradeTransferForm from "./TradeTransferForm/TradeTransferForm";

interface TransferInfoFormProps {
  transferType: string;
}

const AddTransferInfoForm: React.FC<TransferInfoFormProps> = ({ transferType }) => {
  switch (transferType) {
    case "Sale":
      return <SaleTransferForm />;
    case "Trade":
      return <TradeTransferForm />;
    case "Loan":
      return <LoanTransferForm />;
    case "Gift":
      return <GiftTransferForm />;
    default:
      return null;
  }
};

export default AddTransferInfoForm;
