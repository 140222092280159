import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import { CircularProgress, TextField } from "@mui/material";
import uniqid from "uniqid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { CHAT_MESSAGES } from "../../api-queries/ChatRequests";
import { IChat, IMessage } from "../../types/chat";
import { IUser } from "../../types/userTypes";
import ChatMessage from "../chat-message/ChatMessage";
import "./Chat.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import sendIcon from "../../assets/images/iconSend@3x.png";
import galleryIcon from "../../assets/images/image-gallery@3x.png";
import { IImage } from "../../types/imageData";
import { useActions } from "../../hooks/useActions";
import { getUniqueMessages, sortMessagesByDate } from "./utils";
import ChatMessagesSkeleton from "../chat-messages-skeleton/ChatMessagesSkeleton";

interface IChatProps {
  chat: IChat | null;
  setChatName: (chatName: string) => void;
  setChatRecipient: (chatRecipient: IUser) => void;
  scrollToBottom: (smooth?: boolean) => void;
  scrollModalTo: (coordsY: number) => void;
  saveCurrentScroll: () => void;
  getUnreadMessages: () => void;
  scrollOnLoad?: () => void;
  showScrollToBottomButton?: boolean;
}
const limit = 50;
const Chat: FC<IChatProps> = ({
  chat,
  setChatName,
  setChatRecipient,
  scrollToBottom,
  scrollModalTo,
  saveCurrentScroll,
  getUnreadMessages,
  scrollOnLoad,
  showScrollToBottomButton,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const {
    unreadMessagesCount,
    currentMessages: messages,
    currentChatOffset: offset,
    currentScroll,
    messagesToSend,
  } = useTypedSelector(reduxStore => reduxStore.messagesInfoReducer);
  const {
    setCurrentMessages: setMessages,
    setCurrentChatOffset: setOffset,
    setCurrentScroll,
    setMessagesToSend,
  } = useActions();
  const [messageText, setMessageText] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageImages, setMessageImages] = useState<any>([]);
  const [allowScrolling, setAllowScrolling] = useState(true);
  const [isLightboxOpened, setIsLightboxOpened] = useState<boolean>(false);
  const [lightboxImages, setLightboxImages] = useState<IImage[]>([]);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const myMOCAId = localStorage.getItem("myMOCAId") || "";
  const recipient = chat?.participants.find(user => user.participant._id !== myMOCAId)?.participant;
  const [messagesToSet, setMessagesToSet] = useState<IMessage[]>([]);
  const [allowFetchingMore, setAllowFetchingMore] = useState(!!messages.length);

  const [loadMessages, { data, loading, fetchMore }] = useLazyQuery(CHAT_MESSAGES, {
    variables: {
      recipientId: recipient?._id,
      limit: 50,
      offset: 0,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  });

  useEffect(() => {
    if (
      (messagesToSet[0]?.owner._id === myMOCAId &&
        messagesToSet[0]?.recipient._id === recipient?._id) ||
      (messagesToSet[0]?.owner._id === recipient?._id &&
        messagesToSet[0]?.recipient._id === myMOCAId)
    ) {
      setMessages(sortMessagesByDate(messagesToSet));
      setIsLoading(false);
    }
  }, [messagesToSet]);

  useEffect(() => {
    if (lightboxImages.length) {
      setIsLightboxOpened(true);
    }
  }, [lightboxImages]);

  useEffect(() => {
    setAllowScrolling(true);
    loadMessages();
    if (recipient) {
      const newChatName = recipient?.hasInstitution
        ? recipient.institutions[0]?.name
        : `${recipient?.profile.firstName} ${recipient?.profile.secondName}`;
      setChatName(newChatName);
      setChatRecipient(recipient);
    }
  }, [chat]);
  const fetchMoreMessages = async (isRefetch = false, isScroll = false): Promise<void> => {
    if (isLoading || !allowFetchingMore) return;
    setIsLoading(true);
    if (allowScrolling) {
      if (currentScroll) {
        scrollModalTo(currentScroll);
        setCurrentScroll(undefined);
        setAllowScrolling(false);
        setIsLoading(false);
        return;
      }
      scrollToBottom();
      setAllowScrolling(false);
    }

    const currentOffset = isRefetch ? 0 : offset + limit;
    const currentLimit = isRefetch ? messages.length : limit;
    const res = await fetchMore({ variables: { limit: currentLimit, offset: currentOffset } });
    if (!res?.data?.listChatMessages?.messages) {
      setIsLoading(false);
      return;
    }
    const newMessages = isRefetch
      ? [...res?.data?.listChatMessages.messages].reverse()
      : [...(res?.data?.listChatMessages.messages.reverse() || []), ...messages];

    if (isRefetch && newMessages.length === 200) setOffset(200);

    if (!isRefetch) setOffset(currentOffset);

    setHasMore(res.data.listChatMessages.meta.total > newMessages.length);
    const uniqueMessages = getUniqueMessages(newMessages);
    if (!isRefetch && scrollOnLoad) scrollOnLoad();
    setMessagesToSet(uniqueMessages);
    getUnreadMessages();
    if (isScroll) setTimeout(() => scrollToBottom(true), 100);
  };

  useEffect(() => {
    if (allowScrolling && messages.length) {
      setTimeout(() => {
        if (currentScroll) {
          scrollModalTo(currentScroll);
          setCurrentScroll(undefined);
          setAllowScrolling(false);
          return;
        }
        scrollToBottom();
        setAllowFetchingMore(true);
        setAllowScrolling(false);
      }, 100);
    }
  }, [messages]);

  useEffect(() => {
    if (data?.listChatMessages?.messages?.length) {
      const fetchedMessages = [...data.listChatMessages.messages];
      const allMessages = [...messages, ...fetchedMessages.reverse()];
      const uniqueMessages = getUniqueMessages(allMessages);
      setHasMore(data.listChatMessages.meta.total > uniqueMessages.length);
      setMessages(uniqueMessages);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    if (messages.length) {
      fetchMoreMessages(true);
    }
  }, [unreadMessagesCount]);

  const observer = useRef<IntersectionObserver>();
  const lastMessageElementRef = (node: any): void => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMoreMessages();
      }
    });
    if (node) observer.current.observe(node);
  };

  const presendMessage = (images: File[], messageId?: string): void => {
    const mockMessage = {
      _id: messageId,
      owner: {
        _id: myMOCAId,
        profile: chat?.participants.find(
          participant => participant.participant._id !== recipient?._id,
        )?.participant.profile,
      },
      recipient,
      text: messageText,
      imagesOfChat: images.map(file => ({
        medium: { url: URL.createObjectURL(file) },
      })),
      createdAt: Date.now(),
      audited: {},
    };
    setMessages([...messages, mockMessage as any]);
    setTimeout(() => scrollToBottom(true), 200);
  };

  const pushMessageForSending = async (images: any, text?: string): Promise<void> => {
    if (!messageText.length && !images.length) return;
    const blob = (res: any): string => res.blob();
    const blobArray = await Promise.all(
      images
        ? images?.map((item: any) => {
            return fetch(item).then(blob);
          })
        : [],
    );

    const messageId = uniqid();

    const query = {
      recipientId: recipient?._id,
      text: text ?? messageText,
      imagesOfChat: blobArray,
      _id: messageId,
    };

    setMessageText("");
    setMessageImages([]);
    if (!images.length) presendMessage([], messageId);
    setMessagesToSend([...messagesToSend, query]);
  };

  const addMessageImage = (img: any, amount: number): void => {
    setMessageImages((prev: any) => {
      if ([...prev, img].length === amount) pushMessageForSending([...prev, img]);
      return [...prev, img];
    });
  };

  const handleSelectImages = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (files && !!files.length) {
      if (files.length > 4) {
        // eslint-disable-next-line no-alert
        alert("You cannot upload more than 4 images");
        return;
      }

      const filesArray = Array.from(files);
      presendMessage(filesArray);

      filesArray.forEach(file => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          addMessageImage(fileReader.result as string, files.length);
        };
        fileReader.readAsDataURL(file);
      });
    }
  };

  const closeLightbox = (): void => {
    setIsLightboxOpened(false);
    setLightboxImages([]);
  };

  const handleSendMessage = (): void => {
    pushMessageForSending([], messageText);
    setTimeout(() => setMessageText(""));
  };

  const handleSetMesssageText = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage();
    }
  };

  return (
    <div className="chat">
      {isLightboxOpened && (
        <Lightbox
          mainSrc={
            lightboxImages[imageIndex]?.origin?.url || lightboxImages[imageIndex]?.medium?.url
          }
          onCloseRequest={closeLightbox}
          nextSrc={
            lightboxImages[imageIndex + 1]?.origin?.url ||
            lightboxImages[imageIndex + 1]?.medium?.url
          }
          prevSrc={
            lightboxImages[imageIndex - 1]?.origin?.url ||
            lightboxImages[imageIndex - 1]?.medium?.url
          }
          onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
          onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
          enableZoom={false}
        />
      )}
      <div className="chat_messages">
        {!!messages.length && isLoading && hasMore && (
          <div className="chat_messages__loader">
            <CircularProgress size={25} />
          </div>
        )}
        {messages.map((message, idx) => {
          const isCurrentChatMessage =
            (message?.owner._id === myMOCAId && message?.recipient._id === recipient?._id) ||
            (message?.owner._id === recipient?._id && message?.recipient._id === myMOCAId);
          if (!isCurrentChatMessage) return null;
          const isGuestMessage = message.owner._id === recipient?._id;
          const displayAvatar =
            !messages[idx + 1] || messages[idx + 1]?.owner._id !== message.owner._id;
          const displayDate =
            idx === 0 ||
            !moment(message.createdAt).isSame(moment(messages[idx - 1]?.createdAt), "days");
          if (idx === 0)
            return (
              <div key={message._id} ref={lastMessageElementRef}>
                {displayDate && (
                  <p className="chat-message-date">
                    {moment(message.createdAt).format("MMM DD, YYYY")}
                  </p>
                )}
                <ChatMessage
                  key={message._id}
                  message={message}
                  isGuestMessage={isGuestMessage}
                  displayAvatar={displayAvatar}
                  displayDate={displayDate}
                  setLightboxImages={setLightboxImages}
                  setImageIndex={setImageIndex}
                  saveCurrentScroll={saveCurrentScroll}
                />
              </div>
            );
          return (
            <div key={message._id}>
              {displayDate && (
                <p className="chat-message-date">
                  {moment(message.createdAt).format("MMM DD, YYYY")}
                </p>
              )}
              <ChatMessage
                key={message._id}
                message={message}
                isGuestMessage={isGuestMessage}
                displayAvatar={displayAvatar}
                displayDate={displayDate}
                setLightboxImages={setLightboxImages}
                setImageIndex={setImageIndex}
                saveCurrentScroll={saveCurrentScroll}
              />
            </div>
          );
        })}
        {!messages.length && (isLoading || loading) && <ChatMessagesSkeleton />}
        {!messages.length && !isLoading && !loading && (
          <p className="no-results">No messages yet</p>
        )}
      </div>

      <div className="message_field">
        {showScrollToBottomButton && (
          <div className="scroll-to-bottom-btn">
            <button onClick={() => scrollToBottom(true)}>
              <KeyboardArrowDownIcon />
            </button>
          </div>
        )}
        <label className="message_field_icon" htmlFor="choose-message-photo">
          <input
            type="file"
            name=""
            className="message_field_input_hidden"
            id="choose-message-photo"
            accept="image/*"
            multiple
            onChange={handleSelectImages}
          />
          <img className="message_field_icon" src={galleryIcon} alt="" />
        </label>
        <TextField
          className="message_field_input"
          variant="standard"
          value={messageText}
          onChange={e => setMessageText(e.target.value)}
          onKeyPress={handleSetMesssageText}
          type="text"
          minRows={0}
          maxRows={5}
          multiline
          placeholder="Type a message..."
        />
        {messageText && (
          <button type="button" onClick={handleSendMessage} className="submit">
            <img className="message_field_icon" src={sendIcon} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Chat;
