import React from "react";
import { useArtistsPageContext } from "./utils";
import ArtistsPage from "./ArtistsPage";

const ArtistsWrap = (): JSX.Element => {
  const artistsPageContext = useArtistsPageContext();
  const { state, dispatch } = artistsPageContext;
  return (
    <div>
      <ArtistsPage title dispatch={dispatch} state={state} text="" />
    </div>
  );
};

export default ArtistsWrap;
