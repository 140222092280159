import { INSTITUTION } from "./request-params/InstitutionRequestParams";
import { FULL_USER } from "./request-params/UserProfileRequestParams";
import { FULL_ARTWORK } from "./request-params/FullArtwork";
import { ACTION, EXHIBITION_LITE, IMAGE } from "./request-params/RequestsParams";

export const SEARCH_INSTITUTIONS = `
query searchInstitutions(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $text: String
  $isVirtual: Boolean
  $availableForFilmAndTV: Boolean
  $availableForEvents: Boolean
  $location: LocationSearchType
  $duration: ExhibitionDurationInput
  $kategories: [String]
) {
  searchInstitutions(
    sortBy: $sortBy
    orderBy: $orderBy
    text: $text
    limit: $limit
    offset: $offset
    isVirtual: $isVirtual
    availableForFilmAndTV: $availableForFilmAndTV
    availableForEvents: $availableForEvents
    location: $location
    duration: $duration
    kategories: $kategories
  ) {
    institutions {
      ${INSTITUTION}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const SEARCH_USERS = `
query searchArtists(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $text: String
  $bornCity: [String]
  $country: [String]
  $schools: [String]
  $teachers: [String]
  $awards: [String]
  $heritage: [String]
  $culture: [String]
  $ethnic: [String]
  $spiritualInspirations: [String]
  $tribalIdentities: [String]
  $categories: [String]
  $colors: [String]
  $saleableArtworks: Boolean
  $types: [String]
  $sex: [sex]
  $originsOfInspiration: [String]
) {
  searchArtists(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    text: $text
    bornCity: $bornCity
    country: $country
    schools: $schools
    teachers: $teachers
    awards: $awards
    heritage: $heritage
    culture: $culture
    ethnic: $ethnic
    spiritualInspirations: $spiritualInspirations
    tribalIdentities: $tribalIdentities
    categories: $categories
    colors: $colors
    saleableArtworks: $saleableArtworks
    types: $types
    sex: $sex
    originsOfInspiration: $originsOfInspiration
  ) {
    artists {
      ${FULL_USER}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const USERS = `
  query users ($text: String!) {
    users (text: $text) {
        _id
        profile {
          firstName
          middleName
          secondName
          image {
            ${IMAGE}
          }
        }
        hasInstitution
        institutions {
          name
        }
      }
    }
`;

export const SEARCH_ARTWORKS = `
query searchArtworks(
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $searchableText: String
  $categories: [String]
  $materials: [String]
  $inspiration: [String]
  $colors: [String]
  $forSale: Boolean
  $location: LocationSearchType
  $priceRange: searchPrice
  $heightRange: searchDimension
  $widthRange: searchDimension
  $types: [String]
  $ownerId: ID
  $collectorId: ID
  $culture: [String]
  $ethnic: [String]
  $spiritualInspirations: [String]
  $tribalIdentities: [String]
  $sortByLocation: LocationArtworkSortType
  $hasFilmsOrTv: Boolean
) {
  searchArtworks(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    text: $searchableText
    categories: $categories
    materials: $materials
    inspiration: $inspiration
    colors: $colors
    forSale: $forSale
    location: $location
    price: $priceRange
    width: $widthRange
    height: $heightRange
    types: $types
    ownerId: $ownerId
    culture: $culture
    ethnic: $ethnic
    spiritualInspirations: $spiritualInspirations
    tribalIdentities: $tribalIdentities
    sortByLocation: $sortByLocation
    collectorId: $collectorId
    hasFilmsOrTv: $hasFilmsOrTv
  ) {
    artworks {
      ${FULL_ARTWORK}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const SEARCH_EXHIBITIONS = `
query searchExhibitions(
  $limit: Int
  $offset: Int
  $text: String
  $location: LocationSearchType
  $duration: ExhibitionDurationInput
  $dayOfWeek: String
  $isVirtual: Boolean
  $private: Boolean
  $isOpenToSubmitions: Boolean
) {
  searchExhibitions(
    text: $text
    limit: $limit
    offset: $offset
    location: $location
    duration: $duration
    dayOfWeek: $dayOfWeek
    isVirtual: $isVirtual
    private: $private
    isOpenToSubmitions: $isOpenToSubmitions
  ) {
    exhibitions {
      ${EXHIBITION_LITE}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const SEARCH_ACTIONS = `
query searchActions(
  $limit: Int 
  $offset: Int 
  $type: [ActionType]
  $user: [String]
  ) {
  searchActions(
    limit: $limit 
    offset: $offset 
    type: $type
    user: $user
  ) {
    actions {
      ${ACTION}
    }
    meta {
      limit
      offset
      total
      lastSyncedAt
    }
  }
}`;

export const SEARCH_COLLECTORS = `
query searchCollectors(
  $text: String
) {
  searchCollectors(
      text: $text
  ) {
  collectors{
      _id
    profile {
      firstName
      middleName
      secondName
      image{
        ${IMAGE}
      }
    }
  }
  }
}
`;
