import React, { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { CommentsListProps } from "./CommentsList.props";
import CommentComponent from "../comment/CommentComponent";
import { IComment } from "../../types/comment";
import Button from "../../UI/button/Button";
import CommentInput from "../comment/CommentInput";
import {
  addCommentToArtwork,
  addExhibitionComment,
  fetchBlockedUsers,
} from "../../api-queries/GraohQlClient";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import "./CommentsList.scss";

const CommentsListComponent: FC<CommentsListProps> = ({
  comments,
  refetch,
  type,
  artworkOwnerId,
}): JSX.Element => {
  const [isCollector, setIsCollector] = useState(false);
  const [isUserBlockedMe, setIsUserBlockedMe] = useState();
  const [isCollectorBlockedMe, setIsCollectorBlockedMe] = useState(false);
  const [isUserBlockedByMe, setIsUserBlockedByMe] = useState(false);
  const [isCollectorBlockedByMe, setIsCollectorBlockedByMe] = useState(false);
  const { id } = useParams<string>();
  const [limit, setLimit] = useState(5);
  const [index, setIndex] = useState(limit);
  const [commentsList, setCommentsList] = useState<Array<IComment>>(comments?.slice(0, limit));
  const [showMore, setShowMore] = useState(true);
  const myRef = useRef<null | HTMLDivElement>(null);
  const myMOCAId = localStorage.getItem("myMOCAId");
  const { artworkData } = useTypedSelector(state => state.artworkDetailsReducer);

  useEffect(() => {
    if (comments?.length - 1 < limit) {
      setShowMore(false);
    }
    setCommentsList(comments?.slice(0, limit));
  }, [comments]);

  const initWithArtworkId = async (): Promise<void> => {
    await setIsCollector(
      artworkData?.collector !== null && artworkData?.collector._id === myMOCAId,
    );
  };

  const wrapFetchBlockedUsers = async (userId: string): Promise<any> =>
    fetchBlockedUsers({ userId });

  const checkIsBlocked = async (): Promise<void> => {
    try {
      await initWithArtworkId();
      const artworkOwner = artworkOwnerId && (await wrapFetchBlockedUsers(artworkOwnerId));
      const artworkOwnerBlockers = artworkOwner?.blockedUsers?.blockers || [];
      setIsUserBlockedMe(artworkOwnerBlockers.find((user: any) => user._id === myMOCAId));
      const artworkCollector =
        artworkData?.collector._id && (await wrapFetchBlockedUsers(artworkData.collector._id));
      const artworkCollectorBlockers = artworkCollector?.blockedUsers?.blockers || [];
      setIsCollectorBlockedMe(
        artworkCollectorBlockers.find((user: any) => user._id === myMOCAId) || false,
      );
      const artworkOwnerBlocked = artworkOwnerId && (await wrapFetchBlockedUsers(artworkOwnerId));
      const { blockers } = artworkOwnerBlocked;
      setIsUserBlockedByMe(
        blockers?.find((blocking: any) => blocking.blocked._id === myMOCAId) || false,
      );
      setIsCollectorBlockedByMe(
        blockers.find((user: any) => user._id === artworkData?.collector._id) || false,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const shouldShowReplyButton =
    isCollector || artworkData?.collector
      ? !isCollectorBlockedMe && !isCollectorBlockedByMe
      : !isUserBlockedByMe && !isUserBlockedMe;

  const loadMore = (): void => {
    const newIndex = limit + index;
    const newShowMore = newIndex < comments.length - 1;
    setCommentsList([...commentsList, ...comments?.slice(index, newIndex)]);
    setIndex(newIndex);
    setShowMore(newShowMore);
  };

  const handleSubmit = (text: string): void => {
    switch (type) {
      case "exhibition":
        addExhibitionComment({
          exhibitionId: id,
          text,
        }).then(() => {
          if (refetch) refetch();
        });
        break;
      case "artwork":
        addCommentToArtwork({
          artworkId: id,
          text,
        }).then(({ data }) => {
          setLimit(comments.length + 1);
          setCommentsList([...comments, data?.addCommentToArtwork]);
          if (refetch) refetch();
          myRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        });
        break;
      default:
        break;
    }
  };

  const renderCommentInput = (): JSX.Element | undefined => {
    if (shouldShowReplyButton) {
      return <CommentInput handleSubmit={handleSubmit} placeholder="Add comment ..." />;
    }
    return undefined;
  };

  const findComment = (idComment: string): void => {
    const filter = commentsList.filter(comment => comment._id !== idComment);
    setCommentsList(filter);
    refetch();
  };

  useEffect(() => {
    checkIsBlocked();
  }, []);

  return (
    <div className="comments">
      <div className="comments_list">
        {commentsList?.map((comment: IComment) => {
          return (
            <CommentComponent
              type={type}
              findComment={findComment}
              key={comment._id}
              text={comment.text}
              author={comment.author}
              createdAt={comment.createdAt}
              replies={comment.replies}
              likesCount={comment.likesCount}
              repliesCount={comment.repliesCount}
              image={comment.author?.profile?.image?.thumbnail?.url || ""}
              commentId={comment._id || ""}
              likes={comment.likes}
              refetch={refetch}
              _id={id}
              artworkOwnerId={artworkOwnerId || ""}
              shouldShowReplyButton={shouldShowReplyButton}
            />
          );
        })}
      </div>
      <div className="comments_buttons">
        {renderCommentInput()}
        {showMore && (
          <Button
            onClick={loadMore}
            children="Load more comments"
            variant="outlined"
            className="load_more"
          />
        )}
      </div>
      <div ref={myRef} id="hash" />
    </div>
  );
};

export default CommentsListComponent;
