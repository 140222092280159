import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lightbox from "react-image-lightbox";

import Carousel from "react-elastic-carousel";
import { ImageLightboxProps } from "./ImageLightbox.props";
import { IImage } from "../../types/imageData";
import Button from "../../UI/button/Button";
import "react-image-lightbox/style.css";
import ReportButton from "../report-button/ReportButton";
import { ReportItemTypes } from "../report-button/utils";
import { reportArtwork } from "../../api-queries/GraohQlClient";

import "./ImageLightbox.scss";
import SliderArrows from "../slider-arrows/SliderArrows";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { artworks, editArtwork, explore, myNominatedArtwork, uploadedNow } from "../../route-link";

const ImageLightbox: FC<ImageLightboxProps> = ({
  mainImage,
  images,
  artworkId,
  showOwnerActionsButtons,
  showCollectorActionButtons,
  notVotesArtwork,
  artwork,
}) => {
  const navigate = useNavigate();
  const { setBackUrl } = useActions();
  const { backUrl } = useTypedSelector(state => state.backUrlReducer);
  const onCloseLink: any = backUrl || -1;
  const [imageIndex, setImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imageArray, setImageArray] = useState<Array<IImage>>([]);
  const [mainImgUrl, setMainImgUrl] = useState(mainImage?.medium.url);
  const { state, pathname }: any = useLocation();
  const isMyNominatedArtwork = pathname.includes(myNominatedArtwork);

  useEffect(() => {
    setImageArray([mainImage, ...images]);
    if (mainImage?.origin?.url) {
      setTimeout(() => {
        setMainImgUrl(mainImage?.origin?.url);
      }, 1000);
    }
  }, [images, mainImage]);

  const showReportButton = Boolean(!showOwnerActionsButtons && !showCollectorActionButtons);

  const onMainImage = (): void => {
    setImageIndex(0);
    setIsOpen(true);
  };
  const onImageArray = (index: number): void => {
    setImageIndex(index + 1);
    setIsOpen(true);
  };
  const closePage = (): void => {
    setBackUrl("");
    if (pathname.includes(uploadedNow)) {
      navigate(state.backSteps);
      return;
    }
    navigate(onCloseLink);
  };

  const pushEditArtwork = async (): Promise<void> => {
    navigate(`/${explore}/${artworks}/${artworkId}/${editArtwork}`, {
      state: artwork,
    });
  };

  return (
    <div className="light_box">
      <div className="light_box__buttons">
        <Button children="Close" variant="text" onClick={closePage} />
        {isMyNominatedArtwork && (
          <Button children="Edit" variant="text" onClick={pushEditArtwork} />
        )}
        {showReportButton && !isMyNominatedArtwork && (
          <ReportButton
            reportItemType={ReportItemTypes.Artwork}
            itemId={artworkId}
            reportAction={reportArtwork}
            className="explore_item_details_right__report_button"
          />
        )}
      </div>
      <div className="main_image" onClick={onMainImage}>
        <img src={mainImgUrl} alt="" />
      </div>
      {!!images.length && notVotesArtwork && (
        <div className="light_box__images">
          <Carousel
            disableArrowsOnEnd
            renderArrow={SliderArrows}
            isRTL={false}
            pagination={false}
            itemsToShow={4}
            itemPadding={[0, 5, 0, 5]}
          >
            {images.map((image, index) => {
              return (
                // eslint-disable-next-line
                <div
                  className="light_box__image"
                  key={image._id}
                  onClick={() => onImageArray(index)}
                >
                  <img src={image.medium?.url || image.thumbnail?.url} alt="a" />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={imageArray[imageIndex]?.origin?.url || imageArray[imageIndex]?.medium?.url}
          onCloseRequest={() => setIsOpen(false)}
          nextSrc={
            imageArray[imageIndex + 1]?.origin?.url || imageArray[imageIndex + 1]?.medium?.url
          }
          prevSrc={
            imageArray[imageIndex - 1]?.origin?.url || imageArray[imageIndex - 1]?.medium?.url
          }
          onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
          onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
          enableZoom={false}
        />
      )}
    </div>
  );
};

export default ImageLightbox;
