import React from "react";
import { useOutletContext } from "react-router-dom";

import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import ShowsPage from "../shows-page/ShowsPage";

const SubmitArtExplorePage = (): JSX.Element => {
  const { submitArtPageContext }: any = useOutletContext();
  return (
    <div className="submit_art_page">
      <ScrollToTop />
      <ShowsPage
        submitToShowBtn={false}
        isOpenToSubmitions
        context={submitArtPageContext}
        titleText="SUBMIT TO SHOWS"
      />
    </div>
  );
};

export default SubmitArtExplorePage;
