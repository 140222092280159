import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import { IArtwork } from "../../types/artwork/artwork";
import { IImage } from "../../types/imageData";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";

interface SharedArtworkNotificationProps {
  nameFrom: string;
  userId: string;
  artwork: IArtwork;
  time: Date;
  userImage: IImage;
}

const SharedArtworkNotification: React.FC<SharedArtworkNotificationProps> = ({
  nameFrom,
  userId,
  time,
  artwork,
  userImage,
}): JSX.Element => {
  const nameArtwork = artwork?.name;
  const authUserId = localStorage.getItem("myMOCAId") || "";
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        shared artwork "{nameArtwork}" uploaded by{" "}
        <Link className="notification_link" to={`${explore}/${artists}/${authUserId}`}>
          You
        </Link>
      </p>
      <ArtworkNoticeAudited artwork={artwork} time={time} />
    </div>
  );
};

export default SharedArtworkNotification;
