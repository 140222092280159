export interface ButtonUpState {
  classesButton: boolean;
}

export enum SetButtonUpActionTypes {
  TOGGLE_BUTTON = "TOGGLE_BUTTON",
}

interface SetButtonUp {
  type: SetButtonUpActionTypes.TOGGLE_BUTTON;
  payload: boolean;
}

export type ButtonUpAction = SetButtonUp;
