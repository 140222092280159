import React, { FC } from "react";
import { ArtistProfileArtworkTabsValue } from "../artist-profile-artworks-tabs/utils";
import ArtworksForSale from "./ArtworksForSale";
import CollectedArtworks from "./CollectedArtworks";
import CreatedArtworks from "./CreatedArtworks";
import FoundArtworks from "./FoundArtworks";

interface IArtistProfileArtworks {
  activeTab: string;
  isInstitution: boolean;
}

const ArtistProfileArtworks: FC<IArtistProfileArtworks> = ({ activeTab }) => {
  // eslint-disable-next-line
  const displayArtworks = (activeTab: string) => {
    switch (activeTab) {
      case ArtistProfileArtworkTabsValue.createdArtworks:
        return <CreatedArtworks />;
      case ArtistProfileArtworkTabsValue.artworksForSale:
        return <ArtworksForSale />;
      case ArtistProfileArtworkTabsValue.collectedArtworks:
        return <CollectedArtworks />;
      case ArtistProfileArtworkTabsValue.foundArtworks:
        return <FoundArtworks />;
      default:
        return null;
    }
  };
  return displayArtworks(activeTab);
};

export default ArtistProfileArtworks;
