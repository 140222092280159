import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import SelectSort from "../select-sort/SelectSort";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

interface FilterTopBarProps {
  options: any;
  setQueryValue: any;
  sortValue: any;
  setSortValue: any;
  renderValue: any;
}

const FilterTopBar: React.FC<FilterTopBarProps> = ({
  options,
  setQueryValue,
  sortValue,
  setSortValue,
  renderValue,
}): JSX.Element => {
  const { openFilter, closeFilter } = useActions();
  const { filterWindowOpen } = useTypedSelector(store => store.filterReducer);
  const onFilterIconClick = filterWindowOpen ? closeFilter : openFilter;
  return (
    <div className="filter_top_bar">
      <div className="filter_top_bar__icon" onClick={onFilterIconClick}>
        <FilterListIcon className="filter_icon" />
        Filter
      </div>
      <SelectSort
        className="select_sort"
        options={options}
        setQueryValue={setQueryValue}
        sortValue={sortValue}
        setSortValue={setSortValue}
        renderValue={renderValue}
        height={50}
        width={350}
      />
    </div>
  );
};

export default FilterTopBar;
