import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import { USER_GQL } from "../../api-queries/UserRequests";
import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";
import { getUniqueArtworks } from "../../components/artworks-explore-list/utils";
import { IArtwork } from "../../types/artwork/artwork";

const ArtTransferred = (): JSX.Element => {
  const { id }: IParamsId = useParams();
  const { data, refetch } = useQuery(USER_GQL, {
    variables: { limit: 50, _id: id },
  });
  const [uniqueArtworks, setUniqueArtworks] = useState<(IArtwork | undefined)[] | null>(null);

  useEffect(() => {
    if (data?.user.myPastCollectedArtworks) {
      setUniqueArtworks(getUniqueArtworks(data.user.myPastCollectedArtworks));
    }
  }, [data]);
  return (
    <div className="artworks_found">
      <ArtsControllerList
        artworks={uniqueArtworks}
        refetch={refetch}
        title="Past Collected Artworks"
      />
    </div>
  );
};

export default ArtTransferred;
