import uniqid from "uniqid";

export const PREDEFINED_MATERIALS = [
  "Oil",
  "Canvass",
  "Acrylics",
  "Pen",
  "Pencil",
  "Paint",
  "Spray Paint",
  "Paint Pen",
  "Brush",
  "Knife",
];

export const PREDEFINED_MATERIALS_TEST = [
  { label: "Oil", isSelected: true },
  { label: "Canvass", isSelected: true },
  { label: "Acrylics", isSelected: true },
  { label: "Pen", isSelected: true },
  { label: "Pencil", isSelected: true },
  { label: "Paint", isSelected: true },
  { label: "Spray", isSelected: true },
  { label: "Paint", isSelected: true },
  { label: "Brush", isSelected: true },
  { label: "Knife", isSelected: true },
];

export const PREDEFINED_COLORS = [
  "Green",
  "Black",
  "White",
  "Yellow",
  "Grey",
  "Orange",
  "Pink",
  "Red",
  "Blue",
  "Brown",
];

export const PREDEFINED_MATERIALS_EDIT = [
  {
    value: "Oil",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Canvass",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Acrylics",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Pen",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Pencil",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Paint",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Spray Paint",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Paint Pen",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Brush",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Knife",
    id: uniqid(),
    addNewActive: true,
  },
];

export const PREDEFINED_COLORS_EDIT = [
  {
    value: "Green",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Black",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "White",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Yellow",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Grey",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Orange",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Pink",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Red",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Blue",
    id: uniqid(),
    addNewActive: true,
  },
  {
    value: "Brown",
    id: uniqid(),
    addNewActive: true,
  },
];
