import React from "react";
import { useOutletContext } from "react-router-dom";

import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import ExplorePageTitle from "../../components/explore-page-title/ExplorePageTitle";
import ArtworksPageComponent from "../../components/artworks-page-component/ArtworksPageComponent";
import TooltipTextMovieAndTV from "../../components/explore-page-title/components/TooltipTextMovieAndTV";

const TvArtExplorePage = (): JSX.Element => {
  const { tvPageContext }: any = useOutletContext();
  const { state, dispatch } = tvPageContext;
  return (
    <>
      <ScrollToTop />
      <div className="wrapper">
        <ExplorePageTitle
          title="ART FOR MOVIES & TV"
          myMoca
          shareMark
          questionMark
          tooltipText={<TooltipTextMovieAndTV />}
        />
      </div>
      <ArtworksPageComponent
        context={{ state, dispatch }}
        showMap
        query={undefined}
        queryParams={{ hasFilmsOrTv: true, allowUseForFilmAndTv: true }}
        title={false}
      />
    </>
  );
};

export default TvArtExplorePage;
