import React, { useState } from "react";
import Modal from "react-modal";
import { modalWindowStyles } from "../../styles/modalStyles";

import ModalWrap from "../modal-wrap/ModalWrap";

import Login from "../login/Login";
import Signup from "../signup/Signup";
import { logo } from "../../assets";

import "./Registration.scss";

const Registration = ({ isOpen, closeModal }) => {
  const [login, setLogin] = useState(false);
  const loginText = !login ? "have an account? Sign Up" : "have an account? Sign In";
  const accountText = !login ? "Don't " : "Already ";
  const onChangeLogin = () => setLogin(prev => !prev);

  const loginWrap = !login ? <Login /> : <Signup />;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalWindowStyles}
      contentLabel="Example Modal"
      overlayClassName="modal_custom"
      ariaHideApp={false}
    >
      <ModalWrap closeModal={closeModal}>
        <div className="registration">
          <div className="registration_image__block">
            <img className="registration_image" src={logo} alt="Logo Moca" />
          </div>
          <div className="registration_title">My MOCA</div>
          <p className="registration_title__sub">by The New York Museum of Contemporary Art</p>
          {loginWrap}
          <div className="signup_have">
            {accountText}
            <span onClick={onChangeLogin} className="signup_link">
              {loginText} ?
            </span>
          </div>
        </div>
      </ModalWrap>
    </Modal>
  );
};
export default Registration;
