import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Masonry from "react-masonry-css";
import { ExhibitionRequests } from "../../api-queries/AllRequests";
import { useActions } from "../../hooks/useActions";
import "../../styles/buttonStyles.scss";
import {
  SET_CALENDAR_VALUE,
  SET_DURATION_SORT_VALUE,
  SET_FILTER_QUERY_PARAMS,
  SET_LOCATION_AUTOCOMPLETE_VALUE,
  SET_LOCATION_SORT_VALUE,
  SET_OFFSET,
  SET_SHOWS,
} from "../../context/action-creators/showsPageActions";
import { BREAKPOINT_COLUMNS_SHOWS } from "../../misc/constants";
import ShowItem from "../../components/show-item/ShowItem";
import Button from "../../UI/button/Button";
import SelectSort from "../../UI/select-sort/SelectSort";
import { DurationFilters, durationFilters } from "./utils";
import ItemsNotFound from "../../components/items-not-found/ItemsNotFound";
import ExplorePageTitle from "../../components/explore-page-title/ExplorePageTitle";
import TooltipTextShows from "../../components/explore-page-title/components/TooltipTextShows";
import LocationFilterSelect from "../../components/location-filter-select/LocationFilterSelect";
import {
  LocationFilterNames,
  locationFilters,
} from "../../components/location-filter-select/utils";
import LeafletMap from "../../components/leaflet-maps/LeafletMap";

import "./ShowsPage.scss";
import { explore, submitArt } from "../../route-link";

const ShowsPage = ({
  submitToShowBtn = true,
  isOpenToSubmitions,
  context,
  text = "",
  showMap = true,
  title = true,
  titleText,
}) => {
  const limit = 50;
  const { state, dispatch } = context;
  const {
    shows,
    offset,
    durationSortValue,
    filterQueryParams,
    calendarValue,
    locationSortValue,
    locationAutocompleteValue,
  } = state;

  const { data: searchShows, refetch } = useQuery(ExhibitionRequests.SEARCH_EXHIBITIONS, {
    variables: { limit, ...filterQueryParams, isOpenToSubmitions, text },
  });

  const navigate = useNavigate();

  const setShows = payload => dispatch(SET_SHOWS(payload));
  const setOffset = payload => dispatch(SET_OFFSET(payload));
  const setDurationSortValue = payload => dispatch(SET_DURATION_SORT_VALUE(payload));
  const setFilterQueryParams = payload => dispatch(SET_FILTER_QUERY_PARAMS(payload));
  const setCalendarValue = payload => dispatch(SET_CALENDAR_VALUE(payload));
  const setLocationSortValue = payload => dispatch(SET_LOCATION_SORT_VALUE(payload));
  const setLocationAutocompleteValue = payload =>
    dispatch(SET_LOCATION_AUTOCOMPLETE_VALUE(payload));

  const { openModal } = useActions();
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [currentCalendarValue, setCurrentCalendarValue] = useState(new Date());

  const total = searchShows?.searchExhibitions.meta.total;

  const onDurationOptionClick = ({ target }) => {
    if (target?.outerText === DurationFilters.CUSTOM) {
      setIsOpenCalendar(true);
    }
  };

  const sortByDuration = ({ value }) => {
    const { duration, isPrivate } = value;
    if (value === DurationFilters.CUSTOM) {
      setIsOpenCalendar(true);
      return;
    }
    setShows([]);
    setOffset(0);
    setFilterQueryParams({ ...filterQueryParams, duration, private: isPrivate, offset: 0 });
  };

  const onCustomDatePick = value => {
    if (!value) return;
    const duration = {
      from: moment(value).startOf("day").toDate(),
      to: moment(value).startOf("day").add(1, "d").toDate(),
    };
    if (duration.from?.toLocaleString() === filterQueryParams.duration?.from?.toLocaleString()) {
      return;
    }
    setCalendarValue(value);
    setShows([]);
    setOffset(0);
    setFilterQueryParams({
      ...filterQueryParams,
      duration,
      dayOfWeek: undefined,
      isVirtual: undefined,
      offset: 0,
    });

    setIsOpenCalendar(false);
  };

  const onCalendarChange = newValue => {
    setCurrentCalendarValue(newValue);
  };

  const scrollHandler = async () => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
      return;
    }
    if (shows.length >= limit) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setShows([...shows, ...(res.data?.searchExhibitions?.exhibitions || [])]);
    }
  };

  const renderDurationSelectValue = ({ name, value }) => {
    if (value === DurationFilters.CUSTOM && calendarValue) {
      return `${moment(calendarValue).format("MMM D")}`;
    }
    return name;
  };

  const onCalendarClose = () => {
    setIsOpenCalendar(false);
  };

  const backToAllItems = () => {
    const anytimeFilterValue = durationFilters.find(item => item.name === DurationFilters.ANYTIME);
    const anywhereFilterValue = locationFilters.find(
      item => item.name === LocationFilterNames.ANYWHERE,
    );
    setShows([]);
    setOffset(0);
    setDurationSortValue(anytimeFilterValue);
    setLocationSortValue(anywhereFilterValue);
    setFilterQueryParams({
      offset: 0,
      dayOfWeek: undefined,
      duration: undefined,
      isVirtual: undefined,
      location: undefined,
    });
  };

  useEffect(() => {
    if (!shows.length) {
      setShows([...shows, ...(searchShows?.searchExhibitions.exhibitions || [])]);
    }
  }, [searchShows]);

  const redirectToSubmitArt = () => {
    navigate(`/${explore}/${submitArt}`);
  };

  return (
    <div className="shows_page wrapper">
      {title && (
        <ExplorePageTitle
          title={titleText}
          questionMark
          shareMark
          myMoca
          tooltipText={<TooltipTextShows />}
        />
      )}
      {showMap && <LeafletMap setIsOpen={openModal} exhibitions={shows} zoom={3} />}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/dd/yyyy"
          value={currentCalendarValue}
          onChange={onCalendarChange}
          open={isOpenCalendar}
          renderInput={() => ""}
          onAccept={onCustomDatePick}
          onClose={onCalendarClose}
        />
      </LocalizationProvider>
      <div className="explore_list">
        <Masonry
          breakpointCols={BREAKPOINT_COLUMNS_SHOWS}
          className="shows_page__masonry_grid_panel"
          columnClassName="shows_page__grid_column"
        >
          <div className="shows_page__location_filter">
            <LocationFilterSelect
              width="100%"
              setDataItem={setShows}
              locationAutocompleteValue={locationAutocompleteValue}
              setLocationAutocompleteValue={setLocationAutocompleteValue}
              locationSortValue={locationSortValue}
              setLocationSortValue={setLocationSortValue}
              setOffset={setOffset}
              filterQueryParams={filterQueryParams}
              setFilterQueryParams={setFilterQueryParams}
            />
          </div>
          <div className="shows_page__duration_filter">
            <SelectSort
              className="select_sort"
              height={51}
              width="100%"
              sortValue={durationSortValue}
              options={durationFilters}
              setSortValue={setDurationSortValue}
              setQueryValue={sortByDuration}
              onClick={onDurationOptionClick}
              renderValue={renderDurationSelectValue}
            />
          </div>
          <div className="shows_page__buttons">
            {submitToShowBtn && (
              <Button
                onClick={redirectToSubmitArt}
                className="shows_page__submit_button"
                color="light"
                variant="outlined"
              >
                SUBMIT TO SHOW
              </Button>
            )}
            <Button onClick={openModal} className="shows_page__post_button" color="dark">
              POST SHOW
            </Button>
          </div>
        </Masonry>
        {shows && (
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={shows.length}
            next={scrollHandler}
            hasMore
          >
            <Masonry
              breakpointCols={BREAKPOINT_COLUMNS_SHOWS}
              className="shows_page__masonry_grid"
              columnClassName="shows_page__grid_column"
            >
              {shows.map(show => {
                return (
                  <ShowItem
                    key={show._id}
                    image={show.image}
                    name={show.title}
                    location={show.location}
                    id={show._id}
                    isOpenToSubmitions={show.isOpenToSubmitions}
                    duration={show.duration}
                  />
                );
              })}
            </Masonry>
          </InfiniteScroll>
        )}
        {total === 0 && <ItemsNotFound title="No Shows found" onClick={backToAllItems} />}
      </div>
    </div>
  );
};

export default ShowsPage;
