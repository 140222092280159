import React from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

import "./ButtonResult.scss";

interface ButtonResultProps {
  title: string;
  id: string;
  type: string;
  onDelete: (id: string, type: string) => void;
}

const ButtonResult: React.FC<ButtonResultProps> = ({ title, onDelete, id, type }) => {
  return (
    <Button variant="outlined" id="button_result">
      <span className="button_result__title">{title}</span>
      <CloseIcon className="close" onClick={() => onDelete(id, type)} />
    </Button>
  );
};

export default ButtonResult;
