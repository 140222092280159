import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CommentGridReplyProps } from "./CommentGrid.props";
import Button from "../../UI/button/Button";
import formatShortDate from "../../utils/format-short-date";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { artistLink, explore } from "../../route-link";
import ButtonLike from "../../UI/button-like/ButtonLike";
import { removeComment, toggleLike } from "../../api-queries/GraohQlClient";
import { ExhibitionRequests } from "../../api-queries/AllRequests";
import { GetToken } from "../../utils/get-token";
import { useActions } from "../../hooks/useActions";

const CommentGridReply: React.FC<CommentGridReplyProps> = ({
  firstName,
  secondName,
  createdAtDate,
  text,
  likesCount,
  image,
  authorId,
  commentId,
  likes,
  refetch,
  replyLikesCount,
  replyId,
  findCommentReply,
}) => {
  const navigate = useNavigate();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const { openModalLogin } = useActions();
  const [showRemove, setShowRemove] = useState(false);
  const goToProfile = (): void => {
    navigate(`${explore}${artistLink}/${authorId}`);
    window.scroll(0, 0);
  };
  const handleLikeClick = (
    setIsLiked: (toggle: boolean) => void,
    setLikesCounter: (prevState: any) => void,
  ): void => {
    toggleLike(ExhibitionRequests.TOGGLE_COMMENT_LIKE, { commentId: replyId }).then(
      ({ toggleCommentLike }) => {
        if (toggleCommentLike.liked) {
          setLikesCounter((prevState: number) => prevState + 1);
        }
        if (!toggleCommentLike.liked) {
          setLikesCounter((prevState: number) => prevState - 1);
        }
        setIsLiked(toggleCommentLike.liked);
        refetch();
      },
    );
  };

  const handleRemoveReply = async (): Promise<void> => {
    findCommentReply(replyId);
    await removeComment(ExhibitionRequests.REMOVE_COMMENT_REPLY, {
      commentId,
      replyId,
    });
    refetch();
  };

  useEffect(() => {
    if (authorId === myMOCAId) setShowRemove(true);
  }, []);

  return (
    <div className="comment_grid">
      {image ? (
        <Avatar className="comment_grid__avatar" onClick={goToProfile} src={image} />
      ) : (
        <AvatarPlaceholder
          onClick={goToProfile}
          id={authorId}
          width="40px"
          height="40px"
          fontSize="10px"
          className="comment_grid__avatar"
        />
      )}
      <div className="comment_content">
        <div onClick={goToProfile} className="comment_author">
          {firstName} {secondName}
        </div>
        <div className="comment_date">
          {createdAtDate && formatShortDate(createdAtDate)}
          <ButtonLike
            likes={likes || []}
            likesCount={likesCount || 0}
            replyLikesCount={replyLikesCount}
            handleLikeClick={GetToken() ? handleLikeClick : openModalLogin}
          />
        </div>
        <div className="comment_text">
          {text}{" "}
          {showRemove && (
            <Button
              children="Remove"
              variant="text"
              onClick={handleRemoveReply}
              className="reply"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentGridReply;
