import { IArtwork } from "../../types/artwork/artwork";

export interface IScanArtwork {
  artworks: IArtwork[];
}
export interface UserScanArtworksData {
  scans: {
    scans: IScanArtwork[];
  };
}
export const getScanArtworks = (data: UserScanArtworksData): IArtwork[] => {
  return data.scans.scans
    .filter(({ artworks }) => artworks.length > 0)
    .map(({ artworks }) => artworks[0]);
};
