import React, { FC, memo } from "react";
import { Avatar } from "@mui/material";
import cn from "classnames";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import ArtworkMessage from "./artwork-message/ArtworkMessage";
import ExhibitionMessage from "./exhibition-message/ExhibitionMessage";
import PostMessage from "./post-message/PostMessage";
import TextMessage from "./text-message/TextMessage";
import UserMessage from "./user-message/UserMessage";
import { IChatMessageProps } from "./utils";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";

import doubleTickIcon from "../../assets/images/double-tick-white@3x.png";
import singleTickIcon from "../../assets/images/one-tick-white@3x.png";
import clockIcon from "../../assets/images/clock-white@3x.png";

import "./ChatMessage.scss";
import { artists, explore } from "../../route-link";

const ChatMessage: FC<IChatMessageProps> = ({
  message,
  isGuestMessage,
  displayAvatar,
  setImageIndex,
  setLightboxImages,
  saveCurrentScroll,
}) => {
  const navigate = useNavigate();
  const { user, artwork, post, exhibition } = message.audited;
  const renderMessage = (): any => {
    if (user) return <UserMessage message={message} saveCurrentScroll={saveCurrentScroll} />;
    if (artwork) return <ArtworkMessage message={message} saveCurrentScroll={saveCurrentScroll} />;
    if (post) return <PostMessage message={message} />;
    if (exhibition) return <ExhibitionMessage message={message} />;
    return (
      <TextMessage
        message={message}
        setImageIndex={setImageIndex}
        setLightboxImages={setLightboxImages}
      />
    );
  };
  const renderStatus = (): any => {
    if (message.read === undefined) return clockIcon;
    return message.read ? doubleTickIcon : singleTickIcon;
  };

  const goToUserProfile = (): void => {
    navigate(`/${explore}/${artists}/${message.owner._id}`);
  };
  return (
    <>
      <div
        className={cn({
          "guest-message": isGuestMessage,
          "my-message": !isGuestMessage,
          "no-avatar-message": !displayAvatar,
        })}
      >
        <div onClick={goToUserProfile}>
          {displayAvatar && isGuestMessage && (
            <>
              {message.owner.profile.image?.thumbnail?.url ? (
                <Avatar
                  src={message.owner.profile.image?.thumbnail?.url}
                  className="guest-avatar"
                />
              ) : (
                <AvatarPlaceholder
                  className="guest-avatar-placeholder"
                  id={message.owner._id}
                  width="40px"
                  height="40px"
                  fontSize="8px"
                />
              )}
            </>
          )}
        </div>
        <div className="chat-message_content">
          {renderMessage()}
          <div className="chat-message_info">
            <span className="chat-message_timestamp">
              {moment(message.createdAt).format("hh:mm A")}
            </span>
            {!isGuestMessage && <img className="chat-message_status" src={renderStatus()} alt="" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ChatMessage);
