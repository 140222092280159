import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";

// export enum CollectorsPageActionTypes {
export enum OperatorsSearchActionTypes {
  SET_ARTISTS_OPERATORS = "SET_ARTISTS_OPERATORS",
  SET_OFFSET_OPERATORS = "SET_OFFSET_OPERATORS",
  SET_SORT_VALUE_OPERATORS = "SET_SORT_VALUE_OPERATORS",
  SET_FILTER_QUERY_PARAMS_OPERATORS = "SET_FILTER_QUERY_PARAMS_OPERATORS",
  SET_FILTER_RESULTS_OPERATORS = "SET_FILTER_RESULTS_OPERATORS",
  SET_ORIGINS_OF_INSPIRATION_OPERATORS = "SET_ORIGINS_OF_INSPIRATION_OPERATORS",
  SET_GENDER_OPERATORS = "SET_GENDER_OPERATORS",
  SET_LOCATION_FILTER_OPERATORS = "SET_LOCATION_FILTER_OPERATORS",
  SET_RADIUS_OPERATORS = "SET_RADIUS_OPERATORS",
}

export interface ILocationFilter {
  label: string;
  value: any;
}

export interface IArtistsPageInitialState {
  artists: IUser[];
  offset: number;
  sortValue: IArtistSortValue;
  filterQueryParams: any;
  filterResults: IResultButton[];
  originsOfInspiration: IFilter[];
  gender: IGenderFilterOption;
  location: ILocationFilter | null;
  radius: number;
}

export interface setArtistsOperators {
  type: OperatorsSearchActionTypes.SET_ARTISTS_OPERATORS;
  payload: IUser[];
}

export interface setOffsetOperators {
  type: OperatorsSearchActionTypes.SET_OFFSET_OPERATORS;
  payload: number;
}

export interface setSortValueOperators {
  type: OperatorsSearchActionTypes.SET_SORT_VALUE_OPERATORS;
  payload: IArtistSortValue;
}

export interface setFilterQueryParamsOperators {
  type: OperatorsSearchActionTypes.SET_FILTER_QUERY_PARAMS_OPERATORS;
  payload: any;
}

export interface setFilterResultsOperators {
  type: OperatorsSearchActionTypes.SET_FILTER_RESULTS_OPERATORS;
  payload: IResultButton[];
}

export interface setOriginsOfInspirationOperators {
  type: OperatorsSearchActionTypes.SET_ORIGINS_OF_INSPIRATION_OPERATORS;
  payload: IFilter[];
}

export interface setGenderOperators {
  type: OperatorsSearchActionTypes.SET_GENDER_OPERATORS;
  payload: IGenderFilterOption;
}

export interface setLocationFilterOperators {
  type: OperatorsSearchActionTypes.SET_LOCATION_FILTER_OPERATORS;
  payload: ILocationFilter | null;
}

export interface setRadiusOperators {
  type: OperatorsSearchActionTypes.SET_RADIUS_OPERATORS;
  payload: number;
}

export type OperatorsSearchActions =
  | setArtistsOperators
  | setOffsetOperators
  | setSortValueOperators
  | setFilterQueryParamsOperators
  | setFilterResultsOperators
  | setOriginsOfInspirationOperators
  | setGenderOperators
  | setLocationFilterOperators
  | setRadiusOperators;
