/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, FC } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ReplyIcon from "@mui/icons-material/Reply";

import { useActions } from "../../hooks/useActions";
import { IUser } from "../../types/userTypes";
import { IArtwork } from "../../types/artwork/artwork";
import { GetToken } from "../../utils/get-token";
import { submitArtworkShare } from "../../api-queries/GraohQlClient";
import { IShowExhibitions } from "../../types/exhibitions";

interface ShareButtonMenuProps {
  type: "artwork" | "profile" | "exhibition";
  shareData: {
    user?: IUser;
    artwork?: IArtwork;
    exhibition?: IShowExhibitions;
  };
  setSharesCount?: (count: number) => void;
  sharesCount?: number;
  refetch?: () => void;
  openShareArtworkModal?: () => void;
}

const ShareButtonMenu: FC<ShareButtonMenuProps> = ({
  type,
  shareData,
  setSharesCount,
  refetch,
  sharesCount,
  openShareArtworkModal,
}) => {
  const ITEM_HEIGHT = 48;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const token = GetToken();

  const { setShowMessagesModal, setCurrentStage, setMessageToSend, openModalLogin } = useActions();

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const onMenuItemClick = (optionOnClick: (param?: any) => void) => (): void => {
    optionOnClick();
    handleClose();
  };

  const getLocationHref = async (): Promise<void> => {
    navigator.clipboard.writeText(window.location.href);

    if (!token) {
      openModalLogin();
      return;
    }

    if (setSharesCount && refetch && sharesCount) {
      await submitArtworkShare(shareData.artwork?._id);
      setSharesCount(sharesCount + 1);
      refetch();
    }
  };

  const shareViaMessages = (): void => {
    const messageToSend = {
      text: "",
      recipientId: null,
      audited: { user: null, artwork: null, exhibition: null },
      imagesOfChat: [],
    };
    switch (type) {
      case "profile":
        messageToSend.text = shareData.user?.hasInstitution
          ? "Institution's profile"
          : "Artist's profile";
        messageToSend.audited.user = shareData.user?._id as any;
        break;
      case "artwork":
        if (!shareData.artwork) return;
        const currentArtworkOwner = shareData.artwork.collector || shareData.artwork.owner;
        messageToSend.text = `Artwork by ${currentArtworkOwner.profile.firstName} ${currentArtworkOwner.profile.secondName}`;
        messageToSend.audited.artwork = shareData.artwork?._id as any;
        break;
      case "exhibition":
        if (!shareData.exhibition) return;
        const currentExhibitionOwner = shareData.exhibition.owner;
        messageToSend.text = `Show by ${currentExhibitionOwner.profile.firstName} ${currentExhibitionOwner.profile.secondName}`;
        messageToSend.audited.exhibition = shareData.exhibition?._id as any;
        break;
      default:
    }

    setMessageToSend(messageToSend);
    setCurrentStage("search-users");
    if (openShareArtworkModal) openShareArtworkModal();
    if (type !== "artwork") setShowMessagesModal(true);
  };

  const options = [
    { title: "Share via Direct Message", onClick: shareViaMessages },
    { title: "Share via Link", onClick: getLocationHref },
  ];

  return (
    <div>
      <IconButton onClick={handleClick}>
        <ReplyIcon className="share-button__icon" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options.map(
          option =>
            option.title && (
              <MenuItem key={option.title} onClick={onMenuItemClick(option.onClick)}>
                {option.title}
              </MenuItem>
            ),
        )}
      </Menu>
    </div>
  );
};

export default ShareButtonMenu;
