import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import { USER_GQL } from "../../api-queries/UserRequests";
import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";

const ArtFoundIdentified = (): JSX.Element => {
  const { id }: IParamsId = useParams();
  const { data, refetch } = useQuery(USER_GQL, {
    variables: { limit: 50, _id: id },
  });
  return (
    <div className="artworks_found">
      <ArtsControllerList
        artworks={data?.user.suggestedArtworks}
        refetch={refetch}
        title="Art identified"
      />
    </div>
  );
};

export default ArtFoundIdentified;
