export interface UploadImagesState {
  profile: string;
  profileBackground: string;
  artwork: string;
}

export enum UploadImageActionTypes {
  PROFILE = "PROFILE",
  BACKGROUND_PROFILE = "BACKGROUND_PROFILE",
  ARTWORK = "ARTWORK",
}

interface UploadImageProfileInAction {
  type: UploadImageActionTypes.PROFILE;
  payload: string;
}

interface UploadImageBackgroundProfileInAction {
  type: UploadImageActionTypes.BACKGROUND_PROFILE;
  payload: string;
}

interface UploadArtworkAction {
  type: UploadImageActionTypes.ARTWORK;
  payload: string;
}

export type UploadImageAction =
  | UploadImageProfileInAction
  | UploadImageBackgroundProfileInAction
  | UploadArtworkAction;
