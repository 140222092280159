import { IAuthor } from "./author";

export interface IReport {
  reporter: IAuthor;
  message: string;
  type: string;
}

export enum ReportTypes {
  Other = "other",
  Inaccuracy = "inaccuracy",
}
