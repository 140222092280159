import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useLocation } from "react-router-dom";
import { NEW_YORK_COORDINATES } from "../../config";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const ChangeLocationListener = ({ filterLocation, searchThisArea }) => {
  const [showSearchBtn, setShowSearchBtn] = useState(true);
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const { addPrevState, removePrevStateById } = useActions();
  const routerLocation = useLocation();
  const map = useMap();

  const handleSearchArea = (location = null) => {
    const mapCoords = map.getCenter();
    const bounds = map.getBounds();
    const radius = map.distance(bounds._northEast, bounds._southWest) / 1000;
    try {
      if (searchThisArea) {
        searchThisArea(location.lat ? location : mapCoords, location.lat ? 200 : radius);
      }
      setShowSearchBtn(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    map.on("move", () => {
      setShowSearchBtn(true);
    });
    const mapCoordsPrevState = states.find(state => state.id === "map-coords");
    const stateBeforeReloadJSON = localStorage.getItem("mapPrevState");
    const stateBeforeReload = stateBeforeReloadJSON ? JSON.parse(stateBeforeReloadJSON) : null;

    if (mapCoordsPrevState) {
      map.flyTo(mapCoordsPrevState.state.latLng, mapCoordsPrevState.state.zoom, { duration: 0.01 });
      removePrevStateById("map-coords");
      localStorage.removeItem("mapPrevState");
      return;
    }
    if (stateBeforeReload) {
      map.flyTo(stateBeforeReload.state.latLng, stateBeforeReload.state.zoom, { duration: 0.01 });
      localStorage.removeItem("mapPrevState");
      return;
    }
    if (routerLocation?.state?.location) {
      map.flyTo(routerLocation.state.location, 9, { duration: 2.5 });
      handleSearchArea(routerLocation.state.location);
      return;
    }
    map.flyTo(NEW_YORK_COORDINATES);
  }, []);

  useEffect(() => {
    if (!filterLocation) return;
    const { latitude, longitude } = filterLocation;
    map.flyTo([latitude, longitude], 9, { duration: 2.5 });
  }, [filterLocation]);

  useEffect(() => {
    const mapPagePrevState = states.find(state => state.url === routerLocation.pathname);
    const mapCoordsPrevState = states.find(state => state.id === "map-coords");

    if (mapPagePrevState && !mapCoordsPrevState) {
      localStorage.setItem(
        "mapPrevState",
        JSON.stringify({
          state: {
            latLng: map.getCenter(),
            zoom: map.getZoom(),
          },
        }),
      );
      addPrevState({
        id: "map-coords",
        state: {
          latLng: map.getCenter(),
          zoom: map.getZoom(),
        },
      });
    }
  }, [states.length]);

  return (
    <>
      {showSearchBtn && (
        <button onClick={handleSearchArea} className="search-area__button">
          Search this area
        </button>
      )}
    </>
  );
};

export default ChangeLocationListener;
