import React from "react";

const TooltipTextShows = (): JSX.Element => {
  return (
    <>
      <p>* Members can find shows anywhere, any time with the Filter and Sort.</p>
      <ol className="tooltip_list">
        * Host Art Shows by selecting one of four categories:
        <li className="tooltip_item">1. Solo Show: Just one artist.</li>
        <li className="tooltip_item">
          2. Invite exhibitors: Curate show by location and Origins of Inspirations.
        </li>
        <li className="tooltip_item">
          3. Open to Submissions: Posted on listings allows members to submit them self and join in
          your group show.
        </li>
        <li className="tooltip_item">4. Permanent Shows: Museums, galleries not date specific.</li>
      </ol>
    </>
  );
};

export default TooltipTextShows;
