import React, { useState, createContext, useContext, useEffect } from "react";

import { IArtwork } from "../types/artwork/artwork";

export interface ArtworkItem {
  artwork: IArtwork | null;
  id: string;
  removeArtworkFromSlider?: () => void;
  blockTransferModal?: boolean;
  setBlockSliding?: (block: boolean) => void;
  slideNext?: () => void;
}

export interface IArtworksSldrCntx {
  artworks: ArtworkItem[];
  setArtworks: Function;
}

const defaultCntx: IArtworksSldrCntx = {
  artworks: [],
  // eslint-disable-next-line
  setArtworks: () => {},
};

export const ArtworksSldrCntx = createContext<IArtworksSldrCntx>(defaultCntx);

export const ArtworksSldrCntxProvider: React.FC = ({ children }) => {
  const [artworks, setArtworks] = useState<ArtworkItem[]>([]);
  useEffect(() => {
    sessionStorage.setItem("artworkSliderState", JSON.stringify(artworks));
  }, [artworks]);
  return (
    <ArtworksSldrCntx.Provider value={{ artworks, setArtworks }}>
      {children}
    </ArtworksSldrCntx.Provider>
  );
};

export const useArtworksSldrCntx = (): IArtworksSldrCntx => {
  const { artworks, setArtworks } = useContext(ArtworksSldrCntx);

  return { artworks, setArtworks };
};
