import { LocationType } from "../../types/artwork/artworkLocation";

export interface IlocationTab {
  title: string;
  location: LocationType;
}

export enum MapTabs {
  FOUND = "Found",
  LIVES = "Lives",
  CREATED = "Created",
  COLLECTED = "Collected",
}

export enum ArtworkLocationTypes {
  LocationFound = "locationFound",
  LocationLives = "locationLives",
  LocationCreated = "locationCreated",
  LocationCollected = "locationCollected",
}

export const getButtontitle = (key: string): MapTabs | "" => {
  switch (key) {
    case ArtworkLocationTypes.LocationFound:
      return MapTabs.FOUND;
    case ArtworkLocationTypes.LocationLives:
      return MapTabs.LIVES;
    case ArtworkLocationTypes.LocationCreated:
      return MapTabs.CREATED;
    case ArtworkLocationTypes.LocationCollected:
      return MapTabs.COLLECTED;
    default:
      return "";
  }
};
