import { useOutletContext } from "react-router-dom";
import { ISex } from "../../types/sex";
import { IResultButton } from "../../components/filter-units/utils";
import { IArtistsPageContext } from "../../context/exploreContext";
import {
  CLOSEST_ARTISTS,
  MOST_FOLLOWERS_ARTISTS,
  MOST_SCANED_ARTISTS,
  MOST_VOTES_ARTISTS,
  NEWEST_ARTISTS,
  OLDEST_ARTISTS,
} from "../../misc/constants";
import { IFilter, IFilterTypes } from "../../types/filterTypes";
import { ICoordinates } from "../../types/addresTypes";
import { IUser } from "../../types/userTypes";
import { ReturnActionType } from "../../store/types/return-type";
import { ILocationFilter } from "../../context/types/artistsPageTypes";

export interface IArtistSortValue {
  name: string;
  sortBy?: string;
  orderBy?: string;
}

export interface IArtistsPageFilterContext {
  filterQueryParams: {
    filterQueryParams: IFilter[];
    setfilterQueryParams: (param: IFilter[]) => void;
  };
  filterResults: {
    filterResults: IResultButton[];
    setFilterResults: (param: IResultButton[] | any) => void;
  };
  originsOfInspiration: {
    originsOfInspiration: IFilter[];
    setOriginsOfInspiration: (param: IFilter[]) => void;
  };
  offset: {
    offset: number;
    setOffset: (param: number) => void;
  };
  location: {
    location: ILocationFilter | null;
    setLocation: (param: ILocationFilter | null) => void;
  };
}

export const useArtistsPageContext = (): IArtistsPageContext => {
  const { artistsPageContext }: any = useOutletContext();

  if (artistsPageContext === undefined) {
    throw Error("Artists Page Context is undefined");
  }
  return artistsPageContext;
};

export const artistsSortOptions = [
  NEWEST_ARTISTS,
  OLDEST_ARTISTS,
  MOST_VOTES_ARTISTS,
  MOST_FOLLOWERS_ARTISTS,
  MOST_SCANED_ARTISTS,
  CLOSEST_ARTISTS,
];

export enum GenderFilterName {
  ANY = "Any",
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
}

export interface IGenderFilterOption {
  name: GenderFilterName;
  value: { sex?: ISex[] };
  id: string;
}

export const genderFilterOptions: IGenderFilterOption[] = [
  {
    id: `${IFilterTypes.Gender}${GenderFilterName.ANY}`,
    name: GenderFilterName.ANY,
    value: { sex: undefined },
  },
  {
    id: `${IFilterTypes.Gender}${GenderFilterName.MALE}`,
    name: GenderFilterName.MALE,
    value: { sex: [ISex.Male] },
  },
  {
    id: `${IFilterTypes.Gender}${GenderFilterName.FEMALE}`,
    name: GenderFilterName.FEMALE,
    value: { sex: [ISex.Female] },
  },
  {
    id: `${IFilterTypes.Gender}${GenderFilterName.OTHER}`,
    name: GenderFilterName.OTHER,
    value: { sex: [ISex.Other] },
  },
];

export const getCurrentPosition = (): Promise<any> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject),
  );
};

export const sortByClosest = async (
  setIsPositionLoading: (param: boolean) => void,
  coordinates: ICoordinates | undefined,
  setCoordinates: (param: ICoordinates) => ReturnActionType,
  setArtists: (param: IUser[]) => void,
  setOffset: (param: number) => void,
  setfilterQueryParams: (param: any) => void,
  filterQueryParams: any,
  setSortValue: (param: IArtistSortValue) => void,
  currentSortValue: IArtistSortValue,
): Promise<void> => {
  try {
    setIsPositionLoading(true);
    if (!coordinates) {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      setCoordinates({ latitude, longitude });
      setArtists([]);
      setOffset(0);
      setfilterQueryParams({ ...filterQueryParams, sortByLocation: { latitude, longitude } });
      setIsPositionLoading(false);
      return;
    }
    setArtists([]);
    setOffset(0);
    setfilterQueryParams({ ...filterQueryParams, sortByLocation: coordinates });
    setIsPositionLoading(false);
  } catch (err: any) {
    setIsPositionLoading(false);
    setSortValue(currentSortValue);
    // eslint-disable-next-line no-alert
    alert(err.message);
  }
};

export const getUniqueArtists = (artists: IUser[]): (IUser | undefined)[] => {
  const artistsIds = artists.map(artist => artist._id);
  const uniqueArtistsIds = Array.from(new Set(artistsIds));
  return uniqueArtistsIds.map(id => artists.find(artist => artist._id === id));
};
