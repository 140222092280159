import { createContext } from "react";
import { IResultButton } from "../components/filter-units/utils";
import { IArtwork } from "../types/artwork/artwork";
import { IFilter, IFilterCategory } from "../types/filterTypes";
import { IArtistsPageInitialState, ILocationFilter } from "./types/artistsPageTypes";
import { IArtworkFilterState } from "./types/artworkFilterTypes";
import { IInstitutionPageState } from "./types/institutionsPageTypes";
import { IShowPageInitialState } from "./types/showsPageTypes";

interface IExploreListContext {
  explorePageArtworks: IArtwork[];
  setExplorePageArtworks: (param: IArtwork[]) => void;
}

export interface IArtworksPageFilterContext {
  categories: { categories: IFilterCategory[]; setCategories: (param: IFilterCategory[]) => void };
  filterResults: {
    filterResults: IResultButton[];
    setFilterResults: (param: IResultButton[] | any) => void;
  };
  filterQueryParams: { filterQueryParams: any; setfilterQueryParams: (param: any) => void };
  offset: { offset: number; setOffset: (param: number) => void };
  artworks: { artworks: IArtwork[]; setArtworks: (param: IArtwork[]) => void };
  materials: { materials: IFilter[]; setMaterials: (param: IFilter[]) => void };
  colors: { colors: IFilter[]; setColors: (param: IFilter[]) => void };
  maxNumber: number;
  widthMinValue: { widthMinValue?: number; setWidthMinValue: (arg: any) => void };
  widthMaxValue: { widthMaxValue?: number; setWidthMaxValue: (arg: any) => void };
  heightMinValue: { heightMinValue?: number; setHeightMinValue: (arg: any) => void };
  heightMaxValue: { heightMaxValue?: number; setHeightMaxValue: (arg: any) => void };
  originsOfInspiration: {
    originsOfInspiration: any[];
    setOriginsOfInspiration: (arg: any) => void;
  };
  sortValue: { sortValue: any; setSortValue: (arg: any) => void };
  location: { location: ILocationFilter; setLocation: (param: ILocationFilter | null) => void };
}

export interface IArtworkFilterContext {
  state: IArtworkFilterState;
  dispatch: Function;
}

export interface IInstitutionPageContext {
  state: IInstitutionPageState;
  dispatch: Function;
}

export interface IShowsPageContext {
  state: IShowPageInitialState;
  dispatch: Function;
}

export interface IArtistsPageContext {
  state: IArtistsPageInitialState;
  dispatch: Function;
}

export interface IExplorePageContext {
  exploreListContext: IExploreListContext;
  artworksPageContext: IArtworkFilterContext;
  buyPageContext: IArtworkFilterContext;
  movieExplorePageContext: IInstitutionPageContext;
  venuesExplorePageContext: IInstitutionPageContext;
  showsPageContext: IShowsPageContext;
  artistsPageContext: IArtistsPageContext;
}

export type IInitialState =
  | IArtworkFilterState
  | IInstitutionPageState
  | IShowPageInitialState
  | IArtistsPageInitialState;

const ExplorePageContext = createContext<Partial<IExplorePageContext>>({});

export default ExplorePageContext;
