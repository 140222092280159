import React, { useEffect, useState } from "react";

import { IComment } from "../../types/comment";
import CommentGrid from "../comment-grid/CommentGrid";
import Button from "../../UI/button/Button";
import CommentInput from "./CommentInput";
import { replyComment } from "../../api-queries/GraohQlClient";

import "./Comment.scss";
import CommentGridReply from "../comment-grid/CommentGridReply";

interface CommentComponentProps extends IComment {
  commentId: string;
  _id: string | undefined;
  type: string;
  artworkOwnerId: string;
  refetch: () => void;
  findComment: (id: string) => void;
  shouldShowReplyButton: any;
}

const CommentComponent: React.FC<CommentComponentProps> = ({
  text,
  author,
  createdAt,
  repliesCount,
  replies,
  image,
  likesCount,
  commentId,
  likes,
  refetch,
  findComment,
  _id,
  type,
  artworkOwnerId,
  shouldShowReplyButton,
}): JSX.Element => {
  const [viewReply, setViewReply] = useState(false);
  const [inputReply, setInputReply] = useState(false);
  const [repliesList, setRepliesList] = useState(replies);
  const [repliesCountState, setRepliesCount] = useState(repliesCount || 0);
  const firstName = author?.profile.firstName || "";
  const secondName = author?.profile.secondName || "";
  const id = author?._id || "";
  const btnTitle = !viewReply ? `View replies (${repliesCountState})` : "Hide replies";
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const turnOnReply = () => setViewReply(prevState => !prevState);
  const showInputReply = (): void => setInputReply(prevState => !prevState);

  const handleSubmit = async (comment: string): Promise<void> => {
    const { data } = await replyComment({
      commentId,
      text: comment,
    });
    setRepliesCount(prevState => prevState + 1);
    setRepliesList([...repliesList, data.replyComment]);
    refetch();
  };

  const findCommentReply = (idReply: string): void => {
    const filter = repliesList.filter(reply => reply._id !== idReply);
    setRepliesCount(prevState => prevState - 1);
    setRepliesList(filter);
    refetch();
  };

  useEffect(() => {
    setRepliesList(replies);
  }, []);
  return (
    <div className="comment">
      <CommentGrid
        type={type}
        firstName={firstName}
        secondName={secondName}
        image={image}
        createdAtDate={createdAt}
        text={text}
        repliesCount={repliesCount}
        likesCount={likesCount}
        authorId={id}
        commentId={commentId}
        likes={likes}
        refetch={refetch}
        showInputReply={showInputReply}
        _id={_id}
        reply
        findComment={findComment}
        artworkOwnerId={artworkOwnerId}
      />
      {!!repliesList?.length && (
        <Button className="btn_view__reply" onClick={turnOnReply}>
          {btnTitle}
        </Button>
      )}
      {viewReply && (
        <div className="comment_reply">
          {repliesList?.map(reply => {
            const replyLikesCount = reply?.likesCount;
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { firstName, secondName } = reply?.author?.profile;
            return (
              <CommentGridReply
                image={reply?.author?.profile?.image?.thumbnail?.url}
                firstName={firstName}
                secondName={secondName}
                createdAtDate={reply.createdAt}
                text={reply?.text}
                key={reply._id}
                authorId={reply?.author?._id}
                commentId={commentId}
                likes={reply.likes}
                refetch={refetch}
                replyLikesCount={replyLikesCount}
                showInputReply={showInputReply}
                _id={_id}
                reply={false}
                replyId={reply._id}
                findCommentReply={findCommentReply}
              />
            );
          })}
        </div>
      )}
      {inputReply && shouldShowReplyButton && (
        <CommentInput handleSubmit={handleSubmit} placeholder="Reply ..." />
      )}
    </div>
  );
};

export default CommentComponent;
