/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useActions } from "../../../hooks/useActions";
import DialogWindow from "../../dialog-window/DialogWindow";
import MessagesMenu from "../../messages-menu/MessagesMenu";
import "./ShareArtworkModal.scss";

interface IShareArtworkModalprops {
  isOpen: boolean;
  close: () => void;
}

const ShareArtworkModal: React.FC<IShareArtworkModalprops> = ({ isOpen, close }) => {
  const { setMessageToSend, setCurrentStage } = useActions();
  const closeModal = (): void => {
    setMessageToSend(null);
    setCurrentStage("chat");
    close();
  };
  return (
    <DialogWindow open={isOpen} handleClose={closeModal} className="share_artwork_modal">
      <MessagesMenu getUnreadMessages={() => {}} closeModal={close} />
    </DialogWindow>
  );
};

export default ShareArtworkModal;
