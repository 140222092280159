import { IImage } from "../imageData";
import { LocationType } from "./artworkLocation";
import { IArtworkDimensions } from "./artworkDimensions";
import { IAttachment } from "../attachment";
import { ICollector } from "../collector";
import { IExhibitions, IShowExhibitions } from "../exhibitions";
import { IOwner } from "../owner";
import { ITransfer } from "../transfer";
import { IComment } from "../comment";
import { ILike } from "../like";
import { ISuggestion } from "../suggestion";
import { ICategory } from "../category";
import { IArtworkType } from "./artworkType";
import { IReport } from "../report";
import { OrderBy } from "../orderBy";
import { ISearchNumber } from "../searchNumber";
import { ILocationSearch } from "../locationSearch";
import { ILocationArtworkSearch } from "./locationArtworkSearch";
import { ILocationArtworkSort } from "../locationArtworkSort";
import { IDurationNumber } from "../duration";

export interface IArtwork {
  _id: string;
  name: string;
  artistName: string;
  createdByArtistYear: string;
  conditionReport: string;
  notes: string;
  inspiration: string;
  history: string;
  minimumBid: number;
  votesCount: number;
  likesCount: number;
  scansCount: number;
  sharesCount: number;
  iDontKnowCount: number;
  suggestionsCount: number;
  published: boolean;
  winner: boolean;
  saleable: boolean;
  tradeFor: boolean;
  identified: boolean;
  availableOut: boolean;
  selectedForVote: boolean;
  creationInProgress: boolean;
  transferIsPending: boolean;
  selectedForExhibition: boolean;
  availableForLoan: boolean;
  locationFound: LocationType;
  locationLives: LocationType;
  locationCreated: LocationType;
  locationCollected: LocationType;
  dimensions: IArtworkDimensions;
  image: IImage;
  additionalImages: Array<IImage>;
  attachments: Array<IAttachment>;
  collectors: Array<ICollector>;
  pastExhibitions: Array<IExhibitions>;
  owner: IOwner;
  transferringTo: IOwner;
  transferInfo: ITransfer;
  transferHistory: Array<ITransfer>;
  collector: IOwner;
  pastCollectors: Array<IOwner>;
  exhibitions: Array<IShowExhibitions>;
  submitToExhibitions: Array<string>;
  comments: Array<IComment>;
  likes: Array<ILike>;
  suggestions: Array<ISuggestion>;
  categories: Array<ICategory>;
  colors: Array<string>;
  materials: Array<string>;
  filmsOrTv: Array<string>;
  hasFilmsOrTv: boolean;
  allowUseForFilmAndTv: boolean;
  type: IArtworkType;
  reports: Array<IReport>;
  createdAt: string;
  tradeForValue: string;
  tradeForObject: string;
}

export interface SearchArtworksRequest {
  sortBy?: string;
  orderBy?: OrderBy;
  limit?: number;
  offset?: number;
  text?: string;
  tradeForValue?: string;
  tradeForObject?: string;
  filmsOrTv?: Array<string>;
  hasFilmsOrTv?: boolean;
  allowUseForFilmAndTv?: boolean;
  inspiration?: Array<string>;
  culture?: Array<string>;
  ethnic?: Array<string>;
  spiritualInspirations?: Array<string>;
  tribalIdentities?: Array<string>;
  categories?: Array<string>;
  colors?: Array<string>;
  materials?: Array<string>;
  tradeFor?: boolean;
  forSale?: boolean;
  forVote?: boolean;
  isWinner?: boolean;
  reported?: boolean;
  identified?: boolean;
  suggestionsCount?: ISearchNumber;
  location?: ILocationSearch;
  findByLocation?: ILocationArtworkSearch;
  sortByLocation?: ILocationArtworkSort;
  price?: IDurationNumber;
  height?: IDurationNumber;
  length?: IDurationNumber;
  width?: IDurationNumber;
  weight?: IDurationNumber;
  types?: Array<string>;
  ownerId?: string;
  collectorId?: string;
}

export interface IArtworkState {
  artistName: string;
  availableOut: boolean;
  collector: ICollector;
  createdAt: Date;
  createdByArtistYear: string;
  image: IImage;
  locationCollected: LocationType;
  locationCreated: LocationType;
  locationFound: LocationType;
  locationLives: LocationType;
  minimumBid: number;
  name: string;
  owner: IOwner;
  saleable: boolean;
  selectedForExhibition: boolean;
  selectedForVote: boolean;
  type: string;
  votesCount: number;
  __typename: string;
  _id: string;
}

export enum ArtworkMetrics {
  IN = "in",
  CM = "cm",
}

export enum ArtworkTypes {
  ARTIST = "artist",
  COLLECTOR = "collector",
  FOUND = "found",
}

export enum ArtworkQueryParams {
  ownerId = "ownerId",
  collectorId = "collectorId",
}
