import {
  ArtworkDetailActionTypes,
  ArtworkDetailState,
  ArtworkDetailType,
} from "../types/artwork-detail.type";

const initialValue: ArtworkDetailState = {
  artworkData: null,
};

const artworkDetailsReducer = (
  state = initialValue,
  action: ArtworkDetailType,
): ArtworkDetailState => {
  switch (action.type) {
    case ArtworkDetailActionTypes.ADD_ARTWORK:
      return { ...state, artworkData: action.payload };
    default:
      return { ...state };
  }
};
export default artworkDetailsReducer;
