import { Skeleton } from "@mui/material";
import React, { FC } from "react";

import "./NotificationSkeleton.scss";

const NotificationSkeleton: FC = () => {
  return (
    <div className="notification-skeleton">
      <Skeleton variant="circular" width={32} height={32} />
      <div className="notification-skeleton__content">
        <Skeleton variant="rectangular" width={250} height={15} />
        <Skeleton variant="rectangular" width={100} height={15} />
        <Skeleton className="audited" variant="rectangular" width={250} height={150} />
      </div>
    </div>
  );
};

export default NotificationSkeleton;
