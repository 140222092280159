import { gql } from "@apollo/client";
import { COMMENT, EXHIBITION, EXHIBITION_LITE } from "./request-params/RequestsParams";

export const SEARCH_EXHIBITIONS = gql`
    query searchExhibitions(
        $limit: Int
        $offset: Int
        $text: String
        $location: LocationSearchType
        $duration: ExhibitionDurationInput
        $dayOfWeek: String
        $isVirtual: Boolean
        $private: Boolean
        $isOpenToSubmitions: Boolean
    ) {
        searchExhibitions(
            text: $text
            limit: $limit
            offset: $offset
            location: $location
            duration: $duration
            dayOfWeek: $dayOfWeek
            isVirtual: $isVirtual
            private: $private
            isOpenToSubmitions: $isOpenToSubmitions
        ) {
            exhibitions {
                ${EXHIBITION_LITE}
            }
            meta {
                limit
                offset
                total
            }
        }
    }`;

export const FETCH_EXHIBITION = gql`
    query exhibition($_id: ID!) {
        exhibition(_id: $_id) {
            ${EXHIBITION}
        }
    }`;

export const ADD_EXHIBITION_COMMENT = `
mutation addCommentToExhibition($exhibitionId: ID! $text: String!, $isPrivate: Boolean) {
  addCommentToExhibition(exhibitionId: $exhibitionId text: $text, isPrivate: $isPrivate) {
    ${COMMENT}
  }
}`;

export const TOGGLE_COMMENT_LIKE = `
mutation toggleCommentLike($commentId: ID!) {
  toggleCommentLike(commentId: $commentId) {
    liked
  }
}`;

export const REPLY_COMMENT = `
mutation replyComment($commentId: ID! $text: String!, $isPrivate: Boolean) {
  replyComment(commentId: $commentId, text: $text, isPrivate: $isPrivate) {
    ${COMMENT}
  }
}`;

export const REMOVE_EXHIBITION_COMMENT = `
mutation removeCommentFromExhibition($commentId: ID! $exhibitionId: ID!) {
  removeCommentFromExhibition(exhibitionId: $exhibitionId, commentId: $commentId) {
    removed
  }
}`;

export const REMOVE_COMMENT_REPLY = `
mutation removeReply($commentId: ID!, $replyId: ID!) {
  removeReply(commentId: $commentId, replyId: $replyId) {
    removed
  }
}`;

export const REMOVE_ARTWORK_COMMENT = `
mutation removeCommentFromArtwork($commentId: ID! $artworkId: ID!) {
  removeCommentFromArtwork(artworkId: $artworkId, commentId: $commentId) {
    removed
  }
}`;

export const ADD_ARTWORK_COMMENT = `
mutation addCommentToArtwork($artworkId: ID! $text: String!, $isPrivate: Boolean) {
  addCommentToArtwork(artworkId: $artworkId text: $text, isPrivate: $isPrivate) {
    ${COMMENT}
  }
}`;

export const REPORT_SHOW = `
mutation reportExhibition(
  $exhibitionId: ID!
  $message: String
  $reportType: reportType
) {
  reportExhibition(
    exhibitionId: $exhibitionId
    message: $message
    type: $reportType
  ) {
    _id
  }
}`;

export const SUBMITTED_EXHIBITIONS = gql`
query submittedExhibitions (
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
  $submissionStatus: submissionStatus
) {
  submittedExhibitions(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
    submissionStatus: $submissionStatus
  ) {
    exhibitions {
      ${EXHIBITION_LITE}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;

export const MY_EXHIBITIONS_WITH_ARTWORKS_PENDED = gql`
query myExhibitionsWithArtworksPended (
  $sortBy: String
  $orderBy: orderBy
  $limit: Int
  $offset: Int
) {
  myExhibitionsWithArtworksPended(
    sortBy: $sortBy
    orderBy: $orderBy
    limit: $limit
    offset: $offset
  ) {
    exhibitions {
      ${EXHIBITION_LITE}
    }
    meta {
      limit
      offset
      total
    }
  }
}`;
