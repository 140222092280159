import React from "react";
import { useNavigate } from "react-router-dom";

import { VoteListArtworksProps } from "../../vote-explore-page.type";
import { artworks, explore, votesArtwork } from "../../../../route-link";

const VoteListArtworks: React.FC<VoteListArtworksProps> = ({
  artwork,
  widthColumn,
  paramsArtwork,
  saveState,
}): JSX.Element => {
  const navigate = useNavigate();
  const goToDetailsPage = (): void => {
    if (saveState) saveState(artwork);
    navigate(`/${explore}/${artworks}/${artwork._id}/${votesArtwork}`);
  };
  return (
    <div
      onClick={goToDetailsPage}
      className="artwork_item"
      style={{ minHeight: Math.floor(widthColumn / paramsArtwork) }}
    >
      <img className="artwork_item__image" src={artwork.image.medium.url} alt={artwork.name} />
    </div>
  );
};
export default VoteListArtworks;
