export const root = "/";
export const explore = "explore";
export const exploreHome = "explore-home";
export const map = "map";
export const upload = "upload";
export const about = "about";
export const user = "user";
export const login = "login";
export const venues = "venues";
export const lost = "lost";
export const buy = "buy";
export const vote = "vote";
export const winners = "winners";
export const shows = "shows";
export const submitArt = "submit-art";
export const location = "location";
export const institutions = "institutions";
export const artistLinkId = "artist/:id";
export const artistLink = "artist";
export const termsOfUse = "terms-of-use";
export const privacyPolicy = "privacy-policy";
export const artworks = "artworks";
export const artworksFound = "artworks-found";
export const movieTV = "movie-tv";
export const uploadUserImage = "upload-user-image";
export const uploadInstitutionImage = "upload-institution-image";
export const originsOfInspiration = "origins-of-inspiration";
export const registrationOfInstitution = "registration-of-institution";
export const deepLink = "deeplink";
export const artists = "artists";
export const editArtwork = "edit-artwork";
export const editUnfinishedArtwork = "edit-unfinished-artwork";
export const editProfile = "edit-profile";
export const editOriginsAndInspiration = "edit-origins-and-inspirations";
export const editInstitutionProfile = "edit-institution-profile";
export const uploadArtwork = "upload-artwork";
export const uploadArtworkInfo = "upload-artwork-info";
export const likedArtworks = "liked-artworks";
export const followers = "followers";
export const followings = "followings";
export const votedArtworks = "voted-artworks";
export const userScans = "scans";
export const searchResult = "search-result";
export const createdArt = "created-art";
export const collectionArt = "collection-art";
export const artFoundIdentified = "art-found-identified";
export const artTransferred = "art-transferred";
export const campaigns = "campaigns";
export const votes = "votes";
export const unfinishedCreds = "unfinished-creds";
export const showsSubmissions = "shows-submissions";
export const incompleteTransfers = "incomplete-transfers";
export const submissionsReview = "submissions-review";
export const artForSale = "art-for-sale";
export const artResales = "resale-art";
export const incomingTransfers = "incoming-transfers";
export const lostViewMore = "lost-view-more";
export const uploadedNow = "uploaded-now";
export const blockedUsers = "blocked-users";
export const votesArtwork = "votes-artwork";
export const myNominatedArtwork = "my-nominated-artwork";
export const myVotedArtwork = "my-voted-artwork";
export const messagesRoute = "messages";
