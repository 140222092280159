/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-elastic-carousel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import cn from "classnames";

import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { artworkDeatils } from "../../api-queries/GraohQlClient";
import { Stack, Queue } from "./helper";
import Artwork from "./Artwork";
import {
  explore,
  incomingTransfers,
  myNominatedArtwork,
  myVotedArtwork,
  uploadedNow,
  votesArtwork,
} from "../../route-link";

import { useActions } from "../../hooks/useActions";

const nxtItms = new Queue([]);
const prvItms = new Stack([]);

const ArtworkDetailsSlider: React.FC = () => {
  const { id } = useParams();
  const { setBackUrl } = useActions();
  const { artworks, setArtworks } = useArtworksSldrCntx();
  let artwrkIndx = artworks.findIndex(artwork => artwork.id === id);
  const [showArrows, setShowArrows] = useState(false);
  const [removeArtworkId, setRemoveArtworkId] = useState<any>("");
  const [itemsToShow, setItemsToShow] = useState([
    artworks[artwrkIndx - 1] ? artworks[artwrkIndx - 1].id : 0,
    id,
    artworks[artwrkIndx + 1] ? artworks[artwrkIndx + 1].id : 0,
  ]);
  const [blockTransferModal, setBlockTransferModal] = useState(false);
  const [blockSliding, setBlockSliding] = useState(false);
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const isCampaignsPage = routerLocation.pathname.includes(myNominatedArtwork);
  const isVotedPage = routerLocation.pathname.includes(myVotedArtwork);
  const isVotesPage = routerLocation.pathname.includes(votesArtwork);
  const isVotesArtwork = isCampaignsPage || isVotedPage || isVotesPage;
  const sliderRef = useRef<any>(null);
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  if (sliderWrapperRef.current) sliderWrapperRef.current.focus();

  const SliderArrows = ({ type, onClick, isEdge }: any): any => {
    const pointer = type === "PREV" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
    return (
      <button
        className={cn("slider_arrow_button", {
          prev: type === "PREV",
          next: type === "NEXT",
        })}
        onClick={onClick}
        disabled={
          isEdge || (type === "PREV" && !itemsToShow[0]) || (type === "NEXT" && !itemsToShow[2])
        }
      >
        {pointer}
      </button>
    );
  };

  useEffect(() => {
    let artworksState = artworks;
    if (!artworks.length) {
      const savedArtworks = sessionStorage.getItem("artworkSliderState");
      if (!savedArtworks) {
        (async () => {
          const response = await artworkDeatils({ artworkId: id });

          setArtworks([{ id, artwork: response.artwork }]);
          setBackUrl(`/${explore}`);
        })();
        return;
      }
      const parsedArtworks = JSON.parse(savedArtworks);
      artworksState = parsedArtworks;
      setArtworks(parsedArtworks);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    artwrkIndx = artworksState.findIndex(artwork => artwork.id === id);

    prvItms.array = artworksState.slice(0, artwrkIndx);
    nxtItms.array = artworksState.slice(artwrkIndx + 1, artworksState.length);

    (async () => {
      let _artworks: any = [];
      if (!isVotesArtwork) {
        let response = await artworkDeatils({ artworkId: id });

        _artworks = [{ id, artwork: response.artwork }];

        if (prvItms.notEmpty()) {
          const artworkId = prvItms.pop().id;
          response = await artworkDeatils({ artworkId });
          _artworks.unshift({ id: artworkId, artwork: response.artwork });
        }

        if (nxtItms.notEmpty()) {
          const artworkId = nxtItms.shift().id;
          response = await artworkDeatils({ artworkId });
          _artworks.push({ id: artworkId, artwork: response.artwork });
        }
      }

      setItemsToShow([
        artworksState[artwrkIndx - 1] ? artworksState[artwrkIndx - 1].id : 0,
        id,
        artworksState[artwrkIndx + 1] ? artworksState[artwrkIndx + 1].id : 0,
      ]);

      if (!isVotesArtwork) setArtworks([...prvItms.array, ..._artworks, ...nxtItms.array]);
      setShowArrows(true);
    })();
    if (routerLocation.pathname.includes(uploadedNow)) setShowArrows(false);
  }, []);

  const removeArtworkFromSlider = (): void => {
    setRemoveArtworkId(itemsToShow[1]);
    if (itemsToShow[2] && itemsToShow[2] !== 0) {
      sliderRef.current.slideNext();
      return;
    }
    if (itemsToShow[0] && itemsToShow[0] !== 0) {
      sliderRef.current.slidePrev();
      return;
    }
    navigate(-1);
  };

  const onPrevEnd = async (): Promise<void> => {
    setBlockTransferModal(false);
    if (removeArtworkId) {
      setArtworks((prev: any) => prev.filter((artwork: any) => artwork.id !== removeArtworkId));
      setRemoveArtworkId("");
      return;
    }
    if (prvItms.notEmpty()) {
      const artworkId = prvItms.pop().id;
      const response = await artworkDeatils({ artworkId });
      const index = artworks.findIndex(artwork => artwork.id === artworkId);
      setArtworks((prev: any) => [
        ...prev.slice(0, index),
        { ...prev[index], artwork: response.artwork },
        ...prev.slice(index + 1, prev.length),
      ]);
    }
  };

  const onNextEnd = async (): Promise<void> => {
    setBlockTransferModal(false);
    if (removeArtworkId && !nxtItms.notEmpty()) {
      setArtworks((prev: any) => prev.filter((artwork: any) => artwork.id !== removeArtworkId));
      const prevArtworkIdx = artworks.findIndex(art => art.id === removeArtworkId) - 1;
      setItemsToShow(prev => [artworks[prevArtworkIdx]?.id, prev[1], prev[2]]);
      setRemoveArtworkId("");
      return;
    }
    if (nxtItms.notEmpty()) {
      const artworkId = nxtItms.shift().id;
      const response = await artworkDeatils({ artworkId });
      const index = artworks.findIndex(artwork => artwork.id === artworkId);

      if (removeArtworkId) {
        setArtworks((prev: any) =>
          [
            { id: 0, artwork: null },
            ...prev.slice(0, index),
            { ...prev[index], artwork: response.artwork },
            ...prev.slice(index + 1, prev.length),
          ].filter(artwork => artwork.id !== removeArtworkId),
        );
        const prevArtworkIdx = artworks.findIndex(art => art.id === removeArtworkId) - 1;
        setItemsToShow(prev => [artworks[prevArtworkIdx]?.id, prev[1], prev[2]]);
        setRemoveArtworkId("");
        return;
      }

      setArtworks((prev: any) => [
        ...prev.slice(0, index),
        { ...prev[index], artwork: response.artwork },
        ...prev.slice(index + 1, prev.length),
      ]);
    }
  };

  const onNextStart = (): void => {
    setShowArrows(false);
    setBlockTransferModal(true);
  };

  const onPrevStart = (): void => {
    setShowArrows(false);
    setBlockTransferModal(true);
  };

  const handleSwipe = (currentItem: any): void => {
    const currentArtworkIndex = artworks.findIndex(art => art.id === currentItem.item.children.key);
    const currentArtworksArray = [
      artworks[currentArtworkIndex - 1] ? artworks[currentArtworkIndex - 1].id : 0,
      artworks[currentArtworkIndex] ? artworks[currentArtworkIndex].id : 0,
      artworks[currentArtworkIndex + 1] ? artworks[currentArtworkIndex + 1].id : 0,
    ];
    let artworkLink = `/explore/artworks/${currentArtworksArray[1]}/`;
    if (routerLocation.pathname.includes(incomingTransfers)) artworkLink += incomingTransfers;
    if (routerLocation.pathname.includes(myNominatedArtwork)) artworkLink += myNominatedArtwork;
    if (routerLocation.pathname.includes(myVotedArtwork)) artworkLink += myVotedArtwork;
    if (routerLocation.pathname.includes(votesArtwork)) artworkLink += votesArtwork;

    navigate(artworkLink, {
      replace: true,
    });
    setShowArrows(true);
    setItemsToShow(currentArtworksArray);
  };

  const handleKeyboardSwipe = (e: React.KeyboardEvent): void => {
    if (!showArrows || blockSliding) return;

    switch (e.key) {
      case "ArrowLeft":
        if (itemsToShow[0]) {
          onPrevStart();
          sliderRef.current.slidePrev();
        }
        break;
      case "ArrowRight":
        if (itemsToShow[2]) {
          onNextStart();
          sliderRef.current.slideNext();
        }
        break;
      default:
        setBlockTransferModal(false);
    }
  };

  const slideNext = (): void => {
    if (!sliderRef.current) return;
    sliderRef.current.slideNext();
  };

  return (
    <>
      {artwrkIndx >= 0 && (
        <div
          className="arwork-details-slider"
          tabIndex={0}
          ref={sliderWrapperRef}
          onKeyDown={handleKeyboardSwipe}
        >
          <Carousel
            isRTL={false}
            itemsToShow={1}
            pagination={false}
            showArrows={showArrows}
            renderArrow={SliderArrows}
            initialActiveIndex={artwrkIndx}
            onPrevEnd={onPrevEnd}
            onNextEnd={onNextEnd}
            onChange={handleSwipe}
            enableSwipe={false}
            enableMouseSwipe={false}
            transitionMs={950}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            ref={sliderRef}
          >
            {artworks.map(artwork => (
              <>
                {itemsToShow.includes(artwork.id) && (
                  <Artwork
                    slideNext={slideNext}
                    removeArtworkFromSlider={removeArtworkFromSlider}
                    blockTransferModal={blockTransferModal}
                    key={artwork.id}
                    setBlockSliding={setBlockSliding}
                    {...artwork}
                  />
                )}
              </>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default ArtworkDetailsSlider;
