import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import React from "react";
import { useCreateTransferContext } from "../../../../../context/createTransferContext";
import InputField from "../../../../../UI/input-field/InputField";
import LocationAutocomplete from "../../../../locations-autocomplete/LocationAutocomplete";

const GiftTransferForm: React.FC = () => {
  const {
    conditionReport,
    setConditionReport,
    locationLives,
    setLocationLives,
    setTypeOfTransfer,
    locationIsPrivate,
    toggleLocationIsPrivate,
    typeOfTransfer,
  } = useCreateTransferContext();

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTypeOfTransfer(e.target.value);
  };

  return (
    <>
      <h3>Gift</h3>
      <div className="transfer-type-checkboxes">
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={typeOfTransfer === "Gift"}
              value="Gift"
              color="success"
            />
          }
          label="Gift"
        />
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={handleChecked}
              checked={typeOfTransfer === "Will"}
              value="Will"
              color="success"
            />
          }
          label="Will"
        />
      </div>
      <div className="create-transfer_form">
        <InputField
          label="TERMS, CONDITION"
          value={conditionReport}
          onChange={setConditionReport}
          helperText="*required"
          multiline
        />
        <InputLabel className="origin_label">LOCATION ON DISPLAY</InputLabel>
        <LocationAutocomplete
          value={locationLives}
          className="edit_item__location"
          onChange={setLocationLives}
          onCloseIconClick={() => setLocationLives(null)}
          placeholder="Choose location..."
          isPrivate={locationIsPrivate}
        />
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={toggleLocationIsPrivate}
              checked={locationIsPrivate}
              value=""
              color="success"
            />
          }
          label="Location is private"
        />
      </div>
    </>
  );
};

export default GiftTransferForm;
