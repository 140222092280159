import { ModalActionTypes } from "../types/modal";
import { ReturnActionType } from "../types/return-type";

export const openModal = (): ReturnActionType => ({ type: ModalActionTypes.MODAL_OPEN });

export const openModalLogin = (): ReturnActionType => ({ type: ModalActionTypes.MODAL_OPEN_LOGIN });

export const closeModal = (): ReturnActionType => ({ type: ModalActionTypes.MODAL_CLOSE });

export const closeModalLogin = (): ReturnActionType => ({
  type: ModalActionTypes.MODAL_CLOSE_LOGIN,
});

export const openReportModal = (): ReturnActionType => ({ type: ModalActionTypes.REPORT_OPEN });

export const closeReportModal = (): ReturnActionType => ({ type: ModalActionTypes.REPORT_CLOSE });
