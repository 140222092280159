import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";
import {
  CollectorsPageActionTypes,
  CollectorsSearchActions,
  ILocationFilter,
} from "../types/collectorsSearchActions";

export const SET_ARTISTS_COLLECTORS = (payload: IUser[]): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_ARTISTS_COLLECTORS,
  payload,
});

export const SET_SORT_VALUE_COLLECTORS = (payload: IArtistSortValue): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_SORT_VALUE_COLLECTORS,
  payload,
});

export const SET_OFFSET_COLLECTORS = (payload: number): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_OFFSET_COLLECTORS,
  payload,
});

export const SET_FILTER_QUERY_PARAMS_COLLECTORS = (payload: any): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_FILTER_QUERY_PARAMS_COLLECTORS,
  payload,
});

export const SET_ORIGINS_OF_INSPIRATION_COLLECTORS = (
  payload: IFilter[],
): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_ORIGINS_OF_INSPIRATION_COLLECTORS,
  payload,
});

export const SET_FILTER_RESULTS_COLLECTORS = (
  payload: IResultButton[],
): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_FILTER_RESULTS_COLLECTORS,
  payload,
});

export const SET_GENDER_COLLECTORS = (payload: IGenderFilterOption): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_GENDER_COLLECTORS,
  payload,
});

export const SET_LOCATION_FILTER_COLLECTORS = (
  payload: ILocationFilter | null,
): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_LOCATION_FILTER_COLLECTORS,
  payload,
});

export const SET_RADIUS_COLLECTORS = (payload: number): CollectorsSearchActions => ({
  type: CollectorsPageActionTypes.SET_RADIUS_COLLECTORS,
  payload,
});
