export interface CommentsState {
  comments: Array<any>;
  replyComments: Array<any>;
}

export enum CommentsActionType {
  COMMENTS = "COMMENTS",
  ADD_COMMENT = "ADD_COMMENT",
  REMOVE_COMMENT = "REMOVE_COMMENT",
  COMMENTS_REPLY = "COMMENTS_REPLY",
  ADD_COMMENT_REPLY = "ADD_COMMENT_REPLY",
  REMOVE_COMMENT_REPLY = "REMOVE_COMMENT_REPLY",
}

interface SetComment {
  type: CommentsActionType.COMMENTS;
  payload: any;
}

interface SetCommentReply {
  type: CommentsActionType.COMMENTS_REPLY;
  payload: any;
}

interface AddComment {
  type: CommentsActionType.ADD_COMMENT;
  payload: any;
}

interface AddCommentReply {
  type: CommentsActionType.ADD_COMMENT_REPLY;
  payload: any;
}

interface RemoveComment {
  type: CommentsActionType.REMOVE_COMMENT;
  id: string;
}

interface RemoveCommentReply {
  type: CommentsActionType.REMOVE_COMMENT_REPLY;
  id: string;
}

export type CommentType =
  | SetComment
  | AddComment
  | RemoveComment
  | SetCommentReply
  | AddCommentReply
  | RemoveCommentReply;
