import React from "react";
import { useOutletContext } from "react-router-dom";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import InstitutionComponent from "../../components/institution-component/InstitutionComponent";

const InstitutionsExplorePage = (): JSX.Element => {
  const { institutionsExplorePageContext }: any = useOutletContext();
  return (
    <>
      <ScrollToTop />
      <InstitutionComponent
        availableForFilmAndTV={undefined}
        availableForEvents={undefined}
        isVirtual={undefined}
        context={institutionsExplorePageContext}
        title="INSTITUTIONS"
      />
    </>
  );
};

export default InstitutionsExplorePage;
