import React, { FC, useState } from "react";
import Slider from "@mui/material/Slider";

export type SetRadiusValue = (
  event: Event | React.SyntheticEvent<Element, Event>,
  value: number | number[],
) => void;

interface RadiusSliderProps {
  className?: string;
  value: number | number[];
  setRadiusValue: SetRadiusValue;
}
const RadiusSlider: FC<RadiusSliderProps> = ({ value, setRadiusValue, className }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const onChange = (e: any, newValue: any): void => {
    if (typeof newValue === "number") {
      setCurrentValue(newValue);
    }
  };
  return (
    <div className={className}>
      <span>Search in radius: {currentValue} km</span>
      <Slider
        min={1}
        value={currentValue}
        onChange={onChange}
        onChangeCommitted={setRadiusValue}
        max={100}
        valueLabelDisplay="auto"
        size="small"
      />
    </div>
  );
};

export default RadiusSlider;
