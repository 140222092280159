import React, { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import Masonry from "react-masonry-css";
import { useLocation } from "react-router-dom";

import BackButton from "../../components/back-button/BackButton";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkItem from "../../components/artwork-item/ArtworkItem";

import { INCOMPLETE_ARTWORKS } from "../../api-queries/ArtworksRequests";

import "./IncompleteCredentialsPage.scss";
import { editUnfinishedArtwork } from "../../route-link";

const IncompleteCredentialsPage: FC = () => {
  const limit = 1000;
  const { data, loading, refetch } = useQuery(INCOMPLETE_ARTWORKS, {
    variables: { limit, published: false },
  });
  const routerLocation = useLocation();
  const [unfinishedArtworks, setUnfinishedArtworks] = useState<(IArtwork | undefined)[]>([]);

  useEffect(() => {
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevLoadingState?.url === routerLocation.pathname) {
      setUnfinishedArtworks(prevLoadingState.state);
      setTimeout(() => window.scroll(0, prevLoadingState.scroll));
      localStorage.removeItem("prevState");
      return;
    }
    setUnfinishedArtworks([]);
    refetch();
    if (!loading) setUnfinishedArtworks(data?.myArtworks);
  }, [data, loading]);

  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();

  const showEmptyTitle = Boolean(!unfinishedArtworks?.length && !loading);

  const saveState = (): void => {
    localStorage.setItem(
      "prevState",
      JSON.stringify({
        state: unfinishedArtworks,
        url: routerLocation.pathname,
        scroll: window.scrollY,
      }),
    );
  };

  return (
    <div className="wrapper profile-artworks-page profile-unfinished-creds-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        Incomplete Credentials
      </h3>
      {unfinishedArtworks && (
        <>
          <div ref={widthBlock}>
            <Masonry
              ref={masonryColumns}
              breakpointCols={BREAKPOINT_COLUMNS_OBJ}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {unfinishedArtworks?.map(artwork => (
                <div key={artwork?._id}>
                  <ArtworkItem
                    url={`/explore/artworks/${artwork?._id}/${editUnfinishedArtwork}`}
                    artwork={artwork}
                    masonryColumns
                    widthBlock
                    saveState={saveState}
                  />
                </div>
              ))}
            </Masonry>
          </div>
          {showEmptyTitle && (
            <p className="profile-artworks-page__no-artworks">There are no unfinished artworks.</p>
          )}
        </>
      )}
    </div>
  );
};
export default IncompleteCredentialsPage;
