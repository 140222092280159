import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useLocation } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkListItem from "../artwork-list-item/ArtworkListItem";

interface IArtworksGrid {
  artworks: IArtwork[];
  saveState: () => null;
}

const ArtworksGrid: React.FC<IArtworksGrid> = ({ artworks, saveState }) => {
  const [artworksToShow, setArtworksToShow] = useState(artworks);
  const location = useLocation();
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const { removePrevState } = useActions();
  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === location.pathname);
    if (prevState) {
      setArtworksToShow(prevState.state);
      removePrevState(location.pathname);
    }
  }, []);
  return (
    <Masonry
      breakpointCols={BREAKPOINT_COLUMNS_OBJ}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {artworksToShow?.map(artwork => (
        <ArtworkListItem
          saveState={saveState}
          key={artwork._id}
          id={artwork._id}
          artwork={artwork}
        />
      ))}
    </Masonry>
  );
};

export default ArtworksGrid;
