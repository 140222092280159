import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import "../../components/artworks-page-component/ArtworksPageComponent.scss";
import { USER_SCANS } from "../../api-queries/UserRequests";
import { getScanArtworks } from "../profile-scans/utils";
import { getUniqueArtworks } from "../../components/artworks-explore-list/utils";
import "./Scans.scss";
import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";
import { IArtwork } from "../../types/artwork/artwork";

const Scans = (): JSX.Element => {
  const limit = 500;
  const selector = useTypedSelector(state => state.artworksScanFilterReducer);

  const { filterQueryParams } = selector;
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const queryParams = { userId: authUserId };
  const { data, refetch } = useQuery(USER_SCANS, {
    variables: { limit, ...filterQueryParams, ...queryParams },
  });
  const [uniqueArtworks, setUniqueArtworks] = useState<(IArtwork | undefined)[] | null>(null);

  useEffect(() => {
    if (data) {
      const artworksArray = getScanArtworks(data);
      setUniqueArtworks(getUniqueArtworks(artworksArray));
    }
  }, [data]);

  return (
    <div className="scans_wrapper">
      <div className="scans wrapper">
        <ArtsControllerList artworks={uniqueArtworks} refetch={refetch} title="Scans" />
      </div>
    </div>
  );
};

export default Scans;
