import React from "react";
import SelectUnstyled, { SelectUnstyledProps, SelectOption } from "@mui/base/SelectUnstyled";
import { StyledButton, StyledListbox, StyledPopper, StyledOption } from "./MocaCustomSelect.style";

interface DataProps {
  _id: string;
  name: string;
}

interface MocaCustomSelectProps {
  data: Array<DataProps>;
  handleChangeCategory: (value: string | null) => void;
  defaultValue?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function CustomSelect(props: SelectUnstyledProps<string>) {
  const components: SelectUnstyledProps<string>["components"] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    // eslint-disable-next-line react/destructuring-assignment
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

const renderValue = (option: SelectOption<string> | null): JSX.Element => {
  if (option == null) {
    return <span>Choose a category...</span>;
  }

  return <span>{option.label}</span>;
};

const MocaCustomSelect: React.FC<MocaCustomSelectProps> = ({
  data,
  handleChangeCategory,
  defaultValue,
}): JSX.Element => {
  return (
    <CustomSelect
      defaultValue={defaultValue || ""}
      renderValue={renderValue}
      onChange={handleChangeCategory}
    >
      {data.map(item => {
        return (
          <StyledOption key={item._id} value={item._id}>
            {item.name}
          </StyledOption>
        );
      })}
    </CustomSelect>
  );
};

export default MocaCustomSelect;
