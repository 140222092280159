import { SEARCH_USERS } from "../../../api-queries/SearchRequests";
import { searchArtists, searchInstitution } from "../../../api-queries/GraohQlClient";

export const handleSearch = async (type: string, text: string): Promise<any> => {
  switch (type) {
    case "Operators":
      return searchArtists(SEARCH_USERS, { text, limit: 20 });
    case "Institutions":
      return searchInstitution({ text, limit: 20 });
    default:
      return [];
  }
};
