import { Avatar } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUnreadMessages } from "../../api-queries/GraohQlClient";
import AvatarPlaceholder from "../../components/avatar-placeholder/AvatarPlaceholder";
import Chat from "../../components/chat/Chat";
import MessagesMenu from "../../components/messages-menu/MessagesMenu";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { artists, explore } from "../../route-link";
import { IChat } from "../../types/chat";
import { IUser } from "../../types/userTypes";
import "./MessagesPage.scss";

const MessagesPage: FC = () => {
  const { currentChat, chats, currentScroll, showMessagesModal } = useTypedSelector(
    state => state.messagesInfoReducer,
  );
  const [chatName, setChatName] = useState("");
  const [chatRecipient, setChatRecipient] = useState<IUser | null>(null);
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const {
    setCurrentMessages,
    setCurrentScroll,
    setUnreadMessagesCount,
    setCurrentChat,
    setCurrentChatOffset,
    setShowMessagesModal,
  } = useActions();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const navigate = useNavigate();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showScrollToBottomButton, setShowScrollToBottomButton] = useState(false);

  const getUnreadMessages = async (): Promise<void> => {
    const userChats: IChat[] = await fetchUnreadMessages();
    if (!userChats) return;
    let count = 0;
    userChats.forEach(({ participants }) => {
      participants.forEach((user: any) => {
        const { participant, unreadCount } = user;
        if (participant._id === myMOCAId) count += unreadCount;
      });
    });
    setUnreadMessagesCount(count);
  };

  const handleShowScrollToBottomButton = (): void => {
    if (
      chatWindowRef.current &&
      chatWindowRef.current.scrollTop < chatWindowRef.current.scrollHeight - 1000
    ) {
      setShowScrollToBottomButton(true);
      return;
    }
    setShowScrollToBottomButton(false);
  };

  const subscribeForScroll = (): void => {
    if (chatWindowRef.current) {
      chatWindowRef.current.addEventListener("scroll", handleShowScrollToBottomButton);
    }
  };

  const unsubscribeFromScroll = (): void => {
    if (chatWindowRef.current) {
      chatWindowRef.current.removeEventListener("scroll", handleShowScrollToBottomButton);
    }
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      subscribeForScroll();
    }
    return () => {
      unsubscribeFromScroll();
    };
  }, []);

  const scrollModalToBottom = (smooth = false): void => {
    if (chatWindowRef.current) {
      if (currentScroll) {
        setCurrentScroll(undefined);
        return;
      }
      if (smooth) {
        chatWindowRef.current.scrollTo({
          top: chatWindowRef.current.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
        return;
      }
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (showMessagesModal) setShowMessagesModal(false);
  }, [showMessagesModal]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    setCurrentMessages([]);
    setCurrentChatOffset(0);
    setTimeout(() => {
      scrollModalToBottom();
    }, 100);
  }, [currentChat]);

  useEffect(() => {
    if (!currentChat) {
      setCurrentChat(chats[0]);
    }
  }, [chats]);

  const scrollOnLoad = (): void => {
    if (chatWindowRef.current && chatWindowRef.current.scrollTop === 0) {
      chatWindowRef.current.scrollTop = 10;
    }
  };

  const saveCurrentScroll = (): void => {
    setCurrentScroll(chatWindowRef.current?.scrollTop);
  };

  const scrollModalTo = (Ycoords: number): void => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = Ycoords;
    }
  };

  const goToUserProfile = (): void => {
    saveCurrentScroll();
    navigate(`/${explore}/${artists}/${chatRecipient?._id}`, {
      state: { name: chatName, image: chatRecipient?.profile.image?.thumbnail?.url },
    });
  };

  return (
    <div className="messages-page">
      <div className="messages-page_sidebar">
        <MessagesMenu
          isChatsPage
          getUnreadMessages={getUnreadMessages}
          scrollOnLoad={scrollOnLoad}
        />
      </div>
      <div>
        {currentChat && (
          <div className="messages-page_chat_info">
            <h2>{chatName}</h2>
            {chatRecipient?.profile?.image?.thumbnail.url ? (
              <div onClick={goToUserProfile}>
                <Avatar
                  src={chatRecipient.profile.image.thumbnail.url}
                  className="messages-menu_title_image"
                />
              </div>
            ) : (
              <AvatarPlaceholder
                className="messages-menu_title_image"
                id={chatRecipient?._id}
                width="40px"
                height="40px"
                fontSize="8px"
                onClick={goToUserProfile}
              />
            )}
          </div>
        )}
        <div className="messages-page_chat" ref={chatWindowRef}>
          {currentChat && (
            <Chat
              chat={currentChat}
              setChatName={setChatName}
              setChatRecipient={setChatRecipient}
              scrollModalTo={scrollModalTo}
              scrollToBottom={scrollModalToBottom}
              saveCurrentScroll={saveCurrentScroll}
              getUnreadMessages={getUnreadMessages}
              scrollOnLoad={scrollOnLoad}
              showScrollToBottomButton={showScrollToBottomButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
