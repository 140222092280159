import React, { ChangeEvent, useState } from "react";
import { useQuery } from "@apollo/client";
import { Avatar } from "@mui/material";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Button from "../../UI/button/Button";
import { GetToken } from "../../utils/get-token";
import { useActions } from "../../hooks/useActions";

import "./Comment.scss";
import { UserRequests } from "../../api-queries/AllRequests";

interface CommentInputProps {
  handleSubmit: (str: string) => void;
  placeholder: string;
}

const CommentInput: React.FC<CommentInputProps> = ({ handleSubmit, placeholder }): JSX.Element => {
  const [commentValue, setCommentValue] = useState("");
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const token = GetToken();
  const { openModalLogin } = useActions();
  const { data } = useQuery(UserRequests.USER_AVATAR, {
    variables: { _id: authUserId },
  });
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void =>
    setCommentValue(e.target.value);

  const handleClick = (): void => {
    const trimValue = commentValue.trim();
    if (!trimValue) return;

    handleSubmit(trimValue);
    setCommentValue("");
  };

  const addComment = token ? handleClick : openModalLogin;

  const showLoginModal = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (token) return;
    openModalLogin();
    event?.target.blur();
  };

  return (
    <div className="comment_input">
      <Avatar src={data?.user.profile.image?.thumbnail.url} />
      <div className="input_grid">
        <div className="text_area">
          <TextareaAutosize
            className="text_field text_area__input"
            aria-label="minimum height"
            minRows={1}
            value={commentValue}
            onChange={handleChange}
            placeholder={placeholder}
            style={{ width: "100%" }}
            onFocus={showLoginModal}
          />
        </div>
        <Button onClick={addComment} children="Comment" variant="button-dark" />
      </div>
    </div>
  );
};

export default CommentInput;
