export const DeepLinkStyle = {
  marginTop: 20,
  display: "flex",
  justifyContent: "center",
};

export const DeepLinkIconStyle = {
  width: 150,
  height: 150,
};
