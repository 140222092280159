import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import "./SearchLoader.scss";

interface SearchLoaderProps {
  loading: boolean;
  total?: any;
}

const SearchLoader: React.FC<SearchLoaderProps> = ({ loading, total }) => {
  if (loading) return <CircularProgress className="loader" color="inherit" />;
  if (total === 0) return <h3 className="loader">No results</h3>;
  return null;
};

export default SearchLoader;
