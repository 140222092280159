import React, { FC } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ArtworksPageComponent from "../artworks-page-component/ArtworksPageComponent";
import { IParamsId } from "./utils";

const CollectedArtworks: FC = () => {
  const { id }: IParamsId = useParams();
  const { profilePageContext }: any = useOutletContext();
  return (
    <ArtworksPageComponent
      queryParams={{ collectorId: id, types: ["collector", "artist"] }}
      showMap={false}
      context={profilePageContext.collected}
      title={false}
    />
  );
};

export default CollectedArtworks;
