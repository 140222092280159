import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import LeafletMap from "../leaflet-maps/LeafletMap";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { pluralize } from "../../utils/pluralizeWord";
import tooltipText from "../explore-page-title/tooltip-text-vote.json";
import ExplorePageTitle from "../explore-page-title/ExplorePageTitle";
import "./ArtsControllerList.scss";
import { getUniqueArtworks } from "../artworks-explore-list/utils";
import { IArtwork } from "../../types/artwork/artwork";
import VotesCount from "../../UI/votes-count/VotesCount";
import { useActions } from "../../hooks/useActions";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import ArtworkListItem from "../artwork-list-item/ArtworkListItem";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { myNominatedArtwork } from "../../route-link";

interface ArtsControllerListProps {
  artworks: any;
  refetch: () => void;
  title: string;
  showVotes?: boolean;
  showModal?: boolean;
  blockMaps?: boolean;
  displayFullArtworkInfo?: boolean;
  loading?: boolean;
  campaigns?: boolean;
}

const ArtsControllerList: React.FC<ArtsControllerListProps> = ({
  artworks,
  refetch,
  title,
  showVotes,
  showModal,
  blockMaps,
  displayFullArtworkInfo,
  loading,
  campaigns,
}): JSX.Element => {
  const [artworksData, setArtworksData] = useState(artworks);
  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();
  const widthColumn =
    widthBlock?.current?.offsetWidth / masonryColumns.current?.state?.columnCount - 12;

  const total = artworksData?.length;
  const { addPrevState, removePrevState, openModal } = useActions();
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const isCampaignsPage = routerLocation.pathname.includes("campaigns");
  const saveState = (currentArtwork: IArtwork, id = ""): void => {
    const currArtwork = currentArtwork || artworks.find((artwork: IArtwork) => artwork._id === id);
    setSldrAtrwrs(
      artworks.map((el: IArtwork) => {
        if (isCampaignsPage) {
          return { id: el._id, artwork: el };
        }
        if (currArtwork._id === el._id) {
          return { id: el._id, artwork: currArtwork };
        }
        return { id: el._id, artwork: null };
      }),
    );
    localStorage.setItem(
      "prevState",
      JSON.stringify({ state: artworksData, url: routerLocation.pathname, scroll: window.scrollY }),
    );
    addPrevState({
      url: routerLocation.pathname,
      scroll: window.scrollY,
      state: artworks,
    });
  };

  const ArtworkItem = ({ artwork }: any): JSX.Element => {
    const paramsArtwork = artwork.image.medium.width / artwork.image.medium.height;
    const clickHandler = (): void => {
      if (showModal) {
        openModal();
        return;
      }
      saveState(artwork);
      if (campaigns) {
        navigate(`/explore/artworks/${artwork._id}/${myNominatedArtwork}`);
        return;
      }
      navigate(`/explore/artworks/${artwork._id}`);
    };
    return (
      <div
        className="artwork_item"
        onClick={clickHandler}
        style={{ minHeight: Math.floor(widthColumn / paramsArtwork) }}
      >
        <img className="artwork_item__image" src={artwork.image.medium.url} alt={artwork.name} />
        {showVotes && <VotesCount votes={artwork.votesCount} />}
      </div>
    );
  };

  const scrollHandler = (): void => {
    refetch();
    const uniqueArtworks = getUniqueArtworks([...artworksData, ...(artworks || [])]);
    setArtworksData(uniqueArtworks);
  };
  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevState) {
      setArtworksData(prevState.state);
      setTimeout(() => window.scroll(0, prevState.scroll as number), 100);
      removePrevState(routerLocation.pathname);
      localStorage.removeItem("prevState");
      return;
    }

    if (prevLoadingState?.url === routerLocation.pathname) {
      setArtworksData(prevLoadingState.state);
      setTimeout(() => window.scroll(0, prevLoadingState.scroll), 100);
      localStorage.removeItem("prevState");
      return;
    }
    setArtworksData([]);
    refetch();
    setArtworksData(artworks);
  }, [artworks]);
  return (
    <div className="artworks_explore wrapper">
      <ExplorePageTitle
        title={title}
        questionMark={false}
        myMoca={false}
        shareMark={false}
        tooltipText={tooltipText}
        backButton
      />
      <LeafletMap
        locations={artworksData}
        zoom={3}
        location={undefined}
        exhibitions={undefined}
        block={blockMaps}
        saveState={saveState as any}
      />
      {!loading && (
        <>
          <div className="total">
            {!!total && (
              <span className="filter_results__result">
                {total} {pluralize("Artwork", total)}
              </span>
            )}
          </div>
          <div className="explore_list">
            {total === 0 && (
              <p className="profile-artworks-page__no-artworks">There are no artworks</p>
            )}
            {artworksData && (
              <InfiniteScroll
                scrollThreshold={0.7}
                dataLength={artworksData.length ? artworksData.length : 50}
                next={scrollHandler}
                hasMore
                loader
              >
                <div ref={widthBlock}>
                  <Masonry
                    ref={masonryColumns}
                    breakpointCols={BREAKPOINT_COLUMNS_OBJ}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {artworksData.map((artwork: IArtwork) => (
                      <>
                        {displayFullArtworkInfo ? (
                          <ArtworkListItem
                            key={artwork._id}
                            id={artwork._id}
                            saveState={saveState as any}
                            artwork={artwork}
                          />
                        ) : (
                          <ArtworkItem
                            saveState={saveState}
                            showModal={showModal}
                            key={artwork._id}
                            artwork={artwork}
                          />
                        )}
                      </>
                    ))}
                  </Masonry>
                </div>
              </InfiniteScroll>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ArtsControllerList;
