import React, { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import "../ExploreItemDetailsRight.scss";
import Button from "../../../UI/button/Button";

import "./NominatedArtworkDetails.scss";
import { IExploreItemDetailsRight } from "../utils";
import { votesArtwork } from "../../../route-link";

const VotesArtworkDetails: React.FC<IExploreItemDetailsRight> = () => {
  const artworkDetailsRightSection = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const [title, setTitle] = useState("Copy link");

  const copyLink = (): void => {
    const link = window.location.href.replace("my-nominated-artwork", votesArtwork);
    navigator.clipboard.writeText(link);
    setTitle("Link copied");
  };

  return (
    <div ref={artworkDetailsRightSection} className="explore_item_details_right_nominated">
      <div className="wrap">
        <Button color="light" className="nominated-button" variant="button">
          NOMINATED
        </Button>
        <h2 className="heading">
          Votes get Art into <br /> the New York MOCA
        </h2>
        <hr className="horizontal-line" />
        <p>Ask people to vote for you</p>

        <Tooltip onOpen={() => setTitle("Copy link")} title={title}>
          <Button className="share-button" onClick={copyLink} color="dark" value="button-dark">
            SHARE NOW
          </Button>
        </Tooltip>

        <Button variant="text" className="do-later-button" onClick={() => navigate(-1)}>
          I'll do this later
        </Button>
      </div>
    </div>
  );
};
export default memo(VotesArtworkDetails);
