import { ITab } from "./ArtworkTabs.type";

export const artworkTabsList: ITab[] = [
  {
    id: "1",
    label: "Comments",
  },
  {
    id: "2",
    label: "Transfer History",
  },
  {
    id: "3",
    label: "Shows",
  },
];

export const artworkTabsListFound: ITab[] = [
  {
    id: "1",
    label: "Comments",
  },
];
