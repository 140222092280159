import moment from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { artworks, explore } from "../../../../route-link";
import { IArtwork } from "../../../../types/artwork/artwork";
import NotificationImage from "../../NotificationImage";

interface IArtworkNoticeAuditedProps {
  artwork: IArtwork;
  time: Date;
}

const ArtworkNoticeAudited: FC<IArtworkNoticeAuditedProps> = ({ artwork, time }) => {
  const imageSrc = artwork.image.medium.url;

  return (
    <>
      <p className="follow_notification__time">{moment(time).fromNow()}</p>

      <Link to={`${explore}/${artworks}/${artwork._id}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </>
  );
};

export default ArtworkNoticeAudited;
