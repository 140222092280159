import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditArtworkArtist from "../../components/edits/EditArtworkArtist";
import { getSubtitle } from "../upload-artwork-info/utils";
import Button from "../../UI/button/Button";
import DialogWindow from "../../components/dialog-window/DialogWindow";
import DialogContent from "../../UI/dialog-content/DialogContent";
import { removeArtwork } from "../../api-queries/GraohQlClient";
import { UseLocationState } from "../../components/edits/edit.props";

import "./Edit.scss";
import { useActions } from "../../hooks/useActions";
import { removeArtworkFromLocalStorage } from "../../utils/removeArtworkFromLocalStorage";
import { incomingTransfers } from "../../route-link";

const Edit = ({ isUploadArtwork = false }): JSX.Element => {
  const { state } = useLocation() as UseLocationState;
  const { id } = useParams();
  const navigate = useNavigate();
  const subtitle = getSubtitle(state.type);
  const imageArtwork = state?.image?.medium?.url || state?.image?.thumbnail?.url;
  const [dialogOpen, setDialogOpen] = useState(false);
  const { removeArtworkFromStates, removeArtworkFromSpecificState } = useActions();
  const location = useLocation();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const handleDialogClose = (): void => setDialogOpen(false);
  const handleDialogOpen = (): void => setDialogOpen(true);

  const removeArtFromStates = (): void => {
    if (!id) return;
    removeArtworkFromLocalStorage(id);

    if (location.pathname.includes(incomingTransfers)) {
      removeArtworkFromSpecificState(`/${incomingTransfers}/${myMOCAId}`, id);
      return;
    }
    removeArtworkFromStates(id);
  };

  const handleRemoveArtwork = async (): Promise<void> => {
    try {
      if (!id) return;
      removeArtwork({ artworkId: id });
      removeArtFromStates();
      handleDialogClose();
      navigate(isUploadArtwork ? -1 : -2);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="edit">
      <DialogWindow
        className="explore_item_details_right__dialog"
        open={dialogOpen}
        handleClose={handleDialogClose}
        title="Do you really want to remove the artwork?"
      >
        <DialogContent handleDialogClose={handleDialogClose} handleRemove={handleRemoveArtwork} />
      </DialogWindow>
      <div className="edit_container">
        <div className="edit_container__wrapper">
          <div className="edit_title__wrap">
            <ArrowBackIcon className="arrow__back" onClick={() => navigate(-1)} />
            <h2 className="edit_title">Edit Artwork</h2>
            <div className="edit_sub_title">{subtitle}</div>
            <Button onClick={handleDialogOpen} className="edit_delete_button" variant="button-dark">
              Delete
            </Button>
          </div>
          <div className="image_artwork">
            <img src={imageArtwork} alt={state?.name} />
          </div>
        </div>
        <div className="edit_form__content">
          <EditArtworkArtist isUploadArtwork={isUploadArtwork} />
        </div>
      </div>
    </div>
  );
};

export default Edit;
