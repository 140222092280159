import React, { useState, FC, ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreHoriz } from "@mui/icons-material";

interface MoreHorizontalMenuProps {
  options: Array<{ title: string; onClick: (param?: any) => void }>;
  children?: ReactNode;
}

const MoreHorizontalMenu: FC<MoreHorizontalMenuProps> = ({ options, children }) => {
  const ITEM_HEIGHT = 48;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const onMenuItemClick = (optionOnClick: (param?: any) => void) => (): void => {
    optionOnClick();
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map(
          option =>
            option.title && (
              <MenuItem key={option.title} onClick={onMenuItemClick(option.onClick)}>
                {option.title}
              </MenuItem>
            ),
        )}
        {children}
      </Menu>
    </div>
  );
};

export default MoreHorizontalMenu;
