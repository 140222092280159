import React, { FC } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { ArtworksRequests } from "../../api-queries/AllRequests";
import ArtworksPageComponent from "../artworks-page-component/ArtworksPageComponent";
import { IParamsId } from "./utils";

const ArtworksForSale: FC = () => {
  const { id }: IParamsId = useParams();
  const { profilePageContext }: any = useOutletContext();
  return (
    <ArtworksPageComponent
      query={ArtworksRequests.USER_CURRENT_ARTWORKS}
      dataName="userCurrentArtworks"
      queryParams={{ userId: id, saleable: true }}
      showMap={false}
      context={profilePageContext.forSale}
      title={false}
    />
  );
};

export default ArtworksForSale;
