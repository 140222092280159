import { ACTION } from "./request-params/ActionRequestParams";

export const SEARCH_NOTIFICATIONS_ACTIONS = `
query actionsNotifications($limit: Int, $offset: Int) {
  actionsNotifications(limit: $limit, offset: $offset) {
    actions {
      ${ACTION}
    }
    meta {
      limit
      offset
      total
      lastSyncedAt
    }
  }
}`;
