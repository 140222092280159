import React from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";
import FacebookBrands from "../../assets/images/FacebookBrands";
import { privacyPolicy, termsOfUse } from "../../route-link";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="footer_grid">
          <ul className="footer_address">
            <li>My MOCA</li>
            <li>Shop Studio LLC</li>
            <li>528 W 39th St</li>
            <li>New York, New York 07062</li>
            <li>Copy Right 2021 ShopsStudiosLLC</li>
          </ul>
        </div>
        <div className="footer_soc__icons">
          <a href="https://www.facebook.com/My-MOCA-app-is-My-Museum-of-Contemporary-Art-105980677976380/">
            <FacebookBrands />
          </a>
        </div>
        <hr />
        <div className="footer_copy">
          <span className="copyrights__text">&copy; {currentYear} My MOCA</span>
          <div className="footer_copy__link">
            <Link to={`/${termsOfUse}`}>Terms of Use</Link>
            <Link to={`/${privacyPolicy}`}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
