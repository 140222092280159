import React from "react";
import { Link } from "react-router-dom";
import TextTemplateNotification from "./TextTemplateNotification";
import { artists, artworks, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";
import { IArtwork } from "../../types/artwork/artwork";
import Spider from "../../assets/images/spider.jpeg";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { IImage } from "../../types/imageData";

interface PublicCommentsArtworkProps {
  nameFollower: string;
  userId: string;
  textComment: string;
  time: Date;
  artwork: IArtwork;
  userImage: IImage;
}

const PublicCommentsArtworkNotification: React.FC<PublicCommentsArtworkProps> = ({
  nameFollower,
  userId,
  time,
  textComment,
  artwork,
  userImage,
}) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const imageSrc = artwork?.image?.thumbnail.url || Spider;
  const artworkId = artwork?._id;
  const { setArtworks } = useArtworksSldrCntx();
  const nameUser =
    artwork.owner._id === authUserId
      ? "You"
      : `${artwork?.owner?.profile.firstName} ${artwork?.owner?.profile.secondName}`;
  const setArtworkCarouselItems = (): void => {
    setArtworks([{ id: artwork._id, artwork }]);
  };
  return (
    <div className="notification_like__artwork">
      <TextTemplateNotification
        messageText={`left a comment on artwork uploaded by `}
        nameFollower={nameFollower}
        linkNameFollower={`${explore}/${artists}/${userId}`}
        nameUser={nameUser}
        linkNameUser={`${explore}/${artists}/${authUserId}`}
        textComment={textComment}
        userImage={userImage}
      />
      <NotificationTime time={time} />
      <Link onClick={setArtworkCarouselItems} to={`${explore}/${artworks}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </div>
  );
};

export default PublicCommentsArtworkNotification;
