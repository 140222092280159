import { IResultButton } from "../../components/filter-units/utils";
import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { SearchArtworksRequest } from "../../types/artwork/artwork";
import { IFilterCategory } from "../../types/filterTypes";
import { IInstitution } from "../../types/institution";
import { ILocationFilter } from "../types/artistsPageTypes";
import {
  InstitutionsPageActions,
  InstitutionsPageActionTypes,
} from "../types/institutionsPageTypes";

export const SET_INSTITUTIONS = (payload: IInstitution[]): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_INSTITUTIONS,
  payload,
});

export const SET_OFFSET = (payload: number): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_OFFSET,
  payload,
});

export const SET_FILTER_QUERY_PARAMS = (
  payload: SearchArtworksRequest,
): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_FILTER_QUERY_PARAMS,
  payload,
});

export const SET_CATEGORIES = (payload: IFilterCategory[]): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_CATEGORIES,
  payload,
});

export const SET_FILTER_RESULTS = (payload: IResultButton[]): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_FILTER_RESULTS,
  payload,
});

export const SET_LOCATION_SORT_VALUE = (
  payload: ILocationSelectValue,
): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_LOCATION_SORT_VALUE,
  payload,
});

export const SET_LOCATION_AUTOCOMPLETE_VALUE = (
  payload: ILocationFilter | null,
): InstitutionsPageActions => ({
  type: InstitutionsPageActionTypes.SET_LOCATION_AUTOCOMPLETE_VALUE,
  payload,
});
