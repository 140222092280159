import React, { Dispatch, SetStateAction } from "react";
import { InputLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditInput from "./EditInput";
import { DoubleInputType, ErrorFields } from "../edit.props";

interface EditDoubleInputProps {
  labelFirstInput: string;
  labelSecondInput: string;
  setValues: (param: React.SetStateAction<DoubleInputType[]>) => void;
  values: DoubleInputType[];
  setErrorFields?: Dispatch<SetStateAction<ErrorFields>>;
  errorFields?: ErrorFields;
  type?: "collected" | "exhibitions";
  showErrors: boolean;
  isUploadArtwork?: boolean;
}

const EditDoubleInput: React.FC<EditDoubleInputProps> = ({
  labelFirstInput,
  labelSecondInput,
  setValues,
  values,
  setErrorFields,
  errorFields,
  type,
  showErrors,
  isUploadArtwork,
}): JSX.Element => {
  const handleAddCollected = (): void => {
    setValues([
      ...values,
      {
        id: Date.now(),
        firstInputValue: "",
        secondInputValue: "",
      },
    ]);
  };
  const handleDeleteCollected = (id: string | number) => () => {
    setValues(values.filter(el => el.id !== id));
  };
  return (
    <div className="double_input">
      <div className="double_input__content">
        <div className="double_edit_input__items">
          <div className="double_edit_input__value">
            <div className="double_edit_input__label">
              <InputLabel className="origin_label" variant="standard" htmlFor={labelFirstInput}>
                {labelFirstInput}
              </InputLabel>
              <InputLabel className="origin_label" variant="standard" htmlFor={labelSecondInput}>
                {labelSecondInput}
              </InputLabel>
            </div>
            <div className="double_input__grid">
              {values.map((el, index) => (
                <EditInput
                  valuesLength={values.length}
                  handleDeleteCollected={handleDeleteCollected}
                  index={index}
                  labelFirstInput={labelFirstInput}
                  labelSecondInput={labelSecondInput}
                  key={el.id}
                  value={el}
                  setValues={setValues}
                  setErrorFields={setErrorFields}
                  errorFields={errorFields}
                  inputId={el.id}
                  type={type}
                  showErrors={showErrors}
                  isUploadArtwork={isUploadArtwork}
                />
              ))}
            </div>
          </div>
          <AddIcon className="add_input__multi" onClick={handleAddCollected} />
        </div>
      </div>
    </div>
  );
};

export default EditDoubleInput;
