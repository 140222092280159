import React, { Reducer, useEffect, useReducer, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import NavMenuExplore from "../../components/nav-menu/NavMenuExplore";

import "./ExplorePage.scss";
import { IArtwork } from "../../types/artwork/artwork";
import { IInitialState } from "../../context/exploreContext";
import { artworkFilterReducer, initialState } from "../../context/reducers/artworkFilterReducer";
import {
  initialInstitutionPageState,
  institutionPageReducer,
} from "../../context/reducers/institutionsPageReducer";
import { showsPageInitialState, showsPageReducer } from "../../context/reducers/showsPageReducer";
import {
  artistsPageInitialState,
  artistsPageReducer,
} from "../../context/reducers/artistsPageReducer";

const ExplorePage: React.FC = () => {
  const [explorePageArtworks, setExplorePageArtworks] = useState<IArtwork[]>([]);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const createState = (reducer: Reducer<any, any>, initialState: IInitialState): any => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch };
  };
  const artworksPageContext = createState(artworkFilterReducer, initialState);
  const tvPageContext = createState(artworkFilterReducer, initialState);
  const buyPageContext = createState(artworkFilterReducer, initialState);
  const exploreListContext = { explorePageArtworks, setExplorePageArtworks };
  const movieExplorePageContext = createState(institutionPageReducer, initialInstitutionPageState);
  const venuesExplorePageContext = createState(institutionPageReducer, initialInstitutionPageState);
  const institutionsExplorePageContext = createState(
    institutionPageReducer,
    initialInstitutionPageState,
  );
  const showsPageContext = createState(showsPageReducer, showsPageInitialState);
  const submitArtPageContext = createState(showsPageReducer, showsPageInitialState);
  const artistsPageContext = createState(artistsPageReducer, artistsPageInitialState);

  const profilePageContext = {
    created: createState(artworkFilterReducer, initialState),
    forSale: createState(artworkFilterReducer, initialState),
    collected: createState(artworkFilterReducer, initialState),
    found: createState(artworkFilterReducer, initialState),
  };

  useEffect(() => {
    if (pathname.includes("/explore/art-for-sale/")) {
      setShowHeader(false);
      return;
    }
    setShowHeader(true);
  }, [pathname]);

  return (
    <div className="explore">
      {showHeader && (
        <div className="explore_navigation">
          <NavMenuExplore />
        </div>
      )}
      {/* Explore route */}
      <Outlet
        context={{
          exploreListContext,
          artworksPageContext,
          buyPageContext,
          movieExplorePageContext,
          venuesExplorePageContext,
          showsPageContext,
          artistsPageContext,
          profilePageContext,
          institutionsExplorePageContext,
          submitArtPageContext,
          tvPageContext,
        }}
      />
    </div>
  );
};

export default ExplorePage;
