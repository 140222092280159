import React from "react";
import { IOwner } from "../../types/owner";
import { ITransfer } from "../../types/transfer";
import TransferHistoryItem from "../transfer-history-item/TransferHistoryItem";

import "./transfer_history__list.scss";

interface ITransferHistoryList {
  transferHistory: Array<ITransfer>;
  pastCollectors: Array<IOwner>;
}

const TransferHistoryList: React.FC<ITransferHistoryList> = ({
  transferHistory,
  pastCollectors,
}) => {
  return (
    <div className="transfer_history__list">
      {!transferHistory.length ? (
        <p className="profile-artworks-page__no-artworks">Thera is no transfer history</p>
      ) : (
        <>
          <h3 className="artwork_shows__title">Transfers</h3>
          <div className="transfer_history__grid">
            {transferHistory?.map((history, index: number) => (
              <TransferHistoryItem
                key={history._id}
                transferHistory={history}
                owner={pastCollectors[index]}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TransferHistoryList;
