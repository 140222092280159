import React, { useState } from "react";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocationAutocomplete from "../../locations-autocomplete/LocationAutocomplete";
import { ILocationFilter } from "../../../context/types/artistsPageTypes";
import { ErrorFields } from "../edit.props";

const NUMBS_EMT_STR_ONLY_REGEX = /^(\s*|\d+)$/;

interface EditInputProps {
  labelFirstInput: string;
  labelSecondInput: string;
  value: any;
  setValues: any;
  setErrorFields?: (param: any) => void;
  errorFields?: ErrorFields;
  valuesLength: number;
  index: number;
  handleDeleteCollected: (id: string | number) => () => void;
  inputId: string | number;
  type?: "collected" | "exhibitions";
  showErrors: boolean;
  isUploadArtwork?: boolean;
}

const EditInput: React.FC<EditInputProps> = ({
  labelFirstInput,
  labelSecondInput,
  value,
  valuesLength,
  setValues,
  setErrorFields,
  errorFields,
  index,
  handleDeleteCollected,
  inputId,
  type,
  showErrors,
  isUploadArtwork,
  ...props
}): JSX.Element => {
  const [location, setLocation] = useState<ILocationFilter | null>(value.firstInputValue || null);
  const showCollectedAt = labelFirstInput === "collected at";
  const handleChangeFirstInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValues((prev: any) => {
      const newValues = prev.map((el: any) => {
        if (el.id === value.id) {
          return { ...el, firstInputValue: event.target.value };
        }
        return el;
      });
      return [...newValues];
    });
  };

  const fields = Object.values(value).filter(val => val !== value.id);
  const isAllFieldsFilled = fields.every(field => !!field);
  const isSomeFieldsFilled = fields.some(field => !!field);

  const handleChangeSecondInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > 4 || !NUMBS_EMT_STR_ONLY_REGEX.test(event.target.value)) return;
    setValues((prev: any) => {
      const newValues = prev.map((el: any) => {
        if (el.id === value.id) {
          return { ...el, secondInputValue: event.target.value };
        }
        return el;
      });
      return [...newValues];
    });
  };

  const handleChangeLocation = (currentValue: ILocationFilter | null): void => {
    if (errorFields && setErrorFields) {
      setErrorFields({ ...errorFields, collectedAtError: "" });
      setLocation(currentValue);

      setValues((prev: any) => {
        const newValues = prev.map((el: any) => {
          if (el.id === value.id) {
            return { ...el, firstInputValue: currentValue };
          }
          return el;
        });
        return [...newValues];
      });
    }
  };

  const onCloseIconClickLocation = (): void => {
    setLocation(null);
    if (errorFields && setErrorFields)
      setErrorFields({
        ...errorFields,
        collectedAtError: "Location where artwork is collected is required",
      });
    setValues((prev: any) => {
      const newValues = prev.map((el: any) => {
        if (el.id === value.id) {
          return { ...el, firstInputValue: "" };
        }
        return el;
      });
      return [...newValues];
    });
  };

  const emptyFieldCheck = Boolean(!isAllFieldsFilled && isSomeFieldsFilled);
  const errorField = (fieldValue: string | ILocationFilter, isYearField = false): JSX.Element => {
    return (
      <>
        {type === "exhibitions" &&
          emptyFieldCheck &&
          !fieldValue &&
          errorFields?.exhibitionsError && (
            <p className="error_message">
              {isYearField ? "Year must be provided" : "Exhibition/Appraisal field must be filled"}
            </p>
          )}
        {type === "collected" && emptyFieldCheck && !fieldValue && errorFields?.collectedError && (
          <p className="error_message">
            {isYearField ? "Year must be provided" : "Collected field must be filled"}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="double_input__item">
      {showCollectedAt ? (
        <div>
          <LocationAutocomplete
            value={value.firstInputValue || location}
            className="input edit_item__location first_input"
            onChange={handleChangeLocation}
            onCloseIconClick={onCloseIconClickLocation}
            placeholder="Choose location..."
          />
          {isUploadArtwork && (!errorFields?.collectedAtError || !showErrors) && (
            <p className="info_message">Location where artwork is collected is required</p>
          )}
          {showErrors && errorFields?.collectedAtError && (
            <p className="error_message error_message__collectedAt">
              {errorFields?.collectedAtError}
            </p>
          )}
          {errorField(value.firstInputValue)}
        </div>
      ) : (
        <div>
          <TextField
            id={labelFirstInput}
            className="input first_input"
            type="text"
            {...props}
            variant="standard"
            value={value.firstInputValue}
            onChange={handleChangeFirstInput}
          />
          {errorField(value.firstInputValue)}
        </div>
      )}
      <div>
        <TextField
          id={labelSecondInput}
          className="input second_input"
          type="text"
          variant="standard"
          value={value.secondInputValue}
          onChange={handleChangeSecondInput}
        />
        {errorField(value.secondInputValue, true)}
      </div>
      {index !== valuesLength - 1 && (
        <CloseIcon onClick={handleDeleteCollected(inputId)} className="double_input__close" />
      )}
    </div>
  );
};

export default EditInput;
