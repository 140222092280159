import { UserActionTypes, UserState, UserType } from "../types/user.type";

const initialValue: UserState = {
  user: null,
  userCounters: null,
};

const userReducer = (state = initialValue, action: UserType): UserState => {
  switch (action.type) {
    case UserActionTypes.ADD_USER:
      return { ...state, user: action.payload };
    case UserActionTypes.SET_USER_COUNTERS:
      return { ...state, userCounters: action.payload };
    default:
      return { ...state };
  }
};
export default userReducer;
