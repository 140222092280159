import { ITab } from "../../components/artist-profile-artworks-tabs/utils";
import { ProfileActionTypes } from "../types/profile";
import { ReturnActionType } from "../types/return-type";

export const setProfileUserId = (payload: string): ReturnActionType => ({
  type: ProfileActionTypes.SET_PROFILE_USER_ID,
  payload,
});

export const setActiveArtworkTab = (payload: string): ReturnActionType => ({
  type: ProfileActionTypes.SET_ACTIVE_ARTWORK_TAB,
  payload,
});

export const setProfileContainerHeight = (payload: number): ReturnActionType => ({
  type: ProfileActionTypes.SET_PROFILE_HEIGHT,
  payload,
});

export const setArtworkTabs = (payload: ITab[]): ReturnActionType => ({
  type: ProfileActionTypes.SET_ARTWORK_TABS,
  payload,
});
