import React, { FC } from "react";

import moment from "moment";
import { Skeleton } from "@mui/material";

import moca from "../../assets/images/logo.png";
import Button from "../../UI/button/Button";
import UserListItem from "../user-list-item/UserListItem";
import tvIcon from "../../assets/images/iconTelevision@3x.png";
import { IArtwork } from "../../types/artwork/artwork";
import { getArtworkDimensions, getMaterialsAndCategoriesText, getOwnerLabel } from "./utils";
import CollectedByUser from "../collected-by-user/CollectedByUser";

interface MainInfoProps {
  artwork: IArtwork;
  showOwnerActionsButtons: boolean;
  showCollectorActionButtons: boolean;
  showRequestTvModal: () => void;
}
const MainInfo: FC<MainInfoProps> = ({
  artwork,
  showOwnerActionsButtons,
  showCollectorActionButtons,
  showRequestTvModal,
}) => {
  const artworkCollectorId = artwork?.collector?._id;

  const artworkOwnerId = artwork?.owner?._id;
  const showCollectedLabel = Boolean(
    artworkOwnerId === artworkCollectorId && artwork?.type !== "found",
  );
  const ownerLabel = getOwnerLabel(artwork?.type, showCollectedLabel);
  const displayArtistName = Boolean(artwork?.artistName && ownerLabel);
  const isInstitution = Boolean(
    artwork?.owner?.hasInstitution && artwork?.owner?.institutions?.length,
  );
  const showRequestButton = Boolean(!showOwnerActionsButtons && !showCollectorActionButtons);

  const getUploadedDate = (): string => moment(new Date(+artwork?.createdAt)).format("LLL");

  const showPriceForOwner =
    Boolean(showOwnerActionsButtons || showCollectorActionButtons) &&
    artwork?.saleable &&
    !!artwork?.minimumBid &&
    artwork;

  const dataIsReady = Boolean(artwork);

  const displayMaterialsCategories = Boolean(
    artwork?.materials?.length || artwork?.categories?.length || artwork?.colors?.length,
  );

  const showCollectedBy = Boolean(
    artwork?.transferHistory?.length || artwork?.owner?._id !== artwork?.collector?._id,
  );

  return (
    <>
      <div className="explore_item_details_right__description">
        {artwork && artwork?.owner?.profile ? (
          <UserListItem
            profile={artwork?.owner?.profile}
            id={artwork?.owner?._id}
            likedArtworks={artwork?.owner?.likedArtworks}
            ownerLabel={ownerLabel}
            owner={artwork?.owner}
            isInstitution={isInstitution}
          />
        ) : (
          <div className="explore_item_details_right__owner_skeleton">
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rectangular" width={100} height={15} />
          </div>
        )}
        {artwork?.winner && (
          <div className="explore_item_details_right__winner">
            <span className="explore_item_details_right__icon">
              <img src={moca} alt="Moca" />
            </span>
            <span className="explore_item_details_right__winer_text">MOCA Winner</span>
          </div>
        )}
        {artwork?.allowUseForFilmAndTv && artwork?.hasFilmsOrTv && (
          <div className="explore_item_details_right__tv">
            <span className="explore_item_details_right__icon">
              <img alt="tvIcon" src={tvIcon} />
            </span>
            <div className="explore_item_details_right__tv_text">
              <span>Available for Movie/TV</span>
              {showRequestButton && (
                <Button
                  className="explore_item_details_right__request_use_button"
                  color="dark"
                  onClick={showRequestTvModal}
                >
                  Request Use
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {dataIsReady && (
        <div className="explore_item_details_right__title_wrapper">
          <h2 className="explore_item_details_right__title">
            {artwork?.name?.trim() || "No name"}
            {artwork?.createdByArtistYear && <span>{`, ${artwork?.createdByArtistYear}`}</span>}
          </h2>
        </div>
      )}
      {(displayArtistName || showCollectedBy) && (
        <h4 className="explore_item_details_right__artist_name">{artwork?.artistName}</h4>
      )}
      {showPriceForOwner && (
        <p className="explore_item_details_right__price">
          Price: $ {artwork?.minimumBid?.toLocaleString()}
        </p>
      )}
      {displayMaterialsCategories && (
        <div className="explore_item_details_right__materials">
          {getMaterialsAndCategoriesText(artwork)}
        </div>
      )}
      {!!artwork && (
        <div className="explore_item_details_right__dimensions">
          {getArtworkDimensions(artwork)}
        </div>
      )}
      {showCollectedBy && <CollectedByUser collector={artwork?.collector} />}
      {dataIsReady && artwork?.createdAt ? (
        <div className="explore_item_details_right__uploaded">Uploaded on {getUploadedDate()}</div>
      ) : (
        <Skeleton variant="rectangular" width={150} height={10} style={{ marginTop: 20 }} />
      )}
    </>
  );
};

export default MainInfo;
