import { ArtworkLocationTypes } from "../components/artwork-details-map/utils";

export const SORT_BY_DATE = "createdAt";
export const SORT_BY_NAME = "name";
export const SORT_BY_PRICE = "minimumBid";
export const SORT_BY_VOTES_COUNT = "votesCount";
export const SORT_BY_LIKES_COUNT = "likesCount";
export const SORT_BY_SCANS_COUNT = "scansCount";

export const SORT_ARTISTS_BY_FOLLOWERS_COUNT = "myFollowers";
export const SORT_ARTISTS_BY_VOTES_COUNT = "myArtworksVotes";
export const SORT_ARTISTS_BY_CREATEAT = "createdAt";
export const SORT_ARTISTS_BY_SCANS_COUNT = "myArtworksScans";

export const ORDER_BY_ASC = "asc";
export const ORDER_BY_DESC = "desc";

export const maxNumber = 99999999999;

export const SORT_NEWEST = {
  name: "Newest",
  sortBy: SORT_BY_DATE,
  orderBy: ORDER_BY_DESC,
  forSale: undefined,
};

export const SORT_OLDEST = {
  name: "Oldest",
  sortBy: SORT_BY_DATE,
  orderBy: ORDER_BY_ASC,
  forSale: undefined,
};

export const SORT_LOWEST_PRICE = {
  name: "Lowest Price",
  sortBy: SORT_BY_PRICE,
  orderBy: ORDER_BY_ASC,
  forSale: true,
  priceRange: { from: 0, to: maxNumber },
};

export const SORT_HIGHEST_PRICE = {
  name: "Highest Price",
  sortBy: SORT_BY_PRICE,
  orderBy: ORDER_BY_DESC,
  forSale: true,
  priceRange: { from: 0, to: maxNumber },
};

export const MOST_VOTES = {
  name: "Most Votes",
  sortBy: SORT_BY_VOTES_COUNT,
  orderBy: ORDER_BY_DESC,
  forSale: undefined,
};

export const MOST_LIKES = {
  name: "Most Likes",
  sortBy: SORT_BY_LIKES_COUNT,
  orderBy: ORDER_BY_DESC,
  forSale: undefined,
};

export const MOST_SCANS = {
  name: "Most Scans",
  sortBy: SORT_BY_SCANS_COUNT,
  orderBy: ORDER_BY_DESC,
  forSale: undefined,
};

export const CLOSEST_ARTWORKS = {
  name: "Closest",
};

export const MOST_FOLLOWERS_ARTISTS = {
  name: "Most Followers",
  sortBy: SORT_ARTISTS_BY_FOLLOWERS_COUNT,
  orderBy: ORDER_BY_DESC,
};

export const MOST_VOTES_ARTISTS = {
  name: "Most Votes",
  sortBy: SORT_ARTISTS_BY_VOTES_COUNT,
  orderBy: ORDER_BY_DESC,
};

export const MOST_SCANED_ARTISTS = {
  name: "Most Scaned",
  sortBy: SORT_ARTISTS_BY_SCANS_COUNT,
  orderBy: ORDER_BY_DESC,
};

export const NEWEST_ARTISTS = {
  name: "Newest",
  sortBy: SORT_ARTISTS_BY_CREATEAT,
  orderBy: ORDER_BY_DESC,
};

export const OLDEST_ARTISTS = {
  name: "Oldest",
  sortBy: SORT_ARTISTS_BY_CREATEAT,
  orderBy: ORDER_BY_ASC,
};

export const CLOSEST_ARTISTS = {
  name: "Closest",
};

export const PREDEFINED_ARTWORKS_SORT = [
  SORT_NEWEST,
  SORT_OLDEST,
  SORT_LOWEST_PRICE,
  SORT_HIGHEST_PRICE,
  MOST_VOTES,
  MOST_LIKES,
  MOST_SCANS,
  CLOSEST_ARTWORKS,
];

export const PREDEFINED_MAPS_SORT = [
  { value: ArtworkLocationTypes.LocationLives, name: "Lives at" },
  { value: ArtworkLocationTypes.LocationCreated, name: "Created at" },
  { value: ArtworkLocationTypes.LocationCollected, name: "Collected at" },
];

export const BREAKPOINT_COLUMNS_OBJ = {
  default: 6,
  1900: 4,
  1100: 3,
  500: 2,
};

export const BREAKPOINT_COLUMNS_SHOWS = {
  default: 3,
  1100: 2,
  500: 1,
};

export const BREAKPOINT_COLUMNS_ARTISTS = {
  default: 4,
  1900: 4,
  500: 2,
};
