import { IArtworksPageFilterContext } from "../../context/exploreContext";

export enum Range {
  Width = "widthRange",
  Height = "heightRange",
  Price = "priceRange",
}

export enum LengthUnits {
  In = "in",
  Cm = "cm",
}

type RefetchArg = {
  [key in Range]?: {
    from: number;
    to: number;
  };
};

export interface FilterFromToProps {
  refetch?: (arg: RefetchArg) => void;
  setArtworks: (p: any[]) => void;
  requestField: Range;
  unit: string;
  typeFilter: string;
  minValue?: number;
  setMinValue: (arg: any) => void;
  maxValue?: number;
  setMaxValue: (arg: any) => void;
  context: IArtworksPageFilterContext;
  priceRange?: boolean;
}
