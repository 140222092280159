import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Alert, Button, Checkbox, InputAdornment, TextField } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import PhoneIcon from "@mui/icons-material/Phone";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, useNavigate } from "react-router-dom";

import { privacyPolicy, termsOfUse, uploadUserImage, user } from "../../route-link";
import { RegistrationFormInstitutionProps } from "./RegistrationForm.props";
import { RegistrationRequests } from "../../api-queries/AllRequests";
import { useActions } from "../../hooks/useActions";

const RegistrationFormInstitution: React.FC<RegistrationFormInstitutionProps> = ({
  handleChangeCheck,
  checked,
}) => {
  const navigate = useNavigate();
  const [addUser] = useMutation(RegistrationRequests.SIGN_UP);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
  });

  const [errors, setErrors] = useState<Array<string>>([]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const institutionVariables = () => {
    const splitName = formValues.name.split(" ");
    return addUser({
      variables: {
        firstName: splitName[0] || "",
        lastName: splitName[1] || "",
        email: formValues.email,
        password: formValues.password,
        phoneNumber: formValues.phone,
        hasInstitution: true,
      },
    });
  };

  const { closeModalLogin, addToken, login, headerFlag } = useActions();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = await institutionVariables();
      localStorage.setItem("tokenKey", data.data.signUp.token);
      localStorage.setItem("myMOCAId", data.data.signUp.me._id);
      addToken(data.data.signUp.token);
      headerFlag();
      login();
      closeModalLogin();
      navigate(`/${user}/${uploadUserImage}`, {
        state: {
          ...formValues,
          id: data.data.signUp.me._id,
          hasInstitution: true,
        },
      });
    } catch (err: any) {
      setErrors([]);
      if (err.message.toLowerCase().includes("name")) {
        setErrors(["Please provide your first and last name"]);
      }
      if (err.message.toLowerCase().includes("email")) {
        setErrors(["User with this email already registered"]);
      }
      if (err.message.toLowerCase().includes("password")) {
        setErrors(["The password should contains at least 8 characters"]);
      }
    }
  };

  const onChangeFormValue = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>): void =>
    setFormValues({
      ...formValues,
      [name]: value,
    });
  return (
    <form onSubmit={onSubmitForm} noValidate>
      <TextField
        id="first_name"
        className="registration_input input"
        name="name"
        label="administrator name"
        placeholder="Example: John Junior Doe"
        value={formValues.name}
        onChange={onChangeFormValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <TextField
        id="input_email"
        className="registration_input input"
        label="email"
        type="email"
        name="email"
        value={formValues.email}
        onChange={onChangeFormValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <TextField
        id="input_password"
        className="registration_input input"
        label="password"
        name="password"
        type="password"
        value={formValues.password}
        onChange={onChangeFormValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <p className="under_input">Case sensitive</p>
      <TextField
        id="input_phone"
        className="registration_input input"
        label="phone"
        name="phone"
        onChange={onChangeFormValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon className="registration_input__icon" />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      <p className="under_input">
        *Phone number is public and will be displayed on institution public profile (optional)
      </p>

      {!!errors.length && (
        <Alert className="alert_error" severity="error">
          {errors.map(error => (
            <div key={error} className="alert_error__message">
              <strong>{error}.</strong>
            </div>
          ))}
        </Alert>
      )}

      <div className="registration_check__block">
        <div className="check_box">
          <Checkbox
            className="registration_check"
            checked={checked}
            onChange={handleChangeCheck}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        <p className="registration_check__text">
          By creating your account you agree to our{" "}
          <Link className="registration_check__link" target="_blank" to={termsOfUse}>
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link className="registration_check__link" target="_blank" to={privacyPolicy}>
            Privacy Policy
          </Link>
        </p>
      </div>
      <div className="registration_buttons">
        <Button
          disabled={
            !formValues.name ||
            !formValues.email ||
            !formValues.password ||
            !formValues.phone ||
            !checked
          }
          type="submit"
          className="login_button"
          variant="contained"
        >
          Sign up
        </Button>
      </div>
    </form>
  );
};

export default RegistrationFormInstitution;
