import React from "react";
import Button from "@mui/material/Button";
import { EditRenderItemsProps, RenderItemsButtonProps } from "../edit.props";

const EditRenderItems: React.FC<EditRenderItemsProps> = ({
  items,
  onItemClick,
  id,
  value,
  predefinedList,
}) => {
  const RenderItemsButton: React.FC<RenderItemsButtonProps> = ({ item }): JSX.Element => (
    <Button
      variant="outlined"
      className="edit_choice__button"
      onClick={() => onItemClick(item, id)}
    >
      {item}
    </Button>
  );

  const renderModal = (): JSX.Element => {
    if (!value && !!predefinedList?.length) {
      return (
        <div className="edit_button__modal">
          {predefinedList?.map((item, index) => {
            return <RenderItemsButton item={item} key={index + item} />;
          })}
        </div>
      );
    }
    if (value && !!items?.length) {
      return (
        <div className="edit_button__modal">
          {items?.map((item, index) => (
            <RenderItemsButton item={item} key={index + item} />
          ))}
        </div>
      );
    }
    return <></>;
  };

  return renderModal();
};

export default EditRenderItems;
