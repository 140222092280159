import { IFilterCategory } from "../../types/filterTypes";

export const renderCategoriesValue =
  (options: IFilterCategory[]) =>
  (selected: string[]): string => {
    const selectedOptionsName = options
      .filter(value => selected.includes(value.id))
      .map(c => c.value)[0];
    if (!selected.length) {
      return "Any category";
    }
    if (selected.length > 1) return `${selectedOptionsName} + ${selected.length - 1}`;

    return selectedOptionsName;
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
