import moment from "moment";
import React, { useState } from "react";
import Carousel from "react-elastic-carousel";
import Lightbox from "react-image-lightbox";

import { IArtwork } from "../../../types/artwork/artwork";
import Button from "../../../UI/button/Button";
import InputField from "../../../UI/input-field/InputField";
import BackButton from "../../back-button/BackButton";
import DialogWindow from "../../dialog-window/DialogWindow";
import SliderArrows from "../../slider-arrows/SliderArrows";

import "./TransferDetailsModal.scss";

interface TransferDetailsModalProps {
  artwork: IArtwork;
  isOpen: boolean;
  close: () => void;
  acceptTransfer: () => void;
  declineTransfer: () => void;
}

const TransferDetailsModal: React.FC<TransferDetailsModalProps> = ({
  artwork,
  isOpen,
  close,
  acceptTransfer,
  declineTransfer,
}) => {
  const [isLightBoxOpen, setIsLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { transferInfo } = artwork;
  const { images } = transferInfo;
  const typeResale = transferInfo.type === "Resale";
  const typeSale = transferInfo.type === "Direct" || transferInfo.type === "Resale";

  const handleOpenLigtbox = (idx: number): void => {
    setImageIndex(idx);
    setIsLightboxOpen(true);
  };

  return (
    <DialogWindow open={isOpen} handleClose={close} className="remove">
      <div className="transfer-details">
        <h3 className="transfer-details_title">
          <BackButton className="" callback={close} /> Transfer info
        </h3>
        {!!images.length && (
          <div className="transfer-details_images">
            <Carousel
              disableArrowsOnEnd
              renderArrow={SliderArrows}
              isRTL={false}
              pagination={false}
              itemsToShow={2.1}
              showArrows
              showEmptySlots
            >
              {images.map((image, index) => {
                return (
                  <div
                    className="transfer-details_images_image"
                    key={image._id}
                    onClick={() => handleOpenLigtbox(index)}
                  >
                    <img src={image.thumbnail.url} alt={image.thumbnail.url} />
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
        {isLightBoxOpen && (
          <div className="lightbox_wrapper">
            <Lightbox
              mainSrc={images[imageIndex]?.origin?.url || images[imageIndex]?.medium?.url}
              onCloseRequest={() => setIsLightboxOpen(false)}
              nextSrc={images[imageIndex + 1]?.origin?.url || images[imageIndex + 1]?.medium?.url}
              prevSrc={images[imageIndex - 1]?.origin?.url || images[imageIndex - 1]?.medium?.url}
              onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
              onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
              enableZoom={false}
            />
          </div>
        )}
        <h1 className="transfer-details_title">{typeSale ? "Sale" : artwork.transferInfo.type}</h1>
        <div className="transfer-details_content">
          {transferInfo.type === "Direct" && (
            <InputField readOnly label="DIRECT SALE" value={`${transferInfo.price.toString()}$`} />
          )}
          {transferInfo.type === "Loan" && (
            <InputField
              readOnly
              label="LOAN TO"
              value={`${transferInfo.loantTo.name} until ${moment(transferInfo.loantTo.date).format(
                "ll",
              )}`}
            />
          )}
          {transferInfo.type === "Trade" && (
            <InputField
              readOnly
              label="TRADED FOR"
              value={`${transferInfo.tradeForObject} value of ${transferInfo.tradeForValue}`}
            />
          )}
          {typeResale && (
            <>
              <InputField readOnly label="RESALE" value={`${transferInfo.price.toString()}$`} />
              <InputField readOnly label="COMMISSION" value={transferInfo.commission} />
            </>
          )}
          <InputField
            readOnly
            label="LIVES AT"
            value={transferInfo.locationLives.name}
            helperText='Edit "live at" any time after transfer.'
          />
          {artwork.locationCollected && (
            <InputField readOnly label="COLLECTED AT" value={artwork.locationCollected.name} />
          )}
          {!typeResale && (
            <InputField readOnly label="CONDITION REPORT" value={transferInfo.conditionReport} />
          )}
          {typeResale && (
            <>
              <InputField readOnly label="TERMS" value={transferInfo.conditionReport} />
            </>
          )}
          {typeSale && !!transferInfo?.notes?.length && (
            <InputField readOnly label="NOTES" value={transferInfo.notes} />
          )}
        </div>
        <div className="transfer-details_buttons">
          <Button variant="button-dark" onClick={acceptTransfer}>
            ACCEPT
          </Button>
          <Button variant="outlined-dark" onClick={declineTransfer}>
            DECLINE
          </Button>
        </div>
      </div>
    </DialogWindow>
  );
};

export default TransferDetailsModal;
