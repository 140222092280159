import React from "react";
import { Link } from "react-router-dom";

import { artists, explore } from "../../route-link";
import { IArtwork } from "../../types/artwork/artwork";
import { IImage } from "../../types/imageData";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";

interface TransferNotificationProps {
  nameFrom: string;
  userId: string;
  time: Date;
  artwork: IArtwork;
  userImage: IImage;
}

const TransferNotification: React.FC<TransferNotificationProps> = ({
  time,
  userId,
  nameFrom,
  artwork,
  userImage,
}): JSX.Element => {
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        sent request to <strong>You</strong> to transfer artwork "{artwork.name}"
      </p>
      <ArtworkNoticeAudited artwork={artwork} time={time} />
    </div>
  );
};

export default TransferNotification;
