import { SearchActionTypes, SearchType } from "../types/search";

export const setSearch = (payload: string): SearchType => ({
  type: SearchActionTypes.SEARCH_VALUE,
  payload,
});

export const setSearchModal = (payload: boolean): SearchType => ({
  type: SearchActionTypes.SEARCH_MODAL,
  payload,
});
