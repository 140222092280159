import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { USER_FOLLOWERS, USER_FOLLOWINGS } from "../../api-queries/UserRequests";
import { IUser } from "../../types/userTypes";
import DialogWindow from "../dialog-window/DialogWindow";
import {
  FollowersData,
  FollowingsData,
  UserFollowersProps,
  UserModalTitle,
} from "./UserFollowersModal.props";
import UserListItem from "../user-list-item/UserListItem";
import { IParamsId } from "../artist-profile-artworks/utils";
import UserListItemSkeleton from "../../UI/user-list-item-skeleton/UserListItemSkeleton";
import BackButton from "../back-button/BackButton";

import "./UserFollowersModal.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

const UserFollowersModal: FC<UserFollowersProps> = ({ title }) => {
  const limit = 50;
  const { id: userId }: IParamsId = useParams();
  const navigate = useNavigate();
  const isFollowers = title === UserModalTitle.FOLLOWERS;
  const query = isFollowers ? USER_FOLLOWERS : USER_FOLLOWINGS;
  const { data, fetchMore, loading, refetch } = useQuery(query, { variables: { userId, limit } });

  const [userList, setUserList] = useState<IUser[]>([]);
  const [offset, setOffset] = useState(0);
  const total = isFollowers ? data?.followers?.meta?.total : data?.followings?.meta?.total;

  const showEmptyTitle = Boolean(!userList?.length && !loading);

  const { states } = useTypedSelector(state => state.prevStateReducer);

  const location = useLocation();

  const { addPrevState, removePrevState } = useActions();

  const containerRef = useRef<HTMLDivElement>(null);

  const onScroll = async (): Promise<void> => {
    setOffset(offset + limit);
    if (isFollowers) {
      const newData: FollowersData = await fetchMore({
        variables: { offset: offset + limit },
      });
      setUserList([...userList, ...newData.data.followers.followers]);
      return;
    }
    const newData: FollowingsData = await fetchMore({
      variables: { offset: offset + limit },
    });
    setUserList([...userList, ...newData.data.followings.followings]);
  };

  const setPrevState = (): void => {
    removePrevState(location.pathname);
    if (containerRef.current) {
      addPrevState({
        url: location.pathname,
        state: userList,
        scroll: containerRef.current.scrollTop,
      });
    }
  };

  useLayoutEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === location.pathname);
    if (prevState) {
      setUserList(prevState.state);
      if (containerRef.current && prevState.scroll) {
        containerRef.current.scrollTop = prevState.scroll;
      }
      return;
    }
    setOffset(0);
    refetch().then((refetchData: any) => {
      if (isFollowers && refetchData?.data?.followers?.followers) {
        setUserList([...refetchData?.data?.followers?.followers]);
        return;
      }
      if (refetchData?.data?.followings?.followings) {
        setUserList([...refetchData?.data?.followings?.followings]);
      }
    });
  }, [total]);

  useEffect(() => {
    const prevState = states.find(prevSt => prevSt.url === location.pathname);
    if (prevState) {
      setUserList(prevState.state);
      return;
    }
    if (!userList?.length) {
      if (isFollowers) {
        setUserList(data?.followers?.followers);
        return;
      }
      setUserList(data?.followings?.followings);
    }
  }, [data]);

  const handleClose = (): void => navigate(-1);

  return (
    <DialogWindow className="user-followers" open handleClose={handleClose} title={title}>
      <div className="user-followers__user-wrapper" id="scrollableDiv" ref={containerRef}>
        <BackButton className="user-followers__back-button" />
        {userList && !loading && (
          <>
            <InfiniteScroll
              dataLength={userList?.length}
              next={onScroll}
              hasMore
              loader
              scrollableTarget="scrollableDiv"
            >
              {userList?.map(user => (
                <UserListItem
                  saveState={setPrevState}
                  key={user._id}
                  profile={user?.profile}
                  id={user?._id}
                />
              ))}
            </InfiniteScroll>
          </>
        )}
        {showEmptyTitle && (
          <p className="profile-artworks-page__no-artworks">There are no users.</p>
        )}
        {loading && <UserListItemSkeleton amount={5} />}
      </div>
    </DialogWindow>
  );
};

export default UserFollowersModal;
