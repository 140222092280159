import React, { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import "../ExploreItemDetailsRight.scss";
import ExploreItem from "../../artworks-explore-item-details/components/ExploreItem";
import { IArtwork } from "../../../types/artwork/artwork";
import VotesArtworkDetailsMainInfo from "./VotesArtworkDetailsMainInfo";
import Button from "../../../UI/button/Button";

import "./VotesArtworkDetails.scss";
import { explore, myVotedArtwork } from "../../../route-link";
import { USER_VOTES } from "../../../api-queries/UserRequests";
import { toggleArtworkVote } from "../../../api-queries/GraohQlClient";
import { IExploreItemDetailsRight } from "../utils";
import { GetToken } from "../../../utils/get-token";
import { useActions } from "../../../hooks/useActions";

const VotesArtworkDetails: React.FC<IExploreItemDetailsRight> = ({
  artworkId,
  artwork,
  slideNext,
}) => {
  const artworkDetailsRightSection = useRef<null | HTMLDivElement>(null);
  const displayInspiration = Boolean(artwork?.inspiration);
  const routerLocation = useLocation();
  const isMyVotedArtwork = routerLocation.pathname.includes(myVotedArtwork);
  const myMOCAId = localStorage.getItem("myMOCAId");
  const { data, refetch } = useQuery(USER_VOTES, { variables: { userId: myMOCAId, limit: 200 } });
  const [isVoted, setIsVoted] = useState(false);
  const token = GetToken();
  const navigate = useNavigate();
  const { openModalLogin } = useActions();

  useEffect(() => {
    if (token) return;
    openModalLogin();
    navigate(`/${explore}?nextPage=${routerLocation.pathname}`, { replace: true });
  }, []);

  useEffect(() => {
    if (data?.favorites?.artworks) {
      const userVotedArtworks = data.favorites.artworks;
      setIsVoted(
        userVotedArtworks.find((votedArtwork: IArtwork) => votedArtwork._id === artworkId),
      );
    }
  }, [data]);

  const handleVote = async (): Promise<void> => {
    await toggleArtworkVote(artworkId);
    await refetch();
    if (slideNext) slideNext();
  };

  return (
    <div ref={artworkDetailsRightSection} className="explore_item_details_right_votes">
      <div className="wrap">
        <div className="explore_item_details_right_votes_content">
          <VotesArtworkDetailsMainInfo artwork={artwork} />

          {displayInspiration && (
            <ExploreItem title="Origins of Inspiration" text={artwork?.inspiration} />
          )}
        </div>

        {!isMyVotedArtwork && (
          <Button
            disabled={isVoted}
            color="dark"
            onClick={handleVote}
            className="vote-button"
            variant="button-dark"
          >
            {isVoted ? "VOTED" : "VOTE"}
          </Button>
        )}
      </div>
    </div>
  );
};
export default memo(VotesArtworkDetails);
