import { ReturnActionType } from "../types/return-type";
import { UploadImageActionTypes } from "../types/upload-image";

export const setUploadImageProfile = (payload: string): ReturnActionType => {
  return {
    type: UploadImageActionTypes.PROFILE,
    payload,
  };
};

export const setUploadBackgroundImageProfile = (payload: any): ReturnActionType => ({
  type: UploadImageActionTypes.BACKGROUND_PROFILE,
  payload,
});

export const setUploadArtwork = (payload: any): ReturnActionType => ({
  type: UploadImageActionTypes.ARTWORK,
  payload,
});
