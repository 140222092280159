import { Alert } from "@mui/material";
import React from "react";
import Button from "../../../../UI/button/Button";
import UserListItem from "../../../user-list-item/UserListItem";
import { useCreateTransferContext } from "../../../../context/createTransferContext";
import { useActions } from "../../../../hooks/useActions";

interface ChosenCollectorProps {
  chosenCollector: any;
  isSearchStage: boolean;
  onClick: () => void;
}

const ChosenCollector: React.FC<ChosenCollectorProps> = ({
  chosenCollector,
  isSearchStage,
  onClick,
}) => {
  const {
    showCreateTransferModal,
    price,
    commission,
    notes,
    locationLives,
    tradeForValue,
    tradeForObject,
    loanToName,
    loanToDate,
    saleable,
    availableForLoan,
    conditionReport,
    typeOfTransfer,
    images,
    hasFilmsOrTv,
    filmsOrTv,
    tradeFor,
    locationIsPrivate,
    initialHasFilmsOrTv,
    searchText,
    searchResults,
    searchType,
  } = useCreateTransferContext();
  const { addPrevState } = useActions();
  const savePrevState = (): void => {
    const prevState = {
      chosenCollector,
      isSearchStage,
      showCreateTransferModal,
      price,
      commission,
      notes,
      locationLives,
      tradeForValue,
      tradeForObject,
      loanToName,
      loanToDate,
      saleable,
      availableForLoan,
      conditionReport,
      typeOfTransfer,
      images,
      hasFilmsOrTv,
      filmsOrTv,
      tradeFor,
      locationIsPrivate,
      initialHasFilmsOrTv,
      searchText,
      searchResults,
      searchType,
    };
    localStorage.setItem("transferModalPrevState", JSON.stringify({ state: prevState }));
    addPrevState({
      id: "create-transfer-modal",
      state: prevState,
    });
  };

  const { error } = useCreateTransferContext();
  return (
    <>
      {chosenCollector && (
        <div className="chosen-collector">
          {error && (
            <Alert style={{ marginTop: "5px" }} severity="error">
              {error}
            </Alert>
          )}
          {isSearchStage
            ? "Do you really want to transfer artwork to this user?"
            : "Your artwork will be transferred to:"}
          {!chosenCollector.owner ? (
            <UserListItem
              profile={chosenCollector?.profile}
              id={chosenCollector?._id}
              transferModal
              saveState={savePrevState}
            />
          ) : (
            <UserListItem
              profile={chosenCollector?.owner?.profile}
              isInstitution
              institutionName={chosenCollector.name}
              id={chosenCollector.name + chosenCollector.owner._id}
              transferModal
              saveState={savePrevState}
            />
          )}
          <Button className="type-switcher continue-btn" variant="button-dark" onClick={onClick}>
            {isSearchStage ? "CONTINUE" : "TRANSFER ARTWORK"}
          </Button>
        </div>
      )}
    </>
  );
};

export default ChosenCollector;
