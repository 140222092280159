import { Skeleton } from "@mui/material";
import React, { FC } from "react";

interface IChatMessageSkeletonProps {
  myMessage?: boolean;
  size: "large" | "medium" | "small";
}

const ChatMessageSkeleton: FC<IChatMessageSkeletonProps> = ({ myMessage, size }) => {
  const renderMessage = (width: number, height: number): any => {
    return (
      <div
        className={`chat_messages_placeholders_message ${
          myMessage ? "my-message" : "guest-message"
        }`}
      >
        {!myMessage && <Skeleton variant="circular" width={40} height={40} />}
        <Skeleton
          variant="rectangular"
          className="chat_messages_placeholders_message_skeleton"
          width={width}
          height={height}
        />
      </div>
    );
  };
  switch (size) {
    case "small":
      return renderMessage(100, 50);
    case "medium":
      return renderMessage(150, 100);
    case "large":
      return renderMessage(200, 150);
    default:
      return null;
  }
};

export default ChatMessageSkeleton;
