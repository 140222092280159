export enum NotificationItemEnum {
  acceptArtworkSubmission = "acceptArtworkSubmission",
  acceptTransferArtworkRequest = "acceptTransferArtworkRequest",
  addPost = "addPost",
  commentArtwork = "commentArtwork",
  commentExhibition = "commentExhibition",
  commentPost = "commentPost",
  commentReply = "commentReply",
  createExhibition = "createExhibition",
  declineArtworkSubmission = "declineArtworkSubmission",
  declineTransferArtworkRequest = "declineTransferArtworkRequest",
  followUser = "followUser",
  blockUser = "blockUser",
  identifyArtwork = "identifyArtwork",
  inviteToExhibition = "inviteToExhibition",
  likeArtwork = "likeArtwork",
  likeComment = "likeComment",
  likePost = "likePost",
  privateCommentArtwork = "privateCommentArtwork",
  privateCommentExhibition = "privateCommentExhibition",
  privateCommentPost = "privateCommentPost",
  privateCommentUser = "privateCommentUser",
  requestFilmsOrTv = "requestFilmsOrTv",
  scanArtwork = "scanArtwork",
  shareArtwork = "shareArtwork",
  shareArtworkViaPost = "shareArtworkViaPost",
  sharePost = "sharePost",
  sharePostViaPost = "sharePostViaPost",
  shareExhibition = "shareExhibition",
  shareExhibitionViaPost = "shareExhibitionViaPost",
  submitArtworkToExhibition = "submitArtworkToExhibition",
  transferArtworkRequest = "transferArtworkRequest",
  uploadArtwork = "uploadArtwork",
  voteArtwork = "voteArtwork",
}
