import { TokenKeyActionTypes, TokenKeyState, TokenAction } from "../types/token";

const initialState: TokenKeyState = {
  tokenKey: localStorage.getItem("tokenKey") || "",
};

const tokenKeyReducer = (state = initialState, action: TokenAction): TokenKeyState => {
  switch (action.type) {
    case TokenKeyActionTypes.ADD_TOKEN_KEY:
      return { ...state, tokenKey: action.payload };
    default:
      return { ...state };
  }
};
export default tokenKeyReducer;
