import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";
import {
  ArtistsPageActions,
  ArtistsPageActionTypes,
  ILocationFilter,
} from "../types/artistsPageTypes";

export const SET_ARTISTS = (payload: IUser[]): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_ARTISTS,
  payload,
});

export const SET_OFFSET_ARTISTS = (payload: number): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_OFFSET_ARTISTS,
  payload,
});

export const SET_SORT_VALUE_ARTISTS = (payload: IArtistSortValue): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_SORT_VALUE_ARTISTS,
  payload,
});

export const SET_FILTER_QUERY_PARAMS_ARTISTS = (payload: any): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_FILTER_QUERY_PARAMS_ARTISTS,
  payload,
});

export const SET_FILTER_RESULTS_ARTISTS = (payload: IResultButton[]): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_FILTER_RESULTS_ARTISTS,
  payload,
});

export const SET_ORIGINS_OF_INSPIRATION_ARTISTS = (payload: IFilter[]): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_ORIGINS_OF_INSPIRATION_ARTISTS,
  payload,
});

export const SET_GENDER_ARTISTS = (payload: IGenderFilterOption): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_GENDER_ARTISTS,
  payload,
});

export const SET_LOCATION_FILTER_ARTISTS = (
  payload: ILocationFilter | null,
): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_LOCATION_FILTER_ARTISTS,
  payload,
});

export const SET_RADIUS_ARTISTS = (payload: number): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_RADIUS_ARTISTS,
  payload,
});
