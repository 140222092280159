import { IImage } from "../../types/imageData";

export enum ArtistProfileArtworkTabs {
  createdArtworks = "Created",
  artworksForSale = "Art For Sale",
  artCollection = "Art Collection",
  artworksFound = "Artworks Found",
}

export enum ArtistProfileArtworkTabsValue {
  createdArtworks = "createdArtworks",
  collectedArtworks = "collectedArtworks",
  artworksForSale = "artworksForSale",
  foundArtworks = "foundArtworks",
}

interface ILitleArtwork {
  _id: string;
  image: Partial<IImage>;
}

export interface ITab {
  title: string;
  value: string;
}

export type ArtistProfileResponse = Record<
  ArtistProfileArtworkTabsValue,
  { artworks: ILitleArtwork[] }
>;

export const getProfileTabs = (data: ArtistProfileResponse): ITab[] => {
  const { createdArtworks, artworksForSale, collectedArtworks, foundArtworks } = data;
  const artworkTabs = [];
  if (createdArtworks.artworks.length > 0) {
    artworkTabs.push({
      title: ArtistProfileArtworkTabs.createdArtworks,
      value: ArtistProfileArtworkTabsValue.createdArtworks,
    });
  }
  if (artworksForSale.artworks.length > 0) {
    artworkTabs.push({
      title: ArtistProfileArtworkTabs.artworksForSale,
      value: ArtistProfileArtworkTabsValue.artworksForSale,
    });
  }
  if (collectedArtworks.artworks.length > 0) {
    artworkTabs.push({
      title: ArtistProfileArtworkTabs.artCollection,
      value: ArtistProfileArtworkTabsValue.collectedArtworks,
    });
  }
  if (foundArtworks.artworks.length > 0) {
    artworkTabs.push({
      title: ArtistProfileArtworkTabs.artworksFound,
      value: ArtistProfileArtworkTabsValue.foundArtworks,
    });
  }
  return artworkTabs;
};
