import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { artists, explore } from "../../route-link";
import { removeComment, toggleLike } from "../../api-queries/GraohQlClient";
import { ExhibitionRequests } from "../../api-queries/AllRequests";
import AvatarPlaceholder from "../../components/avatar-placeholder/AvatarPlaceholder";
import formatShortDate from "../../utils/format-short-date";
import Button from "../button/Button";
import ButtonLike from "../button-like/ButtonLike";
import { GetToken } from "../../utils/get-token";
import { CommentBodyProps, CommentProps } from "./Comments.type";

/**
 * The Comment Functional Component
 * @typedef Props:
 * @property {function} handleReply - switches to input reply
 * @property {function} refetch - reload data from the server
 * @property {object} comment - object comment
 * @typedef TextFieldProps
 * @property {string} artworkId - id of artwork
 */

const sliceNumber = 200;

const CommentBody: React.FC<CommentBodyProps> = ({
  type,
  commentImage,
  authorReplyID,
  nameProps,
  commentText,
  commentCreatedAt,
  buttonRemove,
  buttonReply,
  likesCountProps,
  reply,
  comment,
  commentId,
  likes,
  goToProfile,
  addReply,
  btnTitle,
  handleViewReply,
  likesCount,
  commentAuthor,
  artworkData,
}): JSX.Element => {
  const [likesCounter, setLikesCounter] = useState(likesCountProps);
  const [sliceText, setSliceText] = useState("");
  const [showAllButton, setShowAllButton] = useState(false);
  const [toggleCommentText, setToggleCommentText] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const myMOCAId = localStorage.getItem("myMOCAId");
  const { openModalLogin } = useActions();
  const commentButtonReply = !!comment.replies.length && buttonReply;
  const commentTextSlice = toggleCommentText ? sliceText : commentText;
  const isInstitution = Boolean(
    commentAuthor?.hasInstitution && commentAuthor?.institutions.length,
  );
  // useLayoutEffect used to appropriate this values before renders, not after
  useLayoutEffect(() => {
    if (commentText.length > sliceNumber) {
      setSliceText(`${commentText.slice(0, sliceNumber)} ...`);
      setToggleCommentText(true);
      setShowAllButton(true);
    }
  }, []);
  useEffect(() => {
    if (
      authorReplyID === myMOCAId ||
      (artworkData?.owner?._id === myMOCAId && !artworkData?.collector) ||
      artworkData?.collector?._id === myMOCAId
    ) {
      setShowRemove(true);
    }
  }, []);
  const likeComments = (id: string): any => {
    return async (setIsLiked: (toggle: boolean) => void) => {
      const { toggleCommentLike } = await toggleLike(ExhibitionRequests.TOGGLE_COMMENT_LIKE, {
        commentId: id,
      });
      if (toggleCommentLike.liked) {
        setLikesCounter((prevState: number) => prevState + 1);
      }
      if (!toggleCommentLike.liked) {
        setLikesCounter((prevState: number) => prevState - 1);
      }
      setIsLiked(toggleCommentLike.liked);
    };
  };
  const likeId = type === "comment" ? commentId : reply?.replyId;
  const chooseFunctionComment = GetToken() ? likeComments(likeId as string) : openModalLogin;
  const likesButton = type === "comment" ? likes : reply?.replyLikes;
  const toggleCommentTextHandler = (): void => {
    setToggleCommentText(prev => !prev);
  };
  return (
    <div className="moca_comment__author">
      {commentImage ? (
        <Avatar
          className="comment_grid__avatar"
          onClick={() => goToProfile(authorReplyID, isInstitution)}
          src={commentImage}
        />
      ) : (
        <AvatarPlaceholder
          onClick={() => goToProfile(authorReplyID, isInstitution)}
          id={authorReplyID}
          width="40px"
          height="40px"
          fontSize="10px"
          className="comment_grid__avatar"
        />
      )}
      <div className="moca_comment__author__grid">
        <div className="moca_comment__name">{nameProps}</div>
        <p className="moca_comment__text">
          {commentTextSlice}
          {showAllButton && (
            <Button onClick={toggleCommentTextHandler} variant="text">
              {toggleCommentText ? "show more" : "show less"}
            </Button>
          )}
        </p>
        <div className="moca_comment__time">
          {!!likesCounter && (
            <span className="like_artwork_button__liked">
              {likesCounter?.toLocaleString()} Liked
            </span>
          )}{" "}
          {formatShortDate(commentCreatedAt)}{" "}
          <Button onClick={addReply} children="reply" className="moca_comment__btn" />
          {showRemove && (
            <Button
              children="Remove"
              variant="text"
              onClick={() => buttonRemove && buttonRemove(commentId)}
              className="moca_comment__btn"
            />
          )}
          {commentButtonReply && (
            <Button
              children={btnTitle}
              variant="text"
              onClick={handleViewReply}
              className="moca_comment__btn"
            />
          )}
        </div>
        <ButtonLike
          likes={likesButton || []}
          likesCount={likesCount || 0}
          replyLikesCount={0}
          handleLikeClick={chooseFunctionComment}
        />
      </div>
    </div>
  );
};

const Comment: React.FC<CommentProps> = ({
  comment,
  artworkId,
  handleReply,
  refetch,
}: any): JSX.Element => {
  const navigate = useNavigate();
  const { removeMyComment } = useActions();
  const { artworkData } = useTypedSelector(state => state.artworkDetailsReducer);
  const { author, text, createdAt, likes, likesCount } = comment;
  // remove button

  const [viewReply, setViewReply] = useState(true);
  const { openModal } = useActions();
  const commentId = comment._id;
  const firstName = author?.profile.firstName || "";
  const secondName = author?.profile.secondName || "";
  const image = author?.profile?.image?.thumbnail?.url || "";
  const authorId = author?._id || "";
  const btnTitle = viewReply ? `View replies (${comment.replies.length})` : "Hide replies";
  const institutionName = author?.institutions[0]?.name;
  const name = author?.institutions.length ? institutionName : `${firstName} ${secondName}`;
  const repliesDisplayStyles = !viewReply ? { display: "block" } : { display: "none" };

  const goToProfile = (id: string, isBlocking: boolean): void => {
    if (isBlocking) {
      openModal();
      return;
    }
    navigate(`/${explore}/${artists}/${id}`);
    window.scroll(0, 0);
  };

  const addReply = (): void => {
    handleReply(commentId);
    setViewReply(false);
  };

  const handleRemove = async (id: string): Promise<void> => {
    removeMyComment(id);
    await removeComment(ExhibitionRequests.REMOVE_ARTWORK_COMMENT, {
      artworkId,
      commentId,
    });
    refetch();
  };

  const handleViewReply = (): void => setViewReply(prevState => !prevState);

  const handleRemoveReply = async (replyId: string): Promise<void> => {
    await removeComment(ExhibitionRequests.REMOVE_COMMENT_REPLY, {
      commentId,
      replyId,
    });
    refetch();
  };
  return (
    <div className="moca_comment">
      <div className="moca_comment__body">
        <CommentBody
          commentAuthor={comment?.author}
          comment={comment}
          commentId={commentId}
          likes={likes}
          goToProfile={goToProfile}
          addReply={addReply}
          btnTitle={btnTitle}
          handleViewReply={handleViewReply}
          likesCount={likesCount}
          type="comment"
          commentImage={image}
          nameProps={name}
          commentText={text}
          commentCreatedAt={createdAt}
          authorReplyID={authorId}
          buttonRemove={() => handleRemove(commentId)}
          likesCountProps={likesCount}
          buttonReply
          artworkData={artworkData}
        />
      </div>
      <div className="moca_reply__wrap" style={repliesDisplayStyles}>
        {comment?.replies.map((reply: any) => {
          const replyParams = {
            replyId: reply?._id,
            replyLikes: reply?.likes,
            replyLikesCount: reply?.likesCount,
          };
          const replyFirstName = reply?.author?.profile?.firstName;
          const replySecondName = reply?.author?.profile?.secondName;
          const replyName =
            reply?.author?.institutions[0]?.name || `${replyFirstName} ${replySecondName}`;

          return (
            <div key={reply._id} className="moca_reply">
              <div className="moca_comment__body">
                <CommentBody
                  commentAuthor={reply?.author}
                  comment={comment}
                  commentId={commentId}
                  likes={likes}
                  goToProfile={goToProfile}
                  addReply={addReply}
                  btnTitle={btnTitle}
                  handleViewReply={handleViewReply}
                  likesCount={likesCount}
                  type="reply"
                  commentImage={reply?.author?.profile?.image?.thumbnail?.url}
                  nameProps={replyName}
                  commentText={reply?.text}
                  commentCreatedAt={reply?.createdAt}
                  authorReplyID={reply?.author._id}
                  buttonRemove={() => handleRemoveReply(reply._id)}
                  likesCountProps={reply?.likesCount}
                  reply={replyParams}
                  artworkData={artworkData}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Comment;
