export interface IGeneralFilter {
  id: string;
  type: string;
  checked?: boolean;
  value: string;
}

export type IFilterCategory = IGeneralFilter;

export type IFilter = IGeneralFilter;

export enum IFilterTypes {
  Categories = "Categories",
  Materials = "Materials",
  Colors = "Colors",
  Price = "Price",
  Width = "Width",
  Height = "Height",
  OriginsOfInspiration = "OriginsOfInspiration",
  Gender = "Gender",
  Location = "Location",
}

export interface ISortOption {
  name: string;
  sortBy: string;
  orderBy: string;
  forSale?: string;
}
