import { ATTACHMENT } from "./AttachmentRequestParams";
import { CATEGORY } from "./CategoryRequestParams";
import { COMMENT } from "./CommentRequestParams";
import { EXHIBITION_LITE } from "./ExhibitionLiteRequestParams";
import { IMAGE } from "./ImageRequestParams";
import { LIKE } from "./LikeRequestParams";
import { LOCATION } from "./LocationRequestParams";

export const FULL_ARTWORK = `
_id
name
artistName
createdByArtistYear
minimumBid
votesCount
selectedForExhibition
selectedForVote
saleable
availableOut
notes
history
inspiration
sharesCount
scansCount
published
locationFound {
  ${LOCATION}
}
locationLives {
  ${LOCATION}
}
locationCollected {
  ${LOCATION}
}
locationCreated {
  ${LOCATION}
}
dimensions {
  width
  height
  length
  weight
  metrics
}
owner {
  _id
  email
  myArtworksLikes
  likedArtworks
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
  institutions {
    name
    description
  }
  hasInstitution

}
collector {
  _id
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
  institutions {
    name
    description
  }
  hasInstitution
}
pastCollectors {
  _id
  myArtworksLikes
  likedArtworks
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
  institutions {
    name
    description
  }
  hasInstitution
}
materials
colors
categories {
  ${CATEGORY}
}
likes {
  ${LIKE}
}
comments {
  ${COMMENT}
}
image {
  ${IMAGE}
}
attachments {
  ${ATTACHMENT}
}
type
createdAt
suggestions {
  _id
  artistName
  artworkTitle
  notes
  iDontKnow
  createdAt
  video {
    _id
    url
    type
    info
  }
  author {
    _id
    institutions {
      name
    }
    hasInstitution
    profile {
      firstName
      middleName
      secondName
      image {
        ${IMAGE}
      }
    }
  }
}
conditionReport
additionalImages {
  ${IMAGE}
}
collectors {
  collectedBy
  yearCollected
  collectedAt {
    ${LOCATION}
  }
}
pastExhibitions {
  exhibition
  year
}
transferIsPending
transferInfo {
  _id
  conditionReport
  tradeFor
  loanTo
  notes
  price
  type
  commission
  allowUseForFilmAndTv
  saleable
  locationLives{
    ${LOCATION}
  }
  locationCollected{
    ${LOCATION}
  }
  filmsOrTv
  hasFilmsOrTv
  images{
    ${IMAGE}
  }
  tradeForValue
  tradeForObject
  availableForLoan
  loantTo {
    name
    date
  }
}
transferHistory {
  _id
  conditionReport
  tradeFor
  loanTo
  notes
  price
  saleable
  type
  commission
  allowUseForFilmAndTv
  locationLives{
    ${LOCATION}
  }
  locationCollected{
    ${LOCATION}
  }
  filmsOrTv
  hasFilmsOrTv
  images{
    ${IMAGE}
  }
  tradeForValue
  tradeForObject
  availableForLoan
  loantTo {
    name
    date
  }
  createdAt
}
transferringTo {
  _id
  hasInstitution
  institutions {
    name
  }
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
}
tradeFor
filmsOrTv
hasFilmsOrTv
allowUseForFilmAndTv
tradeForValue
tradeForObject
availableForLoan
iDontKnowCount
winner
exhibitions {
  ${EXHIBITION_LITE}
}`;
