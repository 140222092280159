import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { ILike } from "../../types/like";

interface ButtonLikeProps {
  likesCount: number;
  replyLikesCount?: number;
  likes: Array<ILike>;
  handleLikeClick: (
    setIsLiked: (toggle: boolean) => void,
    setLikesCounter: (prevState: any) => void,
  ) => void;
}

const ButtonLike: React.FC<ButtonLikeProps> = ({
  likesCount,
  likes,
  handleLikeClick,
  replyLikesCount,
}): JSX.Element => {
  const [isLiked, setIsLiked] = useState(false);
  const [likesCounter, setLikesCounter] = useState(replyLikesCount || likesCount);

  const getIdCommentsLikeAuthor = (likesList: Array<any>): Array<any> => {
    const myMOCAId = localStorage.getItem("myMOCAId");
    const idAuthor = likesList.map(item => item?.author._id === myMOCAId);
    return idAuthor;
  };

  const setLike = async (): Promise<void> => {
    try {
      const likedIds = getIdCommentsLikeAuthor(likes || []);
      if (likedIds.some(item => item)) setIsLiked(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setLike();
  }, []);
  return (
    <div className="like">
      <IconButton
        onClick={() => handleLikeClick(setIsLiked, setLikesCounter)}
        style={{ padding: 3 }}
      >
        {isLiked ? (
          <FavoriteIcon style={{ color: "red" }} fontSize="small" />
        ) : (
          <FavoriteBorderIcon fontSize="small" />
        )}
      </IconButton>
      {false && <span className="like_artwork_button__liked">{likesCounter} Liked</span>}
    </div>
  );
};

export default ButtonLike;
