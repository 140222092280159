import React, { ChangeEventHandler, FC } from "react";
import { InputLabel, TextField, TextareaAutosize } from "@mui/material";

import "./InputField.scss";

interface InputFieldProps {
  label: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  helperText?: string;
  errorText?: string;
  textAreaOnChange?: ChangeEventHandler<HTMLTextAreaElement>;
  readOnly?: boolean;
  type?: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
}

const InputField: FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  isError = false,
  helperText = "",
  errorText = "",
  textAreaOnChange,
  readOnly,
  type,
  multiline,
  minRows,
  maxRows,
}) => {
  const bottomText = isError ? errorText : helperText;

  return (
    <div className="register_item input_field">
      <InputLabel
        className="origin_label input_field__label"
        variant="standard"
        htmlFor="name-of-artist"
      >
        {label}
      </InputLabel>
      {!textAreaOnChange ? (
        <TextField
          error={isError}
          id="name-of-artist"
          className="input"
          name="nameOfArtist"
          variant="standard"
          value={value}
          onChange={onChange}
          helperText={bottomText}
          InputProps={{ readOnly }}
          type={type || "text"}
          minRows={minRows ?? 1}
          maxRows={maxRows ?? 1}
          multiline={multiline}
        />
      ) : (
        <div className="text_area">
          <TextareaAutosize
            className="text_field text_area__input"
            aria-label="minimum height"
            minRows={1}
            value={value}
            onChange={textAreaOnChange}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
};

export default InputField;
