/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import ClearIcon from "@mui/icons-material/Clear";

import { getFiltersValues, getUniqueItems } from "./utils";
import debounce from "../../utils/debounce";

import "./FilterMaterialsColors.scss";

const FilterMaterialsColors = ({
  items,
  setItems,
  query,
  apolloQuery,
  queryDataName,
  type,
  context,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [moreItems, setMoreItems] = useState([]);
  const inputEl = useRef();
  const [loadItems, { loading, data }] = useLazyQuery(apolloQuery, {
    variables: { text: inputValue },
  });
  const {
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    artworks: { setArtworks },
    offset: { setOffset },
    filterResults: { filterResults, setFilterResults },
  } = context;

  const updateQuery = items => {
    setOffset(0);
    setArtworks([]);
    const itemsValue = getFiltersValues(items);
    setfilterQueryParams({
      ...filterQueryParams,
      offset: 0,
      [query]: itemsValue,
    });
  };
  const onItemClick = event => {
    const { id } = event.target;
    const newItems = items.map(item => {
      if (item.id === id) return { ...item, checked: !item.checked };
      return item;
    });
    setItems(newItems);
    const targetItem = newItems.find(item => item.id === id);
    const { type, value, checked } = targetItem;
    if (checked) {
      setFilterResults([...filterResults, { title: value, id, type }]);
    } else {
      setFilterResults(filterResults.filter(item => item.id !== id));
    }
    updateQuery(newItems);
  };
  const onMoreItemClick = event => {
    const { id } = event.target;
    const targetItem = moreItems.find(item => item.id === id);
    const { type, value } = targetItem;
    setMoreItems(prevResults => prevResults.filter(item => item.id !== id));
    const newItems = [...items, { value, id, type, checked: true }];
    setItems(newItems);
    setFilterResults([...filterResults, { title: value, id, type }]);
    updateQuery(newItems);
    setMoreItems([]);
    setInputValue("");
    inputEl.current.children[0].value = "";
  };
  const fetchMoreItems = event => {
    const { value } = event.target;
    setInputValue(value);
    loadItems({ text: inputValue });
  };
  const onInputChange = debounce(fetchMoreItems, 1000);
  useEffect(() => {
    if (!inputValue) {
      setMoreItems([]);
      return;
    }
    if (!loading && data && inputValue) {
      const newItems = [...new Set(data?.[queryDataName]?.map(item => item?.trim()))];
      const newFilterItems = newItems.reduce((accum, item) => {
        if (item) accum.push({ value: item, checked: false, id: item, type });
        return accum;
      }, []);
      const uniqueNewItems = getUniqueItems(newFilterItems, items);
      setMoreItems(uniqueNewItems);
    }
  }, [data, inputValue]);
  const onClearClick = () => {
    setMoreItems([]);
    setInputValue("");
    inputEl.current.children[0].value = "";
  };
  const renderItems = (items, onItemClick, className = "") => {
    if (!items.length && inputValue) return "No results";
    return items?.map(item => {
      return (
        <Button
          id={item.id}
          variant={item.checked ? "contained" : "outlined"}
          key={item.id}
          className={`filter_materials_colors__button ${className}`}
          onClick={onItemClick}
        >
          {item.value}
        </Button>
      );
    });
  };

  return (
    <div className="filter_materials_colors">
      {renderItems(items, onItemClick)}
      <div className="filter_materials_colors__input_wrapper">
        <div className="filter_materials_colors__input">
          <SearchIcon className="filter_materials_colors__searchIcon" />
          <InputBase
            ref={inputEl}
            className="filter_materials_colors__input_base"
            placeholder="Type name here"
            onChange={onInputChange}
          />
          <ClearIcon
            fontSize="small"
            className="filter_materials_colors__clear"
            onClick={onClearClick}
          />
        </div>
        {inputValue && (
          <div className="filter_materials_colors__moreItems">
            {renderItems(moreItems, onMoreItemClick, "filter_materials_colors__more_button")}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterMaterialsColors;
