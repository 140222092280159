import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Fab, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import cn from "classnames";

import { originsOfInspiration, registrationOfInstitution, user } from "../../route-link";
import SearchLoader from "../../components/search-loader/SearchLoader";
import CropImage from "../../components/crop-image/CropImage";
import Button from "../../UI/button/Button";
import { updateUser } from "../../api-queries/GraohQlClient";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

import "./UploadImage.scss";

const UploadImage = (): JSX.Element => {
  const navigate = useNavigate();
  const [cropOpenProfile, setCropOpenProfile] = useState(false);
  const [cropOpenProfileBackground, setCropOpenProfileBackground] = useState(false);
  const { profile, profileBackground } = useTypedSelector(state => state.uploadImagesReducer);
  const [dataProfile, setDataProfile] = useState(profile);
  const [dataBackProfile, setDataBackProfile] = useState(profileBackground);
  const { state }: any = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { setUploadImageProfile, setUploadBackgroundImageProfile } = useActions();
  const [firstName, lastName] = state?.name.split(" ");
  const [loader, setLoader] = useState(false);
  const onChangeProfile = (event: any): any => {
    if (event.target.files && event.target.files.length > 0) {
      setUploadImageProfile(URL.createObjectURL(event.target.files[0]));
      setCropOpenProfile(true);
    }
  };

  const onChangeProfileBackground = (event: any): void => {
    if (event.target.files && event.target.files.length > 0) {
      setUploadBackgroundImageProfile(URL.createObjectURL(event.target.files[0]));
      setDataBackProfile("");
      setCropOpenProfileBackground(true);
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const ProfileImage = () =>
    dataProfile ? <img src={profile} alt="" /> : <AddIcon className="add_icon" />;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const ProfileImageBackground = () =>
    dataBackProfile ? <img src={profileBackground} alt="" /> : <AddIcon className="add_icon" />;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleProfileImage = async (value: string) => {
    fetch(value)
      .then(res => res.blob())
      .then(data => setUploadImageProfile(URL.createObjectURL(data)));
  };
  const handleProfileBackgroundImage = (value: string): void => {
    fetch(value)
      .then(res => res.blob())
      .then(data => setUploadBackgroundImageProfile(URL.createObjectURL(data)));
  };

  const handleClose = (): void => {
    setCropOpenProfile(false);
    setUploadImageProfile("");
  };
  const pathName = state?.hasInstitution
    ? `/${user}/${registrationOfInstitution}`
    : `/${user}/${originsOfInspiration}`;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onUploadImage = async () => {
    setLoader(true);
    const userData = {
      _id: state.id,
      firstName,
      lastName,
      phone: state.phone,
      sex: "male",
    };
    const profileBlob = await fetch(profile).then(res => res.blob());
    const profileBackgroundBlob = await fetch(profileBackground).then(res => res.blob());
    updateUser(userData, profileBlob, profileBackgroundBlob).catch(console.log);
    return navigate(pathName, {
      state: {
        state,
        profileBlob,
        profileBackgroundBlob,
      },
    });
  };

  const disabled = !profile || !profileBackground;

  return (
    <div className="upload_image">
      <SearchLoader loading={loader} />
      <h1 className="upload_image__title">Welcome, {state?.name}</h1>
      <div className="upload_profile__grid">
        <div className="upload_profile__image">
          <p className="upload_profile__text">Upload your profile image</p>
          <div className="upload_image__photo">
            <Fab aria-label="add" className="add_icon__button">
              <input
                type="file"
                name="profile"
                className="upload_input__file"
                onChange={onChangeProfile}
              />
              <ProfileImage />
            </Fab>
          </div>
        </div>
        <div className="upload_background__image__wrap">
          <p className="upload_profile__text">Upload your profile background image</p>
          <div
            className={cn("upload_background__image", {
              crop_background__image: dataBackProfile,
            })}
          >
            <input
              type="file"
              name="profileBackground"
              className="upload_input__file"
              onChange={onChangeProfileBackground}
            />
            <ProfileImageBackground />
          </div>
        </div>
      </div>
      <Modal
        open={cropOpenProfile}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CropImage
          imageSrc={profile || ""}
          cropImage="profile"
          handleProfileImage={handleProfileImage}
          handleClose={() => setCropOpenProfile(false)}
          minCropBoxHeight={50}
          minCropBoxWidth={50}
          setDataProfile={setDataProfile}
        />
      </Modal>

      <Modal
        open={cropOpenProfileBackground}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CropImage
          imageSrc={profileBackground || ""}
          cropImage="profileBackground"
          handleProfileImage={handleProfileImage}
          handleClose={() => setCropOpenProfileBackground(false)}
          handleProfileBackgroundImage={handleProfileBackgroundImage}
          minCropBoxHeight={350}
          minCropBoxWidth={100}
          initialAspectRatio={16 / 9}
          aspectRatio={16 / 9}
          setDataBackProfile={setDataBackProfile}
        />
      </Modal>
      <div className="upload_image__footer">
        <Link
          to={{
            pathname: pathName,
          }}
          state={{ state }}
          className="upload_image__link"
        >
          I`ll complete this later
        </Link>
        <Button
          disabled={disabled}
          variant="button-dark"
          children="Continue"
          onClick={onUploadImage}
          className="bg_dark"
        />
      </div>
    </div>
  );
};

export default UploadImage;
