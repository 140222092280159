import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Share from "../../../../assets/images/share.svg";
import IconComments from "../../../../assets/images/iconCommentsBlackThin.svg";
import "./PostNoticeAudited.scss";
import { useActions } from "../../../../hooks/useActions";
import { togglePostLike } from "../../../../api-queries/GraohQlClient";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import NotificationImage from "../../NotificationImage";
import { artworks, explore } from "../../../../route-link";

interface IPostNoticeAuditedProps {
  post: any;
  time: Date;
}

const PostNoticeAudited: FC<IPostNoticeAuditedProps> = ({ post, time }) => {
  const [currentPost, setCurrentPost] = useState(post);
  const { notices } = useTypedSelector(state => state.notificationsReducer);
  const { openModal, setNotices } = useActions();
  const ownerName = currentPost.owner.hasInstitution
    ? currentPost.owner.institutions[0].name
    : `${currentPost.owner.profile.firstName} ${currentPost.owner.profile.secondName}`;

  useEffect(() => {
    const newCurrentPost = notices.find(notice => notice?.audited?.post?._id === currentPost._id)
      ?.audited?.post;
    if (newCurrentPost) setCurrentPost(newCurrentPost);
  }, [notices]);

  const getClassName = (imagesOfPost: any): any => {
    switch (imagesOfPost.length) {
      case 1:
        return "post-notice-item_images_single";
      case 2:
        return "post-notice-item_images_double";
      case 3:
        return "post-notice-item_images_tripple";
      case 4:
        return "post-notice-item_images_grid";
      default:
        return null;
    }
  };

  const toggleLike = (isLiked: boolean): void => {
    const newNotices = notices.map(notice => {
      if (notice?.audited?.post?._id === currentPost?._id) {
        const newNotice = {
          ...notice,
          audited: { ...notice.audited, post: { ...currentPost, isLiked } },
        };
        return newNotice;
      }
      return notice;
    });
    setCurrentPost((prev: any) => ({ ...prev, isLiked }));
    setNotices(newNotices);
  };

  const likePost = async (): Promise<void> => {
    try {
      toggleLike(!currentPost.isLiked);
      const succeded = await togglePostLike({ postId: currentPost?._id });
      if (!succeded) {
        toggleLike(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderPostBody = (): any => {
    if (!currentPost) return null;
    let auditedPost;
    let auditedArtwork;
    let auditedShow;
    if (currentPost.audited) {
      auditedPost = currentPost.audited?.post;
      auditedArtwork = currentPost.audited?.artwork;
      auditedShow = currentPost.audited?.exhibition;
    }
    const shareLocation = auditedPost?.shareLocation || currentPost?.shareLocation;
    const imagesOfPost = auditedPost?.imagesOfPost || currentPost?.imagesOfPost;
    const createdAt = auditedPost?.createdAt || currentPost?.createdAt;
    const artworkAuthor = auditedArtwork?.collector?.profile
      ? `${auditedArtwork?.collector?.profile?.firstName} ${auditedArtwork?.collector?.profile?.secondName}`
      : `${auditedArtwork?.owner?.profile?.firstName} ${auditedArtwork?.owner?.profile?.secondName}`;
    const showOwner =
      auditedShow &&
      `${auditedShow?.owner?.profile?.firstName} ${auditedShow?.owner?.profile?.secondName}`;
    const postText = auditedPost?.text || currentPost.text;
    if (auditedArtwork)
      return (
        <>
          <strong>
            {artworkAuthor} - "{auditedArtwork.name}"
          </strong>
          <Link to={`${explore}/${artworks}/${auditedArtwork._id}`}>
            <NotificationImage imageSrc={auditedArtwork.image?.medium?.url} alt="Artwork" />
          </Link>
          <span className="notification_time">
            {moment(Number(auditedArtwork.createdAt)).format("MMMM DD, h:mm A")}
          </span>
        </>
      );
    if (auditedShow)
      return (
        <div onClick={openModal}>
          <strong>{showOwner}</strong>
          <p className="notification_time">{auditedShow.title}</p>
          <NotificationImage imageSrc={auditedShow.image?.medium?.url} alt="Artwork" />
        </div>
      );
    return (
      <div onClick={openModal}>
        <strong>{ownerName}</strong>
        <span className="notification_time">∙ {moment(createdAt).fromNow()}</span>
        <div className="notification_text">{postText}</div>
        {!imagesOfPost.length && !shareLocation && <div className="post-notice-item_divider" />}
        {!!imagesOfPost.length && (
          <div className="post-notice-item_images">
            <div className={getClassName(imagesOfPost)}>
              {imagesOfPost.map((image: any) => (
                <img
                  className="post-notice-item_images_image"
                  key={image._id}
                  src={image.medium?.url}
                  alt={image._id}
                />
              ))}
            </div>
          </div>
        )}
        {shareLocation && (
          <div className="notification_like__post__body__location">
            <MapContainer
              center={{
                lat: shareLocation.coordinates.latitude,
                lng: shareLocation.coordinates.longitude,
              }}
              zoom={10}
              zoomControl={false}
              className="leaflet_map"
              scrollWheelZoom={false}
              dragging={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={{
                  lat: shareLocation.coordinates.latitude,
                  lng: shareLocation.coordinates.longitude,
                }}
              />
            </MapContainer>
            <div className="notification_like__post__body__location-name">
              <p>{shareLocation.name}</p>
              <ArrowForwardIosIcon sx={{ width: 15, height: 15 }} />
            </div>
          </div>
        )}
        <div className="notification_icons">
          <div className="notification_icons__item" onClick={likePost}>
            {currentPost.isLiked ? (
              <FavoriteIcon style={{ color: "red", width: 20, height: 20 }} />
            ) : (
              <FavoriteBorderIcon style={{ width: 20, height: 20 }} />
            )}
          </div>
          <div onClick={openModal} className="notification_icons__item">
            <img src={IconComments} alt="comments icon" />
          </div>
          <div onClick={openModal} className="notification_icons__item">
            <img src={Share} alt="share icon" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="notification_like__post__body">{renderPostBody()}</div>
      <p className="follow_notification__time">{moment(time).fromNow()}</p>
    </>
  );
};

export default PostNoticeAudited;
