import React from "react";
import formatDate from "./format-date";
import { IShowExhibitions } from "../types/exhibitions";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const displayDuration = (
  exhibition: IShowExhibitions,
  hasDuration: boolean,
  hasPermanentHours: boolean,
) => {
  if (hasDuration && !hasPermanentHours) {
    return (
      <span>{`${formatDate(new Date(exhibition?.duration?.from), "MMM DD YYYY")} - ${formatDate(
        new Date(exhibition?.duration?.to),
        "MMM DD YYYY",
      )} `}</span>
    );
  }
  return <span>Permanent show </span>;
};
