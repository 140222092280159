import React from "react";
import { useOutletContext } from "react-router-dom";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import ShowsPage from "./ShowsPage";

const ShowsPageWrap = (): JSX.Element => {
  const { showsPageContext }: any = useOutletContext();
  return (
    <>
      <ScrollToTop />
      <ShowsPage isOpenToSubmitions={undefined} context={showsPageContext} titleText="ART SHOWS" />
    </>
  );
};

export default ShowsPageWrap;
