import React, { FC, useEffect, useRef, useState } from "react";

import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { paperProps } from "./Notifications.style";
import NotificationItem from "./NotificationItem";
import { fetchNotification } from "../../api-queries/GraohQlClient";

import "./Notifications.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import NotificationsSkeletonsList from "./notifications-loader/NotificationsSkeletonsList";

interface INotificationsProps {
  anchorEl: null | HTMLDivElement;
}

const Notifications: FC<INotificationsProps> = ({ anchorEl }) => {
  const { notices: notifications } = useTypedSelector(state => state.notificationsReducer);
  const { showNotificationModal, notificationsModalScroll } = useTypedSelector(
    state => state.notificationsReducer,
  );
  const {
    setShowNotificationsModal,
    setNotices: setNotifications,
    setNotificationModalScroll,
  } = useActions();
  const [loading, setLoading] = useState<boolean>(true);
  const notificationModalRef = useRef<HTMLDivElement>(null);

  const saveNotificationsModalScroll = (): void => {
    if (notificationModalRef.current)
      setNotificationModalScroll(notificationModalRef.current.children[2].scrollTop);
  };

  const scrollNotificationModalTo = (scrollY: number | undefined): void => {
    if (notificationModalRef?.current?.children[2] && scrollY) {
      notificationModalRef.current.children[2].scrollTop = scrollY;
    }
  };

  const handleClick = (): void => {
    setShowNotificationsModal(true);
    scrollNotificationModalTo(notificationsModalScroll);
  };
  const handleClose = (): void => {
    saveNotificationsModalScroll();
    setShowNotificationsModal(false);
  };

  const loadNotifications = async (): Promise<void> => {
    setLoading(true);
    try {
      await fetchNotification({
        limit: 500,
      }).then(data => setNotifications(data?.data.actionsNotifications.actions));
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    if (notificationModalRef.current) return;

    setTimeout(() => {
      scrollNotificationModalTo(notificationsModalScroll);
    });
  }, [notificationModalRef.current]);

  return (
    <div className="notifications">
      <Tooltip title="Notifications" className="notification_list">
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
          <NotificationsIcon className="notification_icon" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={showNotificationModal}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        ref={notificationModalRef}
        PaperProps={{
          sx: paperProps,
        }}
      >
        {notifications &&
          notifications.map(notice => {
            return (
              <div key={notice._id}>
                <MenuItem
                  className="notice_list__item"
                  style={{ padding: 10 }}
                  onClick={saveNotificationsModalScroll}
                >
                  <NotificationItem notice={notice} />
                </MenuItem>
                <Divider />
              </div>
            );
          })}
        {loading && <NotificationsSkeletonsList />}

        {!loading && !notifications.length && (
          <p className="profile-artworks-page__no-artworks">No notifications yet</p>
        )}
      </Menu>
    </div>
  );
};
export default Notifications;
