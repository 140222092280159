import { ButtonUpAction, ButtonUpState, SetButtonUpActionTypes } from "../types/button-up";

const initialState: ButtonUpState = {
  classesButton: false,
};

const buttonUpReducer = (state = initialState, action: ButtonUpAction): ButtonUpState => {
  switch (action.type) {
    case SetButtonUpActionTypes.TOGGLE_BUTTON:
      return { ...state, classesButton: action.payload };
    default:
      return { ...state };
  }
};
export default buttonUpReducer;
