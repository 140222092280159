import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { ArtworksRequests } from "../../api-queries/AllRequests";
import FilterResults from "../filter-results/FilterResults";
import ArtworkListItem from "../artwork-list-item/ArtworkListItem";
import LeafletMap from "../leaflet-maps/LeafletMap";
import Filter from "../filter/Filter";
import {
  BREAKPOINT_COLUMNS_OBJ,
  CLOSEST_ARTWORKS,
  maxNumber,
  ORDER_BY_DESC,
  PREDEFINED_ARTWORKS_SORT,
  SORT_BY_DATE,
} from "../../misc/constants";
import FilterItem from "../filter-item/FilterItem";
import FilterFromTo from "../filter-from-to/FilterFromTo";
import ItemsNotFound from "../items-not-found/ItemsNotFound";
import FilterCategories from "../filter-categories/FilterCategories";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import FilterUnits from "../filter-units/FilterUnits";
import { PREDEFINED_COLORS, PREDEFINED_MATERIALS } from "../../data/constants";
import FilterMaterialsColors from "../filter-materials-colors/FilterMaterialsColors";
import { IFilterTypes } from "../../types/filterTypes";
import {
  AVAILABLE_ARTWORK_COLORS,
  AVAILABLE_ARTWORK_MATERIALS,
} from "../../api-queries/ArtworksRequests";
import FilterOriginsOfInspiration from "../filter-origins-of-inspiration/FilterOriginsOfInspiration";
import {
  SET_ARTWORKS,
  SET_CATEGORIES,
  SET_COLORS,
  SET_FILTER_QUERY_PARAMS,
  SET_FILTER_RESULTS,
  SET_HEIGHT_MAX_VALUE,
  SET_HEIGHT_MIN_VALUE,
  SET_LOCATION_FILTER,
  SET_MATERIALS,
  SET_OFFSET,
  SET_ORIGINS_OF_INSPIRATION,
  SET_RADIUS,
  SET_SORT_VALUE,
  SET_WIDTH_MAX_VALUE,
  SET_WIDTH_MIN_VALUE,
} from "../../context/action-creators/artworkFilterActions";

import "./ArtworksPageComponent.scss";
import LocationFilter from "../location-filter/LocationFilter";
import ExplorePageTitle from "../explore-page-title/ExplorePageTitle";
import tooltipText from "../explore-page-title/tooltip-text-vote.json";
import { pluralize } from "../../utils/pluralizeWord";
import FilterTopBar from "../../UI/filter-top-bar/FilterTopBar";
import { getUniqueArtworks } from "../artworks-explore-list/utils";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { buy, explore } from "../../route-link";
import { sortByClosest } from "./utils";

const ArtworksPageComponent = ({
  query = ArtworksRequests.SEARCH_ARTWORKS_LITE,
  dataName = "searchArtworks",
  queryParams,
  showMap = true,
  context,
  title,
  text = "",
  questionMark = true,
  myMoca = true,
  shareMark = true,
  backButton = false,
  showInfoPageTab = "",
  searchPage = false,
  controlsPage = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveSearchPrevTab = () => {},
}) => {
  const limit = 50;
  const { dispatch } = context;
  const setCategories = payload => dispatch(SET_CATEGORIES(payload));
  const setFilterResults = payload => dispatch(SET_FILTER_RESULTS(payload));
  const setfilterQueryParams = payload => dispatch(SET_FILTER_QUERY_PARAMS(payload));
  const setOffset = payload => dispatch(SET_OFFSET(payload));
  const setArtworks = payload => dispatch(SET_ARTWORKS(payload));
  const setMaterials = payload => dispatch(SET_MATERIALS(payload));
  const setColors = payload => dispatch(SET_COLORS(payload));
  const setWidthMinValue = payload => dispatch(SET_WIDTH_MIN_VALUE(payload));
  const setWidthMaxValue = payload => dispatch(SET_WIDTH_MAX_VALUE(payload));
  const setHeightMinValue = payload => dispatch(SET_HEIGHT_MIN_VALUE(payload));
  const setHeightMaxValue = payload => dispatch(SET_HEIGHT_MAX_VALUE(payload));
  const setSortValue = payload => dispatch(SET_SORT_VALUE(payload));
  const setOriginsOfInspiration = payload => dispatch(SET_ORIGINS_OF_INSPIRATION(payload));
  const setLocation = payload => dispatch(SET_LOCATION_FILTER(payload));
  const setRadius = payload => dispatch(SET_RADIUS(payload));

  const {
    categories,
    filterResults,
    filterQueryParams,
    offset,
    artworks,
    materials,
    colors,
    widthMinValue,
    widthMaxValue,
    heightMinValue,
    heightMaxValue,
    sortValue,
    originsOfInspiration,
    location,
    radius,
  } = context.state;
  const stateContext = {
    categories: { categories, setCategories },
    filterResults: { filterResults, setFilterResults },
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    offset: { offset, setOffset },
    artworks: { artworks, setArtworks },
    materials: { materials, setMaterials },
    colors: { colors, setColors },
    widthMinValue: { widthMinValue, setWidthMinValue },
    widthMaxValue: { widthMaxValue, setWidthMaxValue },
    heightMinValue: { heightMinValue, setHeightMinValue },
    heightMaxValue: { heightMaxValue, setHeightMaxValue },
    sortValue: { sortValue, setSortValue },
    originsOfInspiration: { originsOfInspiration, setOriginsOfInspiration },
    location: { location, setLocation },
  };
  const routerLocation = useLocation();
  const buyPage = routerLocation.pathname === `/${explore}/${buy}`;
  const { currentLocation } = useTypedSelector(store => store.currentLocationReducer);

  const { data, refetch } = useQuery(query, {
    variables: buyPage
      ? {
          limit,
          ...filterQueryParams,
          ...{
            ...queryParams,
            priceRange: filterQueryParams.priceRange
              ? filterQueryParams.priceRange
              : { from: 0, to: maxNumber },
          },
        }
      : { limit, ...filterQueryParams, ...queryParams },
  });
  const [loadCategoties] = useLazyQuery(ArtworksRequests.CATEGORIES);
  const { openModal, addPrevState, removePrevState, openFilter, setCurrentLocation } = useActions();
  const { filterWindowOpen } = useTypedSelector(state => state.filterReducer);
  const [units, setUnits] = useState("in/pounds");
  const [priceMinValue, setPriceMinValue] = useState();
  const [priceMaxValue, setPriceMaxValue] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const total = data && data[dataName]?.meta.total;
  const displayArtworks = Boolean(artworks && total !== 0);
  const breakpointCols = { ...BREAKPOINT_COLUMNS_OBJ, default: filterWindowOpen ? 4 : 6 };
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();

  const uniqueArtworks = getUniqueArtworks(artworks);

  const isArtistProfilePage = routerLocation.pathname.includes("artists/");

  const setPrevState = (currentArtwork, id) => {
    const currArtwork = currentArtwork || artworks.find(artwork => artwork._id === id);
    if (!isArtistProfilePage) {
      localStorage.setItem(
        "prevState",
        JSON.stringify({
          state: artworks,
          url: routerLocation.pathname,
          scroll: window.scrollY,
          filtersState: { ...stateContext, filterWindowOpen },
        }),
      );
    }

    if (saveSearchPrevTab) saveSearchPrevTab();
    setSldrAtrwrs(
      artworks.map(el => {
        if (currArtwork._id === el._id) {
          return { id: el._id, artwork: currArtwork };
        }
        return { id: el._id, artwork: null };
      }),
    );
    addPrevState({
      url: routerLocation.pathname,
      state: artworks,
      tab: showInfoPageTab,
      filtersState: stateContext,
    });
  };

  const scrollHandler = async () => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
      return;
    }
    if (artworks.length >= 50) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setArtworks([...artworks, ...(res.data[dataName].artworks || [])]);
    }
  };

  const getCategories = async () => {
    const res = await loadCategoties();
    const defaultCategories = res.data.categories.map(category => {
      return {
        id: category._id,
        type: IFilterTypes.Categories,
        checked: false,
        value: category.name,
      };
    });
    setCategories(categories.length ? categories : defaultCategories);
  };

  useEffect(() => {
    getCategories();
    refetch();
  }, []);

  useEffect(() => {
    setArtworks([]);
    setShowFilters(false);
    refetch();
    if (data && data[dataName].artworks.length) {
      setArtworks(data[dataName]?.artworks);
      setShowFilters(true);
    }
  }, [routerLocation]);

  useEffect(() => {
    if (data && data[dataName].artworks.length) setShowFilters(true);
    if (!artworks.length && data) {
      setArtworks([...artworks, ...(data[dataName]?.artworks || [])]);
    }
  }, [data]);

  useEffect(() => {
    const defaultMaterials = PREDEFINED_MATERIALS.reduce((accum, material) => {
      if (material)
        accum.push({
          value: material,
          checked: false,
          id: `${material}${IFilterTypes.Materials}`,
          type: IFilterTypes.Materials,
        });
      return accum;
    }, []);
    const defaultColors = PREDEFINED_COLORS.reduce((accum, color) => {
      if (color)
        accum.push({
          value: color,
          checked: false,
          id: `${color}${IFilterTypes.Colors}`,
          type: IFilterTypes.Colors,
        });
      return accum;
    }, []);
    setMaterials(materials.length ? materials : defaultMaterials);
    setColors(colors.length ? colors : defaultColors);
  }, []);

  const unitCheck = units === "in/pounds" ? "in" : "cm";

  const sortByArtworks = ({ sortBy, orderBy, priceRange, name }) => {
    const price =
      filterQueryParams.priceRange && !filterResults.find(filter => filter.id === "Price")
        ? undefined
        : filterQueryParams.priceRange || priceRange;

    const newForSale = price ? true : undefined;

    if (name === CLOSEST_ARTWORKS.name) {
      sortByClosest(
        currentLocation,
        setCurrentLocation,
        setArtworks,
        setOffset,
        setfilterQueryParams,
        { ...filterQueryParams, priceRange: price, forSale: newForSale },
        setSortValue,
        sortValue,
      );
      return;
    }
    setArtworks([]);
    setOffset(0);
    setfilterQueryParams({
      ...filterQueryParams,
      sortBy,
      orderBy,
      forSale: newForSale,
      offset: 0,
      priceRange: price,
      sortByLocation: undefined,
    });
  };

  const backToAllItems = () => {
    setFilterResults([]);
    setArtworks([]);
    setOffset(0);
    setCategories(categories.map(category => ({ ...category, checked: false })));
    setMaterials(materials.map(material => ({ ...material, checked: false })));
    setColors(colors.map(color => ({ ...color, checked: false })));
    setOriginsOfInspiration([]);
    setSortValue(PREDEFINED_ARTWORKS_SORT[0]);
    setfilterQueryParams({
      ...filterQueryParams,
      offset: 0,
      categories: undefined,
      materials: [],
      colors: [],
      priceRange: undefined,
      widthRange: undefined,
      heightRange: undefined,
      inspiration: [],
      originsOfInspiration: undefined,
      sortBy: SORT_BY_DATE,
      orderBy: ORDER_BY_DESC,
      forSale: undefined,
      location: undefined,
    });
  };

  const returnPersistedState = (prevState, isAfterRefresh) => {
    localStorage.removeItem("prevState");
    const { filtersState } = prevState;
    if (!filtersState) return;
    const filterPriceResults = filtersState.filterResults.filterResults.find(
      res => res.id === "Price",
    );
    if (isAfterRefresh) setTimeout(() => window.scroll(0, prevState.scroll), 1000);
    if (filtersState.filterWindowOpen) openFilter();
    setPriceMinValue(filterPriceResults?.min);
    setPriceMaxValue(filterPriceResults?.max);
    setCategories(filtersState.categories.categories);
    setFilterResults(filtersState.filterResults.filterResults);
    setfilterQueryParams(filtersState.filterQueryParams.filterQueryParams);
    setOffset(filtersState.offset.offset);
    setArtworks(prevState.state);
    setMaterials(filtersState.materials.materials);
    setColors(filtersState.colors.colors);
    setWidthMinValue(filtersState.widthMinValue.widthMinValue);
    setWidthMaxValue(filtersState.widthMaxValue.widthMaxValue);
    setHeightMinValue(filtersState.heightMinValue.heightMinValue);
    setHeightMaxValue(filtersState.heightMaxValue.heightMaxValue);
    setSortValue(filtersState.sortValue.sortValue);
    setOriginsOfInspiration(filtersState.originsOfInspiration.originsOfInspiration);
    setLocation(filtersState.location.location);

    removePrevState(routerLocation.pathname);
    setShowFilters(true);
  };

  const refetchInstitutions = async () => {
    const prevState = states.find(
      prevSt =>
        prevSt.url === routerLocation.pathname.replace("/followings", "").replace("/followers", ""),
    );
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevState) {
      returnPersistedState(prevState, false);
      return;
    }

    if (prevLoadingState?.url === routerLocation.pathname) {
      returnPersistedState(prevLoadingState, true);
      return;
    }
    const res = await refetch({ text });
    setArtworks([...(res.data[dataName].artworks || [])]);
  };

  useEffect(() => {
    setShowFilters(false);
    setArtworks([]);
    refetchInstitutions();
  }, [text]);

  return (
    <div className="artworks wrapper">
      {title && (
        <div className="">
          <ExplorePageTitle
            title={title}
            questionMark={questionMark}
            myMoca={myMoca}
            shareMark={shareMark}
            tooltipText={tooltipText}
            backButton={backButton}
          />
        </div>
      )}
      {showMap && (
        <LeafletMap setIsOpen={openModal} saveState={setPrevState} locations={artworks} zoom={3} />
      )}
      {showFilters && (
        <FilterTopBar
          renderValue={value => `Sort by: ${value.name}`}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setQueryValue={sortByArtworks}
          options={PREDEFINED_ARTWORKS_SORT}
        />
      )}
      <div
        className={classNames("explore_grid", {
          active: filterWindowOpen,
        })}
      >
        {showFilters && (
          <div className="left_side__bar">
            <div className="artworks_item__side-bar">
              <Filter>
                <FilterItem title="Price" unit="$">
                  <FilterFromTo
                    setArtworks={setArtworks}
                    requestField="priceRange"
                    typeFilter={IFilterTypes.Price}
                    unit="$"
                    minValue={priceMinValue}
                    setMinValue={setPriceMinValue}
                    maxValue={priceMaxValue}
                    setMaxValue={setPriceMaxValue}
                    context={stateContext}
                    priceRange
                  />
                </FilterItem>
                <FilterItem title="Units">
                  <FilterUnits units={units} setUnits={setUnits} context={stateContext} />
                </FilterItem>
                <FilterItem title="Width" unit={unitCheck}>
                  <FilterFromTo
                    setArtworks={setArtworks}
                    requestField="widthRange"
                    typeFilter={IFilterTypes.Width}
                    unit={unitCheck}
                    minValue={widthMinValue}
                    setMinValue={setWidthMinValue}
                    maxValue={widthMaxValue}
                    setMaxValue={setWidthMaxValue}
                    context={stateContext}
                  />
                </FilterItem>
                <FilterItem title="Height" unit={unitCheck}>
                  <FilterFromTo
                    setArtworks={setArtworks}
                    requestField="heightRange"
                    typeFilter={IFilterTypes.Height}
                    unit={unitCheck}
                    minValue={heightMinValue}
                    setMinValue={setHeightMinValue}
                    maxValue={heightMaxValue}
                    setMaxValue={setHeightMaxValue}
                    context={stateContext}
                  />
                </FilterItem>
                <FilterItem title="Categories">
                  <FilterCategories
                    setDataItem={setArtworks}
                    categoryQueryParam="categories"
                    context={stateContext}
                  />
                </FilterItem>
                <FilterItem title="Materials">
                  <FilterMaterialsColors
                    items={materials}
                    setItems={setMaterials}
                    query="materials"
                    apolloQuery={AVAILABLE_ARTWORK_MATERIALS}
                    queryDataName="availableArtworksMaterials"
                    type={IFilterTypes.Materials}
                    context={stateContext}
                  />
                </FilterItem>
                <FilterItem title="Colors">
                  <FilterMaterialsColors
                    items={colors}
                    setItems={setColors}
                    query="colors"
                    apolloQuery={AVAILABLE_ARTWORK_COLORS}
                    queryDataName="availableArtworksColors"
                    type={IFilterTypes.Colors}
                    context={stateContext}
                  />
                </FilterItem>
                <FilterItem title="Origins of inspiration">
                  <FilterOriginsOfInspiration
                    context={stateContext}
                    setItems={setArtworks}
                    filterQueryName="inspiration"
                  />
                </FilterItem>
                <FilterItem title="Location">
                  <LocationFilter
                    value={location}
                    radiusValue={radius}
                    setRadius={setRadius}
                    setLocation={setLocation}
                    context={stateContext}
                    setDataItem={setArtworks}
                  />
                </FilterItem>
              </Filter>
            </div>
          </div>
        )}
        <div className="explore_list">
          {showFilters && (
            <div className="filter_results__content">
              <div className="filter_results__left_content">
                {!!total && (
                  <span className="filter_results__result">
                    {total?.toLocaleString()} {pluralize("Artwork", total)}
                  </span>
                )}
                {filterResults.length > 0 && (
                  <FilterResults
                    total={total}
                    filterResults={filterResults}
                    setFilterResults={setFilterResults}
                    setDataItem={setArtworks}
                    categoriesQuery="categories"
                    context={stateContext}
                    inspipationQuery="inspiration"
                  />
                )}
              </div>
            </div>
          )}
          {total === 0 && (
            <>
              {controlsPage ? (
                <p className="profile-artworks-page__no-artworks">There are no artworks</p>
              ) : (
                <ItemsNotFound
                  title={searchPage ? "No results found" : "There are no artworks"}
                  onClick={backToAllItems}
                />
              )}
            </>
          )}
          {displayArtworks && (
            <InfiniteScroll
              scrollThreshold={0.7}
              dataLength={artworks.length}
              next={scrollHandler}
              hasMore
            >
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {uniqueArtworks.map(artwork => (
                  <ArtworkListItem
                    saveState={setPrevState}
                    key={artwork._id}
                    openModal={openModal}
                    id={artwork._id}
                    artwork={artwork}
                  />
                ))}
              </Masonry>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtworksPageComponent;
