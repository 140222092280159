import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";

import {
  OperatorsSearchActions,
  OperatorsSearchActionTypes,
  ILocationFilter,
} from "../types/operatorsSearchActions";

export const SET_ARTISTS_OPERATORS = (payload: IUser[]): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_ARTISTS_OPERATORS,
  payload,
});

export const SET_SORT_VALUE_OPERATORS = (payload: IArtistSortValue): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_SORT_VALUE_OPERATORS,
  payload,
});

export const SET_OFFSET_OPERATORS = (payload: number): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_OFFSET_OPERATORS,
  payload,
});

export const SET_FILTER_QUERY_PARAMS_OPERATORS = (payload: any): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_FILTER_QUERY_PARAMS_OPERATORS,
  payload,
});

export const SET_ORIGINS_OF_INSPIRATION_OPERATORS = (
  payload: IFilter[],
): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_ORIGINS_OF_INSPIRATION_OPERATORS,
  payload,
});

export const SET_FILTER_RESULTS_OPERATORS = (payload: IResultButton[]): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_FILTER_RESULTS_OPERATORS,
  payload,
});

export const SET_GENDER_OPERATORS = (payload: IGenderFilterOption): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_GENDER_OPERATORS,
  payload,
});

export const SET_LOCATION_FILTER_OPERATORS = (
  payload: ILocationFilter | null,
): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_LOCATION_FILTER_OPERATORS,
  payload,
});

export const SET_RADIUS_OPERATORS = (payload: number): OperatorsSearchActions => ({
  type: OperatorsSearchActionTypes.SET_RADIUS_OPERATORS,
  payload,
});
