import React, { FC } from "react";
import moment from "moment";

import { IDuration } from "../../types/duration";
import formatDate from "../../utils/format-date";
import { getColorByUserId } from "../artist-or-institution-profile/utils";
import { ILocationType } from "../../types/addresTypes";
import { IImage } from "../../types/imageData";

import "./ShowItem.scss";
import { useActions } from "../../hooks/useActions";

interface ShowItemProps {
  image: IImage | undefined;
  name: string;
  id: string;
  location: ILocationType;
  isOpenToSubmitions: boolean;
  duration: IDuration;
}

const ShowItem: FC<ShowItemProps> = ({
  image,
  name,
  id,
  location,
  isOpenToSubmitions,
  duration,
}) => {
  const { openModal } = useActions();
  const hasDuration = !!(duration?.from && duration?.to);
  const hasPermanentHours = !!(hasDuration && moment(duration?.from).year() === 0);
  const backgroundColor = getColorByUserId(id)[0];
  const backgroundTextColor = getColorByUserId(id)[1];
  const displayDuration = (): string => {
    if (hasDuration && !hasPermanentHours) {
      return `${formatDate(new Date(duration?.from), "MMM DD YYYY")} - ${formatDate(
        new Date(duration?.to),
        "MMM DD YYYY",
      )}`;
    }
    return "Permanent show ";
  };

  return (
    // when you use Link change openModal function to goToTop function
    <div onClick={openModal} className="show_item">
      {/* <Link */}
      {/*  to={`/explore/shows/${id}`} */}
      {/*  state={{ */}
      {/*    image: image?.medium?.url, */}
      {/*    name, */}
      {/*  }} */}
      {/* > */}
      <div className="show_item__grid">
        <div style={{ backgroundColor }} className="show_item__image">
          {image?.origin?.url ? (
            <img src={image?.origin?.url} alt={name} />
          ) : (
            <span style={{ color: backgroundTextColor }} className="show_item__placeholder_text">
              MOCA
            </span>
          )}
        </div>
        <div className="show-item-content">
          <div className="show_item__open_to_submission">
            {isOpenToSubmitions ? "OPEN TO SUBMISSION" : "CLOSED TO SUBMISSION"}
          </div>

          <h4 className="show_item__title">{name}</h4>
          <div className="show_item__duration_location">
            <span className="show_item__location">{location?.name || "Virtual Only"},</span>
            {displayDuration()}
            {hasPermanentHours &&
              `(${formatDate(duration?.from, "h:mm a")} - ${formatDate(duration?.to, "h:mm a")})`}
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};
export default ShowItem;
