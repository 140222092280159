export interface FilterState {
  filterWindowOpen: boolean;
}

export enum FilterActionTypes {
  FILTER_OPEN = "FILTER_OPEN",
  FILTER_CLOSE = "FILTER_CLOSE",
}

interface FilterOpenAction {
  type: FilterActionTypes.FILTER_OPEN;
}

interface FilterCloseAction {
  type: FilterActionTypes.FILTER_CLOSE;
}

export type FilterAction = FilterOpenAction | FilterCloseAction;
