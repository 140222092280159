import { IArtwork } from "../../types/artwork/artwork";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getUniqueArtworks = (artworks: IArtwork[]) => {
  const artworksIds = artworks?.map(artwork => artwork._id);
  const uniqueArtworksIds = Array.from(new Set(artworksIds));
  return uniqueArtworksIds.map(uniqueArtworksId => {
    return artworks.find(artwork => artwork._id === uniqueArtworksId);
  });
};
