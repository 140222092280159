import { HeaderFlagActionTypes, HeaderFlaginAction } from "../types/header-flag";

interface HeaderFlagState {
  flag: boolean;
}

const initialValue: HeaderFlagState = {
  flag: false,
};

const headerFlagReducer = (state = initialValue, action: HeaderFlaginAction): HeaderFlagState => {
  switch (action.type) {
    case HeaderFlagActionTypes.HEADER_FLAG:
      return { ...state, flag: !state.flag };
    default:
      return state;
  }
};
export default headerFlagReducer;
