import { Avatar } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../../hooks/useActions";
import { artists, explore } from "../../../route-link";
import AvatarPlaceholder from "../../avatar-placeholder/AvatarPlaceholder";
import { IChatMessageContentProps } from "../utils";

import "./UserMessage.scss";

interface IUserMessageProps extends IChatMessageContentProps {
  saveCurrentScroll: () => void;
}

const UserMessage: FC<IUserMessageProps> = ({ message, saveCurrentScroll }) => {
  const { openModal, setIsOnArtwork } = useActions();
  const navigate = useNavigate();
  const { user } = message.audited;
  const userNickname = user.hasInstitution
    ? user.institutions[0].name
    : `${user.profile.firstName} ${user.profile.secondName}`;

  const goToProfile = (): void => {
    if (user.hasInstitution) {
      openModal();
      return;
    }
    setIsOnArtwork(true);
    saveCurrentScroll();
    navigate(`/${explore}/${artists}/${user._id}`);
  };

  return (
    <div className="user-message" onClick={goToProfile}>
      <div className="user-message_content">
        <div className="user-message_content_username">
          <p>{userNickname}</p>
          <span>View profile</span>
        </div>
        <>
          {user.profile.image?.thumbnail?.url ? (
            <Avatar src={user.profile.image?.thumbnail?.url} />
          ) : (
            <AvatarPlaceholder
              className="guest-avatar-placeholder"
              id={user._id}
              width="40px"
              height="40px"
              fontSize="8px"
            />
          )}
        </>
      </div>
      <p className="chat-message_text">{message.text}</p>
    </div>
  );
};

export default UserMessage;
