import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import navMobile from "../../data/navMobile.json";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import "./NavMenu.scss";

interface NavMobileProps {
  setOpen: (value: boolean) => void;
}

const NavMobile: React.FC<NavMobileProps> = ({ setOpen }) => {
  const { tokenKey } = useTypedSelector(state => state.tokenKeyReducer);
  const [navItem, setNavItem] = useState(navMobile);
  useEffect(() => {
    if (!tokenKey) {
      setNavItem(navMobile.filter(item => !item.token));
    }
  }, []);

  return (
    <nav className="nav">
      {navItem.map(link => (
        <NavLink key={link.id} to={link.link} className="nav_item" onClick={() => setOpen(false)}>
          {link.title}
        </NavLink>
      ))}
    </nav>
  );
};

export default NavMobile;
