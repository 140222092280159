import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("tokenKey") || null;
  return token
    ? {
        headers: {
          ...headers,
          "x-access-token": token ? `${token}` : "",
        },
      }
    : null;
});

const httpLink = createHttpLink({ uri: process.env.REACT_APP_BASE_URL });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
export default client;
