export interface ModalState {
  modalWindow: boolean;
  modalWindowLogin: boolean;
  reportModal: boolean;
}

export enum ModalActionTypes {
  MODAL_OPEN = "MODAL_OPEN",
  MODAL_OPEN_LOGIN = "MODAL_OPEN_LOGIN",
  MODAL_CLOSE = "MODAL_CLOSE",
  MODAL_CLOSE_LOGIN = "MODAL_CLOSE_LOGIN",
  LOGIN = "LOGIN",
  REPORT_OPEN = "REPORT_OPEN",
  REPORT_CLOSE = "REPORT_CLOSE",
}

interface ModalOpenAction {
  type: ModalActionTypes.MODAL_OPEN;
}

interface ModalOpenLoginAction {
  type: ModalActionTypes.MODAL_OPEN_LOGIN;
}

interface ModalCloseAction {
  type: ModalActionTypes.MODAL_CLOSE;
}

interface ModalCloseLoginAction {
  type: ModalActionTypes.MODAL_CLOSE_LOGIN;
}

interface ReportCloseAction {
  type: ModalActionTypes.REPORT_CLOSE;
}

interface ReportOpenAction {
  type: ModalActionTypes.REPORT_OPEN;
}

export type ModalAction =
  | ModalOpenAction
  | ModalCloseAction
  | ModalOpenLoginAction
  | ModalCloseLoginAction
  | ReportCloseAction
  | ReportOpenAction;
