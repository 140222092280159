import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import React from "react";
import { useCreateTransferContext } from "../../../../../context/createTransferContext";
import InputField from "../../../../../UI/input-field/InputField";
import LocationAutocomplete from "../../../../locations-autocomplete/LocationAutocomplete";

const LoanTransferForm: React.FC = () => {
  const {
    loanToName,
    setLoanToName,
    loanToDate,
    setLoanToDate,
    conditionReport,
    setConditionReport,
    locationLives,
    setLocationLives,
    locationIsPrivate,
    toggleLocationIsPrivate,
  } = useCreateTransferContext();

  return (
    <>
      <h3>Loan</h3>
      <InputField
        label="TERMS, CONDITION"
        value={conditionReport}
        onChange={setConditionReport}
        helperText="*required"
        multiline
      />
      <InputField
        label="Loan for"
        value={loanToName}
        onChange={setLoanToName}
        helperText="*required"
      />

      <InputField
        label="Loan to date"
        value={loanToDate}
        onChange={setLoanToDate}
        helperText="*required"
        type="date"
      />

      <InputLabel className="origin_label">LOCATION ON DISPLAY</InputLabel>
      <LocationAutocomplete
        value={locationLives}
        className="edit_item__location"
        onChange={setLocationLives}
        onCloseIconClick={() => setLocationLives(null)}
        placeholder="Choose location..."
        isPrivate={locationIsPrivate}
      />
      <FormControlLabel
        className="checkbox"
        control={
          <Checkbox
            onChange={toggleLocationIsPrivate}
            checked={locationIsPrivate}
            value=""
            color="success"
          />
        }
        label="Location is private"
      />
    </>
  );
};

export default LoanTransferForm;
