import { initialLocationSortValue } from "../../components/location-filter-select/utils";
import {
  IInstitutionPageState,
  InstitutionsPageActions,
  InstitutionsPageActionTypes,
} from "../types/institutionsPageTypes";

export const initialInstitutionPageState: IInstitutionPageState = {
  myInstitutions: [],
  filterQueryParams: {},
  offset: 0,
  categories: [],
  filterResults: [],
  locationSortValue: initialLocationSortValue,
  locationAutocompleteValue: null,
};

export const institutionPageReducer = (
  state = initialInstitutionPageState,
  action: InstitutionsPageActions,
): IInstitutionPageState => {
  switch (action.type) {
    case InstitutionsPageActionTypes.SET_INSTITUTIONS:
      return {
        ...state,
        myInstitutions: [...action.payload],
      };
    case InstitutionsPageActionTypes.SET_FILTER_QUERY_PARAMS: {
      return {
        ...state,
        filterQueryParams: {
          ...action.payload,
        },
      };
    }
    case InstitutionsPageActionTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case InstitutionsPageActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
      };
    case InstitutionsPageActionTypes.SET_FILTER_RESULTS:
      return {
        ...state,
        filterResults: [...action.payload],
      };
    case InstitutionsPageActionTypes.SET_LOCATION_SORT_VALUE:
      return {
        ...state,
        locationSortValue: action.payload,
      };
    case InstitutionsPageActionTypes.SET_LOCATION_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        locationAutocompleteValue: action.payload,
      };
    default:
      return state;
  }
};
