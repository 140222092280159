import React from "react";

import { Close } from "../../assets";

import "./ModalWrap.scss";

interface ModalWrapProps {
  closeModal: () => void;
  children: React.ReactNode;
}

const ModalWrap: React.FC<ModalWrapProps> = ({ closeModal, children }) => (
  <div className="modal_wrap">
    <Close className="close-svg" onClick={closeModal} height={20} />
    {children}
  </div>
);
export default ModalWrap;
