import React, { ChangeEvent, useEffect } from "react";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Button from "../../UI/button/Button";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

import "./MultiInput.scss";

/**
 * The MultiInput Functional Component
 * @typedef Props:
 * @property {string} label - the label by this key you get inputs value
 * @property {function} getData - function with arguments: label and values of inputs
 * @property {boolean} isMulti - boolean checker to indicate multi or single input
 * @property {object} TextFieldProps - boolean checker to indicate multi or single input
 * @typedef TextFieldProps
 * @property {string} type - type of input
 * @property {filled | standard | outlined | undefined} variant - variant of input
 * @property {string} className - css class name
 */

type Data = string | string[];

interface TextFieldProps {
  variant?: "filled" | "standard" | "outlined" | undefined;
  type?: string;
}

interface InputType {
  id: number;
  value: string;
  addNewActive: boolean;
}

interface MultiInputProps {
  label: string;
  getData: (label: string, data: Data) => void;
  isMulti: boolean;
  className?: string;
  textFiledProps?: TextFieldProps;
  initialValue: string[] | string;
}

export const MultiInput: React.FC<MultiInputProps> = ({
  getData,
  label,
  isMulti,
  className = "",
  textFiledProps = {},
  initialValue,
}) => {
  const { variant = "filled", type = "text" } = textFiledProps;
  const [inputs, setInputs] = React.useState<InputType[]>([]);

  useEffect(() => {
    if (!initialValue?.length) {
      setInputs([{ value: "", id: Date.now(), addNewActive: true }]);
      return;
    }
    if (typeof initialValue === "string") {
      setInputs([{ value: initialValue, id: Date.now(), addNewActive: true }]);
      return;
    }
    setInputs(
      initialValue.map((val, idx, arr) => ({
        value: val,
        id: Date.now() + idx,
        addNewActive: idx === arr.length - 1,
      })),
    );
  }, []);

  const handleChangeInput = (
    id: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const values = [...inputs];
    const index = values.findIndex(el => el.id === id);
    values[index].value = capitalizeFirstLetter(event.target.value);
    setInputs(values);
  };

  const handleAddField = (id: number): void => {
    const values = [...inputs];
    const index = values.findIndex(el => el.id === id);
    values[index].addNewActive = false;
    setInputs([...values, { value: "", id: Date.now(), addNewActive: true }]);
  };

  const handleRemoveField = (id: number): void => {
    const values = [...inputs];
    const index = values.findIndex(el => el.id === id);
    values.splice(index, 1);
    setInputs(values);
  };

  React.useEffect(() => {
    if (!inputs.length) return;
    const data: Data = isMulti ? inputs.map(({ value }) => value) : inputs[0].value;
    getData(label, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <div className="input_multi">
      {inputs.map(input => {
        return (
          <div className="input_multi__item" key={input.id}>
            <TextField
              className={className}
              type={type}
              value={input.value}
              variant={variant}
              onChange={e => handleChangeInput(input.id, e)}
            />
            {isMulti &&
              (input.addNewActive ? (
                <AddIcon className="add_input__multi" onClick={() => handleAddField(input.id)} />
              ) : (
                <Button
                  variant="text"
                  className="add_input__multi__delete"
                  onClick={() => handleRemoveField(input.id)}
                >
                  delete
                </Button>
              ))}
          </div>
        );
      })}
    </div>
  );
};
