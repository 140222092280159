import React, { FC, SyntheticEvent } from "react";
import SearchIcon from "@mui/icons-material/Search";

import LocationAutocomplete from "../locations-autocomplete/LocationAutocomplete";
import { ILocationFilter } from "../../context/types/artistsPageTypes";
import RadiusSlider from "../radius-slider/RadiusSlider";
import { IFilterTypes } from "../../types/filterTypes";
import { IArtistsPageFilterContext } from "../../explore-pages/artists-page/utils";
import { IArtworksPageFilterContext } from "../../context/exploreContext";
import { getCoordinates } from "../location-filter-select/utils";

import "./LocationFilter.scss";
import { ICoordinates } from "../../types/addresTypes";

interface LocationFilterProps {
  value: ILocationFilter | null;
  radiusValue: number;
  setRadius: (param: number) => void;
  setLocation: (param: ILocationFilter | null) => void;
  context: IArtistsPageFilterContext | IArtworksPageFilterContext;
  setDataItem: (param: any[]) => void;
  setMapLocation?: (param: ICoordinates | null) => void;
}

const LocationFilter: FC<LocationFilterProps> = ({
  value,
  radiusValue,
  setRadius,
  setLocation,
  context,
  setDataItem,
  setMapLocation,
}) => {
  const {
    filterResults: { filterResults, setFilterResults },
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
    offset: { setOffset },
  } = context;

  const onLocationFilterApply = async (): Promise<void> => {
    const coordinates = await getCoordinates(value);
    if (setMapLocation) setMapLocation(coordinates);
    const prevResult = filterResults.find(item => item.type === IFilterTypes.Location);
    if (value?.label === prevResult?.title && filterQueryParams?.location?.radius === radiusValue) {
      return;
    }
    setOffset(0);
    setDataItem([]);
    setfilterQueryParams({
      ...filterQueryParams,
      location: { ...coordinates, radius: radiusValue },
    });
    if (!prevResult) {
      setFilterResults([
        ...filterResults,
        {
          title: value?.label || "",
          type: IFilterTypes.Location,
          id: `${IFilterTypes.Location}${value?.label}`,
        },
      ]);
      return;
    }
    setFilterResults(
      filterResults.map(item => {
        if (item.type === IFilterTypes.Location) {
          return {
            ...item,
            id: `${IFilterTypes.Location}${value?.label}`,
            title: value?.label || "",
          };
        }
        return item;
      }),
    );
  };

  const onRadiusSliderChange = (
    e: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[],
  ): void => {
    if (typeof newValue === "number") {
      setRadius(newValue);
    }
  };

  return (
    <>
      <div className="location_filter">
        <SearchIcon className="location_filter__search_icon" />
        <LocationAutocomplete
          value={value}
          onChange={e => setLocation(e)}
          className="location_filter__input"
          onCloseIconClick={() => setLocation(null)}
          placeholder="Search..."
        />
      </div>
      {value && (
        <RadiusSlider
          className="artists_page__radius_slider"
          value={radiusValue}
          setRadiusValue={onRadiusSliderChange}
        />
      )}
      <button
        onClick={onLocationFilterApply}
        disabled={!value}
        className="filter_price__input filter_price__button"
      >
        Apply
      </button>
    </>
  );
};

export default LocationFilter;
