/* eslint-disable no-console */
/* eslint-disable radix */
import React, { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputLabel } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import userSex from "../../data/userSex.json";
import SelectBasic from "../../UI/select-basic/SelectBasic";
import InputField from "../../UI/input-field/InputField";
import LocationAutocomplete from "../../components/locations-autocomplete/LocationAutocomplete";
import { ILocationFilter } from "../../context/types/artistsPageTypes";
import { IUser } from "../../types/userTypes";
import MyButton from "../../UI/button/Button";
import { checkErrors, ITextValues } from "./utils";
import { getCoordinates } from "../../components/location-filter-select/utils";
import { updateProfile } from "../../api-queries/GraohQlClient";

import "./EditProfile.scss";

const EditProfile: FC = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { isInstitution } = state;
  const user: IUser = state?.data || state?.userInfo.user;
  const [sexData, setSexData] = useState<string>(user.profile.sex || "");
  const [location, setLocation] = useState<ILocationFilter | null>({
    label: user?.profile?.address?.location?.name || "",
    value: null,
  });
  const [textValues, setTextValues] = useState<ITextValues>({
    firstName: user.profile.firstName || "",
    midName: user.profile.middleName || "",
    lastName: user.profile.secondName || "",
    phone: user.profile.phoneNumber || "",
    postalCode: user.profile.address.postalCode || "",
    bio: user.profile.bio.story[0] || "",
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    sex: false,
  });
  const [isButtonDisablet, setIsButtonDisablet] = useState(false);

  const onSexSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setErrors({ ...errors, sex: false });
    setSexData(e.target.value);
  };

  const onFirstNameInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setErrors({ ...errors, firstName: false });
    setTextValues({ ...textValues, firstName: e.target.value });
  };

  const onMidNameInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextValues({ ...textValues, midName: e.target.value });
  };

  const onLastNameInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setErrors({ ...errors, lastName: false });
    setTextValues({ ...textValues, lastName: e.target.value });
  };

  const onPhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const phone = e.target.value.replace(/\D/, "");
    setTextValues({ ...textValues, phone });
  };

  const onPostalCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextValues({ ...textValues, postalCode: e.target.value });
  };

  const onBioInputChange = (e: any): void => {
    setTextValues({ ...textValues, bio: e.target.value });
  };

  const handleChangeLocation = (value: ILocationFilter): void => {
    setLocation(value);
  };

  const onCloseIconClickLocation = (): void => setLocation(null);

  const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const currentErrors = checkErrors(sexData, textValues);
    setErrors(currentErrors);
    const isError = Object.entries(currentErrors).some(item => item[1] === true);

    if (isError) {
      setIsButtonDisablet(true);
      return;
    }
    const query: any = {
      _id: user._id,
      profile: {
        firstName: textValues.firstName,
        secondName: textValues.lastName,
        middleName: textValues.midName,
        phoneNumber: textValues.phone,
        sex: sexData,
        address: {
          city: location?.label || "",
          postalCode: textValues.postalCode,
          location: {
            name: location?.label || "",
            coordinates: { longitude: 0, latitude: 0 },
          },
        },
        bio: {
          story: [textValues.bio],
        },
      },
    };
    if (isInstitution) {
      delete query.profile.bio;
    }
    if (location && location?.label !== "") {
      const coordinates = await getCoordinates(location);
      query.profile.address.location = {
        name: location?.label,
        coordinates: coordinates ?? {
          longitude: 0,
          latitude: 0,
        },
      };
    }
    try {
      await updateProfile({
        ...query,
      });
      if (!user.hasInstitution) {
        const newName = `${query.profile?.firstName} ${query.profile?.middleName} ${query.profile?.secondName}`;
        localStorage.setItem(
          "userInfo",
          JSON.stringify({ name: newName, image: user?.profile?.image.thumbnail.url }),
        );
      }
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setIsButtonDisablet(false);
  }, [textValues, sexData]);

  return (
    <form onSubmit={onSubmit}>
      <div className="edit edit_profile">
        <div className="edit_container">
          <div className="edit_title__wrap">
            <ArrowBackIcon className="arrow__back" onClick={() => navigate(-1)} />
            <h2 className="edit_title">Edit my Public Profile</h2>
          </div>
          <div className="edit_form__content">
            <InputField
              label="First Name*"
              onChange={onFirstNameInputChange}
              value={textValues.firstName}
              isError={errors.firstName}
              errorText="First name is required"
            />
            <InputField
              label="Mid Name"
              onChange={onMidNameInputChange}
              value={textValues.midName}
            />
            <InputField
              label="Last Name*"
              onChange={onLastNameInputChange}
              value={textValues.lastName}
              isError={errors.lastName}
              errorText="Last name is required"
            />
            <SelectBasic
              options={userSex}
              value={sexData}
              placeholder="Choose a sex..."
              onChange={onSexSelectChange}
              label="SEX*"
              isError={errors.sex}
              errorMessage="Sex is required"
            />
            <InputField
              label="Phone"
              onChange={onPhoneInputChange}
              value={textValues.phone}
              helperText="No calls, or share without permission"
            />
            <div className="register_item">
              <InputLabel className="origin_label" variant="standard" htmlFor="title">
                Map my Location
              </InputLabel>
              <LocationAutocomplete
                value={location}
                className="edit_item__location"
                onChange={handleChangeLocation}
                onCloseIconClick={onCloseIconClickLocation}
                placeholder="Choose location..."
              />
            </div>
            <InputField
              label="Postal Code"
              onChange={onPostalCodeInputChange}
              value={textValues.postalCode}
            />
            {!isInstitution && (
              <InputField textAreaOnChange={onBioInputChange} label="Bio" value={textValues.bio} />
            )}
          </div>
          <MyButton
            disabled={isButtonDisablet}
            className="submit_form"
            variant="button-dark"
            children="Save Changes"
          />
        </div>
      </div>
    </form>
  );
};

export default EditProfile;
