import { IUser } from "../../types/userTypes";

export interface IUserCounters {
  scans: number;
  votes: number;
  shows: number;
}
export interface UserState {
  user: IUser | null;
  userCounters: IUserCounters | null;
}

export enum UserActionTypes {
  ADD_USER = "ADD_USER",
  SET_USER_COUNTERS = "SET_USER_COUNTERS",
}

interface SetUser {
  type: UserActionTypes.ADD_USER;
  payload: IUser | null;
}

interface SetUserCounters {
  type: UserActionTypes.SET_USER_COUNTERS;
  payload: IUserCounters | null;
}

export type UserType = SetUser | SetUserCounters;
