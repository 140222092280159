import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ArtworksRequests } from "../../api-queries/AllRequests";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import ArtworksPageComponent from "../../components/artworks-page-component/ArtworksPageComponent";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import "./ArtForSalePage.scss";

const ArtForSale: FC = () => {
  const { id }: IParamsId = useParams();
  const dispatch = useDispatch();
  const artworks = useTypedSelector(state => state.artworkSearchFilterReducer);
  return (
    <div className="art_for_sale">
      <ArtworksPageComponent
        query={ArtworksRequests.USER_CURRENT_ARTWORKS}
        dataName="userCurrentArtworks"
        queryParams={{ userId: id, saleable: true }}
        showMap
        context={{ dispatch, state: artworks }}
        title="My Art For Sale"
        myMoca={false}
        shareMark={false}
        questionMark={false}
        backButton
        controlsPage
      />
    </div>
  );
};

export default ArtForSale;
