import moment from "moment";
import React, { FC } from "react";
import { useActions } from "../../../../hooks/useActions";
import { IShowExhibitions } from "../../../../types/exhibitions";
import NotificationImage from "../../NotificationImage";

interface IExhibitionNoticeAuditedProps {
  exhibition: IShowExhibitions;
  time: Date;
}

const ExhibitionNoticeAudited: FC<IExhibitionNoticeAuditedProps> = ({ exhibition, time }) => {
  const imageSrc = exhibition.image?.medium?.url || exhibition.image?.thumbnail?.url || "";
  const { openModal } = useActions();
  return (
    <>
      <p className="follow_notification__time">{moment(time).fromNow()}</p>

      <div onClick={openModal}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </div>
    </>
  );
};

export default ExhibitionNoticeAudited;
