import { initialLocationSortValue } from "../../components/location-filter-select/utils";
import { durationFilters, DurationFilters } from "../../explore-pages/shows-page/utils";
import {
  ShowsSearchActions,
  ShowsSearchActionTypes,
  IShowSearchInitialState,
} from "../types/showsSearch";

const initialDurationSortValue =
  durationFilters.find(item => item.name === DurationFilters.ANYTIME) || durationFilters[5];

export const showsSearchInitialState: IShowSearchInitialState = {
  shows: [],
  durationSortValue: initialDurationSortValue,
  filterQueryParams: {},
  offset: 0,
  calendarValue: "",
  locationSortValue: initialLocationSortValue,
  locationAutocompleteValue: null,
};

export const showsSearchReducer = (
  state = showsSearchInitialState,
  action: ShowsSearchActions,
): IShowSearchInitialState => {
  switch (action.type) {
    case ShowsSearchActionTypes.SET_SEARCH_SHOWS:
      return {
        ...state,
        shows: [...action.payload],
      };
    case ShowsSearchActionTypes.SET_SEARCH_DURATION_SORT_VALUE:
      return {
        ...state,
        durationSortValue: action.payload,
      };
    case ShowsSearchActionTypes.SET_SEARCH_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case ShowsSearchActionTypes.SET_SEARCH_FILTER_QUERY_PARAMS:
      return {
        ...state,
        filterQueryParams: action.payload,
      };
    case ShowsSearchActionTypes.SET_SEARCH_CALENDAR_VALUE:
      return {
        ...state,
        calendarValue: action.payload,
      };
    case ShowsSearchActionTypes.SET_SEARCH_LOCATION_SORT_VALUE:
      return {
        ...state,
        locationSortValue: action.payload,
      };
    case ShowsSearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        locationAutocompleteValue: action.payload,
      };
    default:
      return state;
  }
};
