import React from "react";

import ArtworksExploreList from "../../components/artworks-explore-list/ArtworksExploreList";

const ArtWorksExplorePage: React.FC = () => (
  <div className="artworks-explore-page">
    <ArtworksExploreList />
  </div>
);
export default ArtWorksExplorePage;
