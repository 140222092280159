import React from "react";
import classNames from "classnames";
import { ITabsProps } from "./ArtworkTabs.type";

import "./ArtworkTabs.scss";

const ArtworkTabs: React.FC<ITabsProps> = ({
  className,
  tabs,
  selectedId,
  onClick,
}): JSX.Element => {
  const onClickTabs = (tabId: string) => () => onClick && onClick(tabId);
  return (
    <div className={classNames("moca_tabs", className)}>
      {tabs?.map(tab => (
        <div
          className={classNames("tab", {
            tab_selected: tab.id === selectedId,
          })}
          key={tab.id}
          onClick={onClickTabs(tab.id)}
        >
          <div
            className={classNames("tab_label", {
              tab_label__selected: tab.id === selectedId,
            })}
          >
            {tab.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArtworkTabs;
