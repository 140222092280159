import {
  INotificationsState,
  NotificationsAction,
  NotificationsActionTypes,
} from "../types/notifications.type";

const initialValue: INotificationsState = {
  unreadNoticesCount: 0,
  showNotificationModal: false,
  notices: [],
  notificationsModalScroll: undefined,
};

const NotificationsReducer = (
  state = initialValue,
  action: NotificationsAction,
): INotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.SET_SHOW_NOTIFICATIONS_MODAL:
      return { ...state, showNotificationModal: action.payload };
    case NotificationsActionTypes.SET_UNREAD_NOTICES_COUNT:
      return { ...state, unreadNoticesCount: action.payload };
    case NotificationsActionTypes.SET_NOTICES:
      return { ...state, notices: action.payload };
    case NotificationsActionTypes.SET_NOTIFICATIONS_MODAL_SCROLL:
      return { ...state, notificationsModalScroll: action.payload };
    default:
      return state;
  }
};
export default NotificationsReducer;
