import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import "./BackButton.scss";

interface IBackButton {
  className: string;
  url?: string;
  callback?: () => void;
}

const BackButton: React.FC<IBackButton> = ({ className, url, callback }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onBackClick = () => {
    if (callback) {
      callback();
      return;
    }
    if (url) {
      navigate(url);
      sessionStorage.removeItem("searchPageBackUrl");
      return;
    }
    navigate(-1);
  };
  return <ArrowBackIcon className={`arrow_back_icon ${className}`} onClick={onBackClick} />;
};

export default BackButton;
