import { IArtwork } from "../types/artwork/artwork";

export function removeArtworkFromLocalStorage(artworkId: string): void {
  const prevLoadingStateJSON = localStorage.getItem("prevState");
  const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;
  if (!prevLoadingState) return;
  localStorage.setItem(
    "prevState",
    JSON.stringify({
      ...prevLoadingState,
      state: prevLoadingState.state.filter((art: IArtwork) => art._id !== artworkId),
    }),
  );
}
