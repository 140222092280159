import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./UsersBlockedByMePage.scss";
import { fetchBlockedUsers } from "../../api-queries/GraohQlClient";
import UserListItem from "../../components/user-list-item/UserListItem";
import { IUser } from "../../types/userTypes";

const UsersBlockedByMePage = (): JSX.Element => {
  const navigate = useNavigate();
  const myMOCAId = localStorage.getItem("myMOCAId");
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async (): Promise<void> => {
    setLoading(true);
    const users = await fetchBlockedUsers({ userId: myMOCAId });
    setLoading(false);
    setBlockedUsers(users?.blockers || []);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="blocked-users">
      <div className="blocked-users_container">
        <div className="blocked-users_container__wrapper">
          <div className="blocked-users_title__wrap">
            <ArrowBackIcon className="arrow__back" onClick={() => navigate(-1)} />
            <h2 className="blocked-users_title">Blocked Users</h2>
          </div>
          <div className="blocked-users_content">
            {blockedUsers.map((user: IUser) => (
              <UserListItem
                key={user._id}
                profile={user?.profile}
                id={user?._id}
                blockedUser
                refetch={fetchUsers}
              />
            ))}
            {!blockedUsers.length && !loading && (
              <p className="profile-artworks-page__no-artworks">There are no users.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersBlockedByMePage;
