import { IArtwork } from "../../types/artwork/artwork";

export interface ArtworkDetailState {
  artworkData: IArtwork | null;
}

export enum ArtworkDetailActionTypes {
  ADD_ARTWORK = "ADD_ARTWORK",
}

interface SetArtworkDetail {
  type: ArtworkDetailActionTypes.ADD_ARTWORK;
  payload: IArtwork | null;
}

export type ArtworkDetailType = SetArtworkDetail;
