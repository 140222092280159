import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import EditArtworkArtist from "../../components/edits/EditArtworkArtist";
import { getSubtitle } from "./utils";

import "./UploadArtworkInfo.scss";

const UploadArtworkInfo: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location?.state;
  const image = state?.image;
  const type = state?.type;

  const subtitle = getSubtitle(type);
  return (
    <div className="edit upload_artwork_info">
      <div className="edit_container">
        <div className="edit_container__wrapper">
          <div className="edit_title__wrap">
            <ArrowBackIcon className="arrow__back" onClick={() => navigate(-1)} />
            <h2 className="edit_title">Add Artwork</h2>
            <p>{subtitle}</p>
          </div>
          <div className="upload_artwork_info__image">
            <img src={image} alt="artwork" />
          </div>
        </div>
        <div className="edit_form__content">
          <EditArtworkArtist isUploadArtwork />
        </div>
      </div>
    </div>
  );
};

export default UploadArtworkInfo;
