import { ILocationSelectValue } from "../../components/location-filter-select/utils";
import { IShowDurationSelectValue } from "../../explore-pages/shows-page/utils";
import { IShowExhibitions } from "../../types/exhibitions";
import { ILocationFilter, ShowsSearchActions, ShowsSearchActionTypes } from "../types/showsSearch";

export const SET_SEARCH_SHOWS = (payload: IShowExhibitions[]): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_SHOWS,
  payload,
});

export const SET_SEARCH_OFFSET = (payload: number): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_OFFSET,
  payload,
});

export const SET_SEARCH_DURATION_SORT_VALUE = (
  payload: IShowDurationSelectValue,
): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_DURATION_SORT_VALUE,
  payload,
});

export const SET_SEARCH_FILTER_QUERY_PARAMS = (payload: any): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_FILTER_QUERY_PARAMS,
  payload,
});

export const SET_SEARCH_CALENDAR_VALUE = (payload: string): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_CALENDAR_VALUE,
  payload,
});

export const SET_SEARCH_LOCATION_SORT_VALUE = (
  payload: ILocationSelectValue,
): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_LOCATION_SORT_VALUE,
  payload,
});

export const SET_SEARCH_LOCATION_AUTOCOMPLETE_VALUE = (
  payload: ILocationFilter | null,
): ShowsSearchActions => ({
  type: ShowsSearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_VALUE,
  payload,
});
