import React from "react";

const TooltipTextMovieAndTV = (): JSX.Element => {
  return (
    <>
      <ol className="tooltip_list">
        <li className="tooltip_item">
          *Art for Film and TV is made available in filter, sort and map. It is up to the artist, or
          collector who is current owner of artwork, to negotiate use of art please use “private”
          feature in comment section of credential pages.
        </li>
        <li className="tooltip_item">
          *Scan feature can identify artworks from a TV or film scene if it is a clear
          representation.
        </li>
        <li className="tooltip_item">
          *We recommend users negotiate one full shot of art work is included in production so it
          can be identified with a My Moca scan.{"\n"}
          Example: If your art is in a show, even for a second, audience should be able to stop it
          to grab a screen shot and scan to identify.
        </li>
        <li className="tooltip_item">
          *We recommend a “credit” for the app and the artist for use.
        </li>
        <li className="tooltip_item">
          *If Production Company cannot promise a readable shot we recommend payment in lieu of
          exposure.
        </li>
        <li className="tooltip_item">
          *Art, made available for film and TV MUST be released by the artist at the creation of a
          credential page. A collector can continue to keep art available after an artist release.
          Only artist can release for use.
        </li>
        <li className="tooltip_item">
          *Use transfer, feature, to “loan” and include permission, and details, of use. Use the
          “Private” feature in Comment section to Negotiate details until both parties agree on same
          set of facts. When the terms are agreeable, transfer, accept, based on details.
        </li>
      </ol>
    </>
  );
};

export default TooltipTextMovieAndTV;
