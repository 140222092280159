import React, { FC } from "react";
import classNames from "classnames";

import "./Tabs.scss";

interface ITabs {
  title: string;
  className: string;
  onClick: (title: string) => void;
  active: boolean;
}

const Tabs: FC<ITabs> = ({ title, className, onClick, active }) => (
  <div
    className={classNames(`${className}`, {
      active,
    })}
    onClick={() => onClick(title)}
  >
    {title}
  </div>
);

export default Tabs;
