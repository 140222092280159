import React from "react";

import { ExploreItemProps } from "../ArtWorksExploreItemDetails.type";

const ExploreItem: React.FC<ExploreItemProps> = ({ title, text, children }) => {
  return (
    <div className="explore_item">
      <h4 className="explore_item__title">{title}</h4>
      {!children ? (
        <p className="explore_item__text">{text}</p>
      ) : (
        <div className="explore_item__children">{children}</div>
      )}
    </div>
  );
};

export default ExploreItem;
