import React from "react";

import privacyPolicyText from "../../assets/PrivacyPolicy";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";

import "./PrivacyPolicy.scss";

const PrivacyPolicyPage: React.FC = () => {
  const title = "Privacy Policy";
  return (
    <div className="privacy_policy_page">
      <ScrollToTop />
      <h3 className="privacy_policy_page__title">{title}</h3>
      <p className="privacy_policy_page__text">{privacyPolicyText}</p>
    </div>
  );
};

export default PrivacyPolicyPage;
