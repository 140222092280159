/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import FormControl from "@mui/material/FormControl";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Modal,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import TimePickerComponents from "../../components/time-picker-components/TimePickerComponents";
import {
  addAdditionalImage,
  addInstitution,
  editInstitution,
} from "../../api-queries/GraohQlClient";
import days from "../../data/days.json";
import MocaCustomSelect from "../../UI/moca-custom-select/MocaCustomSelect";
import CropImage from "../../components/crop-image/CropImage";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { InstitutionRequests } from "../../api-queries/AllRequests";
import Button from "../../UI/button/Button";
import LocationAutocomplete from "../../components/locations-autocomplete/LocationAutocomplete";
import SearchLoader from "../../components/search-loader/SearchLoader";

import { getLocationInput } from "./utils";
import "./RegistrationInstitution.scss";

const RegistrationInstitution = ({ isEditInstitution }) => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const institution = state?.data?.institutions[0];
  const exhibition = institution?.exhibitions[0];
  const exhibitionsLocation = exhibition?.location?.name;
  const defaultLocation = exhibitionsLocation
    ? {
        label: exhibitionsLocation,
        value: null,
      }
    : null;
  const defaultWeek = exhibition?.daysOpen?.map(el => ({
    dayOfWeek: el.dayOfWeek,
    hoursOpen: { from: new Date(el.hoursOpen.from), to: new Date(el.hoursOpen.to) },
  }));
  const getDefaultRadioCheck = () => {
    if (exhibition?.isSolo) return { radio: "isSolo" };
    if (exhibition?.isOpenToSubmitions) return { radio: "isOpenToSubmitions" };

    return false;
  };
  const { data } = useQuery(InstitutionRequests.INSTITUTION_CATEGORIES);
  const [permanent, setPermanent] = useState(false);
  const [privateCheck, setPrivateCheck] = useState(institution?.isVirtual || false);
  const [radioCheck, setRadioCheck] = useState(getDefaultRadioCheck());
  const [week, setWeek] = useState(defaultWeek || []);
  const [institutionImages, setInstitutionImages] = useState(
    institution?.imagesOfInstitution || [],
  );
  const images = institutionImages.map(el => el?.medium?.url);
  const [category, setCategory] = useState(institution?.kategory?._id || "");
  const [cropOpenProfile, setCropOpenProfile] = useState(false);
  const [profile, setProfile] = useState("");
  const [nameInstitution, setNameInstitution] = useState(institution?.name || "");
  const [description, setDescription] = useState(institution?.description || "");
  const [location, setLocation] = useState(defaultLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setRadioCheck({ [name]: value });
  };
  const handleChangeCategory = value => setCategory(value);
  const handleNameInstitution = event =>
    setNameInstitution(capitalizeFirstLetter(event.target.value));
  const handleDescription = event => setDescription(capitalizeFirstLetter(event.target.value));
  const handleChangeLocation = value => {
    setLocation(value);
  };
  const onCloseIconClickLocation = () => setLocation(null);
  const goBack = () => navigate(-1);
  const onPrivateClick = () => setPrivateCheck(prev => !prev);

  const onSubmit = async dataValue => {
    setIsLoading(true);
    const instLocation = await getLocationInput(location);

    await addInstitution(
      {
        name: nameInstitution,
        description,
        kategory: category,
        private: privateCheck,
        isVirtual: privateCheck,
        isSolo: radioCheck.radio === "isSolo",
        availableForFilmAndTV: dataValue.availableForFilmAndTV,
        availableForEvents: dataValue.availableForEvents,
        isOpenToSubmitions: radioCheck.radio === "isOpenToSubmitions",
        daysOpen: week,
        location: privateCheck ? undefined : instLocation,
        title: nameInstitution,
        imagesOfInstitution: institutionImages.map(image => {
          delete image.__typename;
          delete image.medium.__typename;
          delete image.origin.__typename;
          delete image.thumbnail.__typename;
          return image;
        }),
      },
      state.profile,
      state.profileBackground,
    )
      .then(() => navigate("/"))
      .finally(() => setIsLoading(false));
  };
  const onEditInstitution = async dataValue => {
    setIsLoading(true);
    const instLocation = await getLocationInput(location);

    const query = {
      _id: institution?._id,
      name: nameInstitution?.trim(),
      description: description?.trim(),
      title: nameInstitution,
      kategory: category,
      daysOpen: privateCheck ? [] : week,
      location: privateCheck ? undefined : instLocation,
      private: privateCheck,
      isSolo: radioCheck.radio === "isSolo",
      isVirtual: privateCheck,
      isOpenToSubmitions: radioCheck.radio === "isOpenToSubmitions",
      exhibitionId: exhibition?._id,
      availableForFilmAndTV: dataValue.availableForFilmAndTV,
      availableForEvents: dataValue.availableForEvents,
      imagesOfInstitution: institutionImages.map(image => {
        delete image.__typename;
        delete image.medium.__typename;
        delete image.origin.__typename;
        delete image.thumbnail.__typename;
        return image;
      }),
    };

    try {
      await editInstitution(query);
      navigate(-1);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setCropOpenProfile(false);
  const handleProfileImage = async value => {
    const uploadedImage = await addAdditionalImage(value);
    if (uploadedImage) setInstitutionImages([...institutionImages, uploadedImage]);
  };
  const onChangeProfile = event => {
    if (event.target.files && event.target.files.length > 0) {
      setProfile(URL.createObjectURL(event.target.files[0]));
      setCropOpenProfile(true);
    }
  };

  const deletePhoto = mediumUrl => {
    const filter = institutionImages.filter(item => item.medium.url !== mediumUrl);
    setInstitutionImages(filter);
  };

  const refSolo = useRef();
  const refIsOpen = useRef();
  const onclickCheckedRadioButton = reference => {
    if (reference.current?.control.checked) {
      setRadioCheck(false);
    }
  };

  const onClickRadioSolo = () => onclickCheckedRadioButton(refSolo);
  const onClickRadioIsOpen = () => onclickCheckedRadioButton(refIsOpen);

  useEffect(() => {
    setIsDisabled(
      Boolean(
        !nameInstitution ||
          !description ||
          !category ||
          isLoading ||
          (!privateCheck && (!week.length || !location?.label)),
      ),
    );
  }, [nameInstitution, description, category, isLoading, privateCheck, week, location]);

  return (
    <div className="register_institution">
      <SearchLoader loading={isLoading} />
      <div className="wrapper">
        {!isEditInstitution && (
          <div className="register_title__wrap">
            <ArrowBackIcon className="arrow__back" onClick={goBack} />
            <h2 className="register_title">Register</h2>
          </div>
        )}
      </div>
      <form
        className="register_institution__form"
        onSubmit={handleSubmit(isEditInstitution ? onEditInstitution : onSubmit)}
      >
        <div className="register_institution__wrapper">
          {isEditInstitution && (
            <div className="edit_title__wrap">
              <ArrowBackIcon className="arrow__back_icon" onClick={goBack} />
              <h2 className="edit_title">Edit institution info</h2>
            </div>
          )}
          <div className="register_item">
            <InputLabel variant="standard" htmlFor="name_institution" className="label">
              name of institution
            </InputLabel>
            <TextField
              id="name_institution"
              className="input"
              type="text"
              name="name"
              variant="standard"
              value={nameInstitution}
              onChange={handleNameInstitution}
            />
            <p className="required">*Required</p>
          </div>
          <div className="register_checkbox register_item">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={privateCheck}
                    onClick={onPrivateClick}
                    {...register("private")}
                  />
                }
                label="Private collection/virtual only"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={institution?.availableForFilmAndTV}
                    {...register("availableForFilmAndTV")}
                  />
                }
                label="This location available for film and tv shoots"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={institution?.availableForEvents}
                    {...register("availableForEvents")}
                  />
                }
                label="This location available for events"
              />
            </FormGroup>
          </div>
          <div className="register_item">
            {data?.kategories && (
              <>
                <InputLabel variant="standard" htmlFor="category" className="label">
                  Category
                </InputLabel>

                <MocaCustomSelect
                  handleChangeCategory={handleChangeCategory}
                  data={data.kategories}
                  defaultValue={institution?.kategory?._id}
                />
                <p className="required">*Required</p>
              </>
            )}
          </div>
          <div className="register_item">
            <InputLabel variant="standard" htmlFor="description" className="label">
              Description
            </InputLabel>
            <div className="description__textarea">
              <TextareaAutosize
                maxRows={5}
                value={description}
                onChange={handleDescription}
                autoFocus
                className="text_area__input"
              />
            </div>

            <p className="required">*Required</p>
          </div>
          {!privateCheck && (
            <>
              <div className="register_item">
                <InputLabel variant="standard" htmlFor="location" className="label">
                  Location
                </InputLabel>
                <LocationAutocomplete
                  value={location || ""}
                  className="edit_item__location"
                  onChange={handleChangeLocation}
                  onCloseIconClick={onCloseIconClickLocation}
                  placeholder="Choose location..."
                />
                <p className="required">*Required</p>
              </div>
              <div className="register_item">
                <InputLabel variant="standard" className="label">
                  open days/hours
                </InputLabel>
                {days?.map(day => (
                  <TimePickerComponents
                    setWeek={setWeek}
                    week={week}
                    key={day.id}
                    day={day.label}
                  />
                ))}
              </div>
            </>
          )}
          <div className="register_item">
            <FormControl component="fieldset">
              <FormControlLabel
                value="Permanent exhibition"
                control={
                  <Checkbox
                    className="register_radio__checkbox"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    {...register("permanent")}
                  />
                }
                label="Permanent exhibition"
                onChange={() => setPermanent(!permanent)}
              />
              <RadioGroup aria-label="solo" defaultValue="female" name="radio">
                <FormControlLabel
                  value="isSolo"
                  onChange={handleChange}
                  control={<Radio checked={radioCheck.radio === "isSolo"} />}
                  label="Solo show"
                  onClick={onClickRadioSolo}
                  ref={refSolo}
                />
                {!permanent && (
                  <FormControlLabel
                    value="isOpenToSubmitions"
                    onChange={handleChange}
                    control={<Radio checked={radioCheck.radio === "isOpenToSubmitions"} />}
                    label="Open to submissions"
                    ref={refIsOpen}
                    onClick={onClickRadioIsOpen}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="register_item">
            <InputLabel variant="standard" htmlFor="add_photo" className="label">
              Additional photos
            </InputLabel>
            <div className="register_photos__grid">
              <div className="register_add__photo__item register_add__photo">
                <InsertPhotoIcon className="add_photo__svg" />
                <input type="file" className="upload_input__file" onChange={onChangeProfile} />
              </div>
              {!!images.length && (
                <div className="register_photos">
                  {images?.map(image => {
                    if (!image) return null;
                    return (
                      <div key={image} className="register_add__photo__item">
                        <CloseIcon
                          className="register_photo__close"
                          onClick={() => deletePhoto(image)}
                        />
                        <img id="image" className="register_photos__item" src={image} alt="" />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <Modal
              open={cropOpenProfile}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <CropImage
                imageSrc={profile || ""}
                cropImage="profile"
                handleProfileImage={handleProfileImage}
                handleClose={() => setCropOpenProfile(false)}
                minCropBoxHeight={50}
                minCropBoxWidth={50}
                cropArray
              />
            </Modal>
          </div>
          {isEditInstitution && (
            <div className="register_item edit_button">
              <Button
                disabled={isDisabled}
                className="submit_form"
                variant="button-dark"
                children="Edit Institution"
                type="submit"
              />
            </div>
          )}
        </div>
        {!isEditInstitution && (
          <div className="upload_image__footer">
            <Button
              disabled={isDisabled}
              variant="button-dark"
              children="Add institution"
              className="bg_dark add_institution"
            />
          </div>
        )}
      </form>
    </div>
  );
};
export default RegistrationInstitution;
