import React, { FC, useState } from "react";
import { InputLabel, NativeSelect, TextareaAutosize } from "@mui/material";

import { Close } from "../../assets";
import Button from "../../UI/button/Button";
import DialogWindow from "../dialog-window/DialogWindow";
import { ReportTypes } from "../../types/report";
import { IReportActionParams } from "../report-button/utils";

import "./ReportContent.scss";

interface ReportContentProps {
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
  title: string;
  reportAction: (param: IReportActionParams) => Promise<void>;
  itemId: string | undefined;
  reportIdKey: string;
}

const ReportContent: FC<ReportContentProps> = ({
  isOpen,
  setIsOpen,
  title,
  reportAction,
  itemId,
  reportIdKey,
}) => {
  const [reportSelectValue, setReportSelectValue] = useState<string>(ReportTypes.Inaccuracy);
  const [inputValue, setInputValue] = useState("");

  const reportTypes = [
    { id: 0, title: ReportTypes.Inaccuracy },
    { id: 1, title: ReportTypes.Other },
  ];

  const onHandleClose = (): void => setIsOpen(false);
  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void =>
    setReportSelectValue(e.target.value);
  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setInputValue(e.target.value);

  const onReportClick = async (): Promise<void> => {
    const reportData: any = {
      [reportIdKey]: itemId,
      message: inputValue,
      reportType: reportSelectValue,
    };
    try {
      await reportAction(reportData);
      setIsOpen(false);
      setInputValue("");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <DialogWindow open={isOpen} handleClose={onHandleClose} title={title} className="report_dialog">
      <Close className="report_dialog__close_button" onClick={() => setIsOpen(false)} height={20} />
      <div className="report_dialog__content">
        <InputLabel variant="standard" className="report_label" htmlFor="uncontrolled-native">
          REPORT TYPE
        </InputLabel>
        <NativeSelect
          value={reportSelectValue}
          onChange={onSelectChange}
          className="report_select"
          inputProps={{
            name: "report",
            id: "uncontrolled-native",
          }}
        >
          {reportTypes.map(item => (
            <option key={item.id} value={item.title.toLowerCase()}>
              {item.title}
            </option>
          ))}
        </NativeSelect>
        <TextareaAutosize
          maxRows={5}
          value={inputValue}
          onChange={onInputChange}
          autoFocus
          className="report_dialog__input text_area__input"
        />
        <Button onClick={onReportClick} className="report_dialog__report_button" color="dark">
          Send Report
        </Button>
      </div>
    </DialogWindow>
  );
};

export default ReportContent;
