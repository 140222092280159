import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  FormControlLabel,
  InputLabel,
  SelectChangeEvent,
  TextField,
  Checkbox,
} from "@mui/material";

import { MenuProps } from "../edit.style";
import { IArtworkDimensions } from "../../../types/artwork/artworkDimensions";
import { ErrorFields } from "../edit.props";

interface EditDimensionsProps {
  dimensions: IArtworkDimensions;
  getDimensionsValue: (dimensions: IArtworkDimensions) => void;
  type: "artist" | "collector" | "found";
  dimensionsError: ErrorFields;
  setDimensionsError: (value: any) => void;
  isUploadArtwork?: boolean;
  showErrors: boolean;
}

const EditDimensions: React.FC<EditDimensionsProps> = ({
  dimensions,
  getDimensionsValue,
  type,
  dimensionsError,
  setDimensionsError,
  isUploadArtwork,
  showErrors,
}): JSX.Element => {
  const { widthError, weightError, lengthError, heightError } = dimensionsError;
  const initialMetrics = dimensions.metrics === "in" ? "in" : "cm";
  const [units, setUnits] = useState(initialMetrics);
  const [width, setWidth] = useState(dimensions.width || "");
  const [height, setHeight] = useState(dimensions.height || "");
  const [thickness, setSickness] = useState(dimensions.length || "");
  const [weight, setWeight] = useState(dimensions.weight || "");
  const [unknown, setUnknown] = useState(false);

  const handleChangeUnits = (event: SelectChangeEvent): void => {
    setUnits(event.target.value);
  };
  const re = /^[0-9\b]+$/;
  const handleChangeWidth = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value.length) {
      setWidth(event.target.value);
      setDimensionsError({
        ...dimensionsError,
        widthError: type === "found" ? "" : "Width is required",
      });
      return;
    }
    if (re.test(event.target.value)) {
      setWidth(event.target.value);
      setDimensionsError({ ...dimensionsError, widthError: "" });
    }
  };
  const handleChangeHeight = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value.length) {
      setHeight(event.target.value);
      setDimensionsError({
        ...dimensionsError,
        heightError: type === "found" ? "" : "Height is required",
      });
      return;
    }
    if (re.test(event.target.value)) {
      setHeight(event.target.value);
      setDimensionsError({ ...dimensionsError, heightError: "" });
    }
  };
  const handleChangeThickness = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value.length) {
      setSickness(event.target.value);
      setDimensionsError({
        ...dimensionsError,
        lengthError: type === "found" ? "" : "Thickness is required",
      });
      return;
    }
    if (re.test(event.target.value)) {
      setSickness(event.target.value);
      setDimensionsError({ ...dimensionsError, lengthError: "" });
    }
  };
  const handleChangeWeight = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value.length) {
      setWeight(event.target.value);
      setDimensionsError({
        ...dimensionsError,
        weightError: type === "found" ? "" : "Weight is required",
      });
      return;
    }
    if (re.test(event.target.value)) {
      setWeight(event.target.value);
      setDimensionsError({ ...dimensionsError, weightError: "" });
    }
  };
  const handleChangeUnknown = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUnknown(event.target.checked);
  };
  useEffect(() => {
    getDimensionsValue({
      height: +height,
      length: +thickness,
      width: +width,
      weight: +weight,
      metrics: units,
    });
  }, [width, height, weight, thickness, units]);

  return (
    <div className="dimensions">
      <div className="edit_text">Dimensions (inches)</div>
      <div className="register_item">
        <InputLabel className="origin_label" variant="standard" htmlFor="units">
          Units
        </InputLabel>
        <Select
          displayEmpty
          className="register_item__select"
          labelId="simple_select_label"
          id="units"
          value={units}
          onChange={handleChangeUnits}
          MenuProps={MenuProps}
        >
          <MenuItem value="in">in/pounds</MenuItem>
          <MenuItem value="cm">cm/kg</MenuItem>
        </Select>
        {type === "found" && (
          <FormControlLabel
            className="register_item__unknown"
            control={<Checkbox checked={unknown} onChange={handleChangeUnknown} />}
            label="Unknown"
          />
        )}
      </div>

      <div className="register_item">
        <InputLabel className="origin_label" variant="standard" htmlFor="width">
          Width
        </InputLabel>
        <TextField
          id="width"
          className="input"
          type="text"
          name="name"
          variant="standard"
          value={width}
          onChange={handleChangeWidth}
        />
        {isUploadArtwork && type !== "found" && (!widthError || !showErrors) && (
          <p className="info_message">Width is required</p>
        )}
        {showErrors && widthError && <p className="error_message">{widthError}</p>}
      </div>

      <div className="register_item">
        <InputLabel className="origin_label" variant="standard" htmlFor="height">
          Height
        </InputLabel>
        <TextField
          id="height"
          className="input"
          type="text"
          name="name"
          variant="standard"
          value={height}
          onChange={handleChangeHeight}
        />
        {isUploadArtwork && type !== "found" && (!heightError || !showErrors) && (
          <p className="info_message">Height is required</p>
        )}
        {showErrors && heightError && <p className="error_message">{heightError}</p>}
      </div>

      <div className="register_item">
        <InputLabel className="origin_label" variant="standard" htmlFor="thickness">
          Thickness
        </InputLabel>
        <TextField
          id="thickness"
          className="input"
          type="text"
          name="name"
          variant="standard"
          value={thickness}
          onChange={handleChangeThickness}
        />
        {isUploadArtwork && type !== "found" && (!lengthError || !showErrors) && (
          <p className="info_message">Thickness is required</p>
        )}
        {showErrors && lengthError && <p className="error_message">{lengthError}</p>}
      </div>

      <div className="register_item">
        <InputLabel className="origin_label" variant="standard" htmlFor="weight">
          Weight
        </InputLabel>
        <TextField
          id="weight"
          className="input"
          type="text"
          name="name"
          variant="standard"
          value={weight}
          onChange={handleChangeWeight}
        />
        {isUploadArtwork && type !== "found" && (!weightError || !showErrors) && (
          <p className="info_message">Weight is required</p>
        )}
        {showErrors && weightError && <p className="error_message">{weightError}</p>}
      </div>
    </div>
  );
};

export default EditDimensions;
