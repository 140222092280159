import React, { FC } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import { ISuggestion } from "../../types/suggestion";
import { explore, artists } from "../../route-link";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import MoreVertMenu from "../more-vert-menu/MoreVertMenu";
import { removeSuggestionFromArtwork } from "../../api-queries/GraohQlClient";

import "./SuggestionItem.scss";
import { useActions } from "../../hooks/useActions";

interface SuggestionItemProps {
  suggestion: ISuggestion;
  refetch: () => void;
  artworkId: string | undefined;
}

const SuggestionItem: FC<SuggestionItemProps> = ({ suggestion, refetch, artworkId }) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const userId = suggestion?.author?._id;
  const isMySuggestion = authUserId === userId;
  const suggestionId = suggestion?._id;
  const isInstitution = suggestion?.author?.hasInstitution;
  const authorProfile = suggestion?.author?.profile;
  const { openModal } = useActions();
  const image = authorProfile?.image;
  const notes = suggestion?.notes;
  const video = suggestion?.video?.url;
  const authorName = isInstitution
    ? suggestion?.author?.institutions[0]?.name
    : `${authorProfile?.firstName} ${authorProfile?.middleName || ""} ${authorProfile?.secondName}`;
  const suggestionText = [suggestion?.artistName, suggestion?.artworkTitle]
    .filter(Boolean)
    .join(", ");

  const navigate = useNavigate();
  const goToProfile = (): void => {
    if (isInstitution) {
      openModal();
      return;
    }
    navigate(`/${explore}/${artists}/${userId}`);
    window.scroll(0, 0);
  };

  const onRemove = async (): Promise<void> => {
    try {
      await removeSuggestionFromArtwork({ artworkId, suggestionId });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="suggestion">
      <div onClick={goToProfile} className="suggestion__avatar">
        {image?.medium.url ? (
          <Avatar
            onClick={goToProfile}
            className="artist_item__avatar_icon"
            alt="Remy Sharp"
            src={image?.medium.url}
          />
        ) : (
          <AvatarPlaceholder
            onClick={goToProfile}
            id={userId}
            fontSize="12px"
            width="45px"
            height="45px"
            className="artist_item__avatar_icon"
          />
        )}
      </div>
      <div className="suggestion__info">
        <div onClick={goToProfile} className="suggestion__author_name">
          {authorName}
        </div>
        <div className="suggestion__text_info">{suggestionText}</div>
        <div className="suggestion__notes">{notes}</div>
        {video && <ReactPlayer url={video} style={{ maxHeight: 300 }} controls width="100%" />}
      </div>
      {isMySuggestion && <MoreVertMenu onRemove={onRemove} />}
    </div>
  );
};

export default SuggestionItem;
