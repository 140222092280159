import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import ExhibitionNoticeAudited from "./audited-components/ExhibitionNoticeAudited/ExhibitionNoticeAudited";
import { IShowExhibitions } from "../../types/exhibitions";
import { Actions } from "./Notifications.props";
import { IImage } from "../../types/imageData";

interface InviteToShowNotificationProps {
  nameFrom: string;
  userId: string;
  time: Date;
  exhibition: IShowExhibitions;
  notice: Actions;
  userImage: IImage;
}

const InviteToShowNotification: React.FC<InviteToShowNotificationProps> = ({
  userId,
  nameFrom,
  time,
  exhibition,
  notice,
  userImage,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  let invitedUser;
  if (notice.user._id === exhibition.owner._id) {
    invitedUser = notice.associated.user;
  } else {
    invitedUser = notice.user;
  }
  const isOwnerInvitingUs = authUserId === invitedUser._id;
  const renderHeader = (): any => {
    if (isOwnerInvitingUs)
      return (
        <>
          <Link
            className="notification_link"
            to={`${explore}/${artists}/${userId}`}
            state={{ name: nameFrom, image: userImage }}
          >
            {nameFrom}
          </Link>{" "}
          invited{" "}
          <Link className="notification_link" to={`${explore}/${artists}/${authUserId}`}>
            You
          </Link>{" "}
          to <strong>{exhibition.title}</strong> exhibition!
        </>
      );

    return (
      <>
        <Link className="notification_link" to={`${explore}/${artists}/${authUserId}`}>
          You
        </Link>{" "}
        were invited by{" "}
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        to <strong>{exhibition.title}</strong> exhibition!
      </>
    );
  };
  return (
    <div className="notification_grid">
      <p className="notification_text">{renderHeader()}</p>
      <ExhibitionNoticeAudited time={time} exhibition={exhibition} />
    </div>
  );
};

export default InviteToShowNotification;
