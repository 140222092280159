export interface ITextValues {
  firstName: string;
  midName: string;
  lastName: string;
  phone: string;
  postalCode: string;
  bio: string;
}

export interface IErrors {
  firstName: boolean;
  lastName: boolean;
  sex: boolean;
}

export const checkErrors = (sexData: any, textValues: ITextValues): IErrors => {
  const errors = {
    firstName: false,
    lastName: false,
    sex: false,
  };
  if (!textValues.firstName) {
    errors.firstName = true;
  }
  if (!textValues.lastName) {
    errors.lastName = true;
  }
  if (!sexData) {
    errors.sex = true;
  }

  return errors;
};
