import { IResultButton } from "../../components/filter-units/utils";
import { IArtistSortValue, IGenderFilterOption } from "../../explore-pages/artists-page/utils";
import { IFilter } from "../../types/filterTypes";
import { IUser } from "../../types/userTypes";
import {
  ArtistsPageActions,
  ArtistsPageActionTypes,
  ILocationFilter,
} from "../../context/types/artistsPageTypes";

export const SET_ARTISTS = (payload: IUser[]): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_ARTISTS,
  payload,
});

export const SET_SORT_VALUE = (payload: IArtistSortValue): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_SORT_VALUE_ARTISTS,
  payload,
});

export const SET_OFFSET = (payload: number): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_OFFSET_ARTISTS,
  payload,
});

export const SET_FILTER_QUERY_PARAMS = (payload: any): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_FILTER_QUERY_PARAMS_ARTISTS,
  payload,
});

export const SET_ORIGINS_OF_INSPIRATION = (payload: IFilter[]): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_ORIGINS_OF_INSPIRATION_ARTISTS,
  payload,
});

export const SET_FILTER_RESULTS = (payload: IResultButton[]): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_FILTER_RESULTS_ARTISTS,
  payload,
});

export const SET_GENDER = (payload: IGenderFilterOption): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_GENDER_ARTISTS,
  payload,
});

export const SET_LOCATION_FILTER = (payload: ILocationFilter | null): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_LOCATION_FILTER_ARTISTS,
  payload,
});

export const SET_RADIUS = (payload: number): ArtistsPageActions => ({
  type: ArtistsPageActionTypes.SET_RADIUS_ARTISTS,
  payload,
});
