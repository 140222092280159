import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import { IArtwork } from "../../types/artwork/artwork";
import { IImage } from "../../types/imageData";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";

interface SuggestedArtworkNotificationProps {
  nameFrom: string;
  userId: string;
  time: Date;
  artwork: IArtwork;
  userImage: IImage;
}

const SuggestedArtworkNotification: React.FC<SuggestedArtworkNotificationProps> = ({
  nameFrom,
  userId,
  time,
  artwork,
  userImage,
}) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const artworkOwnerName = artwork.owner.hasInstitution
    ? artwork.owner.institutions[0].name
    : `${artwork.owner.profile.firstName}${artwork.owner.profile.secondName}`;
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link
          className="notification_link"
          to={`/${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        submitted a suggestion for artwork uploaded by{" "}
        <Link to={`${explore}/${artists}/${artwork.owner._id}`}>
          {artwork.owner._id === authUserId ? "You" : artworkOwnerName}
        </Link>
      </p>
      <ArtworkNoticeAudited artwork={artwork} time={time} />
    </div>
  );
};

export default SuggestedArtworkNotification;
