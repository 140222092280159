import { ArtworkTypes } from "../../types/artwork/artwork";

export enum UploadPageSubtitles {
  ARTIST_SUBTITLE = "I AM THE ARTIST",
  COLLECTOR_SUBTITLE = "I AM THE COLLECTOR",
  FOUND_SUBTITLE = "I FOUND THIS ART",
}

export const getSubtitle = (type: string): string => {
  switch (type) {
    case ArtworkTypes.ARTIST:
      return UploadPageSubtitles.ARTIST_SUBTITLE;
    case ArtworkTypes.COLLECTOR:
      return UploadPageSubtitles.COLLECTOR_SUBTITLE;
    case ArtworkTypes.FOUND:
      return UploadPageSubtitles.FOUND_SUBTITLE;
    default:
      throw new Error("Unknown artwork type");
  }
};
