import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DialogWindow from "../../dialog-window/DialogWindow";

import "./ArtworkLikesModal.scss";
import UserListItem from "../../user-list-item/UserListItem";
import { ILike } from "../../../types/like";

interface ArtworkLikesModalProps {
  isOpen: boolean;
  close: () => void;
  likes: ILike[];
}

const ArtworkLikesModal: React.FC<ArtworkLikesModalProps> = ({ isOpen, close, likes }) => {
  return (
    <DialogWindow open={isOpen} handleClose={close} className="artwork-likes_modal_wrapper">
      <div className="artwork-likes_modal">
        <div className="artwork-likes_modal_title__wrap">
          <ArrowBackIcon onClick={close} className="artwork-likes_modal_back_button" />
          <h2 className="artwork-likes_modal_title">Artwork likes</h2>
        </div>

        {likes &&
          likes.map(like => (
            <UserListItem
              key={like.author._id}
              profile={like?.author?.profile}
              id={like?.author?._id}
            />
          ))}
      </div>
    </DialogWindow>
  );
};

export default ArtworkLikesModal;
