import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import React from "react";
import { useCreateTransferContext } from "../../../../../context/createTransferContext";
import InputField from "../../../../../UI/input-field/InputField";
import LocationAutocomplete from "../../../../locations-autocomplete/LocationAutocomplete";

const TradeTransferForm: React.FC = () => {
  const {
    tradeForValue,
    setTradeForValue,
    tradeForObject,
    setTradeForObject,
    conditionReport,
    setConditionReport,
    locationLives,
    setLocationLives,
    locationIsPrivate,
    toggleLocationIsPrivate,
  } = useCreateTransferContext();

  return (
    <>
      <h3>Trade & Use Art as Currency</h3>
      <InputField
        label="Trade for"
        value={tradeForObject}
        onChange={setTradeForObject}
        helperText="*required"
      />

      <InputField
        label="Value"
        value={tradeForValue}
        onChange={setTradeForValue}
        helperText="*required"
      />
      <InputField
        label="TERMS, CONDITION"
        value={conditionReport}
        onChange={setConditionReport}
        helperText="*required"
        multiline
      />
      <InputLabel className="origin_label">LOCATION ON DISPLAY</InputLabel>
      <LocationAutocomplete
        value={locationLives}
        className="edit_item__location"
        onChange={setLocationLives}
        onCloseIconClick={() => setLocationLives(null)}
        placeholder="Choose location..."
        isPrivate={locationIsPrivate}
      />
      <FormControlLabel
        className="checkbox"
        control={
          <Checkbox
            onChange={toggleLocationIsPrivate}
            checked={locationIsPrivate}
            value=""
            color="success"
          />
        }
        label="Location is private"
      />
    </>
  );
};

export default TradeTransferForm;
