const privacyPolicyText = `
We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be
required to provide certain personal information to us when you elect to use certain products or services available
on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest
sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us
an email message; (e) submitting your credit card or other payment information when ordering and purchasing
products and services on our Site. To wit, we will use your information for, but not limited to, communicating with
you in relation to services and/or products you have requested from us. We also may gather additional personal or
non-personal information in the future.

Use of your Personal Information
My Moca collects and uses your personal information to operate its website(s) and deliver the services you have
requested.

My Moca may also use your personally identifiable information to inform you of other products or services available
from My Moca and its affiliates.

Sharing Information with Third Parties
My Moca does not sell, rent or lease its customer lists to third parties.

My Moca may share data with trusted partners to help perform statistical analysis, send you email or postal mail,
provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal
information except to provide these services to My Moca, and they are required to maintain the confidentiality of
your information.

My Moca may disclose your personal information, without notice, if required to do so by law or in the good faith
belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on
My Moca or the site; (b) protect and defend the rights or property of My Moca; and/or (c) act under exigent
circumstances to protect the personal safety of users of My Moca, or the public.

Tracking User Behavior
My Moca may keep track of the websites and pages our users visit within My Moca, in order to determine what My
Moca services are the most popular. This data is used to deliver customized content and advertising within My
Moca to customers whose behavior indicates that they are interested in a particular subject area.

Automatically Collected Information
Information about your computer hardware and software may be automatically collected by My Moca. This
information can include: your IP address, browser type, domain names, access times and referring website
addresses. This information is used for the operation of the service, to maintain quality of the service, and to
provide general statistics regarding use of the My Moca website.

Security of your Personal Information
My Moca secures your personal information from unauthorized access, use, or disclosure. My Moca uses the
following methods for this purpose:


   - Scan feature identifies art by share and color using image recognition and notifies person who uploads.

We strive to take appropriate security measures to protect against unauthorized access to or alteration of your
personal information. Unfortunately, no data transmission over the Internet or any wireless network can be
guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge
that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b)
security, integrity, and privacy of any and all information and data exchanged between you and us through this Site
cannot be guaranteed.

Right to Deletion

Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:

 • Delete your personal information from our records; and

 • Direct any service providers to delete your personal information from their records.

Please note that we may not be able to comply with requests to delete your personal information if it is necessary
to:

 • Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty
or product recall conducted in accordance with federal law, provide a good or service requested by you, or
reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a
contract between you and us;

 • Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those
responsible for that activity;

 • Debug to identify and repair errors that impair existing intended functionality;

 • Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or
exercise another right provided for by law;

 • Comply with the California Electronic Communications Privacy Act;

 • Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres
to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or
seriously impair the achievement of such research, provided we have obtained your informed consent;

 • Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with
us;

 • Comply with an existing legal obligation; or

 • Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in
which you provided the information.

Children Under Thirteen
My Moca does not knowingly collect personally identifiable information from children under the age of thirteen. If
you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.

Changes to this Statement
My Moca reserves the right to change this Privacy Policy from time to time. We will notify you about significant
changes in the way we treat personal information by sending a notice to the primary email address specified in
your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page.
Your continued use of the Site and/or Services available through this Site after such modifications will constitute
your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.

Contact Information
My Moca welcomes your questions or comments regarding this Statement of Privacy. If you believe that My Moca
has not adhered to this Statement, please contact My Moca at:

Shop LLC
445 West 49th Street
New York, New York 10019

Email Address:
shopstudiosvlog@gmail.com


Telephone number:
917-257-8947

Effective as of August 22, 2020
`;

export default privacyPolicyText;
