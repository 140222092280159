import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { explore } from "../route-link";
import { useActions } from "../hooks/useActions";

export enum ACTION_NAMES {
  institution = "institution",
  artForSale = "artForSale",
  movieTVArt = "movieTVArt",
  artwork = "artwork",
  artist = "artist",
  screen = "screen",
  show = "show",
  post = "post",
  vote = "vote",
}

const removeQueryParams = (): void => {
  const url = new URL(document.URL);
  url.searchParams.delete("actionName");
  url.searchParams.delete("actionParameter");
  window.history.pushState({}, "", url);
};

export const DynamicRoute: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { openModal } = useActions();
  let actionName = searchParams.get("actionName");
  let actionParameter = searchParams.get("actionParameter");
  let screenTitle = searchParams.get("screenTitle");
  const predefinedFilters = searchParams.get("predefinedFilters");
  let ownerId;
  let collectorId;
  let userId;

  if (!actionName || !actionParameter) {
    return <Navigate to={explore} />;
  }

  if (predefinedFilters) {
    try {
      ({ ownerId, collectorId, userId } = JSON.parse(predefinedFilters));
      // eslint-disable-next-line
    } catch {}
  }

  actionName = actionName.replaceAll('"', "");
  actionParameter = actionParameter.replaceAll('"', "");
  screenTitle = screenTitle && screenTitle.replaceAll('"', "");

  switch (actionName) {
    case ACTION_NAMES.artwork:
      removeQueryParams();
      return <Navigate to={`/explore/artworks/${actionParameter}`} />;
    case ACTION_NAMES.artist:
      removeQueryParams();
      return <Navigate to={`/explore/artists/${actionParameter}`} />;
    case ACTION_NAMES.show:
    case ACTION_NAMES.institution:
      openModal();
      return <Navigate to="/explore" />;
    case ACTION_NAMES.screen:
      switch (actionParameter) {
        case "artworks map screen":
          openModal();
          return <Navigate to="/explore" />;
        case "Vote screen":
          return <Navigate to="/explore/vote" />;
        case "artworks screen":
          switch (screenTitle) {
            case "Art For Sale":
              if (predefinedFilters) {
                removeQueryParams();
                return <Navigate to={`/explore/artists/${userId}/?tab=artworksForSale`} />;
              }
              return <Navigate to="/explore/buy" />;
            case "Art for Movies/TV":
              return <Navigate to="/explore/movie-tv" />;
            case "Artworks":
              return <Navigate to="/explore/artworks" />;
            case "Created":
              removeQueryParams();
              return <Navigate to={`/explore/artists/${ownerId}/?tab=createdArtworks`} />;
            case "Art collection":
            case "Collected":
              removeQueryParams();
              return <Navigate to={`/explore/artists/${collectorId}/?tab=collectedArtworks`} />;
            case "Found":
              removeQueryParams();
              return <Navigate to={`/explore/artists/${ownerId}/?tab=foundArtworks`} />;
            default:
              return <Navigate to="/explore" />;
          }
        case "show details screen":
          return <Navigate to="/explore/submit-art" />;
        case "institutions screen":
          switch (screenTitle) {
            case "AVAILABLE MOVIE AND TV LOCATIONS":
              return <Navigate to="/explore/location" />;
            case "Institutions":
              return <Navigate to="/explore/institutions" />;
            case "VENUES FOR EVENTS/SHOWS":
              return <Navigate to="/explore/venues" />;
            default:
              return <Navigate to="/explore" />;
          }
        case "shows screen":
          return <Navigate to="/explore/shows" />;
        default:
          return <Navigate to="/explore" />;
      }
    case "post":
      openModal();
      return <Navigate to="/explore" />;
    case "vote":
    default:
      return <Navigate to={explore} />;
  }
};
