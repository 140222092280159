import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkNoticeAudited from "./audited-components/ArtworkNoticeAudited/ArtworkNoticeAudited";
import { IShowExhibitions } from "../../types/exhibitions";
import { IImage } from "../../types/imageData";

interface AcceptArtworkSubmissionNotificationProps {
  nameFrom: string;
  userId: string;
  time: Date;
  artwork: IArtwork;
  exhibition: IShowExhibitions;
  userImage: IImage;
}

const AcceptArtworkSubmissionNotification: React.FC<AcceptArtworkSubmissionNotificationProps> = ({
  userId,
  nameFrom,
  time,
  artwork,
  exhibition,
  userImage,
}): JSX.Element => {
  const isShowVirtual = exhibition?.owner?.institutions?.length || !exhibition?.location;
  return (
    <div className="notification_grid">
      <div className="notification_text">
        <Link
          className="notification_link"
          to={`${explore}/${artists}/${userId}`}
          state={{ name: nameFrom, image: userImage }}
        >
          {nameFrom}
        </Link>{" "}
        accepted an artwork to{isShowVirtual ? " virtual show" : ""}{" "}
        <strong>{exhibition.title}</strong>
      </div>
      <ArtworkNoticeAudited artwork={artwork} time={time} />
    </div>
  );
};

export default AcceptArtworkSubmissionNotification;
