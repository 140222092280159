import { IChat, IMessage } from "../../types/chat";
import { IUser } from "../../types/userTypes";

export type MessagesStage = "chats-list" | "chat" | "search-users";

export interface MessagesInfoState {
  showMessagesModal: boolean;
  unreadMessagesCount: number;
  chatsSearchValue: string;
  chatsSearchOffset: number;
  chats: IChat[];
  hasMore: boolean;
  offset: number;
  isLoading: boolean;
  currentStage: MessagesStage;
  currentChat: IChat | null;
  currentMessages: IMessage[];
  currentScroll: number | undefined;
  currentChatOffset: number;
  messageToSend: any;
  chatsSearchResults: IUser[];
  chatsListScroll: number;
  chatsPrevStage: MessagesStage;
  defaultSearchResults: IUser[];
  isOnArtwork: boolean;
  followingsLoading: boolean;
  messagesToSend: any[];
  isMessageSending: boolean;
}

export enum MessagesInfoActionTypes {
  SET_SHOW_MESSAGES_MODAL = "SET_SHOW_MESSAGES_MODAL",
  SET_UNREAD_MESSAGES_COUNT = "SET_UNREAD_MESSAGES_COUNT",
  SET_CHATS = "SET_CHATS",
  SET_HAS_MORE = "SET_HAS_MORE",
  SET_OFFSET = "SET_OFFSET",
  SET_LOADING = "SET_LOADING",
  INCREMENT_UNREAD_MESSAGES = "INCREMENT_UNREAD_MESSAGES",
  SET_CURRENT_STAGE = "SET_CURRENT_STAGE",
  SET_CURRENT_CHAT = "SET_CURRENT_CHAT",
  SET_CURRENT_SCROLL = "SET_CURRENT_SCROLL",
  SET_CURRENT_MESSAGES = "SET_CURRENT_MESSAGES",
  SET_CURRENT_CHAT_OFFSET = "SET_CURRENT_CHAT_OFFSET",
  SET_MESSAGE_TO_SEND = "SET_MESSAGE_TO_SEND",
  SET_CHATS_SEARCH_VALUE = "SET_CHATS_SEARCH_VALUE",
  SET_CHATS_SEARCH_RESULTS = "SET_CHATS_SEARCH_RESULTS",
  SET_CHATS_SEARCH_OFFSET = "SET_CHATS_SEARCH_OFFSET",
  SET_CHATS_LIST_SCROLL = "SET_CHATS_LIST_SCROLL",
  SET_CHATS_PREV_STAGE = "SET_CHATS_PREV_STAGE",
  SET_DEFAULT_SEARCH_RESULTS = "SET_DEFAULT_SEARCH_RESULTS",
  SET_IS_ON_ARTWORK = "SET_IS_ON_ARTWORK",
  SET_FOLLOWINGS_LOADING = "SET_FOLLOWINGS_LOADING",
  SET_MESSAGE_UNREAD_STATUS = "SET_MESSAGE_UNREAD_STATUS",
  SET_MESSAGES_TO_SEND = "SET_MESSAGES_TO_SEND",
  SET_IS_MESSAGE_SENDING = "SET_IS_MESSAGE_SENDING",
  SHIFT_MESSAGES_TO_SEND = "SHIFT_MESSAGES_TO_SEND",
}

interface setShowMessagesMenu {
  type: MessagesInfoActionTypes.SET_SHOW_MESSAGES_MODAL;
  payload: boolean;
}

interface messageInfoAction {
  type: MessagesInfoActionTypes.SET_UNREAD_MESSAGES_COUNT;
  payload: number;
}

interface incrementUnreadMessagesAction {
  type: MessagesInfoActionTypes.INCREMENT_UNREAD_MESSAGES;
}

interface setChatsInfoAction {
  type: MessagesInfoActionTypes.SET_CHATS;
  payload: IChat[];
}

interface setHasMoreChats {
  type: MessagesInfoActionTypes.SET_HAS_MORE;
  payload: boolean;
}

interface setChatsOffset {
  type: MessagesInfoActionTypes.SET_OFFSET;
  payload: number;
}

interface setChatsLoading {
  type: MessagesInfoActionTypes.SET_LOADING;
  payload: boolean;
}

interface setCurrentStage {
  type: MessagesInfoActionTypes.SET_CURRENT_STAGE;
  payload: MessagesStage;
}

interface setCurrentMessages {
  type: MessagesInfoActionTypes.SET_CURRENT_MESSAGES;
  payload: IMessage[];
}

interface setCurrentChat {
  type: MessagesInfoActionTypes.SET_CURRENT_CHAT;
  payload: IChat | null;
}

interface setCurrentScroll {
  type: MessagesInfoActionTypes.SET_CURRENT_SCROLL;
  payload: number | undefined;
}

interface setCurrentChatOffset {
  type: MessagesInfoActionTypes.SET_CURRENT_CHAT_OFFSET;
  payload: number;
}

interface setMessageToSend {
  type: MessagesInfoActionTypes.SET_MESSAGE_TO_SEND;
  payload: any;
}

interface setChatsSearchValue {
  type: MessagesInfoActionTypes.SET_CHATS_SEARCH_VALUE;
  payload: string;
}

interface setChatsSearchResults {
  type: MessagesInfoActionTypes.SET_CHATS_SEARCH_RESULTS;
  payload: IUser[];
}

interface setChatsSearchOffset {
  type: MessagesInfoActionTypes.SET_CHATS_SEARCH_OFFSET;
  payload: number;
}

interface setChatsListScroll {
  type: MessagesInfoActionTypes.SET_CHATS_LIST_SCROLL;
  payload: number;
}

interface setChatsPrevStage {
  type: MessagesInfoActionTypes.SET_CHATS_PREV_STAGE;
  payload: MessagesStage;
}

interface setDefaultSearchResults {
  type: MessagesInfoActionTypes.SET_DEFAULT_SEARCH_RESULTS;
  payload: IUser[];
}

interface setIsOnArtwork {
  type: MessagesInfoActionTypes.SET_IS_ON_ARTWORK;
  payload: boolean;
}

interface setFollowingsLoading {
  type: MessagesInfoActionTypes.SET_FOLLOWINGS_LOADING;
  payload: boolean;
}

interface setMessageUnreadStatus {
  type: MessagesInfoActionTypes.SET_MESSAGE_UNREAD_STATUS;
  payload: string[];
}

interface setMessagesToSend {
  type: MessagesInfoActionTypes.SET_MESSAGES_TO_SEND;
  payload: any[];
}

interface setIsMessageSending {
  type: MessagesInfoActionTypes.SET_IS_MESSAGE_SENDING;
  payload: boolean;
}

interface shiftMessagesToSend {
  type: MessagesInfoActionTypes.SHIFT_MESSAGES_TO_SEND;
}

export type MessagesInfoAction =
  | setShowMessagesMenu
  | messageInfoAction
  | setChatsInfoAction
  | setHasMoreChats
  | setChatsOffset
  | setChatsLoading
  | incrementUnreadMessagesAction
  | setCurrentMessages
  | setCurrentStage
  | setCurrentChat
  | setCurrentScroll
  | setCurrentChatOffset
  | setMessageToSend
  | setChatsSearchValue
  | setChatsSearchResults
  | setChatsSearchOffset
  | setChatsListScroll
  | setChatsPrevStage
  | setDefaultSearchResults
  | setIsOnArtwork
  | setFollowingsLoading
  | setMessageUnreadStatus
  | setMessagesToSend
  | setIsMessageSending
  | shiftMessagesToSend;
