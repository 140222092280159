import React, { useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery, useLazyQuery } from "@apollo/client";
import classNames from "classnames";

import LeafletMap from "../leaflet-maps/LeafletMap";
import CardItem from "../card-item/CardItem";
import { InstitutionRequests } from "../../api-queries/AllRequests";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { IFilterTypes } from "../../types/filterTypes";
import ItemsNotFound from "../items-not-found/ItemsNotFound";

import {
  SET_CATEGORIES,
  SET_FILTER_QUERY_PARAMS,
  SET_FILTER_RESULTS,
  SET_INSTITUTIONS,
  SET_LOCATION_AUTOCOMPLETE_VALUE,
  SET_LOCATION_SORT_VALUE,
  SET_OFFSET,
} from "../../context/action-creators/institutionPageActions";
import LocationFilterSelect from "../location-filter-select/LocationFilterSelect";
import { initialLocationSortValue } from "../location-filter-select/utils";
import ExplorePageTitle from "../explore-page-title/ExplorePageTitle";

import { pluralize } from "../../utils/pluralizeWord";
import FilterCategoriesSelect from "../filter-categories-select/FilterCategoriesSelect";
import "./InstitutionComponent.scss";

const InstitutionComponent = ({
  availableForEvents,
  availableForFilmAndTV,
  isVirtual,
  context,
  title = "",
  text = "",
  showMap = true,
}) => {
  const limit = 50;
  const {
    myInstitutions,
    categories,
    filterQueryParams,
    offset,
    locationSortValue,
    locationAutocompleteValue,
  } = context.state;
  const { filterWindowOpen } = useTypedSelector(state => state.filterReducer);
  const { openModal } = useActions();
  const [loadCategoties] = useLazyQuery(InstitutionRequests.INSTITUTION_CATEGORIES);
  const { data: searchInstitutions, refetch } = useQuery(InstitutionRequests.SEARCH_INSTITUTIONS, {
    variables: {
      text,
      limit,
      availableForFilmAndTV,
      availableForEvents,
      isVirtual,
      ...filterQueryParams,
    },
  });

  const total = searchInstitutions?.searchInstitutions.meta.total;
  const { dispatch } = context;
  const setCategories = payload => dispatch(SET_CATEGORIES(payload));
  const setfilterQueryParams = payload => dispatch(SET_FILTER_QUERY_PARAMS(payload));
  const setOffset = payload => dispatch(SET_OFFSET(payload));
  const setFilterResults = payload => dispatch(SET_FILTER_RESULTS(payload));
  const setInstitutions = payload => dispatch(SET_INSTITUTIONS(payload));
  const setLocationSortValue = payload => dispatch(SET_LOCATION_SORT_VALUE(payload));
  const setLocationAutocompleteValue = payload =>
    dispatch(SET_LOCATION_AUTOCOMPLETE_VALUE(payload));

  const initialCategoriesId = useMemo(
    () => categories.filter(el => el.checked).map(el => el.id),
    [categories],
  );

  const [chekedCategoriesId, setChekedCategoriesId] = React.useState(initialCategoriesId || []);

  const handleChangeCategories = event => {
    const {
      target: { value },
    } = event;

    setOffset(0);
    setInstitutions([]);

    if (value.includes("clear")) {
      setChekedCategoriesId([]);
      setfilterQueryParams({
        ...filterQueryParams,
        kategories: undefined,
      });
      return;
    }

    const newChecked = typeof value === "string" ? value.split(",") : value;
    const newCategories = newChecked.length ? newChecked : undefined;
    setChekedCategoriesId(newChecked);
    setCategories(
      categories.map(category => {
        return newChecked.includes(category.id)
          ? { ...category, checked: true }
          : { ...category, checked: false };
      }),
    );
    setfilterQueryParams({
      ...filterQueryParams,
      kategories: newCategories,
    });
  };

  const getCategories = async () => {
    const res = await loadCategoties();
    const defaultCategories = res.data.kategories.map(category => {
      return {
        id: category._id,
        type: IFilterTypes.Categories,
        checked: false,
        value: category.name,
      };
    });
    setCategories(categories.length ? categories : defaultCategories);
  };

  useEffect(() => {
    if (!myInstitutions.length) {
      setInstitutions([
        ...myInstitutions,
        ...(searchInstitutions?.searchInstitutions?.institutions || []),
      ]);
    }
  }, [searchInstitutions]);

  useEffect(() => {
    if (!categories.length) {
      getCategories();
    }
  }, []);

  const scrollHandler = async () => {
    if (total < limit) {
      setOffset(0);
      refetch({ offset: 0 });
      return;
    }
    if (myInstitutions.length >= 50) {
      setOffset(offset + limit);
      const res = await refetch({ offset: offset + limit });
      setInstitutions([...myInstitutions, ...(res.data?.searchInstitutions?.institutions || [])]);
    }
  };

  const backToAllItems = () => {
    setInstitutions([]);
    setFilterResults([]);
    setOffset(0);
    setChekedCategoriesId([]);
    setLocationSortValue(initialLocationSortValue);
    setfilterQueryParams({
      offset: 0,
      kategories: undefined,
      location: undefined,
    });
  };

  const exhibitions = myInstitutions.reduce((acc, prev) => {
    if (prev.exhibitions.length) acc.push(...prev.exhibitions);
    return acc;
  }, []);

  return (
    <div className="venues wrapper">
      {title && <ExplorePageTitle title={title} myMoca shareMark />}
      {showMap && <LeafletMap zoom={3} exhibitions={exhibitions} setIsOpen={openModal} />}
      <div className="filter_top_bar">
        <FilterCategoriesSelect
          handleChange={handleChangeCategories}
          options={categories}
          chekedOptions={chekedCategoriesId}
        />
        <LocationFilterSelect
          width="350px"
          setDataItem={setInstitutions}
          locationAutocompleteValue={locationAutocompleteValue}
          setLocationAutocompleteValue={setLocationAutocompleteValue}
          locationSortValue={locationSortValue}
          setLocationSortValue={setLocationSortValue}
          setOffset={setOffset}
          filterQueryParams={filterQueryParams}
          setFilterQueryParams={setfilterQueryParams}
        />
      </div>
      <div
        className={classNames("explore_grid", {
          active: filterWindowOpen,
        })}
      >
        <div className="explore_list">
          <div className="filter_results__content">
            <div className="filter_results__left_content">
              {!!total && (
                <span className="filter_results__result">
                  {total?.toLocaleString()} {pluralize("Institution", total)}
                </span>
              )}
            </div>
          </div>
          {total === 0 && <ItemsNotFound title="No Institutions found" onClick={backToAllItems} />}
          {myInstitutions && (
            <InfiniteScroll
              scrollThreshold={0.7}
              dataLength={myInstitutions.length}
              next={scrollHandler}
              hasMore
            >
              <div className="card_wrap">
                {myInstitutions.map(institution => (
                  <CardItem
                    key={institution._id}
                    img={institution.owner?.profile?.image}
                    name={institution.name || institution.title}
                    tag="Link"
                    id={institution._id}
                    location={institution?.exhibitions[0]?.location}
                    data={institution}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstitutionComponent;
