import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

interface DialogWindowProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  title?: string;
  className: string;
}

const DialogWindow: FC<DialogWindowProps> = ({
  open,
  handleClose,
  children,
  title = "",
  className,
}) => {
  return (
    <Dialog className={className} fullWidth onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
};

export default DialogWindow;
