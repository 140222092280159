import { LIKE } from "./LikeRequestParams";
import { IMAGE } from "./ImageRequestParams";
import { COMMENT } from "./CommentRequestParams";
import { POST_AUDITED } from "./PostAuditedRequestParams";
import { LOCATION } from "./LocationRequestParams";

export const POST = `
  _id
  imagesOfPost {
    ${IMAGE}
  }
  text
  isLiked
  likesCount
  sharesCount
  commentsCount
  likes {
    ${LIKE}
  }
  comments {
    ${COMMENT}
  }
  createdAt
  audited {
    ${POST_AUDITED}
  }
  shareLocation {
    ${LOCATION}
  }
  owner {
    _id
    likedArtworks
    hasInstitution
    institutions {
      name
    }
    profile {
      image {
        ${IMAGE}
      }
      firstName
      secondName
      middleName
    }
  }
`;
