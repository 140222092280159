import gql from "graphql-tag";
import { IMAGE } from "./request-params/ImageRequestParams";
import { LOCATION } from "./request-params/LocationRequestParams";
import { USER_PROFILE } from "./request-params/UserProfileRequestParams";

const CHAT = `
_id
updatedAt
participants {
  unreadCount
  participant {
    _id
    hasInstitution
    institutions {
      name
    }
    profile {
      ${USER_PROFILE}
    }
  }
}
messages {
  text
}
`;

const POST_LITE = `
  _id
  imagesOfPost {
    ${IMAGE}
  }
  text
  isLiked
  likesCount
  sharesCount
  shareLocation {
    ${LOCATION}
  }
  commentsCount
  createdAt
  owner {
    _id
    likedArtworks
    hasInstitution
    institutions {
      name
    }
    profile {
      image {
        ${IMAGE}
      }
      firstName
      secondName
      middleName
    }
  }
`;

const MESSAGE = `
_id
audited {
  user {
    _id
    hasInstitution
    institutions {
      name
    }
    profile {
      firstName
      middleName
      secondName
      image {
        ${IMAGE}
      }
    }
  }
  exhibition {
    _id
    title
    image {
      ${IMAGE}
    }
  }
  artwork {
    _id
    artistName
    name
    image {
      ${IMAGE}
    }
    createdAt
  }
  post {
    ${POST_LITE}
  }
}
owner {
  _id  
  profile {
    firstName
    middleName
    secondName
    image {
      ${IMAGE}
    }
  }
}
recipient {
  _id
  hasInstitution
  profile {
    firstName
    middleName
    secondName
    image {
      ${IMAGE}
    }
  }
}
text
read
imagesOfChat {
  ${IMAGE}
}
updatedAt
createdAt
`;

export const MESSAGES_UNREAD_COUNT = `
query listChat(
  $lastSyncedAt: searchDate
  $offset: Int
) {
  listChat(
    lastSyncedAt: $lastSyncedAt
    limit: 20
    offset: $offset
    sortBy: "updatedAt"
    orderBy: desc
  ) {
    chats {
      participants {
        unreadCount
        participant {
          _id
        }
      }
    }
  }
}`;

export const SEARCH_CHATS = `
query listChat(
  $lastSyncedAt: searchDate
  $limit: Int
  $offset: Int
) {
  listChat(
    lastSyncedAt: $lastSyncedAt
    limit: $limit
    offset: $offset
    sortBy: "updatedAt"
    orderBy: desc
  ) {
    chats {
      ${CHAT}
    }
    meta {
      total
    }
  }
}`;

export const SEARCH_CHATS_GQL = gql`
query listChat(
  $lastSyncedAt: searchDate
  $limit: Int
  $offset: Int
) {
  listChat(
    lastSyncedAt: $lastSyncedAt
    limit: $limit
    offset: $offset
    sortBy: "updatedAt"
    orderBy: desc
  ) {
    chats {
      ${CHAT}
    }
    meta {
      total
    }
  }
}`;

export const SEND_MESSAGE = `
  mutation addChatMessage(
    $text: String!
    $recipientId: ID!
    $audited: AuditedInput
    $imagesOfChat: [ImageInput]
  ) {
    addChatMessage(
      text: $text
      recipientId: $recipientId
      audited: $audited
      imagesOfChat: $imagesOfChat
    ) {
      _id
    }
  }
`;

export const CHAT_MESSAGES = gql`
query listChatMessages(
  $lastSyncedAt: searchDate
  $limit: Int
  $offset: Int
  $chatId: ID
  $recipientId: ID
) {
  listChatMessages(
    lastSyncedAt: $lastSyncedAt
    limit: $limit
    offset: $offset
    chatId: $chatId
    recipientId: $recipientId
  ) {
    messages {
      ${MESSAGE}
    }
    meta {
      total
      lastSyncedAt
    }
  }
}`;
