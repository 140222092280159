import { IMAGE } from "./ImageRequestParams";
import { EXHIBITION_LITE } from "./ExhibitionLiteRequestParams";
import { ARTWORK_LITE } from "./ArtworkLiteRequestParams";
import { POST_LITE } from "./PostLiteRequestParams";

export const POST_AUDITED = `
  exhibition {
    owner {
      _id
      hasInstitution
      institutions {
        name
      }
      profile {
        image {
          ${IMAGE}
        }
        firstName
        secondName
        middleName
      }
    }
    ${EXHIBITION_LITE}
  }
  artwork {
    ${ARTWORK_LITE}
  }
  post {
    ${POST_LITE}
  }
`;
