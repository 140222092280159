import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { getCurrentPosition } from "../../explore-pages/artists-page/utils";
import { ReturnActionType } from "../../store/types/return-type";
import { ICoordinates } from "../../types/addresTypes";
import { ILocationSearch } from "../../types/locationSearch";

export enum LocationFilterNames {
  ANYWHERE = "Anywhere",
  NEAR_CURRENT_LOCATION = "Near current location",
  SELECT_LOCATION = "Select location",
}

export interface ILocationSelectValue {
  name: string;
  value: string;
}

export const locationFilters = [
  {
    name: LocationFilterNames.NEAR_CURRENT_LOCATION,
    value: LocationFilterNames.NEAR_CURRENT_LOCATION,
  },
  {
    name: LocationFilterNames.SELECT_LOCATION,
    value: LocationFilterNames.SELECT_LOCATION,
  },
  {
    name: LocationFilterNames.ANYWHERE,
    value: LocationFilterNames.ANYWHERE,
  },
];

export const initialLocationSortValue =
  locationFilters.find(item => item.name === LocationFilterNames.ANYWHERE) || locationFilters[2];

export const getCoordinates = async (value: any): Promise<ICoordinates | null> => {
  try {
    const geocodes = await geocodeByAddress(value.label);
    const coordinates = await getLatLng(geocodes[0]);
    return { latitude: coordinates.lat, longitude: coordinates.lng };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getUserLocationCoords = async (
  currentLocation: ICoordinates | undefined,
  setIsPositionLoading: (param: boolean) => void,
  setCurrentLocation: (param: ICoordinates) => ReturnActionType,
  setLocationSortValue: (param: ILocationSelectValue) => void,
  locationSortValue: ILocationSelectValue,
  setDataItems: (param: any[]) => void,
  setOffset: (param: number) => void,
  setFilterQueryParams: (param: any) => void,
  filterQueryParams: any,
): Promise<ILocationSearch | void> => {
  if (!currentLocation) {
    setIsPositionLoading(true);
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      setCurrentLocation({ latitude, longitude });
      setIsPositionLoading(false);
      setDataItems([]);
      setOffset(0);
      setFilterQueryParams({
        ...filterQueryParams,
        location: { latitude, longitude, radius: 10 },
        offset: 0,
      });

      return;
    } catch (err) {
      setLocationSortValue(locationSortValue);
      setIsPositionLoading(false);
      alert("Please allow location use");

      return;
    }
  }
  setDataItems([]);
  setOffset(0);
  setFilterQueryParams({
    ...filterQueryParams,
    location: { ...currentLocation, radius: 10 },
    offset: 0,
  });
};
