import { IUser } from "../../types/userTypes";
import { IUserCounters, UserActionTypes, UserType } from "../types/user.type";

export const setUser = (payload: IUser | null): UserType => ({
  type: UserActionTypes.ADD_USER,
  payload,
});

export const setUserCounters = (payload: IUserCounters | null): UserType => ({
  type: UserActionTypes.SET_USER_COUNTERS,
  payload,
});
