import React from "react";
import Button from "@mui/material/Button";

import "./ItemsNotFound.scss";

interface ItemsNotFoundProps {
  title: string;
  onClick?: () => void;
  button?: boolean;
}

const ItemsNotFound: React.FC<ItemsNotFoundProps> = ({ title, onClick, button }) => {
  return (
    <div className="items_not__found">
      <div className="items_not__found__title">{title}</div>
      {button && (
        <Button onClick={onClick} className="items_not__found__button" variant="contained">
          Back to all {title}
        </Button>
      )}
    </div>
  );
};

export default ItemsNotFound;
