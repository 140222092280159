/* eslint-disable no-case-declarations */
import {
  MessagesInfoAction,
  MessagesInfoState,
  MessagesInfoActionTypes,
} from "../types/messages-info";

const initialValue: MessagesInfoState = {
  showMessagesModal: false,
  unreadMessagesCount: 0,
  chats: [],
  hasMore: true,
  offset: 0,
  isLoading: false,
  currentStage: "chats-list",
  currentChat: null,
  currentMessages: [],
  currentScroll: 0,
  currentChatOffset: 0,
  messageToSend: null,
  chatsSearchValue: "",
  chatsSearchOffset: 0,
  chatsSearchResults: [],
  chatsListScroll: 0,
  chatsPrevStage: "chats-list",
  defaultSearchResults: [],
  isOnArtwork: false,
  followingsLoading: true,
  messagesToSend: [],
  isMessageSending: false,
};

const MessagesInfoReducer = (
  state = initialValue,
  action: MessagesInfoAction,
): MessagesInfoState => {
  switch (action.type) {
    case MessagesInfoActionTypes.SET_SHOW_MESSAGES_MODAL:
      return { ...state, showMessagesModal: action.payload };
    case MessagesInfoActionTypes.SET_UNREAD_MESSAGES_COUNT:
      return { ...state, unreadMessagesCount: action.payload };
    case MessagesInfoActionTypes.SET_CHATS:
      return { ...state, chats: action.payload };
    case MessagesInfoActionTypes.SET_HAS_MORE:
      return { ...state, hasMore: action.payload };
    case MessagesInfoActionTypes.SET_OFFSET:
      return { ...state, offset: action.payload };
    case MessagesInfoActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case MessagesInfoActionTypes.INCREMENT_UNREAD_MESSAGES:
      return { ...state, unreadMessagesCount: state.unreadMessagesCount + 1 };
    case MessagesInfoActionTypes.SET_CURRENT_CHAT:
      return { ...state, currentChat: action.payload };
    case MessagesInfoActionTypes.SET_CURRENT_MESSAGES:
      return { ...state, currentMessages: action.payload };
    case MessagesInfoActionTypes.SET_CURRENT_STAGE:
      return { ...state, currentStage: action.payload };
    case MessagesInfoActionTypes.SET_CURRENT_SCROLL:
      return { ...state, currentScroll: action.payload };
    case MessagesInfoActionTypes.SET_CURRENT_CHAT_OFFSET:
      return { ...state, currentChatOffset: action.payload };
    case MessagesInfoActionTypes.SET_MESSAGE_TO_SEND:
      return { ...state, messageToSend: action.payload };
    case MessagesInfoActionTypes.SET_CHATS_SEARCH_VALUE:
      return { ...state, chatsSearchValue: action.payload };
    case MessagesInfoActionTypes.SET_CHATS_SEARCH_RESULTS:
      return { ...state, chatsSearchResults: action.payload };
    case MessagesInfoActionTypes.SET_CHATS_SEARCH_OFFSET:
      return { ...state, chatsSearchOffset: action.payload };
    case MessagesInfoActionTypes.SET_CHATS_LIST_SCROLL:
      return { ...state, chatsListScroll: action.payload };
    case MessagesInfoActionTypes.SET_CHATS_PREV_STAGE:
      return { ...state, chatsPrevStage: action.payload };
    case MessagesInfoActionTypes.SET_DEFAULT_SEARCH_RESULTS:
      return { ...state, defaultSearchResults: action.payload };
    case MessagesInfoActionTypes.SET_IS_ON_ARTWORK:
      return { ...state, isOnArtwork: action.payload };
    case MessagesInfoActionTypes.SET_FOLLOWINGS_LOADING:
      return { ...state, followingsLoading: action.payload };
    case MessagesInfoActionTypes.SET_MESSAGES_TO_SEND:
      return { ...state, messagesToSend: action.payload };
    case MessagesInfoActionTypes.SET_IS_MESSAGE_SENDING:
      return { ...state, isMessageSending: action.payload };
    case MessagesInfoActionTypes.SHIFT_MESSAGES_TO_SEND:
      const newMessagesToSend = [...state.messagesToSend];
      newMessagesToSend.shift();
      return { ...state, messagesToSend: newMessagesToSend };
    case MessagesInfoActionTypes.SET_MESSAGE_UNREAD_STATUS:
      const newMessages = state.currentMessages.map(message => {
        if (message._id === action.payload[0]) {
          return { ...message, read: false, _id: action.payload[1] };
        }
        return message;
      });
      return { ...state, currentMessages: newMessages };
    default:
      return state;
  }
};
export default MessagesInfoReducer;
