import React, { FC } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

import { IChatMessageContentProps } from "../utils";
import { artworks, explore } from "../../../route-link";

import "./ArtworkMessage.scss";
import { useArtworksSldrCntx } from "../../../context/artworksSldrCntx";
import { useActions } from "../../../hooks/useActions";

interface IArtworkMessageProps extends IChatMessageContentProps {
  saveCurrentScroll: () => void;
}

const ArtworkMessage: FC<IArtworkMessageProps> = ({ message, saveCurrentScroll }) => {
  const navigate = useNavigate();
  const { setIsOnArtwork } = useActions();
  const { setArtworks: setSliderArtworks } = useArtworksSldrCntx();
  const goToArtwork = (): void => {
    saveCurrentScroll();
    setIsOnArtwork(true);
    setSliderArtworks([{ id: message.audited.artwork._id, artwork: message.audited.artwork }]);
    navigate(`/${explore}/${artworks}/${message.audited.artwork._id}`);
  };
  return (
    <div className="artwork-message" onClick={goToArtwork}>
      <div className="chat-message_audited_name">
        <p>{message.audited.artwork.name}</p>
        <ArrowForwardIosIcon sx={{ width: 15, height: 15 }} />
      </div>
      <img className="chat-message_image" src={message.audited.artwork?.image.medium.url} alt="" />
      <p className="chat-message_text">{message.text}</p>
    </div>
  );
};

export default ArtworkMessage;
