import React, { useState } from "react";
import { InputLabel, Modal } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CloseIcon from "@mui/icons-material/Close";
import CropImage from "../../crop-image/CropImage";
import { EditImagesProps } from "../edit.props";
import DialogContent from "../../../UI/dialog-content/DialogContent";
import DialogWindow from "../../dialog-window/DialogWindow";
import { addAdditionalImage } from "../../../api-queries/GraohQlClient";

/**
 * images(state) - array images(type string)
 * setImages - function change images(state)
 */

const EditImages: React.FC<EditImagesProps> = ({
  images,
  setImages,
  titleWindow,
  isEditPage,
  additionalImages,
  setAdditionalImagesToDelete,
  showHeader,
  transferModal,
}): JSX.Element => {
  const [profile, setProfile] = useState("");
  const [imageValue, setImageValue] = useState("");
  const [cropOpenProfile, setCropOpenProfile] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const onChangeProfile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setProfile(URL.createObjectURL(event.target.files[0]));
      setCropOpenProfile(true);
    }
  };
  const imagesToDisplay = !isEditPage ? images : images?.map((el: any) => el?.medium?.url);

  const handleDialogClose = (): void => setDialogOpen(false);

  const handleDialogOpen = (value: string) => () => {
    setImageValue(value);
    setDialogOpen(true);
  };

  const handleClose = (): void => setCropOpenProfile(false);

  const deletePhoto = (): void => {
    if (additionalImages) {
      const image = additionalImages?.find((item: any) => item.medium.url === imageValue);
      if (image) {
        if (setAdditionalImagesToDelete)
          setAdditionalImagesToDelete((prev: string[]) => [...prev, image._id]);
      }
    }
    if (transferModal) {
      setImages(images.filter((item: string) => item !== imageValue));
      handleDialogClose();
      return;
    }
    setImages(images.filter((item: any) => item?.medium?.url !== imageValue));
    handleDialogClose();
  };

  const handleProfileImage = async (value: string): Promise<void> => {
    if (isEditPage) {
      const uploadedImage = await addAdditionalImage(value);
      if (uploadedImage) setImages([...images, uploadedImage]);
      return;
    }
    setImages([...images, value]);
  };

  const titleText = `Do you really want to remove the ${titleWindow}?`;

  return (
    <>
      <DialogWindow
        className="explore_item_details_right__dialog"
        open={dialogOpen}
        handleClose={handleDialogClose}
        title={titleText}
      >
        <DialogContent handleDialogClose={handleDialogClose} handleRemove={deletePhoto} />
      </DialogWindow>
      <div className="register_item">
        {showHeader && (
          <InputLabel variant="standard" htmlFor="add_photo" className="label">
            Additional photos
          </InputLabel>
        )}
        <div className="register_photos__grid">
          <div className="register_add__photo__item register_add__photo">
            <InsertPhotoIcon className="add_photo__svg" />
            <input type="file" className="upload_input__file" onChange={onChangeProfile} />
          </div>
          {!!imagesToDisplay?.length && (
            <div className="register_photos">
              {imagesToDisplay?.map((image: string) => {
                if (!image) return null;
                return (
                  <div key={image} className="register_add__photo__item">
                    <CloseIcon
                      className="register_photo__close"
                      onClick={handleDialogOpen(image)}
                    />
                    <img className="register_photos__item" src={image} alt="" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Modal
          open={cropOpenProfile}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CropImage
            imageSrc={profile || ""}
            cropImage="profile"
            handleProfileImage={handleProfileImage}
            handleClose={() => setCropOpenProfile(false)}
            minCropBoxHeight={50}
            minCropBoxWidth={50}
            cropArray
          />
        </Modal>
      </div>
    </>
  );
};

export default EditImages;
